import {
    isIKraftRequired,
    isLovRefRequired,
    isVertNivaRequired,
    isLovRefLimited,
} from './helpers';

// types
interface FieldValidator {
    field: string;
    validator: Validator;
}

type Validator = (values) => string | undefined;

// validators
export const validatePlanId: Validator = (values) => {
    if (!values.planId) return 'Planid er påkrevd';
};

export const validateKomnr: Validator = (values) => {
    if (!values.komnr) return 'Adm. enhet er påkrevd';
};

export const validateNavn: Validator = (values) => {
    if (!values.planNavn) return 'Plannavn er påkrevd';
    if (values.planNavn.length > 256)
        return 'Plannavn er begrenset til 256 tegn';
};

export const validatePlantypeId: Validator = (values) => {
    if (!values.planTypeId) return 'Plantype er påkrevd';
};

export const validatePlanStatusId: Validator = (values) => {
    if (values.planStatusId == null) return 'Planstatus er påkrevd';
};

export const validateIKraft: Validator = (values) => {
    if (!values.iKraft && isIKraftRequired(values.planStatusId))
        return 'Påkrevd for planstatus 3 og 8';
};

export const validateLovreferanseId: Validator = (values) => {
    if (!values.lovreferanseId && isLovRefRequired(values.planTypeId))
        return 'Lovreferanse er påkrevd';
    if (values.lovreferanseId == 6 && isLovRefLimited(values.planTypeId))
        return 'Lovereferanse kan ikke være 6 pga. plantype';
};

export const validateVertikalniva: Validator = (values) => {
    if (
        (!values.vertikalniva || values.vertikalniva.length == 0) &&
        isVertNivaRequired(values.planTypeId, values.planStatusId)
    ) {
        return 'Vertikalnivå er påkrevd for planstatus 3 og 8';
    }
};

// helpers
const mapError = (field: string, error: string | undefined) => {
    if (error) return {[field]: error};
};

// validate
export default (values) => {
    let errors = {} as any;
    const fieldValidators: FieldValidator[] = [
        {field: 'planId', validator: validatePlanId},
        {field: 'komnr', validator: validateKomnr},
        {field: 'planNavn', validator: validateNavn},
        {field: 'planTypeId', validator: validatePlantypeId},
        {field: 'planStatusId', validator: validatePlanStatusId},
        {field: 'iKraft', validator: validateIKraft},
        {field: 'lovreferanseId', validator: validateLovreferanseId},
        {field: 'vertikalniva', validator: validateVertikalniva},
    ];
    fieldValidators.map(({field, validator}) => {
        errors = {
            ...errors,
            ...mapError(field, validator(values)),
        };
    });
    return errors;
};
