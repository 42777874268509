import {useQuery} from 'react-query';
import {fetchPlanLayerAsync} from '../../features/map/helpers/layerHelpers';
import {PlanLayerState} from '../../features/map/types';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Arealplan} from '../arealplaner/types';

export default function usePlanLayer(plan?: Arealplan) {
    const kundeId = useSelectedKundeId();

    const query = useQuery<PlanLayerState>(
        ['arealplanLag', {kundeId, arealplanId: plan?.id}],
        () => fetchPlanLayerAsync(plan!, kundeId).then((res) => res),
        {enabled: !!plan}
    );
    return query;
}
