import {createContext} from 'react';

type authContextType = {
  clientId: string;
  applicationProfile?: string;
  bearerToken?: string;
};

const authContext = createContext<authContextType>({clientId: 'Test'});

export default authContext;
