import * as React from 'react';
import {connect} from 'react-redux';
import {DialogState} from '../../store/dialog/types';
import {ApplicationState, ConnectedReduxProps} from '../../store';
import DeleteBehandlingDialog from '../../features/behandling/DeleteBehandlingDialog';
import BehandlingFormDialog from '../../features/behandling/BehandlingFormDialog';
import DispFormDialog from '../../features/dispensasjon/DispFormDialog/DispFormDialog';
import DeletePlanDialog from '../../features/plan/DeletePlanDialog';
import ImportDocumentDialog from '../../features/dokumenter/ImportDocumentDialog';
import PlanFormDialog from '../../features/plan/PlanFormDialog';
import RemoveDocumentDialog from '../../features/plan/PlanDocumentList/RemoveDocumentDialog';
import DocumentFormDialog from '../../features/dokumenter/DocumentFormDialog';
import DeleteDocumentDialog from '../../features/dokumenter/DeleteDocumentDialog';
import DocumentDetail from '../DocumentList/DocumentDetail';
import PlanRelationshipFormDialog from '../../features/planforhold/PlanRelationshipFormDialog';
import PlanRelationshipConfirmUpdatePlanDialog from '../../features/planforhold/PlanRelationshipConfirmUpdatePlanDialog';
import DeleteDispDialog from '../../features/dispensasjon/DeleteDispDialog/DeleteDispDialog';
import MapInformationDialog from '../../features/map/Components/InfoDialog/MapInformationDialog';
import SelectBehandlingDialog from '../../features/dokumenter/MoveDocument/DocumentSelectBehandlingFormDialog';
import MoveDocumentDialog from '../../features/dokumenter/MoveDocument/MoveDocumentFormDialog';
import DocumentListDialog from '../../features/dokumenter/DocumentListDialog';
import RearrangeDocumentDialog from '../../features/dokumenter/RearrangeDocumentDialog';
import ImportDocumentFromArchiveDialog from '../../features/dokumenter/ImportDocumentFromArchive/ImportDocumentFromArchiveDialog';
import PlanDescriptionDialog from '../../features/plan/PlanDescription/PlanDescriptionDialog';

const DIALOG_COMPONENTS = {
    DELETE_PLAN: DeletePlanDialog,
    EDIT_PLAN: PlanFormDialog,
    DELETE_BEHANDLING: DeleteBehandlingDialog,
    EDIT_BEHANDLING: BehandlingFormDialog,
    CREATE_DISP: DispFormDialog,
    EDIT_DISP: DispFormDialog,
    DELETE_DISP: DeleteDispDialog,
    IMPORT_DOCUMENTS: ImportDocumentDialog,
    IMPORT_DOCUMENTS_FROM_ARCHIVE: ImportDocumentFromArchiveDialog,
    CONFIRM_REMOVE_DOCUMENT: RemoveDocumentDialog,
    EDIT_DOKUMENT: DocumentFormDialog,
    DELETE_DOKUMENT: DeleteDocumentDialog,
    DOKUMENT_DETAIL: DocumentDetail,
    EDIT_PLAN_RELATIONSHIPS: PlanRelationshipFormDialog,
    CONFIRM_PLAN_STATUS_CHANGE: PlanRelationshipConfirmUpdatePlanDialog,
    OPEN_MAP_INFORMATION: MapInformationDialog,
    SELECT_BEHANDLING: SelectBehandlingDialog,
    MOVE_DOCUMENTS: MoveDocumentDialog,
    DOKUMENT_LIST: DocumentListDialog,
    REARRANGE_DOCUMENTS: RearrangeDocumentDialog,
    EDIT_DESCRIPTION: PlanDescriptionDialog,
};

export type DialogType = keyof typeof DIALOG_COMPONENTS;

export interface DialogRootProps extends DialogState, ConnectedReduxProps {}

const DialogRoot = ({dialogType, dialogProps, ...props}: DialogRootProps) => {
    if (!dialogType) {
        return null;
    }

    const SpecificDialog = DIALOG_COMPONENTS[dialogType];
    return <SpecificDialog {...dialogProps} {...props} />;
};

export default connect((state: ApplicationState) => state.dialog)(DialogRoot);
