import * as React from 'react';
import {useQuery} from 'react-query';
import {format} from 'date-fns';
import {useFetchTokenHeaderWithAuthData} from '@norkart/nk-auth';
import {graphQLRequest} from '../../services/api';
import {PlanforholdQueryVariables} from '../../features/reports/Reports/Planforhold/Planforhold';
import {PlanforholdCsv, PlanforholdReport} from './types';
import {planforholdCsvQuery} from './planforholdQueries';

export function usePlanforholdQLCsv(variables: PlanforholdQueryVariables) {
    const headings = [
        'PlanforholdTypeId',
        'PlanforholdTypeForloper',
        'PlanforholdTypeEtterkommer',
        'PlanIdForloper',
        'PlannavnForloper',
        'PlantypeTypeForløper',
        'PlanStatusForløper',
        'IKraftForloper',
        'PlanIdEtterkommer',
        'PlannavnEtterkommer',
        'PlantypeTypEtterkommer',
        'PlanStatusEtterkommer',
        'IKraftEtterkommer',
    ];
    const getHeaders = useFetchTokenHeaderWithAuthData();

    const reactQuery = useQuery<any>(
        ['planforholdReportCsv'],
        async () => {
            const headers = await getHeaders();
            return await graphQLRequest(
                planforholdCsvQuery,
                variables,
                headers
            );
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
            cacheTime: 0,
            keepPreviousData: false,
        }
    );

    const csvArray = React.useMemo(() => {
        if (reactQuery.data) {
            const planforhold: PlanforholdReport[] =
                reactQuery.data.planforhold;
            const planforholdCsv: PlanforholdCsv[] = planforhold.map((d) => {
                const value: PlanforholdCsv = {
                    PlanforholdTypeId: d.planforholdType
                        ? d.planforholdType?.planforholdtypeId
                        : undefined,
                    PlanforholdTypeForloper: d.planforholdType
                        ? d.planforholdType?.beskrivelseForloper
                        : '',
                    PlanforholdTypEtterkommer: d.planforholdType
                        ? d.planforholdType.beskrivelseEtterkommer
                        : '',
                    PlanIdForloper: d.planIdFraNavigation
                        ? d.planIdFraNavigation?.planId
                        : '',
                    PlannavnForloper: d.planIdFraNavigation
                        ? d.planIdFraNavigation?.planNavn
                        : '',
                    PlanstatusForloper: d.planIdFraNavigation
                        ? d.planIdFraNavigation?.planStatus
                        : '',
                    PlantypeForloper: d.planIdFraNavigation
                        ? d.planIdFraNavigation?.planType
                        : '',
                    IKraftForloper: d.planIdFraNavigation?.iKraft
                        ? format(
                              new Date(d.planIdFraNavigation.iKraft),
                              'dd.MM.yyyy'
                          )
                        : '',
                    PlanIdEtterkommer: d.planIdTilNavigation
                        ? d.planIdTilNavigation?.planId
                        : '',
                    PlannavnEtterkommer: d.planIdTilNavigation
                        ? d.planIdTilNavigation?.planNavn
                        : '',
                    PlanstatusEtterkommer: d.planIdTilNavigation
                        ? d.planIdTilNavigation?.planStatus
                        : '',
                    PlantypeEtterkommer: d.planIdTilNavigation
                        ? d.planIdTilNavigation?.planType
                        : '',
                    IKraftEtterkommer: d.planIdTilNavigation?.iKraft
                        ? format(
                              new Date(d.planIdTilNavigation?.iKraft),
                              'dd.MM.yyyy'
                          )
                        : '',
                };
                return value;
            });
            const newLines = planforholdCsv.reduce((acc: any, d) => {
                acc.push(
                    [
                        d.PlanforholdTypeId,
                        d.PlanforholdTypeForloper,
                        d.PlanforholdTypEtterkommer,
                        d.PlanIdForloper,
                        d.PlannavnForloper,
                        d.PlanstatusForloper,
                        d.PlantypeForloper,
                        d.IKraftForloper,
                        d.PlanIdEtterkommer,
                        d.PlannavnEtterkommer,
                        d.PlanstatusEtterkommer,
                        d.PlantypeEtterkommer,
                        d.IKraftEtterkommer,
                    ].join(';')
                );
                return acc;
            }, []);

            return newLines;
        }
    }, [reactQuery.data]);

    return {...reactQuery, csvData: csvArray, headers: headings};
}
