const downloadFile = ({data, fileName, fileType}) => {
    const blob = new Blob(['\ufeff', data], {
        type: fileType,
        endings: 'native',
    });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const exportToCsv = (
    //e,
    filename: string | undefined,
    data: any,
    headers: string[]
) => {
    //e.preventDefault()

    const csvHeaders = headers.join(';');
    const processedData = data.map((a) =>
        a.replace(/\n/g, ' ').replace(/\r/g, ' ')
    );
    downloadFile({
        data: [csvHeaders, ...processedData].join('\n'),
        fileName: `${filename ? filename : 'data'}.csv`,
        fileType: 'data:text/csv; charset=utf-8',
    });
};
