import {CSSObject, Palette, Theme} from '@mui/material';
import {merge} from 'lodash-es';
import {themeIsLightMode} from '../utils/themeIsLightMode';
import {ToiButtonColor, ToiButtonProps, ToiButtonVariant} from './ToiButton';

/* Have to add focus-style with border on a pseudo element, in order to get correct radius on the dashed outline in safari */
export const focusStyle = (palette: Palette, isLightMode: boolean) => {
  return {
    content: "''",
    display: 'block',
    position: 'absolute',
    top: ' -4px',
    bottom: ' -4px',
    left: ' -4px',
    right: ' -4px',
    borderRadius: ' 4px',
    border: `1px dashed ${isLightMode ? palette.black[120] /* black */ : palette.secondary.main /* White */}`,
    outline: 'none',
  };
};

export function toiButtonStyle({
  theme,
  variant = 'contained',
  size = 'medium',
  color = 'primary',
}: ToiButtonProps & {theme: Theme}): CSSObject {
  const palette = theme.palette;
  const isLightMode = themeIsLightMode(theme);

  const disabled_style = {
    color: isLightMode ? palette.black[70] : palette.black[30],
    backgroundColor: isLightMode ? palette.black[20] : palette.black[70],
  };

  const defaultStyle = {
    borderRadius: '2px',
    fontSize: size === 'large' ? '16px' : '14px',
    textTransform: 'none',
    fontWeight: 500,
    fontFamily: 'Figtree',
    fontStyle: 'normal',
    align: 'center',
    lineHeight: '140%',
    boxShadow: 'none',
    transition: 'none',
    height: 'fit-content',
    position: 'relative',
    padding: size === 'large' ? '12px 24px 12px 24px' : '8px 12px 8px 12px',
    '&:hover, &:focus, &:active, &:target': {
      boxShadow: 'none',
      transition: 'none',
    },
    '&.Mui-focusVisible::before': focusStyle(palette, isLightMode),
  } satisfies CSSObject;

  const variantStyle = (variant: ToiButtonVariant, color: ToiButtonColor) => {
    switch (variant) {
      case 'contained':
        return containedStyle(color);
      case 'outlined':
        return outlinedStyle(color);
      case 'text':
        return textStyle(color);
    }
  };

  const containedStyle = (color: ToiButtonColor) => {
    const colors = getContainedColors(color);
    return {
      backgroundColor: colors?.background,
      outlineWidth: '1px',
      outlineColor: colors?.background,
      color: colors?.text,
      '&:hover': {
        backgroundColor: colors?.background_hover,
        outlineColor: colors?.background_hover,
      },
      '&.Mui-focusVisible': {
        backgroundColor: colors?.background_focus,
      },
      '&:disabled': {
        ...disabled_style,
      },
    };
  };

  const getContainedColors = (color: ToiButtonColor) => {
    if (color === 'primary' && isLightMode) {
      return {
        text: palette.secondary.main,
        background: palette.primary.main,
        background_hover: palette.primary[120],
        background_focus: palette.primary[120],
      };
    } else if (color === 'primary' && !isLightMode) {
      return {
        text: palette.secondary.main,
        background: palette.primary.main,
        background_hover: palette.primary[120],
        background_focus: palette.primary[120],
      };
    } else if (color === 'secondary' && isLightMode) {
      return {
        text: palette.secondary.main,
        background: palette.black[100],
        background_hover: palette.black[120],
        background_focus: palette.black[120],
      };
    } else if (color === 'secondary' && !isLightMode) {
      return {
        text: palette.black[120],
        background: palette.black[10],
        background_hover: palette.primary[30],
        background_focus: palette.black[10],
      };
    } else if (color === 'error' && isLightMode) {
      return {
        text: palette.secondary.main,
        background: palette.error.main,
        background_hover: palette.error[120],
        background_focus: palette.error[120],
      };
    } else if (color === 'error' && !isLightMode) {
      return {
        text: palette.error.main,
        background: palette.error[10],
        background_hover: palette.error[30],
        background_focus: palette.error[30],
      };
    }
    return {};
  };

  const outlinedStyle = (color: ToiButtonColor) => {
    const colors = getOutlinedColors(color);
    return {
      boxShadow: `inset 0px 0px 0px 1px ${colors?.border}`,
      color: colors?.text,
      border: 'none',
      backgroundColor: colors?.background,
      '&:focus': {
        boxShadow: `inset 0px 0px 0px 1px ${colors?.border}`,
      },
      '&:hover': {
        border: 'none',
        boxShadow: `inset 0px 0px 0px 2px ${colors?.border_hover}`,
        color: colors?.text_hover,
        backgroundColor: colors?.background_hover,
      },
      '&.Mui-focusVisible': {
        boxShadow: `inset 0px 0px 0px 2px ${colors?.border_hover}`,
        color: colors?.text_hover,
        backgroundColor: colors?.background_focus,
      },

      '&:disabled': {
        ...disabled_style,
        boxShadow: 'none',
        border: 'none',
      },
    };
  };

  const getOutlinedColors = (color: ToiButtonColor) => {
    if (color === 'primary' && isLightMode) {
      return {
        text: palette.primary[100],
        text_hover: palette.primary[120],
        text_focus: palette.primary[120],
        border: palette.primary[100],
        border_hover: palette.primary[120],
        border_focus: palette.primary[120],
        background: palette.secondary.main,
        background_hover: palette.secondary.main,
        background_focus: palette.secondary.main,
      };
    } else if (color === 'primary' && !isLightMode) {
      return {
        text: palette.secondary.main, //White
        text_hover: palette.secondary.main, //White
        text_focus: palette.secondary.main, //White
        border: palette.primary[70],
        border_hover: palette.primary[100],
        border_focus: palette.primary[100],
        background: palette.black[100],
        background_hover: palette.black[100],
        background_focus: palette.black[100],
      };
    } else if (color === 'secondary' && isLightMode) {
      return {
        text: palette.black[100],
        text_hover: palette.black[100],
        text_focus: palette.black[100],
        border: palette.black[100],
        border_hover: palette.black[100],
        border_focus: palette.black[100],
        background: palette.secondary.main,
        background_hover: palette.secondary.main,
        background_focus: palette.secondary.main,
      };
    } else if (color === 'secondary' && !isLightMode) {
      return {
        text: palette.secondary.main,
        text_hover: palette.secondary.main,
        text_focus: palette.secondary.main,
        border: palette.secondary.main,
        border_hover: palette.secondary.main,
        border_focus: palette.secondary.main,
        background: palette.black[100],
        background_hover: palette.black[100],
        background_focus: palette.black[100],
      };
    } else if (color === 'error' && isLightMode) {
      return {
        text: palette.error.main,
        text_hover: palette.error[120],
        text_focus: palette.error[120],
        border: palette.error.main,
        border_hover: palette.error[120],
        border_focus: palette.error[120],
        background: palette.secondary.main,
        background_hover: palette.secondary.main,
        background_focus: palette.secondary.main,
      };
    } else if (color === 'error' && !isLightMode) {
      return {
        text: palette.secondary.main,
        text_hover: palette.secondary.main,
        text_focus: palette.secondary.main,
        border: palette.error.main,
        border_hover: palette.error[120],
        border_focus: palette.error[120],
        background: palette.black[100],
        background_hover: palette.black[100],
        background_focus: palette.black[100],
      };
    }
    return {};
  };

  const getTextColors = (color: ToiButtonColor) => {
    if (color === 'primary') {
      return {
        text: isLightMode ? palette.primary[100] : palette.secondary.main,
        background_hover: isLightMode ? palette.primary[20] : palette.black[110],
      };
    }
    if (color === 'secondary') {
      return {
        text: isLightMode ? palette.black[120] : palette.secondary.main,
        background_hover: isLightMode ? palette.black[20] : palette.black[110],
      };
    }
    if (color === 'error') {
      return {
        text: isLightMode ? palette.error.main : palette.error[70],
        background_hover: isLightMode ? palette.error[10] : palette.black[110],
      };
    }
    return {};
  };

  const textStyle = (color: ToiButtonColor) => {
    const colors = getTextColors(color);

    return {
      color: colors?.text,
      '&:hover': {
        backgroundColor: colors?.background_hover,
      },
      '&:disabled': {
        color: palette.black[60],
      },
    };
  };

  return merge(defaultStyle, variantStyle(variant, color));
}
