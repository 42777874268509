import {useMutation, useQueryClient} from 'react-query';
import {
    arealplanerCreateDispenasjon,
    DispensasjonDto,
    DispensasjonForCreationDto,
} from '@norkart/nkapi-arealplaner-api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

type Props = {
    arealplanId: number;
    dispensasjon: DispensasjonForCreationDto;
};

export default function useCreateDispensasjon() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useMutation(
        async (props: Props): Promise<DispensasjonDto> => {
            const {data, error} = await arealplanerCreateDispenasjon({
                client: authorizedClient,
                path: {kundeId, arealplanId: props.arealplanId},
                body: props.dispensasjon,
            });
            if (error) {
                throw new Error('Failed to create dispensasjon');
            }
            return data;
        },
        {
            onError: notify,
            onSuccess: (dispensasjon) => {
                queryClient.invalidateQueries([
                    'dispensasjoner',
                    {kundeId, arealplanId: dispensasjon.arealplanId},
                ]);
            },
        }
    );
}
