import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Tooltip,
    Fade,
    IconButton,
    DialogActions,
    Button,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LoadingSpinner from '../LoadingSpinner';

type Props = {
    isOpen: boolean;
    handleClose: () => void;
    title: string;
    textToShare: string;
    isLoading?: boolean;
};

function ShareTextDialog(props: Props) {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (tooltipOpen) {
            timer = setTimeout(handleTooltipClose, 1500);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [tooltipOpen]);

    const copyToClipboard = () => {
        handleTooltipOpen();
        navigator.clipboard.writeText(props.textToShare);
    };

    const handleClose = () => {
        handleTooltipClose();
        props.handleClose();
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth={true}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Grid container={true} wrap='nowrap' alignItems='baseline'>
                        <Grid item={true} style={{width: '100%'}}>
                            <input
                                type='text'
                                readOnly={true}
                                value={props.textToShare}
                                style={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item={true}>
                            <Tooltip
                                open={tooltipOpen}
                                onClose={handleTooltipClose}
                                title='Kopiert'
                                placement='top'
                                TransitionComponent={Fade}
                                disableFocusListener={true}
                                disableHoverListener={true}
                                disableTouchListener={true}
                            >
                                <IconButton onClick={copyToClipboard}>
                                    <FileCopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Lukk</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ShareTextDialog;
