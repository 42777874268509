//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const MapRoutePins1Icon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11ZM12 0C7.58 0 4 3.58 4 8C4 9.46 4.39999 10.82 5.07999 12L12 24L18.92 12C19.6 10.82 20 9.46 20 8C20 3.58 16.42 0 12 0Z' style={props.secondaryStyle} />
        </g>
    ),
    'MapRoutePins1Icon' // aria-label
);
