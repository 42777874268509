import {Typography} from '@mui/material';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import CheckIcon from '@mui/icons-material/CheckCircle';
import green from '@mui/material/colors/green';
import LoadingSpinner from '../../../components/LoadingSpinner';
import SnackbarMessage from '../../../components/SnackbarMessage';
import useDokuments from '../../../hooks/dokumenter/useDokuments';
import {getSelectedCustomer} from '../../../store';
import {select} from '../../../store/kunder/actions';

function OpenDocument() {
    const {dokumentId, customerId} = useParams<{
        dokumentId: string;
        customerId: string;
    }>();
    const dokuments = useDokuments();
    const dispatch = useDispatch();
    const selectedCustomer = useSelector(getSelectedCustomer);
    const [loading, setLoading] = React.useState(true);

    if (!selectedCustomer) dispatch(select(customerId));

    React.useEffect(() => {
        openDokument();
    }, []);

    const openDokument = async () => {
        await dokuments.openDokument(parseInt(dokumentId || ''), 'replace');
        setLoading(false);
    };

    if (loading)
        return (
            <>
                <SnackbarMessage />
                <LoadingSpinner text='Henter dokument' />
            </>
        );

    return (
        <div style={{margin: '0 auto', marginTop: 50, textAlign: 'center'}}>
            <CheckIcon fontSize='large' style={{color: green[600]}} />
            <Typography variant='h1'>
                Dokumentet er nå lastet ned og du kan trygt lukke dette vinduet.
            </Typography>
        </div>
    );
}

export default OpenDocument;
