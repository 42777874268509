import {Theme, styled} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {themeIsLightMode} from '../utils/themeIsLightMode';
import {ToiDatePickerComponent} from './ToiDatePicker';

const focusStyle = (theme: Theme) => {
  const palette = theme.palette;
  const isLightMode = themeIsLightMode(theme);

  return {
    outline: `1px dashed ${isLightMode ? palette.black[120] : palette.secondary.main /* White */}`,
    outlineOffset: '-5px',
  };
};

export const StyledDatePicker = styled(DatePicker)(({theme}) => {
  return {
    '& button.MuiIconButton-root': {
      borderRadius: 0,
    },
    '& button.Mui-focusVisible': focusStyle(theme),
  };
}) as ToiDatePickerComponent;

/*
These are styles applied to the expaded date picker. These are rendered in a popper,
and are not children of the component */
export const datePickerPopperStyle = (theme: Theme) => ({
  pt: '2px',
  '& button.MuiIconButton-root': {
    borderRadius: 0,
  },
  '& button.Mui-focusVisible': focusStyle(theme),
});
