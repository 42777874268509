import {EventType, IPublicClientApplication} from '@azure/msal-browser';

export const setActiveAccountOnApp = (instance: IPublicClientApplication) => {
  // Default to using the first account if no account is active on page load.
  if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. May need to adjust as needed for different use cases but for now this is good enough for all our apps
    instance.setActiveAccount(instance.getAllAccounts()[0]);
  }

  instance.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      instance.setActiveAccount(account);
    }
  });
};
