import {useQuery} from 'react-query';
import {fetchKodelisteBehandlingstyper} from '../../services/api';

export type Behandlingstyper = {id: number; beskrivelse: string};

const getKodelisterBehandlingstyper = async () => {
    const {data} = await fetchKodelisteBehandlingstyper();
    return data as Behandlingstyper[];
};

export function useKodelisterBehandlingstyper() {
    const query = useQuery<Behandlingstyper[]>(
        ['kodeliste_behandlingstyper'],
        () => getKodelisterBehandlingstyper(),
        {refetchOnWindowFocus: false}
    );
    return {...query, data: query.data ?? ([] as Behandlingstyper[])};
}
