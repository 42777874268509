import axios from 'axios';
import {backend} from '../Consts';
import postType from '../Types/PostType';

const postApi = axios.create({
  headers: {'X-WAAPI-PROFILE': 'ReleaseNotesKlient'},
});
const url = backend + 'posts';

//TODO Do something about url definition and appId
export async function fetchPosts(clientId: string): Promise<postType[]> {
  const result = await postApi.get(url + '/' + clientId);
  return result.data;
}

export async function postPost(clientId: string, post: postType, token?: string, applicationProfile?: string) {
  if (token) {
    return postApi.post(url + '/' + clientId, post, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-WAAPI-PROFILE': applicationProfile,
      },
    });
  } else {
    throw 'No authentication token provided!';
  }
}

export async function deletePost(clientId: string, post: postType, token?: string, applicationProfile?: string) {
  if (token) {
    return postApi.delete(url + '/' + clientId, {
      data: post,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-WAAPI-PROFILE': applicationProfile,
      },
    });
  } else {
    throw 'No authentication token provided!';
  }
}
