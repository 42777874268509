import {Theme} from '@mui/material';
import {merge} from 'lodash-es';
import {themeIsLightMode} from '../utils/themeIsLightMode';

export const toiCardStyle = (theme: Theme) => {
  const isLightMode = themeIsLightMode(theme);

  const outlineColor = isLightMode ? theme.palette.common.black : theme.palette.common.white;

  const focus = {
    outline: `1.5px dashed ${outlineColor}`,
    borderRadius: '8px',
  };

  const defaultStyle = {
    backgroundColor: isLightMode ? theme.palette.white : theme.palette.black[100],
    color: outlineColor,
    outlineOffset: '-6px',
    '&.Mui-focusVisible': focus,
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
    border: `1px solid ${isLightMode ? theme.palette.black[30] : theme.palette.black[80]}`,
    backgroundImage: 'none',
  };

  return merge({}, defaultStyle);
};
