export const findMyLocation = (
    callback: (position: {lat: number; lng: number}) => void
): {lat: number; lng: number} | undefined => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            callback({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
    } else {
        alert('Geolocation is not supported by this browser.');
        return undefined;
    }
};
