import axios from 'axios';
import {backend} from '../Consts';

const accessApi = axios.create();
const url = backend + 'access';

export async function checkAccess(clientId: string, applicationProfile: string, token: string) {
  try {
    const result = await accessApi.get(url + '/' + clientId, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-WAAPI-PROFILE': applicationProfile,
      },
    });
    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.status === 403) {
        return false;
      } else {
        throw e;
      }
    } else {
      throw e;
    }
  }
}
