import regexItemTypes from './regex-item-types';
import {combine, combineWithOr} from './regex';
import {coordinateSystem} from './coordinate-systems';

// Building blocks for the coordinate parser ( Online regex editor: http://regexr.com/ )
export const regexItems = [];
const regexPartTypes = regexItemTypes;

/////////////
// NUMBERS
//////////////////////
regexItems['Number'] = {
    regex: /[-+]{0,1}(\d*[\.\,])?\d+/, //  number with dot or comma as comma
    type: regexPartTypes.NUMBER,
    prepare: function (input) {
        return input.replace(',', '.').replace('+', '');
    },
};

regexItems['NumberDMS'] = {
    regex: /[-+]{0,1}\d+[°]\s{0,1}\d+[']\s{0,1}(\d*[\.\,])?\d+([']{2}|[']{1})/, // e.x: 59° 49' 12.972''
    type: regexPartTypes.NUMBER_SPECIAL,
    prepare: function (input) {
        const numbers = input.match(/\d+/g); // 59°49'12.972'' -> [59,49,12,987]
        const decimal =
            numbers[0] * 1 +
            numbers[1] / 60 +
            parseFloat(numbers[2] + '.' + (numbers[3] || 0)) / 3600; // THIS IS A FIX
        //var decimal = numbers[0] * 1 + numbers[1] / 60 + (numbers[2] + '.' + (numbers[3] || 0)) as any / 3600; // THIS CRASHES
        return decimal;
    },
};

regexItems['NumberDM'] = {
    regex: /[-+]{0,1}\d+[°]\s{0,1}\d+([\.\,]\d*)?[']/, // e.x: 59° 49.972 '
    type: regexPartTypes.NUMBER_SPECIAL,
    prepare: function (input) {
        const numbers = input.match(/\d+/g); // 59°49.972' -> [59,49,972]
        const decimal =
            numbers[0] * 1 +
            parseFloat(numbers[1] + '.' + (numbers[2] || 0)) / 60;
        return decimal;
    },
};

regexItems['NumberD'] = {
    regex: /[-+]{0,1}\d+([\.\,]\d*)?[°]/, // e.x: 59.123°
    type: regexPartTypes.NUMBER_SPECIAL,
    prepare: function (input) {
        return input.replace(',', '.').replace('+', '').replace('°', '');
    },
};

// handle all number types
regexItems['NumberManyTypes'] = {
    regex: combineWithOr([
        regexItems['NumberDMS'].regex,
        regexItems['NumberDM'].regex,
        regexItems['NumberD'].regex,
        regexItems['Number'].regex,
    ]),
    type: regexPartTypes.NUMBER_COMPOSITE,
    prepare: function (input) {
        // warning: the order matter. regexItems.Number must be last
        if (regexItems['NumberDMS'].regex.test(input)) {
            return regexItems['NumberDMS'].prepare(input);
        } else if (regexItems['NumberDM'].regex.test(input)) {
            return regexItems['NumberDM'].prepare(input);
        } else if (regexItems['NumberD'].regex.test(input)) {
            return regexItems['NumberD'].prepare(input);
        } else if (regexItems['Number'].regex.test(input)) {
            return regexItems['Number'].prepare(input);
        } else {
            return null;
        }
    },
};

////////////////
// LETTERS
///////////////////
regexItems['CoordLetter1'] = {
    regex: /[NS]/,
    type: regexPartTypes.LETTER,
    prepare: function (input) {
        return input.replace('°', '');
    },
};

regexItems['OptionalCoordLetter1'] = makeRegexItemOptional(
    regexItems['CoordLetter1']
);

regexItems['CoordLetter2'] = {
    regex: /[EØVW]/,
    type: regexPartTypes.LETTER,
    prepare: function (input) {
        return input.replace('°', '').replace('Ø', 'E').replace('V', 'W');
    },
};

regexItems['OptionalCoordLetter2'] = makeRegexItemOptional(
    regexItems['CoordLetter2']
);

//////////////
// SEPERATORS
////////////////////
regexItems['Seperator'] = {
    regex: /\,{0,1}\s{1,}/,
    type: regexPartTypes.NO_FUNCTION,
    prepare: function (input) {
        return input;
    },
};

regexItems['Spaces'] = {
    regex: /\s{1,}/,
    type: regexPartTypes.NO_FUNCTION,
    prepare: function (input) {
        return input;
    },
};

regexItems['OptionalSpaces'] = makeRegexItemOptional(regexItems['Spaces']);

///////////////////////
// COORDINATE SYSTEM
///////////////////////
const coordSystemRegexesWgs = combineWithOr(Object.keys(coordinateSystem.wgs)); // --> ((WGS84)|(WGS 84)...)
const coordSystemRegexesUtm = combineWithOr(Object.keys(coordinateSystem.utm));

regexItems['CoordSystemUtm'] = {
    regex: combine([coordSystemRegexesUtm, /([\:\s]{1})\s*/]),
    type: regexPartTypes.COORDINATE_SYSTEM,
    prepare: function (input) {
        return coordinateSystem.utm[input.replace(':', '').trim()];
    },
};

regexItems['CoordSystemWgs'] = {
    regex: combine([coordSystemRegexesWgs, /([\:\s]{1})\s*/]),
    type: regexPartTypes.COORDINATE_SYSTEM,
    prepare: function (input) {
        return coordinateSystem.wgs[input.replace(':', '').trim()];
    },
};

regexItems['OptionalCoordSystemUtm'] = makeRegexItemOptional(
    regexItems['CoordSystemUtm']
);
regexItems['OptionalCoordSystemWgs'] = makeRegexItemOptional(
    regexItems['CoordSystemWgs']
);

//////////////////////////////////////////////////
// Helper method: make an regex item optional
////////////////////////////////////////////////
function makeRegexItemOptional(rPart) {
    let regexString = rPart.regex.toString();
    regexString = regexString.substring(1, regexString.length - 1); // remove slash in front and back

    //\s*(\d\d)
    return {
        regex: new RegExp('(' + regexString + '){0,1}'),
        type: rPart.type,
        prepare: rPart.prepare,
    };
}
