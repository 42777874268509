import {styled, TextField} from '@mui/material';

export const ToiLikeStyledTextField = styled(TextField)(({theme, error}) => ({
    '& .MuiInputLabel-root': {
        position: 'relative',
        color: error ? theme.palette.error.main : theme.palette.primary.main,
        transformOrigin: 'unset',
        transform: 'unset',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '24px',
        textTransform: 'uppercase',
        '&.Mui-focused': {
            color: error ? theme.palette.error.main : 'black!important',
        },
    },
    '&:hover fieldset': {
        outline: `2px solid ${
            error ? theme.palette.error.main : theme.palette.primary.main
        }`,
    },
    '& .Mui-focused fieldset': {
        outline: `1px solid ${
            error ? theme.palette.error.main : theme.palette.secondary.main
        }`,
    },
    '& .Mui-placeholder fieldset': {
        color: theme.palette.primary.main,
        opacity: 0.5,
    },
    '& fieldset': {
        outline: `1px solid ${
            error ? theme.palette.error.main : theme.palette.primary.main
        }`,
        border: 'none',
        marginTop: 5,
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
        '& ::placeholder': {
            color: theme.palette.primary.main,
            opacity: 0.5,
        },
    },
}));
