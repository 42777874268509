/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import authContext from '../Hooks/authContext';
import {useClients} from '../Hooks/useClients';
import {usePosts} from '../Hooks/usePosts';
import {useTags} from '../Hooks/useTags';
import postType from '../Types/PostType';
import tagType from '../Types/TagType';
import {isArrayofStrings} from '../Types/TypeGuards';
import Editor from './TextEditor/Editor';

type props = {
  post?: postType;
  open: boolean;
  onClose: () => void;
};

function PostDialog({post, open, onClose}: props) {
  const auth = useContext(authContext);
  const [title, setTitle] = useState('');
  const [currentTags, setCurrentTags] = useState<tagType[]>([]);
  const {createOrUpdatePost} = usePosts(auth.clientId, auth.applicationProfile, auth.bearerToken);
  const {clients} = useClients();
  const tags = useTags();

  const [editorHTML, setEditorHTML] = useState<string>('');
  const handleEditorState = (newEditorHTML: string) => {
    setEditorHTML(newEditorHTML);
  };

  useEffect(() => {
    if (post) {
      setTitle(post.title);
      setCurrentTags(tags.data.filter((tag) => post.tagIds.includes(tag.id)) || []);
    } else {
      clearValues();
    }
  }, [post]);

  function handleTagChange(event: SelectChangeEvent<unknown>) {
    const {
      target: {value},
    } = event;

    if (isArrayofStrings(value)) {
      const newTags = tags.data.filter((tag) => value.includes(tag.name));
      setCurrentTags(newTags);
    } else {
      throw new TypeError();
    }
  }

  function clearValues() {
    setTitle('');
    setCurrentTags([]);
  }

  function submitPost() {
    const clientId = clients?.data?.find((client) => client.clientId === auth.clientId)?.id;
    const tagIds = currentTags.map((tag) => tag.id);

    if (typeof clientId === 'number') {
      const postToSubmit: postType = {
        id: post?.id || 0,
        date: post?.date || new Date(),
        title: title,
        content: editorHTML,
        tagIds: tagIds,
        clientId: clientId,
      };

      createOrUpdatePost.mutate(postToSubmit);
    } else {
      console.log('No clientData. ');
    }

    clearValues();
    onClose();
  }

  return (
    <Dialog open={open} fullWidth maxWidth='lg'>
      <DialogTitle>{post ? 'Rediger Innlegg' : 'Nytt innlegg'}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} marginTop={1}>
          <TextField
            autoFocus
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant='outlined'
            required
            label='Tittel'
          />
          <Editor
            initialEditorContent={post?.content ? post.content : ''}
            toolbarOptions={['bold', 'italic', 'list', 'undo', 'redo', 'link']}
            currentEditorState={handleEditorState}
          />
          <TextField
            label='Kategorier'
            variant='outlined'
            select
            SelectProps={{
              multiple: true,
              value: currentTags.map((tag) => tag.name),
              onChange: handleTagChange,
              renderValue: (selected) => {
                return isArrayofStrings(selected) ? selected.map((tag) => <Chip key={tag} label={tag} />) : null;
              },
            }}
          >
            {tags.data?.map((tag) => (
              <MenuItem key={tag.id} value={tag.name}>
                {tag.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Avbryt</Button>
        <Button onClick={submitPost} variant='contained'>
          Lagre
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PostDialog;
