import {useQueries, UseQueryResult} from 'react-query';
import {fetchArealplan} from '../../services/api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Arealplan} from './types';

export default function useArealplaner(ids: number[]) {
    const kundeId = useSelectedKundeId();

    function mapArealplaner(id) {
        return {
            queryKey: ['arealplan', kundeId, id],
            queryFn: () => fetchArealplan({id}),
        };
    }

    const query = useQueries(ids.map(mapArealplaner)) as UseQueryResult<
        Arealplan,
        unknown
    >[];
    return query;
}
