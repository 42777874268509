import {useQuery} from 'react-query';
import {useFetchTokenHeaderWithAuthData} from '@norkart/nk-auth';
import {SaksbehandlingstidQueryVariables} from '../../features/reports/Reports/Saksbehandlingstid/Saksbehandlingstid';
import {GraphQLUnpagedResponse} from '../../graphql/types';
import {graphQLRequest} from '../../services/api';
import {SaksbehandlingstidReport} from './types';

export default function useSaksbehandlingstidGraphQL(
    graphqlQuery: string,
    variables: SaksbehandlingstidQueryVariables
) {
    const getHeaders = useFetchTokenHeaderWithAuthData();

    const query = useQuery<GraphQLUnpagedResponse>(
        ['saksbehandlingstidReportGraphQL', variables],
        async () => {
            const headers = await getHeaders();
            return await graphQLRequest(graphqlQuery, variables, headers);
        },
        {
            cacheTime: 1000 * 60 * 5,
            refetchOnWindowFocus: false,
        }
    );

    return {
        ...query,
        saksbehandlingstid:
            (query.data
                ?.saksbehandlingstidReport as SaksbehandlingstidReport[]) ||
            ([] as SaksbehandlingstidReport[]),
    };
}
