import {isNumber} from 'lodash-es';
import {useIsDeviceSize} from '@norkart/design-responsive';

const mobileSizes: Record<string, string> = {
  '2xs': '4px',
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '24px',
  xl: '24px',
  '2xl': '32px',
  '3xl': '40px',
  '4xl': '48px',
  '5xl': '56px',
  '6xl': '64px',
  '7xl': '72px',
};

const tabletSizes: Record<string, string> = {
  '2xs': '4px',
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '24px',
  xl: '32px',
  '2xl': '40px',
  '3xl': '48px',
  '4xl': '56px',
  '5xl': '64px',
  '6xl': '72px',
  '7xl': '96px',
};

const desktopSizes: Record<string, string> = {
  '2xs': '4px',
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '32px',
  xl: '48px',
  '2xl': '56px',
  '3xl': '64px',
  '4xl': '72px',
  '5xl': '96px',
  '6xl': '128px',
  '7xl': '160px',
};

export const useToiSpacing = () => {
  const isMobilePortrait = useIsDeviceSize('mobilePortrait', 'down');
  const isTablet = useIsDeviceSize('tablet', 'down');

  const spacing = (value: number | string): string => {
    if (isNumber(value)) {
      return `${8 * value}px`; // default mui way
    }
    const lowerCase = value.toLowerCase();
    return isMobilePortrait ? mobileSizes[lowerCase] : isTablet ? tabletSizes[lowerCase] : desktopSizes[lowerCase];
  };

  return spacing;
};
