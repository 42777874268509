import {useQuery} from 'react-query';
import {
    arkivKodelisterGetTilgangerByType,
    KodelisteTilgangDto,
    KodelisteType,
} from '@norkart/nkapi-arealplaner-api';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useSelectedKundeId from '../kunder/useSelectedKundeId';

export function useArkivKodelisteTilganger(
    arkivId: number,
    kodelisteType: KodelisteType
) {
    const {authorizedClient} = useArealplanerApiClientContext();
    const kundeId = useSelectedKundeId();
    const getKodeliste = async () => {
        const {data} = await arkivKodelisterGetTilgangerByType({
            client: authorizedClient,
            path: {arkivId, kundeId},
            query: kodelisteType ? {kodelisteType} : undefined,
        });
        return data as KodelisteTilgangDto[];
    };

    const query = useQuery<KodelisteTilgangDto[]>(
        ['arkivkodelistetilganger', arkivId, kodelisteType],
        getKodeliste,
        {refetchOnWindowFocus: false}
    );
    return {...query, data: query?.data || ([] as KodelisteTilgangDto[])};
}
