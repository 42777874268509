import type {ApiRequestOptions} from './ApiRequestOptions';

export type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
export type Headers = Record<string, string>;
export type HeaderGenerator = Headers | Resolver<Headers>;

export type Config = {
    BASE?: string;
    HEADERS?: HeaderGenerator;
    ENCODE_PATH?: (path: string) => string;
};

export const getApiKeyHeader = (apiKey: string): HeaderGenerator => ({
    'X-WAAPI-TOKEN': apiKey,
});
