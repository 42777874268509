import {useMutation, useQueryClient} from 'react-query';
import {updateBehandling} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Behandling} from './types';

const editBehandling = async (behandling: Behandling) => {
    const {data} = await updateBehandling(behandling);
    return data;
};

export default function useUpdateBehandling() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    return useMutation(editBehandling, {
        onError: notify,
        onSuccess: (data, behandling) => {
            queryClient.invalidateQueries([
                'behandlinger',
                {kundeId, arealplanId: behandling.arealplanId},
            ]);
        },
    });
}
