import axios from 'axios';

const defaultSearchOperation = 'suggest';
const BASE_URL = '//www.webatlas.no/WAAPI-FritekstSok/';

function extend(to, from) {
    for (const key in from) {
        if (Object.prototype.hasOwnProperty.call(from, key)) {
            to[key] = from[key];
        }
    }
    return to;
}

const buildQueryString = (params) =>
    Object.keys(params)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join('&');

async function search(
    params,
    extraHeaders,
    searchOperation = undefined,
    groupByType
) {
    const urlSearchOperation = searchOperation
        ? searchOperation
        : defaultSearchOperation;

    const headers = extend(
        {Accept: 'application/json; charset=utf-8'},
        extraHeaders
    );
    const response = await axios({
        method: 'GET',
        url: `${BASE_URL}${urlSearchOperation}/kommunecustom/?${buildQueryString(
            params
        )}`,
        headers: headers,
        responseType: 'json',
    });
    let res = response.data.Options
        ? response.data.Options
        : response.data.SearchResults;

    if (groupByType) {
        //Grupperer i rekkefølge: gate, eier_juridiskperson, eier_fysiskperson
        res = res.sort((a, b) => {
            if (a.Type > b.Type) {
                return -1;
            } else if (a.Type < b.Type) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    let duplicates = [];
    if (res.length > 1) {
        const filtered = res.filter((x, i, array) => {
            const copy = array.slice();

            copy.splice(i, i + 1);
            if (
                copy.some(
                    (otherElement) =>
                        otherElement.Text === x.Text && x.Type !== 'stedsnavn'
                )
            ) {
                return x;
            }
        });
        duplicates = duplicates.concat(filtered);
    }

    if (duplicates.length > 0) {
        for (let index = 0; index < duplicates.length; index++) {
            const element = duplicates[index];
            const data = await getDataObject(
                element,
                extraHeaders,
                urlSearchOperation,
                false,
                params
            );
            if (data) {
                if (data.Gate) {
                    if (data.Gate.Adresser) {
                        if (data.Gate.Adresser.length > 0) {
                            const obj = res.find(
                                (x) => x.Id === data.Gate.GateId
                            );
                            obj.PostalArea = data.Gate.Adresser[0].PostSted;
                        }
                    }
                }
            }
        }
        return res;
    }

    return res;
}
async function getDataObject(
    searchObject,
    extraHeaders,
    urlSearchOperation,
    formatResponse = true,
    params,
    disableNewPropertyRedirect = false
) {
    const headers = extend(
        {Accept: 'application/json; charset=utf-8'},
        extraHeaders
    );
    const response = await axios({
        method: 'GET',
        url: getDataObjectUrl(
            searchObject,
            urlSearchOperation,
            params,
            disableNewPropertyRedirect
        ),
        headers: headers,
        responseType: 'json',
    });
    let res = response.data;

    if (formatResponse) {
        res = getDataObjecRes(response);
        if (!Array.isArray(res)) {
            res = new Array(res);
        }
        if (
            urlSearchOperation !== 'data/matrikkel/adresse/gate/' &&
            res.length > 1
        ) {
            res = res.filter((x) => x.Id === searchObject.Id);
        }

        if (res.length > 1) {
            res = res.sort((a, b) => {
                const streetNumber = Number(a.Text.split(/(\d+)/g)[1]);
                const streetNumberB = Number(b.Text.split(/(\d+)/g)[1]);
                if (!streetNumber || !streetNumberB) {
                    return 0;
                }
                if (streetNumber > streetNumberB) {
                    return 1;
                } else if (streetNumber < streetNumberB) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
    }

    return res;
}

function getDataObjecRes(response) {
    let res = response.data;
    if (response.data.Gate) {
        res = response.data.Gate.Adresser;
    } else if (response.data.GateAdresse) {
        res = response.data.GateAdresse;
    } else if (res.SearchResults) {
        res = res.SearchResults;
    }
    return res;
}

function getDataObjectUrl(
    searchObject,
    urlSearchOperation,
    params,
    disableNewPropertyRedirect
) {
    let id = searchObject.Id;
    if (
        (searchObject.GateId && !searchObject.AdresseId) ||
        (searchObject.Type && searchObject.Type === 'gate')
    ) {
        if (searchObject.GateId) {
            id = searchObject.GateId;
        }

        return `${BASE_URL}data/matrikkel/adresse/gate/${id}`;
    } else if (
        searchObject.AdresseId ||
        (searchObject.Type && searchObject.Type === 'gateadresse')
    ) {
        if (searchObject.AdresseId) {
            id = searchObject.AdresseId;
        }
        return `${BASE_URL}data/matrikkel/adresse/gateadresse/${id}`;
    }
    return `${BASE_URL}${urlSearchOperation}/kommunecustom/?${buildQueryString(
        params
    )}${
        disableNewPropertyRedirect
            ? '&ExcludeExpiredMatrikkelenheter=false'
            : ''
    }`;
}
function getParams(value, targets, limits, size) {
    const params = {
        Query: value,
        Targets: targets.join(','),
        Size: size,
    };
    if (limits) {
        params['kommuneLimit'] = limits.join(',');
    }
    return params;
}
function searchAppBackend(
    value,
    targets,
    limits,
    size,
    auth,
    searchOperation: any = undefined,
    object = undefined,
    groupByType,
    multiCustomerContext: string | null | undefined,
    disableNewPropertyRedirect
) {
    const params = getParams(value, targets, limits, size);
    auth.getToken(function (err, token) {
        const headers = {
            Authorization: 'Bearer ' + token,
            'X-WAAPI-Profile': auth.config.profile,
        };
        if (multiCustomerContext != null) {
            headers['X-WAAPI-CONTEXT'] = multiCustomerContext;
        }
        return object
            ? getDataObject(
                  object,
                  headers,
                  searchOperation,
                  true,
                  params,
                  disableNewPropertyRedirect
              )
            : search(params, headers, searchOperation, groupByType);
    });
}
function searchToken(
    value,
    targets,
    limits,
    size,
    auth,
    searchOperation: any,
    object: any,
    groupByType,
    multiCustomerContext: string | null | undefined,
    disableNewPropertyRedirect
) {
    const headers = {
        Authorization: 'Bearer ' + auth.token,
        'X-WAAPI-Profile': auth.profile,
    };
    if (multiCustomerContext != null) {
        headers['X-WAAPI-CONTEXT'] = multiCustomerContext;
    }
    const params = getParams(value, targets, limits, size);
    return object
        ? getDataObject(
              object,
              headers,
              searchOperation,
              true,
              params,
              disableNewPropertyRedirect
          )
        : search(params, headers, searchOperation, groupByType);
}

function searchApiKey(
    value,
    targets,
    limits,
    size,
    token,
    searchOperation: any,
    object: any,
    groupByType,
    disableNewPropertyRedirect = false
) {
    const headers = {'X-WAAPI-Token': token};
    const params = getParams(value, targets, limits, size);
    return object
        ? getDataObject(
              object,
              headers,
              searchOperation,
              true,
              params,
              disableNewPropertyRedirect
          )
        : search(params, headers, searchOperation, groupByType);
}

export {searchAppBackend, searchToken, searchApiKey};
