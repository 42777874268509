import * as React from 'react';
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import {Field, Form, FormSpy} from 'react-final-form';
import {AxiosError} from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {OnChange} from 'react-final-form-listeners';
import {useState} from 'react';
import {MapboxLayer} from '@norkart/nkm-mapbox-map';
import {Arealplan} from '../../../hooks/arealplaner/types';
import {Behandling} from '../../../hooks/behandlinger/types';
import {Behandlingstype} from '../../../hooks/meta/types';
import Checkbox from '../../../components/Checkbox';
import DatePicker from '../../../components/DatePicker';
import Error from '../../../components/FormError/Error';
import FilterableDropdownField from '../../../components/Dropdown/FilterableDropdownField';
import FormError from '../../../components/FormError';
import FormInputField from '../../../components/FormInputField';
import FormSelectInput from '../../../components/FormSelectInput';
import {KLAGEFRIST_WEEKS} from '../../../config/const';
import LoadingSpinner from '../../../components/LoadingSpinner';
import PositionOptions from '../../../components/PositionOptions';
import SpinnerButton from '../../../components/SpinnerButton';
import {formatDate} from '../../../utils/formatDate';
import {isVertNivaEnabled} from '../../plan/PlanForm/helpers';
import {openSnackbarMessage} from '../../../components/SnackbarMessage';
import {parseDate} from '../../../utils/parseDate';
import useCreateBehandling from '../../../hooks/behandlinger/useCreateBehandling';
import useMeta from '../../../hooks/meta/useMeta';
import useUpdateArealplan from '../../../hooks/arealplaner/useUpdateArealplan';
import useUpdateBehandling from '../../../hooks/behandlinger/useUpdateBehandling';
import useBehandlingFormHelpers from './useBehandlingFormHelpers';
import './behandlingsform.css';
import {isValidDate} from '../../../utils/isValidDate';

export interface BehandlingFormProps {
    behandling: Behandling;
    plan: Arealplan;
    planAreaLayer: MapboxLayer | undefined;
    onDeleteCoords: () => void;
    coordsHasChanged: boolean;
    coords: {x: number; y: number} | undefined;
    setCoords: React.Dispatch<
        React.SetStateAction<
            | {
                  x: number;
                  y: number;
              }
            | undefined
        >
    >;
    onCancel: () => void;
    onDelete: () => void;
    onClose: () => void;
    focusRow: (id: string) => void;
    setDisplaySelectMapPosition: (value: boolean) => void;
}
export interface BehandlingFormState {
    errors?: AxiosError;
    editSakVerdiId: boolean;
}

export interface BehandlingFormValues extends Partial<Behandling> {
    fraDato: Date;
    klagefrist?: Date;
    klagerFerdigbehandlet: boolean;
}

const Condition = ({when, predictor, children}: any) => (
    <Field name={when} subscription={{value: true, initial: true}}>
        {({input: {value}}) => (predictor ? children : null)}
    </Field>
);

function BehandlingForm({plan, ...props}: BehandlingFormProps) {
    const meta = useMeta();
    const valuesRef = React.useRef<{values: string} | any>();
    const helpers = useBehandlingFormHelpers();
    const {mutateAsync: updateArealplan} = useUpdateArealplan();
    const {mutateAsync: updateBehandling} = useUpdateBehandling();
    const {mutateAsync: createBehandling} = useCreateBehandling();
    const [editSakVerdiId, setEditSakVerdiId] = useState(false);

    React.useEffect(() => {
        if (props.coords) {
            if (valuesRef.current) {
                valuesRef.current.xkoord = props.coords.x;
                valuesRef.current.ykoord = props.coords.y;
            }
        } else {
            if (valuesRef.current) {
                valuesRef.current.xkoord = '';
                valuesRef.current.ykoord = '';
            }
        }
    }, [props.coords]);

    const handleUpdatePlan = async (values: BehandlingFormValues) => {
        const data = {
            ...plan,
        } as any;

        let shouldUpdatePlan = false;
        type field = {key: string; value: string};
        const updatedFields = [] as field[];

        // Controlled by plan
        if (helpers.shouldHaveCheckboxForKlage(values.planProsessId)) {
            data.gjenstaendeKlage = !values.klagerFerdigbehandlet;
            shouldUpdatePlan = true;
        }
        if (helpers.shouldHaveKlagefrist(values.planProsessId, plan)) {
            data.klagefrist = parseDate(values.klagefrist);
            shouldUpdatePlan = true;
            updatedFields.push({
                key: 'Klagefrist',
                value: formatDate(values.klagefrist),
            });
        }

        // Automatic on new behandling, might need to implement in backend
        if (!behandling.id) {
            if (helpers.shouldSetKlage(values.planProsessId)) {
                data.gjenstaendeKlage = true;
                shouldUpdatePlan = true;
            }
            if (helpers.shouldSetUbehandletInnsigelse(values.planProsessId)) {
                data.ubehandletInnsigelse = true;
                shouldUpdatePlan = true;
            }
            if (helpers.shouldClearUbehandletInnsigelse(values.planProsessId)) {
                data.ubehandletInnsigelse = false;
                shouldUpdatePlan = true;
            }
            if (helpers.shouldUpdatePlanstatusTo6(values.planProsessId)) {
                data.planStatusId = 6;
                shouldUpdatePlan = true;
                updatedFields.push({
                    key: 'Planstatus',
                    value: helpers.getPlanstatus(6, meta.data.planstatuser),
                });
            }
            if (
                helpers.shouldUpdatePlanstatusTo3(values.planProsessId) &&
                data.planStatusId != 3
            ) {
                data.planStatusId = 3;
                shouldUpdatePlan = true;
                updatedFields.push({
                    key: 'Planstatus',
                    value: helpers.getPlanstatus(3, meta.data.planstatuser),
                });
            }
            if (helpers.shouldUpdatePlanstatusTo3(values.planProsessId)) {
                shouldUpdatePlan = true;
                data.meldingKlagefrist = true;
                updatedFields.push({
                    key: 'Vis melding om klagefrist:',
                    value: 'Ja',
                });
            }
            if (
                helpers.shouldUncheckVismeldingOmKlagefrist(
                    values.planProsessId,
                    plan
                )
            ) {
                shouldUpdatePlan = true;
                data.meldingKlagefrist = false;
                updatedFields.push({
                    key: 'Vis melding om klagefrist',
                    value: 'Nei',
                });
            }

            if (
                helpers.shouldUpdateVertniv(values.planProsessId) &&
                isVertNivaEnabled(data.planTypeId, data.planStatusId) &&
                data.vertikalniva.length === 0
            ) {
                data.Vertikalniva = [{VertikalnivaId: 2, Beskrivelse: ''}];
                shouldUpdatePlan = true;
                updatedFields.push({
                    key: 'Vertikalnivå',
                    value: helpers.getVertikalniva(2, meta.data.vertikalniva),
                });
            }
            if (!isVertNivaEnabled(data.planTypeId, data.planStatusId)) {
                data.vertikalniva = null;
            }
            if (
                helpers.shouldUpdateIkraft(values.planProsessId) &&
                data.iKraft != values.fraDato
            ) {
                data.iKraft = parseDate(values.fraDato);
                shouldUpdatePlan = true;
                updatedFields.push({
                    key: 'Ikrafttredelsesdato',
                    value: formatDate(values.fraDato)!,
                });
            }
            if (
                helpers.shouldHaveTwoDateInputs(values.planProsessId) &&
                values.tilDato
            ) {
                data.horingsStart = parseDate(values.fraDato);
                data.Horingsfrist = parseDate(values.tilDato);
                shouldUpdatePlan = true;
                updatedFields.push({
                    key: 'Høringsfrist',
                    value: formatDate(values.tilDato)!,
                });
                updatedFields.push({
                    key: 'HøringsStart',
                    value: formatDate(values.fraDato)!,
                });
            }
        }

        if (shouldUpdatePlan) {
            const res = await updateArealplan({
                arealplanForUpdate: data,
                arealplanId: data.id,
            });
        }
        if (updatedFields.length > 0) {
            openSnackbarMessage({
                message: (
                    <div>
                        {`Behandlingen "${helpers.getBehandlingstype(
                            values.planProsessId
                        )}" er registrert og egenskaper på planen er endret:`}
                        <table>
                            <tbody>
                                {updatedFields.map(({key, value}) => (
                                    <tr key={key}>
                                        <td style={{paddingRight: '20px'}}>
                                            {key}
                                        </td>
                                        <td>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {plan.lovreferanse == null && (
                            <div key='missinginfo' style={{marginTop: 10}}>
                                <p>
                                    Det mangler påkrevde egenskaper på planen.
                                    Velg Endre plan for å legge til flere
                                    egenskaper på planen
                                </p>
                            </div>
                        )}
                    </div>
                ),
                variant: 'info',
                verticalAnchor: 'bottom',
            });
        } else {
            if (!behandling.id) {
                if (
                    helpers.shouldDisplayStatusChangeMessage(
                        values.planProsessId
                    )
                )
                    openSnackbarMessage({
                        message: 'Planstatus må kanskje endres på planen',
                        variant: 'info',
                        verticalAnchor: 'bottom',
                    });
            }
        }
    };

    const onSubmit = async (values) => {
        const errors = {} as any;
        if (!values.fraDato) errors.fraDato = 'Fra dato er påkrevd';

        if (Object.keys(errors).length != 0) {
            return errors;
        }

        // Extract plan specific props
        const {klagerFerdigbehandlet, klagefrist, ...filteredValues} = values;

        const data = {
            ...filteredValues,
            sak: {
                sakAar: filteredValues.sak?.sakAar || null,
                sakSeknr: filteredValues.sak?.sakSeknr || null,
                arkivId: filteredValues.sak?.arkivId || null,
            },
            journalpostnummer: filteredValues.journalpostnummer,
            endringstypeId: helpers.shouldHaveEndringstype(values.planProsessId)
                ? values.endringstypeId
                : null,
            fraDato: parseDate(values.fraDato),
            tilDato: helpers.shouldHaveTwoDateInputs(values.planProsessId)
                ? parseDate(values.tilDato)
                : null,
            arealplanId: plan.id,
            posisjon:
                helpers.shouldHaveMapPositionOption(values.planProsessId) &&
                props.coords
                    ? {
                          x: props.coords.x,
                          y: props.coords.y,
                          koordsys: meta.referansesystemKode,
                      }
                    : null,
        } as Behandling;

        const res = data.id
            ? await updateBehandling(data)
            : await createBehandling(data);
        await handleUpdatePlan(values);
        props.onClose();
        props.focusRow(String(res.id ?? data.id));
    };

    if (meta.isLoading) return <LoadingSpinner />;

    const {dokumenter: Dokumenter, ...behandling} = props.behandling;
    const initialValues = {...behandling} as BehandlingFormValues;
    if (!behandling.id) {
        initialValues.klagerFerdigbehandlet = true;
    } else {
        initialValues.klagerFerdigbehandlet = !plan.ubehandletKlage;
        initialValues.klagefrist = plan.klagefrist;
    }

    const filter_behandlingstype = (
        behandlingstyper: Behandlingstype[]
    ): Behandlingstype[] => {
        const behandlingsOptions = behandlingstyper.filter(
            (b, i, res) => b.planTypeId == plan.planTypeId
        );

        if (behandling.planProsessId != null) {
            //Is current planprosess in list
            const searched_behandlingstype = behandlingsOptions.find(
                (o) => o.planProsessId === behandling.planProsessId
            );
            if (searched_behandlingstype === undefined) {
                const new_behandlingstype: Behandlingstype = {
                    behandlingstypeId: behandling.behandlingstypeId!,
                    beskrivelse: behandling.behandlingstype!,
                    planProsessId: behandling.planProsessId!,
                    journalpostnummer: behandling.journalpostnummer!,
                    planTypeId: plan.planTypeId,
                    unntak: [],
                };
                behandlingsOptions.push(new_behandlingstype);
            }
        }

        return behandlingsOptions;
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={(values: any) => {
                const errors = {} as any;
                if (!values.planProsessId)
                    errors.planProsessId = 'Behandlingstype er påkrevd';
                if (values.beskrivelse && values.beskrivelse.length > 1000)
                    errors.beskrivelse = 'Beskrivelsen overskrider 1000 tegn.';
                if (
                    (!values.sak?.sakAar || !values.sak?.sakSeknr) &&
                    values.journalpostnummer
                )
                    errors.journalpostnummer =
                        'Journalpostnummer krever saksnummer';
                if (values.fraDato && !isValidDate(new Date(values.fraDato)))
                    errors.fraDato = 'Ugyldig dato';
                if (values.tilDato && !isValidDate(new Date(values.tilDato)))
                    errors.tilDato = 'Ugyldig dato';
                return errors;
            }}
            render={({
                handleSubmit,
                values,
                submitting,
                pristine,
                errors,
                submitErrors,
                form,
            }) => (
                <Box
                    sx={(theme) => ({
                        form: {
                            '& .importantFormLabel': {fontWeight: 600},
                            '& .formInput': {
                                width: '100%',
                                padding: '5px',
                                fontFamily: theme.typography.fontFamily,
                            },
                            '& .formInput:disabled': {
                                backgroundColor: '#f0f0f0',
                            },
                            '& .formInputError': {border: '1px solid red'},
                            '& .textarea': {fontSize: 'inherit'},
                        },
                    })}
                >
                    <form onSubmit={handleSubmit} autoComplete='off'>
                        <FormSpy
                            subscription={{values: true}}
                            onChange={({values}) => {
                                valuesRef.current = values;
                            }}
                        />
                        <Grid container={true} spacing={6}>
                            <Grid item={true} xs={12}>
                                <FilterableDropdownField
                                    name='planProsessId'
                                    title='Behandlingstype'
                                    options={filter_behandlingstype(
                                        meta.data.behandlingstyper
                                    )}
                                    keyCol='planProsessId'
                                    valueCol='beskrivelse'
                                    displayId={false}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Typography
                                    className='importantFormLabel'
                                    style={{whiteSpace: 'nowrap'}}
                                >
                                    Saksår
                                </Typography>
                                <FormInputField
                                    name='sak.sakAar'
                                    maxLength={4}
                                    optional={true}
                                    fieldProps={{
                                        parse: (value) => {
                                            if (value == '') return value;
                                            return Number(
                                                value.replace(/[^\d]/g, '')
                                            );
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Typography
                                    className='importantFormLabel'
                                    style={{whiteSpace: 'nowrap'}}
                                >
                                    Sakssekvensnummer
                                </Typography>
                                <FormInputField
                                    name='sak.sakSeknr'
                                    optional={true}
                                    fieldProps={{
                                        parse: (value) => {
                                            if (value == '') return value;
                                            return Number(
                                                value.replace(/[^\d]/g, '')
                                            );
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <FormInputField
                                    name='journalpostnummer'
                                    optional={true}
                                    disabled={values.sak?.sakAar ? false : true}
                                    label='Journalpostnummer'
                                    fieldProps={{
                                        parse: (value) => {
                                            if (value == '') return undefined;
                                            return Number(
                                                value.replace(/[^\d]/g, '')
                                            );
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Typography
                                    className='importantFormLabel'
                                    style={{whiteSpace: 'nowrap'}}
                                >
                                    <span
                                        style={{
                                            height: 24,
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        Arkiv
                                        <IconButton
                                            onClick={() =>
                                                setEditSakVerdiId(
                                                    !editSakVerdiId
                                                )
                                            }
                                            size='small'
                                        >
                                            <EditIcon fontSize='small' />
                                        </IconButton>
                                    </span>
                                </Typography>
                                <Grid
                                    container={true}
                                    spacing={8}
                                    justifyContent='space-between'
                                >
                                    <Grid item={true} xs={12}>
                                        <FormSelectInput
                                            width='100%'
                                            hideMinWidth={true}
                                            name='sak.arkivId'
                                            options={meta.data.arkiver}
                                            keyCol='id'
                                            valueCol='beskrivelse'
                                            displayId={false}
                                            optional={false}
                                            disabled={!editSakVerdiId}
                                            onBlur={() =>
                                                setEditSakVerdiId(false)
                                            }
                                            nullOption={false}
                                            parse={(v) => Number(v)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <FormSelectInput
                                    name='myndighetId'
                                    title='Myndighet'
                                    keyCol='id'
                                    valueCol='beskrivelse'
                                    optional={true}
                                    options={meta.data.myndigheter.filter(
                                        (myndighet) =>
                                            (myndighet.ikkeIBruk == false ||
                                                myndighet.id ==
                                                    values.myndighetId) &&
                                            myndighet.myndighettypeId ==
                                                (helpers.shouldHaveDifferentMyndighet(
                                                    values.planProsessId
                                                )
                                                    ? 2
                                                    : 1)
                                    )}
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Condition
                                    when='planProsessId'
                                    predictor={helpers.shouldHaveEndringstype(
                                        values.planProsessId
                                    )}
                                >
                                    <FormSelectInput
                                        name='endringstypeId'
                                        title='Endringstype'
                                        keyCol='id'
                                        valueCol='beskrivelse'
                                        optional={true}
                                        options={meta.data.endringstyper}
                                    />
                                </Condition>
                                <Condition
                                    when='planProsessId'
                                    predictor={helpers.shouldHaveCheckboxForKlage(
                                        values.planProsessId
                                    )}
                                >
                                    <FormControlLabel
                                        label='Ingen klager under behandling'
                                        control={
                                            <Field
                                                name='klagerFerdigbehandlet'
                                                type='checkbox'
                                                component={Checkbox}
                                            />
                                        }
                                    />
                                </Condition>
                            </Grid>
                            <Grid
                                item={true}
                                xs={
                                    helpers.shouldHaveTwoDateInputs(
                                        values.planProsessId
                                    )
                                        ? 6
                                        : 12
                                }
                            >
                                <Typography className='importantFormLabel'>
                                    {helpers.shouldHaveTwoDateInputs(
                                        values.planProsessId
                                    )
                                        ? 'Fra dato'
                                        : 'Dato'}
                                </Typography>
                                <div style={{height: '42px'}}>
                                    <Field
                                        name='fraDato'
                                        render={({
                                            input: {name, value, onChange},
                                            meta,
                                        }) => (
                                            <DatePicker
                                                input={{name, value, onChange}}
                                            />
                                        )}
                                    />
                                    <Field
                                        name='fraDato'
                                        subscription={{
                                            error: true,
                                            submitError: true,
                                            dirtySinceLastSubmit: true,
                                        }}
                                        render={({meta}) =>
                                            meta.error ||
                                            (meta.submitError &&
                                                !meta.dirtySinceLastSubmit) ? (
                                                <Error
                                                    error={
                                                        meta.error ||
                                                        meta.submitError
                                                    }
                                                />
                                            ) : null
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Condition
                                    when='planProsessId'
                                    predictor={helpers.shouldHaveTwoDateInputs(
                                        values.planProsessId
                                    )}
                                >
                                    <Typography className='importantFormLabel'>
                                        Til dato
                                    </Typography>
                                    <div style={{height: '42px'}}>
                                        <Field
                                            name='tilDato'
                                            render={({
                                                input: {name, value, onChange},
                                                meta,
                                            }) => (
                                                <div>
                                                    <DatePicker
                                                        input={{
                                                            name,
                                                            value,
                                                            onChange,
                                                        }}
                                                        minDate={
                                                            values.fraDato &&
                                                            new Date(
                                                                values.fraDato
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        />
                                        <Field
                                            name='tilDato'
                                            subscription={{
                                                error: true,
                                                submitError: true,
                                                dirtySinceLastSubmit: true,
                                            }}
                                            render={({meta}) =>
                                                meta.error ||
                                                (meta.submitError &&
                                                    !meta.dirtySinceLastSubmit) ? (
                                                    <Error
                                                        error={
                                                            meta.error ||
                                                            meta.submitError
                                                        }
                                                    />
                                                ) : null
                                            }
                                        />
                                    </div>
                                </Condition>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Condition
                                    when='planProsessId'
                                    predictor={helpers.shouldHaveKlagefrist(
                                        values.planProsessId,
                                        plan
                                    )}
                                >
                                    <Typography className='importantFormLabel'>
                                        Siste klagefrist på planen
                                    </Typography>
                                    <div style={{height: '42px'}}>
                                        <Field name='klagefrist'>
                                            {({input: {onChange}}) => (
                                                <OnChange name='fraDato'>
                                                    {(value) => {
                                                        if (
                                                            value &&
                                                            !values.Klagefrist
                                                        ) {
                                                            const newDate =
                                                                new Date(value);
                                                            const klagefristDate =
                                                                newDate.setDate(
                                                                    newDate.getDate() +
                                                                        KLAGEFRIST_WEEKS *
                                                                            7
                                                                );
                                                            onChange(
                                                                new Date(
                                                                    klagefristDate
                                                                )
                                                            );
                                                        }
                                                    }}
                                                </OnChange>
                                            )}
                                        </Field>
                                        <Field
                                            name='klagefrist'
                                            render={({
                                                input: {name, value, onChange},
                                                meta,
                                            }) => (
                                                <div>
                                                    <DatePicker
                                                        input={{
                                                            name,
                                                            value,
                                                            onChange,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                        <Field
                                            name='klagefrist'
                                            subscription={{
                                                error: true,
                                                submitError: true,
                                                dirtySinceLastSubmit: true,
                                            }}
                                            render={({meta}) =>
                                                meta.error ||
                                                (meta.submitError &&
                                                    !meta.dirtySinceLastSubmit) ? (
                                                    <Error
                                                        error={
                                                            meta.error ||
                                                            meta.submitError
                                                        }
                                                    />
                                                ) : null
                                            }
                                        />
                                    </div>
                                </Condition>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Condition
                                    when='planProsessId'
                                    predictor={helpers.shouldHaveTilgang(
                                        values.planProsessId
                                    )}
                                >
                                    <FormSelectInput
                                        name='tilgangId'
                                        title='Visning av kommentar'
                                        options={meta.data.tilgangstyper}
                                        keyCol='id'
                                        valueCol='navn'
                                        displayId={false}
                                        nullOption={false}
                                    />
                                </Condition>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Typography>Utfyllende informasjon</Typography>
                                <Field
                                    name='beskrivelse'
                                    component='textarea'
                                    className='formInput textarea'
                                    rows={3}
                                />
                                <FormError name={'beskrivelse'} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Condition
                                    when='planProsessId'
                                    predictor={helpers.shouldHaveMapPositionOption(
                                        values.planProsessId
                                    )}
                                >
                                    <p
                                        style={{
                                            fontWeight: 500,
                                            marginBottom: 0,
                                        }}
                                    >
                                        Valgt posisjon:
                                    </p>
                                    <PositionOptions
                                        planAreaLayer={props.planAreaLayer}
                                        coords={props.coords}
                                        setCoords={props.setCoords}
                                        setDisplaySelectMapPosition={
                                            props.setDisplaySelectMapPosition
                                        }
                                        allFormData={valuesRef}
                                    />
                                </Condition>
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                style={{marginTop: '20px'}}
                            >
                                <Grid
                                    container={true}
                                    justifyContent='space-between'
                                    alignItems='baseline'
                                >
                                    <Grid item={true}>
                                        {props.behandling &&
                                            props.behandling.id &&
                                            ((
                                                <Button
                                                    startIcon={<DeleteIcon />}
                                                    onClick={props.onDelete}
                                                >
                                                    Slett behandling
                                                </Button>
                                            ) ||
                                                null)}
                                    </Grid>
                                    <Grid item={true}>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            onClick={props.onCancel}
                                            disabled={submitting}
                                        >
                                            Avbryt
                                        </Button>

                                        <SpinnerButton
                                            type='submit'
                                            label='Lagre'
                                            style={{marginLeft: '20px'}}
                                            disabled={
                                                submitting ||
                                                (pristine &&
                                                    !props.coordsHasChanged)
                                            }
                                            loading={submitting}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre>
              <pre>Pristine: {JSON.stringify(pristine, null, 2)}</pre>
              <pre>Submitting: {JSON.stringify(submitting, null, 2)}</pre>
              <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              <pre>SubmitErrors: {JSON.stringify(submitErrors, null, 2)}</pre> */}
                    </form>
                </Box>
            )}
        />
    );
}

export default BehandlingForm;
