export const BREAK_MEDIUM = 960;

export const breakpointValues = {
    xs: 0,
    sm: 600,
    md: BREAK_MEDIUM,
    lg: 1280,
    xl: 1920,

    mobile: 0,
    mobilePortrait: 600,
    mobileLandscape: 600,
    tablet: 600,
    laptop: 900,
    desktop: 1200,
    ultrawide: 1536,
};

export const errorCodes = {
    serverError: 'Network Error',
};

export const FILE_SIZE_LIMIT = 200000000;

export const BASE_TITLE = 'arealplaner.no';

export const KLAGEFRIST_WEEKS = 3;

export const municipalitiesWithHeradEnding = ['Voss', 'Kvam', 'Ulvik'];

export const northSamiMunicipalities: string[] = [];

export const southSamiMunicipalities: string[] = ['Namsos'];

export const errorText = 'Noe gikk galt, prøv igjen senere.';

export const introTitle = 'Planprat';

export const introText =
    'Søk på det du lurer på angående plandokumenter. Husk å ikke dele persondata.';
