import {Box} from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import {helpObject} from '../../help/helpObject';

const About = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifySelf: 'center',
                margin: '50px 200px 50px 200px',
            }}
        >
            <ReactMarkdown>{helpObject.Planinfo}</ReactMarkdown>
        </Box>
    );
};

export default About;
