import React from 'react';
import {Checkbox, CheckboxProps} from '@mui/material';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';

const StyledCheckbox = ({style, ...rest}: CheckboxProps) => {
    const disableStyle = rest.disabled ? {opacity: 0.3} : {};
    return (
        <div style={disableStyle}>
            <Checkbox
                {...rest}
                icon={<CheckBoxOutlineBlankSharpIcon color='primary' />}
                checkedIcon={<CheckBoxSharpIcon color='secondary' />}
            />
        </div>
    );
};
export default StyledCheckbox;
