import {createTheme} from '@mui/material/styles';
import {breakpointValues} from './config/const';

export const hoverOverWhiteColor = '#f9f9f9';

const theme = createTheme({
    typography: {
        fontSize: 16,
        htmlFontSize: 16,
        h1: {
            fontSize: '1.6rem',
            lineHeight: '1.5',
            fontWeight: 400,
        },
        h2: {
            fontSize: '1.5rem',
            lineHeight: '1.5',
            fontWeight: 400,
        },
        h3: {
            fontSize: '1.35rem',
            lineHeight: '1.5',
            fontWeight: 400,
        },
        body1: {
            fontSize: '1rem',
            lineHeight: '1.5',
            color: '#2e2d30',
            fontWeight: 400,
        },
    },
    spacing: 2,
    palette: {
        primary: {
            main: '#2e2d30',
        },
        secondary: {
            main: '#1E7400',
        },
        background: {
            default: '#ffffff',
            paper: '#f9f9f9',
        },
        warning: {
            main: '#f5ad4d',
        },
    },
    breakpoints: {values: breakpointValues},
    components: {
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 500,
                    fontSize: '1rem',
                    color: 'black',
                    whiteSpace: 'nowrap',
                },
                root: {
                    wordBreak: 'keep-all',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '0',
                    radius: 'none',
                },
                contained: {
                    boxShadow: 'none',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: '0',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&$focused': {
                        borderColor: 'red',
                    },
                },
                notchedOutline: {
                    '&:hover': {
                        borderColor: 'red!important',
                    },
                    borderRadius: '0',
                },
            },
        },
    },
});

const arealplanerTheme = createTheme(theme, {
    components: {
        MuiButton: {
            variants: [
                {
                    props: {variant: 'contained', color: 'grey'},
                    style: {
                        color: theme.palette.getContrastText(
                            theme.palette.grey[300]
                        ),
                        backgroundColor: '#e0e0e0',
                        '&:hover': {
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                            backgroundColor: '#d5d5d5',
                        },
                    },
                },
            ],
        },
    },
});

export default arealplanerTheme;
