import * as React from 'react';
import {Radio} from '@mui/material';
import {FieldRenderProps} from 'react-final-form';

const Radiobutton = ({
    input: {checked, value, name, onChange, ...otherInput},
    meta,
    ...other
}: FieldRenderProps<any, HTMLElement>) => {
    return (
        <Radio
            {...other}
            name={name}
            value={value}
            checked={!!checked}
            onChange={onChange}
            inputProps={otherInput}
        />
    );
};

export default Radiobutton;
