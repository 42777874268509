import {epsg} from './const';

window.Math.cosh =
    window.Math.cosh ||
    function (x) {
        return (Math.exp(x) + Math.exp(-x)) / 2;
    };

const A = 6378137.0;
const F = 1 / 298.25722;
const E2 = F * (2 - F);

window.Math.sinh =
    window.Math.sinh ||
    function (x) {
        return (Math.exp(x) - Math.exp(-x)) / 2;
    };

const SRS = {
    Unspecified: 0,
    EPSG_32631: 21,
    EPSG_32632: 22,
    EPSG_32633: 23,
    EPSG_32634: 24,
    EPSG_32635: 25,
    EPSG_32636: 26,

    EPSG_25831: 27,
    EPSG_25832: 28,
    EPSG_25833: 29,
    EPSG_25834: 30,
    EPSG_25835: 31,
    EPSG_25836: 21,

    EPSG_3007: 3007, // SWEREF99
    EPSG_3008: 3008,
    EPSG_3009: 3009,
    EPSG_3010: 3010,
    EPSG_3011: 3011,
    EPSG_3012: 3012,
    EPSG_3013: 3013,
    EPSG_3014: 3014,
    EPSG_3015: 3015,
    EPSG_3016: 3016,
    EPSG_3017: 3017,
    EPSG_3018: 3018,

    NTM_205: 205,
    NTM_206: 206,
    NTM_207: 207,
    NTM_208: 208,
    NTM_209: 209,
    NTM_210: 210,
    NTM_211: 211,
    NTM_212: 212,
    NTM_213: 213,
    NTM_214: 214,
    NTM_215: 215,
    NTM_216: 216,
    NTM_217: 217,
    NTM_218: 218,
    NTM_219: 219,
    NTM_220: 220,
    NTM_221: 221,
    NTM_222: 222,
    NTM_223: 223,
    NTM_224: 224,
    NTM_225: 225,
    NTM_226: 226,
    NTM_227: 227,
    NTM_228: 228,
    NTM_229: 229,
    NTM_230: 230,
    EPSG_4326: 231,
    EPSG_54004: 232,
    EPSG_3857: 233,
    EPSG_900913: 234,
    EPSG_3395: 235,
};

function Deg2Rad(dDeg) {
    const dRad = (dDeg * Math.PI) / 180.0;

    return dRad;
}

function Rad2Deg(dRad) {
    const dGrader = dRad * (180.0 / Math.PI);

    return dGrader;
}

export function transform(north, east, fromEPSG, toEPSG) {
    if (fromEPSG === SRS.Unspecified)
        throw new Error('Unspecified fromSRS:' + fromEPSG);

    if (toEPSG === SRS.Unspecified)
        throw new Error('Unspecified toSRS: ' + toEPSG);

    if (fromEPSG === toEPSG)
        return {
            north: north,
            east: east,
            epsg: toEPSG,
        };

    let latitude = north;
    let longitude = east;

    let coord;

    switch (fromEPSG) {
        case epsg.LAT_LNG: {
            //SRS.EPSG_4326:
            latitude = Deg2Rad(latitude);
            longitude = Deg2Rad(longitude);
            coord = [latitude, longitude];

            break;
        }
        case epsg.EPSG_54004: // SRS.EPSG_54004:
        case epsg.WORLD_MERCATOR: {
            //SRS.EPSG_3395:
            coord = EllipsoidalMercatorToLatLon(north, east);
            break;
        }
        case epsg.EPSG_3857: // SRS.EPSG_3857:
        case epsg.EPSG_900913: {
            // SRS.EPSG_900913:
            coord = SphericalMercatorToLatLon(north, east);
            break;
        }
        case epsg.UTM31: //SRS.EPSG_32631:
        case epsg.EPSG_25831: {
            // SRS.EPSG_25831:
            coord = UtmToLatLon(north, east, latitude, longitude, 3.0);
            break;
        }
        case epsg.UTM32: //SRS.EPSG_32632:
        case epsg.EPSG_25832: {
            // SRS.EPSG_25832:
            coord = UtmToLatLon(north, east, latitude, longitude, 9.0);
            break;
        }
        case epsg.UTM33: // SRS.EPSG_32633:
        case epsg.EPSG_25833: {
            // SRS.EPSG_25833:
            coord = UtmToLatLon(north, east, latitude, longitude, 15.0);
            break;
        }
        case epsg.UTM34: // SRS.EPSG_32634:
        case epsg.EPSG_25834: {
            // SRS.EPSG_25834:
            coord = UtmToLatLon(north, east, latitude, longitude, 21.0);
            break;
        }
        case epsg.UTM35: // SRS.EPSG_32635:
        case epsg.EPSG_25835: {
            // SRS.EPSG_25835:
            coord = UtmToLatLon(north, east, latitude, longitude, 27.0);
            break;
        }
        case epsg.UTM36: // SRS.EPSG_32636:
        case epsg.EPSG_25836: {
            // SRS.EPSG_25835:SRS.EPSG_25836:
            coord = UtmToLatLon(north, east, latitude, longitude, 33.0);
            break;
        }
        /*  wait with supporting NTM
                        case SRS.NTM_205:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 5.5);
                                break;
                            }
                        case SRS.NTM_206:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 6.5);
                                break;
                            }
                        case SRS.NTM_207:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 7.5);
                                break;
                            }
                        case SRS.NTM_208:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 8.5);
                                break;
                            }
                        case SRS.NTM_209:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 9.5);
                                break;
                            }
                        case SRS.NTM_210:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 10.5);
                                break;
                            }
                        case SRS.NTM_211:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 11.5);
                                break;
                            }
                        case SRS.NTM_212:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 12.5);
                                break;
                            }
                        case SRS.NTM_213:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 13.5);
                                break;
                            }
                        case SRS.NTM_214:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 14.5);
                                break;
                            }
                        case SRS.NTM_215:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 15.5);
                                break;
                            }
                        case SRS.NTM_216:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 16.5);
                                break;
                            }
                        case SRS.NTM_217:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 17.5);
                                break;
                            }
                        case SRS.NTM_218:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 18.5);
                                break;
                            }
                        case SRS.NTM_219:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 19.5);
                                break;
                            }
                        case SRS.NTM_220:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 20.5);
                                break;
                            }
                        case SRS.NTM_221:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 21.5);
                                break;
                            }
                        case SRS.NTM_222:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 22.5);
                                break;
                            }
                        case SRS.NTM_223:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 23.5);
                                break;
                            }
                        case SRS.NTM_224:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 24.5);
                                break;
                            }
                        case SRS.NTM_225:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 25.5);
                                break;
                            }
                        case SRS.NTM_226:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 26.5);
                                break;
                            }
                        case SRS.NTM_227:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 27.5);
                                break;
                            }
                        case SRS.NTM_228:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 28.5);
                                break;
                            }
                        case SRS.NTM_229:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 29.5);
                                break;
                            }
                        case SRS.NTM_230:
                            {
                                NtmToLatLon(north, east, ref latitude, ref longitude, 30.5);
                                break;
                            }*/
        default:
            throw new Error('Unknown coord sys');
    }

    latitude = coord[0];
    longitude = coord[1];

    switch (toEPSG) {
        case epsg.LAT_LNG: {
            // SRS.EPSG_4326:
            //Convert to Degrees
            north = Rad2Deg(latitude);
            east = Rad2Deg(longitude);
            coord = [north, east];
            break;
        }
        case epsg.EPSG_54004: //SRS.EPSG_54004:
        case epsg.WORLD_MERCATOR: {
            // SRS.EPSG_3395:
            coord = LatLonToEllipsoidalMercator(latitude, longitude);
            break;
        }
        case epsg.EPSG_900913: // SRS.EPSG_900913:
        case epsg.EPSG_3857: {
            // SRS.EPSG_3857:
            coord = LatLonToSphericalMercator(latitude, longitude);
            break;
        }
        case epsg.UTM31: // SRS.EPSG_32631:
        case epsg.EPSG_25831: {
            // SRS.EPSG_25831:
            coord = LatLonToUtm(latitude, longitude, north, east, 3.0);
            break;
        }
        case epsg.UTM32: // SRS.EPSG_32632:
        case epsg.EPSG_25832: {
            // SRS.EPSG_25832:
            coord = LatLonToUtm(latitude, longitude, north, east, 9.0);
            break;
        }
        case epsg.UTM33: // SRS.EPSG_32633:
        case epsg.EPSG_25833: {
            // SRS.EPSG_25833:
            coord = LatLonToUtm(latitude, longitude, north, east, 15.0);
            break;
        }
        case epsg.UTM34: // SRS.EPSG_32634:
        case epsg.EPSG_25834: {
            // SRS.EPSG_25834:
            coord = LatLonToUtm(latitude, longitude, north, east, 21.0);
            break;
        }
        case epsg.UTM35: // SRS.EPSG_32635:
        case epsg.EPSG_25835: {
            // SRS.EPSG_25835:
            coord = LatLonToUtm(latitude, longitude, north, east, 27.0);
            break;
        }
        case epsg.UTM36: // SRS.EPSG_32636:
        case epsg.EPSG_25836: {
            // SRS.EPSG_25836:
            coord = LatLonToUtm(latitude, longitude, north, east, 33.0);
            break;
        }
        /* wait with supporting NTM
                        case SRS.NTM_205:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 5.5);
                                break;
                            }
                        case SRS.NTM_206:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 6.5);
                                break;
                            }
                        case SRS.NTM_207:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 7.5);
                                break;
                            }
                        case SRS.NTM_208:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 8.5);
                                break;
                            }
                        case SRS.NTM_209:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 9.5);
                                break;
                            }
                        case SRS.NTM_210:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 10.5);
                                break;
                            }
                        case SRS.NTM_211:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 11.5);
                                break;
                            }
                        case SRS.NTM_212:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 12.5);
                                break;
                            }
                        case SRS.NTM_213:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 13.5);
                                break;
                            }
                        case SRS.NTM_214:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 14.5);
                                break;
                            }
                        case SRS.NTM_215:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 15.5);
                                break;
                            }
                        case SRS.NTM_216:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 16.5);
                                break;
                            }
                        case SRS.NTM_217:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 17.5);
                                break;
                            }
                        case SRS.NTM_218:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 18.5);
                                break;
                            }
                        case SRS.NTM_219:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 19.5);
                                break;
                            }
                        case SRS.NTM_220:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 20.5);
                                break;
                            }
                        case SRS.NTM_221:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 21.5);
                                break;
                            }
                        case SRS.NTM_222:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 22.5);
                                break;
                            }
                        case SRS.NTM_223:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 23.5);
                                break;
                            }
                        case SRS.NTM_224:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 24.5);
                                break;
                            }
                        case SRS.NTM_225:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 25.5);
                                break;
                            }
                        case SRS.NTM_226:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 26.5);
                                break;
                            }
                        case SRS.NTM_227:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 27.5);
                                break;
                            }
                        case SRS.NTM_228:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 28.5);
                                break;
                            }
                        case SRS.NTM_229:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 29.5);
                                break;
                            }
                        case SRS.NTM_230:
                            {
                                LatLonToNtm(latitude, longitude, ref north, ref east, 30.5);
                                break;
                            }*/
        default:
            throw new Error('Not implemented for coord system');
    }

    return {
        north: coord[0],
        east: coord[1],
        epsg: toEPSG,
    };

    function LatLonToGausisk(dB, dL, pdX, pdY, l0) {
        l0 = (l0 / 180.0) * Math.PI; // l0: tangeringsmeridian
        const e = Math.sqrt(F * (2 - F)); // Eccentricity

        const df2 = F * F; // pow(F,2)
        const df3 = df2 * F; // pow(F,3)

        dL = Kvadrant(dL - l0, 0, -2); // dL : lengdeforskjell

        const b0 = A * (1 - F / 2.0 + df2 / 16.0 + df3 / 32.0);
        const b1 = A * (F / 4 - df2 / 6.0 - (df3 * 11.0) / 384.0);
        const b2 = A * ((df2 * 13.0) / 192.0 - (df3 * 79.0) / 1920.0);
        const b3 = A * ((df3 * 61.0) / 1920.0);

        const w =
            (Math.atan(
                Math.tan(dB / 2.0 + Math.PI / 4.0) *
                    Math.pow(
                        (1.0 - e * Math.sin(dB)) / (1.0 + e * Math.sin(dB)),
                        e / 2.0
                    )
            ) -
                Math.PI / 4.0) *
            2.0;

        const u = Math.atan2(Math.tan(w), Math.cos(dL));
        const v =
            Math.log(
                (1.0 + Math.cos(w) * Math.sin(dL)) /
                    (1.0 - Math.cos(w) * Math.sin(dL))
            ) / 2.0;

        const d2u = 2.0 * u;
        const d2v = 2.0 * v;
        const d4u = 4.0 * u;
        const d4v = 4.0 * v;
        const d6u = 6.0 * u;
        const d6v = 6.0 * v;

        pdX =
            b0 * u +
            b1 * Math.sin(d2u) * window.Math.cosh(d2v) +
            b2 * Math.sin(d4u) * window.Math.cosh(d4v) +
            b3 * Math.sin(d6u) * window.Math.cosh(d6v);
        pdY =
            b0 * v +
            b1 * Math.cos(d2u) * window.Math.sinh(d2v) +
            b2 * Math.cos(d4u) * window.Math.sinh(d4v) +
            b3 * Math.cos(d6u) * window.Math.sinh(d6v);

        return [pdX, pdY];
    }

    function GausiskToLatLon(dX, dY, pdB, pdL, l0) {
        //let u, v, p, l, w, fi;

        l0 = (l0 / 180.0) * Math.PI; // l0: tangeringsmeridian
        const e = Math.sqrt(F * (2.0 - F)); // Eccentricity

        const df2 = F * F; // pow(F,2)
        const df3 = df2 * F; // pow(F,3)

        const b0 = A * (1.0 - F / 2.0 + df2 / 16.0 + df3 / 32.0);
        const c1 = F / 4.0 - df2 / 24.0 - (df3 * 43.0) / 768.0;
        const c2 = df2 / 192.0 + (df3 * 13.0) / 960.0;
        const c3 = (df3 * 17.0) / 3840.0;

        const d2X = 2.0 * dX;
        const d2Y = 2.0 * dY;
        const d4X = 4.0 * dX;
        const d4Y = 4.0 * dY;
        const d6X = 6.0 * dX;
        const d6Y = 6.0 * dY;

        const u =
            dX / b0 -
            c1 * Math.sin(d2X / b0) * window.Math.cosh(d2Y / b0) -
            c2 * Math.sin(d4X / b0) * window.Math.cosh(d4Y / b0); // - c3*sin(d6X/b0)*cosh(d6Y/b0);
        const v =
            dY / b0 -
            c1 * Math.cos(d2X / b0) * window.Math.sinh(d2Y / b0) -
            c2 * Math.cos(d4X / b0) * window.Math.sinh(d4Y / b0); // - c3*cos(d6X/b0)*sinh(d6Y/b0);

        const p = (Math.atan(Math.exp(v)) - Math.PI / 4.0) * 2.0;

        // l	 : lengdeforskjell    (rad)
        const l = Math.atan2(Math.tan(p), Math.cos(u)); // Longitude relative to the central meridian

        const w = Math.atan2(
            Math.sin(u),
            Math.cos(u) * Math.cos(l) + Math.tan(p) * Math.sin(l)
        );

        const fi =
            w +
            (F + df2 / 3.0 - df3 / 6.0) * Math.sin(2.0 * w) +
            ((df2 * 7.0) / 12.0 + (df3 * 23.0) / 60.0) * Math.sin(4.0 * w) +
            ((df3 * 7.0) / 15.0) * Math.sin(6.0 * w);

        pdB = fi; // Latitude in rad
        pdL = Kvadrant(l + l0, 0, -2); // Longitude in rad

        return [pdB, pdL];
    }

    function GausiskToUtm(dNorthGausisk, dEastGausisk) {
        const K = 0.9996;
        const N0 = 0;
        const E0 = 500000;

        const N = dNorthGausisk * K + N0;
        const E = dEastGausisk * K + E0;

        const pdNorthUtm = N;
        const pdEastUtm = E;

        return [pdNorthUtm, pdEastUtm];
    }

    function UtmToGausisk(dXutm, dYutm) {
        const K = 0.9996;
        const N0 = 0;
        const E0 = 500000; //AddE

        const N = dXutm;
        const E = dYutm;

        const pdXg = (N - N0) / K; // Målestokk på sentralmer og Translasjon
        const pdYg = (E - E0) / K;

        return [pdXg, pdYg];
    }

    function UtmToLatLon(dNorthUtm, dEastUtm, pdB, pdL, l0) {
        const gausisk = UtmToGausisk(dNorthUtm, dEastUtm);
        const dNorthGausisk = gausisk[0];
        const dEastGausisk = gausisk[1];

        const latLng = GausiskToLatLon(
            dNorthGausisk,
            dEastGausisk,
            pdB,
            pdL,
            l0
        );
        return latLng;
    }

    function LatLonToUtm(dB, dL, pdNorthUtm, pdEastUtm, l0) {
        // eslint-disable-next-line prefer-const
        let dNorthGausisk, dEastGausisk;

        const gausisk = LatLonToGausisk(
            dB,
            dL,
            dNorthGausisk,
            dEastGausisk,
            l0
        );
        dNorthGausisk = gausisk[0];
        dEastGausisk = gausisk[1];

        const utm = GausiskToUtm(dNorthGausisk, dEastGausisk);
        return utm;
    }

    function GausiskToNtm(dXg, dYg, pdXutm, pdYutm) {
        const K = 1; //Skalafaktor
        const N0 = -5431282.672; //AddN
        const E0 = 100000; //AddE

        const N = dXg * K + N0;
        const E = dYg * K + E0;

        pdXutm = N;
        pdYutm = E;
        return [pdXutm, pdYutm];
    }

    function NtmToGausisk(dXutm, dYutm) {
        const K = 1; //Skalafaktor
        const N0 = -5431282.672; //AddN
        const E0 = 100000; //AddE

        const N = dXutm;
        const E = dYutm;

        const pdXg = (N - N0) / K; // Målestokk på sentralmer og Translasjon
        const pdYg = (E - E0) / K;
        return [pdXg, pdYg];
    }

    function NtmToLatLon(dNorthUtm, bEastUtm, pdB, pdL, l0) {
        const gausisk = NtmToGausisk(dNorthUtm, bEastUtm);
        const dNorthGausisk = gausisk[0];
        const dEastGausisk = gausisk[1];
        const latLng = GausiskToLatLon(
            dNorthGausisk,
            dEastGausisk,
            pdB,
            pdL,
            l0
        );
        return latLng;
    }

    function LatLonToNtm(dB, dL, pdXutm, pdYutm, l0) {
        // eslint-disable-next-line prefer-const
        let dXg, dYg;
        const gausisk = LatLonToGausisk(dB, dL, dXg, dYg, l0);
        dXg = gausisk[0];
        dYg = gausisk[1];

        const utm = GausiskToNtm(dXg, dYg, pdXutm, pdYutm);
        return utm;
    }

    function LatLonToEllipsoidalMercator(lat, lon) {
        if (lat >= 0.5 * Math.PI || lat <= -(0.5 * Math.PI)) {
            return false;
        }

        //const double k0_ = 1;
        //const double lon0_ = 0;

        //lon -= lon0_;
        const east = A * lon;

        const e = Math.sqrt(E2);
        //double e = 0.05790346021439801066627631850595;
        const sin_lat = Math.sin(lat);
        let north = Math.log(
            ((1 + sin_lat) / (1 - sin_lat)) *
                Math.pow((1 - e * sin_lat) / (1 + e * sin_lat), e)
        );
        north *= A / 2;

        return [north, east];
    }

    function EllipsoidalMercatorToLatLon(dNorth, dEast) {
        const lng = dEast / A;

        const e = Math.sqrt(E2);
        //double e = 0.05790346021439801066627631850595;
        const t = Math.pow(Math.E, -dNorth / A);

        let lat = 0.5 * Math.PI - 2 * Math.atan(t);
        let prev_lat;
        do {
            prev_lat = lat;
            const sin_lat = Math.sin(lat);
            lat =
                0.5 * Math.PI -
                2 *
                    Math.atan(
                        t *
                            Math.pow(
                                (1 - e * sin_lat) / (1 + e * sin_lat),
                                e / 2
                            )
                    );
        } while (Math.abs(lat - prev_lat) > 1e-9);

        return [lat, lng];
    }

    function LatLonToSphericalMercator(lat, lon) {
        if (lat >= 0.5 * Math.PI || lat <= -(0.5 * Math.PI)) {
            return false;
        }

        //const double k0_ = 1;
        //const double lon0_ = 0;

        //lon -= lon0_;
        east = A * lon;

        const e = 0;
        //double e = 0.05790346021439801066627631850595;
        const sin_lat = Math.sin(lat);
        north = Math.log(
            ((1 + sin_lat) / (1 - sin_lat)) *
                Math.pow((1 - e * sin_lat) / (1 + e * sin_lat), e)
        );
        north *= A / 2;

        return [north, east];
    }

    function SphericalMercatorToLatLon(dNorth, dEast) {
        const lng = dEast / A;

        const e = 0;
        //double e = 0.05790346021439801066627631850595;
        const t = Math.pow(Math.E, -dNorth / A);

        let lat = 0.5 * Math.PI - 2 * Math.atan(t);
        let prev_lat;
        do {
            prev_lat = lat;
            const sin_lat = Math.sin(lat);
            lat =
                0.5 * Math.PI -
                2 *
                    Math.atan(
                        t *
                            Math.pow(
                                (1 - e * sin_lat) / (1 + e * sin_lat),
                                e / 2
                            )
                    );
        } while (Math.abs(lat - prev_lat) > 1e-9);

        return [lat, lng];
    }

    function Kvadrant(r, Enhet, StartKvadrant) {
        const runde = [2.0 * Math.PI, 360.0, 400.0];

        let r1 = r;
        const rundt = runde[Enhet];
        const min = 0.25 * rundt * StartKvadrant;
        const max = min + rundt;

        while (r1 > max) {
            r1 = r1 - runde[Enhet];
        }
        while (r1 < min) {
            r1 = r1 + runde[Enhet];
        }

        return r1;
    }
}
