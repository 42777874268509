import * as React from 'react';
import {useQuery} from 'react-query';
import {useFetchTokenHeaderWithAuthData} from '@norkart/nk-auth';
import {graphQLRequest} from '../../services/api';
import {PlanforholdQueryVariables} from '../../features/reports/Reports/Planforhold/Planforhold';
import {ArealplanReport} from './types';
import {arealplanerReportCsvQuery} from './arealplanerQueries';

export function useArealplanQLCsv(variables: PlanforholdQueryVariables) {
    const headers = [
        'PlanId',
        'Plannavn',
        'Plantype',
        'PlantypeId',
        'Planstatus',
        'PlanstatusId',
        'IKraft',
        'Lovreferanse',
        'LovreferanseId',
    ];
    const getHeaders = useFetchTokenHeaderWithAuthData();
    const reactQuery = useQuery<any>(
        ['arealplanReportCsv'],
        async () => {
            const headers = await getHeaders();
            return await graphQLRequest(
                arealplanerReportCsvQuery,
                variables,
                headers
            );
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
            cacheTime: 0,
            keepPreviousData: false,
        }
    );

    const csvArray = React.useMemo(() => {
        if (reactQuery.data) {
            const arealplaner: ArealplanReport[] = reactQuery.data.arealplaner;

            const newLines = arealplaner.reduce((acc: any, d) => {
                acc.push(
                    [
                        d.planId,
                        d.planNavn,
                        d.planType,
                        d.planTypeId,
                        d.planStatus,
                        d.planStatusId,
                        d.iKraft,
                        d.lovreferanse,
                        d.lovreferanseId,
                    ].join(';')
                );
                return acc;
            }, []);

            return newLines;
        }
    }, [reactQuery.data]);

    return {...reactQuery, csvData: csvArray, headers: headers};
}
