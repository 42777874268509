import * as React from 'react';
import {ColumnDef} from '@tanstack/react-table';
import {Link} from '@mui/material';
import {LogLines} from '../../../../hooks/konsistenssjekk/types';

export default function KonsistenssjekkColumns(
    kundeId: string,
    kommunenummer: string
) {
    const baseUrl = window.location.origin;

    const columns = React.useMemo<ColumnDef<LogLines>[]>(
        () => [
            {
                id: 'planId',
                header: () => 'PlanId',
                cell: (r) => (
                    <Link
                        key={r.row.id + 'link'}
                        href={`${baseUrl}/${kundeId}/gi?funksjon=VisPlan&planidentifikasjon=${r.row.original?.planId}&kommunenummer=${kommunenummer}`}
                        target='_blank'
                        variant='body1'
                        underline='none'
                        color='secondary'
                    >
                        {r.row.original?.planId}
                    </Link>
                ),
                enableSorting: true,
                minSize: 150,
            },
            {
                accessorKey: 'kategoriFeil',
                header: () => 'Feil',
                enableSorting: true,
                minSize: 190,
            },
            {
                accessorKey: 'qmsOppgavenavn',
                header: () => 'Oppgavenavn QMS',
                enableSorting: true,
                minSize: 220,
            },
            {
                accessorKey: 'verdiArealplaner',
                header: () => 'Verdi arealplaner',
                enableSorting: true,
                minSize: 400,
            },
            {
                accessorKey: 'verdiQms',
                header: () => 'Verdi QMS',
                enableSorting: true,
                minSize: 400,
            },
        ],
        []
    );
    return columns;
}
