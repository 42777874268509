import {useMatch} from 'react-router-dom';
import {ArealplanId} from './types';

export default function useSelectedArealplanId() {
    const match = useMatch('/:customerId/arealplaner/:id/*');

    const id = parseInt(match?.params.id || '0');

    if (isNaN(id)) {
        return 0 as ArealplanId;
    } else {
        return id as ArealplanId;
    }
}
