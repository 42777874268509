//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const ArrowDownIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M13.0034 15.1618L15.802 12.3633L17.2162 13.7775L12 18.9938L6.78369 13.7775L8.1979 12.3633L11.0034 15.1688L11.0034 4.99377L13.0034 4.99377L13.0034 15.1618Z' style={props.secondaryStyle} />
        </g>
    ),
    'ArrowDownIcon' // aria-label
);
