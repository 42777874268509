import {Component} from 'react';
import React from 'react';
import {
    searchAppBackend,
    searchToken,
    searchApiKey,
} from './utils/searchFunction';
import SearchBox from './utils/SearchBox';
import {parseEiendomsident} from './utils/HitList';
import {SearchResponse, Hit} from './utils/types';
import {isValidCoordiate} from './coordinateHelper';

type State = {
    text?: string;
    hits?: Hit[];
    hoverIndex: undefined | number;
    selectedIndex: undefined | number;
    resultStatus?: string;
    showNoResults: boolean;
    displayHits: boolean;
    isSubSearch: boolean;
    lastSearchValue?: string;
    isExtraSearchActive: boolean;
    isInitSearch: boolean;
    savedHit?: Hit;
    selectedStreetHit?: Hit;
    isHitSelected: boolean;
    fullScreen: boolean;
    defaultDocumentOverflow: string;
    defaultBodyOverflow: string;
    defaultBodyPosition: string;
};

type Props = {
    id?: string;
    hitSelected: (hit: Hit, shouldFormat: boolean) => void;
    targets?: string[];
    className?: string;
    placeholder?: string;
    noHitsMessage?: string;
    closeOnSelect?: boolean;
    onChangeCallback?: (value: string) => void;
    limits?: string[];
    apiKey?: string;
    NkAuth?: any;
    nkToken?: any;
    setCoordinates?: string;
    initialValue?: string;
    subSearch?: {
        useSubSearch: (hit: Hit) => boolean;
        search: (text: string, props, searchFunction, hit: Hit) => Promise<any>;
    };
    enableStreetButton?: boolean;
    numResults?: number;
    extraSearch?: (search: string) => SearchResponse | undefined;
    onExternalSearch?: (searchValue: string) => void;
    theme?: string;
    whenClearingResults?: () => void;
    statementColor?: string;
    searchIconColor?: string;
    shouldFormatResult?: boolean;
    autofocus?: boolean;
    mobileFriendly?: boolean;
    savedHit?: Hit;
    hitlistStyle?: any;
    groupByType?: boolean;
    multiCustomerContext?: string;
    hideSearchButton?: boolean;
    inputAriaLabel?: string;
    disableNewPropertyRedirect?: boolean;
};

//Removing undefined option for props that gets a default value

class NkmNorkartSearch extends Component<Props, State> {
    private wrapperRef; // = React.createRef<any>();
    state: State = {
        text: '',
        hits: [],
        hoverIndex: undefined,
        selectedIndex: undefined,
        resultStatus: 'ok',
        showNoResults: false,
        displayHits: false,
        isSubSearch: false,
        lastSearchValue: undefined,
        isExtraSearchActive: false,
        isInitSearch: this.props.initialValue ? true : false,
        savedHit: this.props.savedHit,
        selectedStreetHit: undefined,
        isHitSelected: false,
        fullScreen: false,
        defaultDocumentOverflow: document.documentElement.style.overflowY
            ? document.documentElement.style.overflowY
            : '',
        defaultBodyOverflow: document.body.style.overflow
            ? document.body.style.overflow
            : '',
        defaultBodyPosition: document.body.style.position
            ? document.body.style.position
            : '',
    };
    baseState = this.state;

    public static defaultProps = {
        closeOnSelect: true,
        shouldFormatResult: true,
        targets: ['matrikkelenhet', 'gateadresse'],
        numResults: 10,
        theme: 'light',
        placeholder: 'Søk',
        statementColor: '#008000',
        searchIconColor: '#008000',
        noHitsMessage: 'Ingen treff',
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            this.props.setCoordinates &&
            prevProps.setCoordinates !== this.props.setCoordinates &&
            this.props.setCoordinates !== this.state.text
        ) {
            if (this.props.setCoordinates) {
                this.setState({
                    text: this.props.setCoordinates,
                    hits: [],
                });
                this.getSearchFunction(
                    this.props.setCoordinates,
                    this.props,
                    this.props.targets,
                    'search'
                )
                    .then(this.gotSearchStringResult)
                    .catch(this.handleError);
            }
        }
        if (prevState.fullScreen !== this.state.fullScreen) {
            if (!this.state.fullScreen) {
                document.documentElement.style.overflowY =
                    this.state.defaultDocumentOverflow;
                document.body.style.position = this.state.defaultBodyPosition;
            } else {
                const searchBox = document.getElementById('SearchBox');
                if (searchBox) {
                    searchBox.scrollIntoView();
                }
                document.documentElement.style.overflowY = 'hidden';
                document.body.style.position = 'fixed';
            }
        }
        if (prevState.isHitSelected !== this.state.isHitSelected) {
            if (this.state.isHitSelected) {
                this.setState({fullScreen: false});
            }
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        const value = this.props.initialValue;
        if (value !== undefined && value !== undefined && value !== '') {
            this.searchApi(value);
        }
        // rewrite got result
        if (this.props.savedHit) {
            const hit = this.props.savedHit.properties
                ? {
                      Text: this.props.savedHit.properties.Text,
                      Type: this.props.savedHit.properties.Type,
                      Id: this.props.savedHit.properties.Id,
                  }
                : this.props.savedHit;
            const matrikkelType = 'matrikkelenhet';
            if (hit && hit.Text) {
                this.setState({text: hit.Text});
                if (hit.Text.length > 0) {
                    if (
                        !this.props.apiKey &&
                        !this.props.NkAuth &&
                        !this.props.nkToken
                    ) {
                        throw new Error('Supply either apiKey, token or auth');
                    }
                    if (this.props.extraSearch && hit.Type === 'Point') {
                        const res = this.props.extraSearch(hit.Text);
                        this.setState({
                            text: hit.Text,
                            resultStatus: res ? 'ok' : 'error',
                            isExtraSearchActive: true,
                        });
                    } else {
                        this.getSearchFunction(
                            hit.Text,
                            this.props,
                            hit.Type === matrikkelType
                                ? [hit.Type]
                                : this.props.targets,
                            'search'
                        )
                            .then(this.gotSavedResult)
                            .catch(this.handleError);
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    async getSearchFunction(
        searchTerm: string,
        props,
        targets,
        searchOperation: undefined | string = undefined,
        numResults: undefined | number = undefined,
        searchObject: any = undefined,
        disableNewPropertyRedirect = false
    ) {
        if (props.apiKey) {
            return await searchApiKey(
                searchTerm,
                targets,
                props.limits,
                numResults ? numResults : props.numResults,
                props.apiKey,
                searchOperation,
                searchObject,
                props.groupByType,
                disableNewPropertyRedirect
            );
        } else if (props.NkAuth) {
            return await searchAppBackend(
                searchTerm,
                targets,
                props.limits,
                numResults ? numResults : props.numResults,
                props.NkAuth,
                searchOperation,
                searchObject,
                props.groupByType,
                props.multiCustomerContext,
                disableNewPropertyRedirect
            );
        } else if (props.nkToken) {
            return await searchToken(
                searchTerm,
                targets,
                props.limits,
                numResults ? numResults : props.numResults,
                props.nkToken,
                searchOperation,
                searchObject,
                props.groupByType,
                props.multiCustomerContext,
                disableNewPropertyRedirect
            );
        }
    }

    gotSavedResult(hits) {
        if (hits.length > 0) {
            this.setState({
                hits: hits,
                displayHits: false,
                hoverIndex: undefined,
                selectedIndex: undefined,
                showNoResults: false,
                isExtraSearchActive: false,
                resultStatus: 'ok',
                isInitSearch: false,
            });
            this.props.hitSelected(hits[0], !!this.props.shouldFormatResult);
        }
    }
    //update this to work with hits
    gotSearchStringResult = (hits) => {
        if (this.props.extraSearch && this.props.setCoordinates) {
            const res = this.props.extraSearch(this.props.setCoordinates);
            this.setState({
                resultStatus: res ? 'ok' : 'error',
                isExtraSearchActive: true,
            });
        }
    };

    onKeyDown = (event: React.KeyboardEvent) => {
        const key = event.key;
        const target = event.target as HTMLTextAreaElement;
        const searchQuery = target.value;

        switch (key) {
            case 'Enter': {
                if (this.state.hoverIndex === undefined) {
                    if (this.props.onExternalSearch) {
                        this.props.onExternalSearch(searchQuery);
                        this.closeHits();
                        return;
                    }
                    if (this.state.isExtraSearchActive) {
                        if (this.props.extraSearch) {
                            const res = this.props.extraSearch(searchQuery);
                            const stateObj: {
                                text?: string;
                                resultStatus?: 'ok' | 'error';
                            } = {
                                resultStatus: res ? 'ok' : 'error',
                            };
                            if (res) {
                                stateObj.text = searchQuery;
                            }
                            this.setState(stateObj);
                        } else {
                            this.setState({isExtraSearchActive: false});
                        }
                    } else if (
                        this.state.hits &&
                        this.state.hits.length !== 1
                    ) {
                        this.searchApi(searchQuery);
                    }
                    if (this.state.hits && this.state.hits.length === 1) {
                        this.hitSelected(0);
                    }
                } else {
                    this.setState({
                        resultStatus: 'ok',
                    });
                    const selectedIndex = this.state.hoverIndex;

                    if (this.state.isSubSearch && selectedIndex === 0) {
                        this.goBack();
                    } else if (
                        this.state.isSubSearch &&
                        this.props.enableStreetButton &&
                        selectedIndex === 1
                    ) {
                        this.returnStreetName();
                    } else {
                        this.hitSelected(selectedIndex);
                    }
                }
                break;
            }
            case 'ArrowDown': {
                // Open the hits list if it has been closed by onExternalSearch
                this.openHits();
                this.changeHoverIndex(1);
                break;
            }
            case 'ArrowUp': {
                // Open the hits list if it has been closed by onExternalSearch
                this.openHits();
                if (this.state.hoverIndex === 0) {
                    // This is relevant when we have a onExternalSearch prop
                    // When the user press the down key once to the first element in the hit list, but regrets it and
                    // press the up key to continue typing, the focus is set on the last element in the hit list,
                    // and it is impossible to trigger onExternalSearch
                    this.setHoverIndex(undefined);
                } else {
                    this.changeHoverIndex(-1);
                }
                break;
            }
            case 'Delete':
            case 'Backspace': {
                const stateObj: any = {
                    isExtraSearchActive: false,
                };
                if (this.state.isSubSearch) {
                    stateObj.isSubSearch = false;
                }
                this.setState(stateObj);
                break;
            }
        }
    };
    // comment
    hitSelected = (selectedIndex) => {
        if (this.props.enableStreetButton && !this.state.isSubSearch) {
            this.setState({
                selectedStreetHit:
                    this.state.hits && this.state.hits[selectedIndex],
            });
        }

        if (this.state.isSubSearch && selectedIndex > 0) {
            if (this.props.enableStreetButton) {
                selectedIndex -= 2; // Street button and back button
            } else {
                selectedIndex -= 1; //Back button
            }
        }

        this.setState({selectedIndex: selectedIndex});

        const hit = this.state.hits && this.state.hits[selectedIndex];

        if (!hit) {
            return;
        }
        if (hit.PayLoad && hit.PayLoad.Utgatt) {
            if (!this.props.disableNewPropertyRedirect) {
                const replacedSearchText: string = parseEiendomsident(
                    hit.PayLoad.NyMatrikkelenhet
                ) as string;
                this.setState({text: replacedSearchText});
                this.searchApi(replacedSearchText);
                return;
            }
            this.setState({isHitSelected: true});
            this.searchApi(
                hit.Text,
                undefined,
                'search',
                hit,
                this.props.disableNewPropertyRedirect
            );
            return;
        }

        if (this.props.subSearch && this.props.subSearch.useSubSearch(hit)) {
            this.props.subSearch
                .search(hit.Text, this.props, this.getSearchFunction, hit)
                .then(this.gotResults)
                .catch((err) => {
                    this.handleError(err);
                });
            const index = hit.Text.indexOf(',');
            const text = hit.Text.substring(0, index);

            this.setState({
                hits: [hit],
                lastSearchValue: this.state.text,
                text: text + ' ',
                isSubSearch: true,
                displayHits: true,
            });
        } else {
            this.setState({isHitSelected: true});
            this.searchApi(hit.Text, undefined, 'search', hit);
        }
    };

    goBack = () => {
        this.searchApi(this.state.lastSearchValue);
        this.setState({
            text: this.state.lastSearchValue,
            displayHits: false,
            isSubSearch: false,
        });
    };

    returnStreetName = () => {
        this.setState({
            text: this.state.selectedStreetHit?.Text,
            displayHits: false,
            isSubSearch: false,
            fullScreen: false,
        });
        if (this.state.selectedStreetHit) {
            this.props.hitSelected(
                this.state.selectedStreetHit,
                !!this.props.shouldFormatResult
            );
        }
    };

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.closeHits();
        }
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    closeHits = () => {
        if (this.state && this.state.displayHits) {
            this.setState({displayHits: false});
        }
    };

    openHits = () => {
        if (this.state.hits && this.state.hits.length && this.state.text) {
            this.setState({displayHits: true});
        }
    };

    setHoverIndex = (index) => {
        this.setState({hoverIndex: index});
    };

    changeHoverIndex = (delta) => {
        const currentIndex =
            this.state.hoverIndex !== undefined ? this.state.hoverIndex : -1;
        let newIndex = currentIndex + delta;
        let maxLimit = this.state.hits ? this.state.hits.length : 0;
        if (this.state.isSubSearch) {
            maxLimit = maxLimit + 1;
        }

        if (newIndex >= maxLimit) {
            newIndex = 0;
        } else if (newIndex < 0) {
            newIndex = this.state.hits && this.state.hits.length - 1;
        }
        this.setHoverIndex(newIndex);
    };

    clearResults = (e) => {
        if (this.props.whenClearingResults) {
            this.props.whenClearingResults();
        }

        this.setState({
            showNoResults: false,
            displayHits: false,
            hits: [],
            text: '',
            lastSearchValue: undefined,
            isExtraSearchActive: false,
            isSubSearch: false,
            resultStatus: '',
        });
    };

    handleError = (err) => {
        console.error(err);
        this.setState({
            hits: [],
            displayHits: false,
            hoverIndex: undefined,
            selectedIndex: undefined,
            showNoResults: false,
            resultStatus: 'error',
        });
    };

    gotResults = (hits) => {
        if (hits.length === 0) {
            const stateObj = {
                displayHits: false,
                hits: hits,
                showNoResults: true,
                resultStatus: 'error',
                isExtraSearchActive: false,
            };
            if (this.props.extraSearch && isValidCoordiate(this.state.text)) {
                stateObj.isExtraSearchActive = true;
                stateObj.showNoResults = false;
                stateObj.resultStatus = 'ok';
            }

            this.setState(stateObj);
        } else if (this.state.isInitSearch && this.props.initialValue) {
            this.setState({
                hits: hits,
                displayHits: false,
                hoverIndex: undefined,
                selectedIndex: undefined,
                showNoResults: false,
                isExtraSearchActive: false,
                resultStatus: 'ok',
                isInitSearch: false,
            });
        } else if (this.state.isHitSelected) {
            this.setState({
                hits: hits,
                hoverIndex: undefined,
                displayHits: false,
                selectedIndex: 0,
                showNoResults: false,
                isHitSelected: false,
                isExtraSearchActive: false,
                resultStatus: 'ok',
            });

            this.props.hitSelected(hits[0], !!this.props.shouldFormatResult);
        } else {
            this.setState({
                hits: hits,
                hoverIndex: undefined,
                displayHits: true,
                selectedIndex: undefined,
                showNoResults: false,
                isExtraSearchActive: false,
                resultStatus: 'ok',
            });
        }
    };

    onChange = (e) => {
        const value = e.target.value;
        if (!value || (value && value.trim() === '')) {
            this.clearResults(e);
        }
        const stateObj: any = {text: value};
        if (this.state.isSubSearch) {
            if (
                value &&
                this.state.text &&
                value.length < this.state.text.length
            ) {
                stateObj.isSubSearch = false;
                this.setState(stateObj);
                this.searchApi(value, undefined, 'suggest');
            } else {
                const filteredHits =
                    this.state.hits &&
                    this.state.hits.filter((x) => x.Text.includes(value));
                stateObj.hits = filteredHits;
                this.setState(stateObj);
            }
        } else {
            this.setState(stateObj);
            this.searchApi(value, undefined, 'suggest');
        }

        if (this.props.onChangeCallback) {
            this.props.onChangeCallback(value);
        }
    };

    searchApi = (
        value: string | undefined,
        targets?: undefined | string[],
        searchOperation?: string | undefined,
        searchObject?: any,
        disableNewPropertyRedirect = false
    ) => {
        this.setState({text: value});
        const targ = targets ? targets : this.props.targets;

        if (value && value.length > 0) {
            this.setState({resultStatus: 'ok'});
            if (
                !this.props.apiKey &&
                !this.props.NkAuth &&
                !this.props.nkToken
            ) {
                throw new Error('Supply either apiKey, token or auth');
            }
            this.getSearchFunction(
                value,
                this.props,
                targ,
                searchOperation,
                undefined,
                searchObject,
                disableNewPropertyRedirect
            )
                .then(this.gotResults)
                .catch((err) => {
                    this.handleError(err);
                });
        } else {
            this.closeHits();
            this.setState({hits: []});
        }
    };

    mobileFullScreen = (e) => {
        const mobileTest =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/;
        if (mobileTest.test(navigator.userAgent)) {
            this.setState({fullScreen: true});
        }
    };

    render() {
        return (
            <SearchBox
                {...this.props}
                statementColor={this.props.statementColor}
                searchIconColor={this.props.searchIconColor}
                placeholder={this.props.placeholder}
                noHitsMessage={this.props.noHitsMessage}
                className={
                    this.state.fullScreen
                        ? this.props.className + ' mobile-fullscreen'
                        : this.props.className
                }
                selectedIndex={this.state.selectedIndex}
                hoverIndex={this.state.hoverIndex}
                showNoResults={this.state.showNoResults}
                displayHits={this.state.displayHits}
                hits={this.state.hits}
                resultStatus={this.state.resultStatus}
                text={this.state.text}
                setWrapperRef={this.setWrapperRef}
                onKeyDown={this.onKeyDown}
                openHits={this.openHits}
                clearResults={this.clearResults}
                isExtraSearchActive={this.state.isExtraSearchActive}
                onChange={this.onChange}
                goBack={this.goBack}
                returnStreetName={this.returnStreetName}
                subSearch={this.props.subSearch}
                isSubSearch={this.state.isSubSearch}
                hitSelected={this.hitSelected}
                theme={this.props.theme}
                hitlistStyle={this.props.hitlistStyle}
                setHoverIndex={this.setHoverIndex}
                onClick={(e) => {
                    if (this.props.mobileFriendly) {
                        if (e === 'icon') {
                            this.setState({fullScreen: false});
                        } else {
                            this.mobileFullScreen(e);
                        }
                    }
                }}
                mobileFullScreen={this.state.fullScreen}
            />
        );
    }
}

export default NkmNorkartSearch;
