import {Map} from 'mapbox-gl';

/* 
EXAMPLE: 

const fullscreenButton: IControl = new MapboxGLButtonControl(
  fullscreenBtn.fullscreenIsActive ? 'mapbox-gl-fullscreen-btn' : 'mapbox-gl-fullscreen-btn',
  'Trykk for å vise kart i fullskjerm',
  fullscreenBtn.onClick,
  'fullscreebtn'
);

*/

export class MapboxGLButtonControl {
  private _className: string;
  private _title: string;
  private _id: string;
  private _onClick: () => void;

  public constructor(className: string, title: string, onClick: () => void, id: string) {
    this._className = className;
    this._title = title;
    this._onClick = onClick;
    this._id = id;
  }

  onAdd(map: Map) {
    const _btn = document.createElement('button');
    _btn.className = 'mapboxgl-ctrl-icon' + ' ' + this._className;
    _btn.type = 'button';
    _btn.onclick = this._onClick;
    _btn.title = this._title;
    _btn.id = this._id;
    const _container = document.createElement('div');
    _container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
    _container.appendChild(_btn);
    return _container;
  }

  onRemove() {
    const _container = document.getElementById(this._id);
    if (_container && _container.parentNode) {
      _container.parentNode.removeChild(_container);
    }
  }
}

/* 



*/
