import {Autocomplete, Grid} from '@mui/material';
import * as React from 'react';
import DateRangePickerMui, {
    StartOrEndDate,
} from '../../../../components/DatePicker/DateRangePickerMui/DateRangePickerMui';
import {ToiLikeStyledTextField} from '../../../../components/TextField/ToiLikeStyledTextField';
import {Lovreferanse, Planstatus, Plantype} from '../../../../hooks/meta/types';
import {useFilterQL} from '../ReportTools/FilterQL';
import {StyledMuiTextFieldSearch} from '../ReportTools/StyledComponents/StyledMuiTextFieldSearch';

type FilterArealplanProps = {
    setFilterValues: React.Dispatch<any>;
    planstatuser?: Planstatus[];
    plantyper?: Plantype[];
    lovreferanser?: Lovreferanse[];
    clearFilter: boolean;
};

type SelectedFiltersProps = {
    planstatus?: Planstatus[];
    plantype?: Plantype[];
    planNavn?: string;
    lovreferanse?: Lovreferanse[];
    fraDato?: Date;
    tilDato?: Date;
};

export function PlanFilter({
    setFilterValues,
    plantyper,
    planstatuser,
    lovreferanser,
    clearFilter,
}: FilterArealplanProps) {
    const filterQl = useFilterQL();
    const [selectedFilters, setSelectedFilters] = React.useState<
        SelectedFiltersProps | undefined
    >();

    const handleDatoChange = (dates: StartOrEndDate | undefined) => {
        setSelectedFilters({
            ...selectedFilters,
            tilDato: dates?.end,
            fraDato: dates?.start,
        });
    };

    //Lager array av objekter som skal brukes som filtere
    const handleFiltering = React.useCallback(
        (filters: SelectedFiltersProps | undefined) => {
            const newFilter: any[] = [];
            filters?.planstatus &&
                filters.planstatus.length !== 0 &&
                newFilter.push({
                    or: filters.planstatus.map((ps) => ({
                        planStatusId: {eq: ps.id},
                    })),
                });

            filters?.plantype &&
                filters.plantype.length !== 0 &&
                newFilter.push({
                    or: filters.plantype.map((pt) => ({
                        planTypeId: {eq: pt.id},
                    })),
                });

            filters?.lovreferanse &&
                filters.lovreferanse.length !== 0 &&
                newFilter.push({
                    or: filters.lovreferanse.map((lr) => ({
                        lovreferanseId: {eq: lr.id},
                    })),
                });

            filters?.fraDato &&
                newFilter.push({
                    iKraft: {
                        gte: filters.fraDato.toDateString(),
                    },
                });

            filters?.tilDato &&
                newFilter.push({
                    iKraft: {
                        lte: filters.tilDato.toDateString(),
                    },
                });

            filters?.planNavn &&
                filters.planNavn !== '' &&
                newFilter.push({
                    planNavn: {contains: filters?.planNavn},
                });
            if (newFilter.length === 0) filterQl.updateFilter(undefined);
            else filterQl.updateFilter(newFilter);
        },
        [selectedFilters]
    );

    React.useMemo(() => {
        handleFiltering(selectedFilters);
    }, [
        selectedFilters?.planstatus,
        selectedFilters?.plantype,
        selectedFilters?.lovreferanse,
        selectedFilters?.fraDato,
        selectedFilters?.tilDato,
    ]);

    //Setter oppdaterte filterverdier så det kan brukes som variabler i graphql request
    React.useEffect(() => {
        setFilterValues(filterQl.filterReturn);
    }, [filterQl.filterReturn]);

    React.useMemo(() => {
        if (selectedFilters?.planNavn === '' || !selectedFilters?.planNavn) {
            return handleFiltering(selectedFilters);
        }

        const filterTimeout = setTimeout(() => {
            handleFiltering(selectedFilters);
        }, 500);

        return () => clearTimeout(filterTimeout);
    }, [selectedFilters?.planNavn]);

    React.useEffect(() => {
        if (clearFilter) setSelectedFilters(undefined);
    }, [clearFilter]);

    return (
        <div style={{marginTop: 10}}>
            <Grid direction='row' container={true}>
                <Grid item={true}>
                    <Autocomplete
                        size='small'
                        value={
                            selectedFilters?.planstatus
                                ? selectedFilters.planstatus
                                : []
                        }
                        options={planstatuser ? planstatuser : []}
                        sx={{
                            width: 400,
                        }}
                        limitTags={1}
                        ChipProps={{
                            color: 'primary',
                            variant: 'outlined',
                            sx: {borderRadius: 1},
                        }}
                        componentsProps={{
                            popper: {
                                color: 'secondary',
                                sx: {paddingTop: 5, boxShadow: 3},
                            },
                        }}
                        color='secondary'
                        multiple={true}
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                planstatus: newValue,
                            })
                        }
                        renderInput={(params) => (
                            <ToiLikeStyledTextField
                                {...params}
                                size='small'
                                label='Planstatus'
                                variant='outlined'
                                color='secondary'
                            />
                        )}
                    />
                    <Autocomplete
                        size='small'
                        value={
                            selectedFilters?.plantype
                                ? selectedFilters.plantype
                                : []
                        }
                        options={plantyper ? plantyper : []}
                        sx={{
                            width: 400,
                        }}
                        limitTags={1}
                        ChipProps={{
                            color: 'primary',
                            variant: 'outlined',
                            sx: {borderRadius: 1},
                        }}
                        componentsProps={{
                            popper: {
                                color: 'secondary',
                                sx: {paddingTop: 5, boxShadow: 3},
                            },
                        }}
                        color='secondary'
                        multiple={true}
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                plantype: newValue,
                            })
                        }
                        renderInput={(params) => (
                            <ToiLikeStyledTextField
                                {...params}
                                size='small'
                                label='Plantype'
                                variant='outlined'
                                color='secondary'
                            />
                        )}
                    />
                    <Autocomplete
                        size='small'
                        value={
                            selectedFilters?.lovreferanse
                                ? selectedFilters.lovreferanse
                                : []
                        }
                        options={lovreferanser ? lovreferanser : []}
                        sx={{
                            width: 400,
                        }}
                        limitTags={1}
                        ChipProps={{
                            color: 'primary',
                            variant: 'outlined',
                            sx: {borderRadius: 1},
                        }}
                        componentsProps={{
                            popper: {
                                color: 'secondary',
                                sx: {paddingTop: 5, boxShadow: 3},
                            },
                        }}
                        color='secondary'
                        multiple={true}
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                lovreferanse: newValue,
                            })
                        }
                        renderInput={(params) => (
                            <ToiLikeStyledTextField
                                {...params}
                                size='small'
                                label='Lovreferanse'
                                variant='outlined'
                                color='secondary'
                            />
                        )}
                    />
                </Grid>
                <Grid sx={{paddingLeft: 5}} item={true}>
                    <StyledMuiTextFieldSearch
                        variant='outlined'
                        sx={{paddingLeft: 3, width: 400}}
                        size='small'
                        label='Plannavn'
                        onChange={(e) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                planNavn: e.target.value,
                            })
                        }
                        onKeyUp={(e) => {
                            if (e.key === 'Escape')
                                setSelectedFilters({
                                    ...selectedFilters,
                                    planNavn: '',
                                });
                        }}
                        value={
                            selectedFilters?.planNavn
                                ? selectedFilters.planNavn
                                : ''
                        }
                        handleClearSearch={() =>
                            setSelectedFilters({
                                ...selectedFilters,
                                planNavn: '',
                            })
                        }
                        active={selectedFilters?.planNavn ? true : false}
                    />
                    <div style={{paddingLeft: 6}}>
                        <DateRangePickerMui
                            onChange={(d) => {
                                handleDatoChange(d);
                            }}
                            label='Ikrafttredelsesdato'
                            startDate={selectedFilters?.fraDato}
                            endDate={selectedFilters?.tilDato}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
