import {instances} from './coordinate-parser-instances';

export const isValidCoordiate = function (input) {
    return !!getSuccessfulParser(input);
};

export const getNorthEastInLatLng = function (input) {
    const parser = getSuccessfulParser(input);
    if (parser) {
        return parser.parseAndTransformToWgs(input);
    }
};

export const getNorthEastInUtm = function (input) {
    const parser = getSuccessfulParser(input);
    if (parser) {
        return parser.parseAndTransformToUTM(input);
    }
};

export const getSuccessfulParser = function (input) {
    const coordinateParsers = instances;

    // Iterates through all the availible coordinate parsers and returns the parser that successfully validates the input
    for (let i = 0; i < coordinateParsers.length; i++) {
        const currentParser = coordinateParsers[i];
        if (currentParser.validate(input)) {
            return currentParser;
        }
    }

    return undefined;
};
