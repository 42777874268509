import {CoordinateParser} from './coordinate-parser';
import {regexItems} from './regex-items';

export const instances = [];

const r = regexItems;

const utmCoordinateOfManyTypesNorthFirst = new CoordinateParser([
    r.OptionalSpaces,
    r.OptionalCoordSystemUtm,
    r.Number,
    r.CoordLetter1,
    r.Seperator,
    r.Number,
    r.CoordLetter2,
    r.OptionalSpaces,
]);
instances.push(utmCoordinateOfManyTypesNorthFirst);

const utmCoordinateOfManyTypesNorthSecond = new CoordinateParser([
    r.OptionalSpaces,
    r.OptionalCoordSystemUtm,
    r.Number,
    r.CoordLetter2,
    r.Seperator,
    r.Number,
    r.CoordLetter1,
    r.OptionalSpaces,
]);
instances.push(utmCoordinateOfManyTypesNorthSecond);

const utmCoordinateOfManyTypesWithoutCoordLetters = new CoordinateParser([
    r.OptionalSpaces,
    r.OptionalCoordSystemUtm,
    r.Number,
    r.Seperator,
    r.Number,
    r.OptionalSpaces,
]);
instances.push(utmCoordinateOfManyTypesWithoutCoordLetters);

// unfortuantly, an dependency has arrived that requires the wgs-parsers to be placed after the utm parsers :(
// wgs numbers uses the type regexPartTypes.NUMBER_COMPOSITE, which excludes utm as default value
const wgsCoordinateOfManyTypesNorthFirst = new CoordinateParser([
    r.OptionalSpaces,
    r.OptionalCoordSystemWgs,
    r.NumberManyTypes,
    r.CoordLetter1,
    r.Seperator,
    r.NumberManyTypes,
    r.CoordLetter2,
    r.OptionalSpaces,
]);
instances.push(wgsCoordinateOfManyTypesNorthFirst);

const wgsCoordinateOfManyTypesNorthSecond = new CoordinateParser([
    r.OptionalSpaces,
    r.OptionalCoordSystemWgs,
    r.NumberManyTypes,
    r.CoordLetter2,
    r.Seperator,
    r.NumberManyTypes,
    r.CoordLetter1,
    r.OptionalSpaces,
]);
instances.push(wgsCoordinateOfManyTypesNorthSecond);

const wgsCoordinateOfManyTypesWithoutCoordLetters = new CoordinateParser([
    r.OptionalSpaces,
    r.OptionalCoordSystemWgs,
    r.NumberManyTypes,
    r.Seperator,
    r.NumberManyTypes,
    r.OptionalSpaces,
]);
instances.push(wgsCoordinateOfManyTypesWithoutCoordLetters);
