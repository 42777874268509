import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Box, IconButton, List, Tab, Tabs} from '@mui/material';
import {useEffect, useState} from 'react';
import {ToiSpinner} from '@norkart/toi-deprecated-components';
import Post from './Components/Post';
import PostDialog from './Components/PostDialog';
import authContext from './Hooks/authContext';
import {usePosts} from './Hooks/usePosts';
import {useTags} from './Hooks/useTags';
import {checkAccess} from './Requests/AccessRequests';
import postType from './Types/PostType';

type props = {
  /**name of the client accessing the releasenote service */
  clientId: string;
  /**the clients setup.norkart app profile */
  applicationProfile?: string;
  /**a jwt token identifying the current authorized user if any */
  bearerToken?: string;
};

export function PostFeed({clientId, applicationProfile, bearerToken}: props) {
  const tags = useTags();
  const {posts, removePost} = usePosts(clientId, applicationProfile, bearerToken);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [currentPost, setCurrentPost] = useState<postType>();
  const [category, setCategory] = useState(0);

  useEffect(() => {
    async function setAccess() {
      if (applicationProfile && bearerToken) {
        setIsAuthenticated(await checkAccess(clientId, applicationProfile, bearerToken));
      } else {
        setIsAuthenticated(false);
      }
    }

    setAccess();
  }, [clientId, applicationProfile, bearerToken, setIsAuthenticated]);

  function onEdit(post: postType) {
    setShowDialog(true);
    setCurrentPost(post);
  }
  function onDelete(post: postType) {
    if (window.confirm('Er du sikker på at du vil slette innlegget?')) {
      removePost.mutate(post);
    }
  }

  function renderPosts() {
    return (
      <>
        {isAuthenticated ? (
          <IconButton
            onClick={() => {
              setCurrentPost(undefined);
              setShowDialog(true);
            }}
          >
            <AddCircleIcon color='primary' fontSize='large' />
          </IconButton>
        ) : null}
        {posts.isLoading ? (
          <ToiSpinner />
        ) : (
          posts.data.map((post) => {
            if (post.tagIds?.filter((tagId) => tagId === category).length > 0 || category === 0) {
              return (
                <Post key={post.id} post={post} onEdit={onEdit} onDelete={onDelete} isAuthenticated={isAuthenticated} />
              );
            } else {
              return null;
            }
          })
        )}
      </>
    );
  }

  return (
    <authContext.Provider value={{clientId, applicationProfile, bearerToken}}>
      <Box>
        <PostDialog
          post={currentPost}
          open={showDialog}
          onClose={() => {
            setShowDialog(false);
            setCurrentPost(undefined);
          }}
        />

        <Tabs value={category} onChange={(event, value) => setCategory(value)}>
          <Tab label='Alle' value={0} />
          {tags.data.map((tag) => (
            <Tab key={tag.id} label={tag.name} value={tag.id} />
          ))}
        </Tabs>

        <List sx={{display: 'flex', flexDirection: 'column'}}>{renderPosts()}</List>
      </Box>
    </authContext.Provider>
  );
}
