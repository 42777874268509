import {isAbsoluteUrl} from './isAbsoluteUrl';

export const isSafeUrl = (url: string) => {
    const protocol = isAbsoluteUrl(url)
        ? new URL(url).protocol
        : window.location.protocol;
    return ['https:', 'http:', 'mailto:'].includes(protocol);
};

export const getSecureUrl = (url?: string) => {
    return url && isSafeUrl(url) ? url : undefined;
};
