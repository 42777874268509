import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Typography} from '@mui/material';
import {getMapState} from '../../../../../store';
import useArealplaner from '../../../../../hooks/arealplaner/useArealplaner';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import {Arealplan} from '../../../../../hooks/arealplaner/types';
import {
    gfiAttId,
    WmsFeature,
} from '../../../../../services/apiTjenestekatalogen';
import {setVisibleInMap} from '../../../../../store/map/actions';
import useSelectedArealplanId from '../../../../../hooks/arealplaner/useSelectedArealplanId';
import useMeta from '../../../../../hooks/meta/useMeta';
import Attribute from './components/Attribute';
import RelatertPlan from './components/RelatertPlan';
import {getArealplanId} from './components/helpers/getSurroundingArealplan';

type planIdMapping = (
    | {
          id: any;
          planId: string;
      }
    | undefined
)[];

type Props = {
    planIds: string[];
    displaySurroundingPlanWms: boolean;
    zoomToDrawnLayer;
};

export default function PunktinfoRelatertePlaner({
    planIds,
    displaySurroundingPlanWms,
    zoomToDrawnLayer,
}: Props) {
    const mapState = useSelector(getMapState);
    const selectedArealplanId = useSelectedArealplanId();
    const dispatch = useDispatch();
    const [planId, setPlanId] = useState<planIdMapping>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const meta = useMeta();
    const [openAttribute, setOpenAttribute] = useState<gfiAttId | undefined>(
        undefined
    );
    const arealplaner = useArealplaner(planId.map((i) => i?.id));

    useEffect(() => {
        let isMounted = true; // unngå state update av unmounted komponent
        setLoading(true);
        Promise.all(
            planIds.map((planId) =>
                getArealplanId(planId, meta.getInnstilling('komnr'))
            )
        ).then((ids) => {
            if (isMounted) {
                setPlanId(ids.filter((i) => i !== undefined));
                setLoading(false);
            }
        });

        return () => {
            isMounted = false;
        };
    }, [planIds]);

    const getAttributes = () => {
        if (mapState.gfiState.planAttributes) {
            return mapState.gfiState.planAttributes.map((feature) => {
                const attributes = feature.wmsLayers;
                const planId = feature.planId;

                const attributesToHide = ['RpOmråde']; // Ønsker ikke å vise planen sin "hovedinfo" som gfi info
                const attsToWithFilteredAttList = attributes.map((att) => {
                    return {
                        ...att,
                        attrList: att.attrList.filter(
                            (att) =>
                                !attributesToHide.includes(att.FeatureTypeReal)
                        ),
                    };
                });
                const attsToDisplay = attsToWithFilteredAttList.filter(
                    (att) => att.attrList.length > 0
                );
                return {planId: planId, attributes: attsToDisplay};
            });
        }
    };

    function getAttributesForPlan(plan: Arealplan) {
        const attributeList = getAttributes()?.find(
            (i) => i.planId === plan.planId
        )?.attributes;
        let formattedAttributeList: WmsFeature[] = [];
        if (attributeList) {
            for (const x of attributeList) {
                formattedAttributeList = formattedAttributeList.concat(
                    x.attrList
                );
            }
        }

        return formattedAttributeList;
    }

    /** Rendrer punktinfo for treff i nåværende plan */
    function renderInfoOmPunkt() {
        let punktInfo = arealplaner.map((result) => {
            if (result.data && result.data.id === selectedArealplanId) {
                return getAttributesForPlan(result.data).map(
                    (gfiAttribute, index) => (
                        <Attribute
                            key={index}
                            attributeItem={gfiAttribute}
                            setVisibleInMap={(id: gfiAttId, value: boolean) => {
                                dispatch(setVisibleInMap(value, id));
                            }}
                            visibleGfiAtt={mapState.drawnAttInMap}
                            zoomToDrawnLayer={zoomToDrawnLayer}
                            openAttribute={openAttribute}
                            setOpenAttribute={setOpenAttribute}
                        />
                    )
                );
            }
        });

        // Fjern undefined og planer som ikke har noen attributes
        punktInfo = punktInfo.filter(
            (plan) => plan !== undefined && plan.length
        );
        if (punktInfo.length) {
            return (
                <>
                    <Typography
                        sx={(theme) => ({
                            fontSize: 18,
                            fontWeight: 500,
                            marginBottom: '6px',
                        })}
                    >
                        Treff i valgt plan
                    </Typography>
                    {punktInfo}
                </>
            );
        } else {
            return 'Ingen treff i valgt plan';
        }
    }

    /**Rendrer punktinfo for treff i andre planer enn den valgte */
    function renderAndrePlaner() {
        let andrePlaner = arealplaner.map((result, index) => {
            if (result.data && result.data.id !== selectedArealplanId) {
                return (
                    <RelatertPlan
                        key={index}
                        plan={result.data}
                        gfi={getAttributesForPlan(result.data)}
                        openAttribute={openAttribute}
                        setOpenAttribute={setOpenAttribute}
                        visibleGfiAtt={mapState.drawnAttInMap}
                        zoomToDrawnLayer={zoomToDrawnLayer}
                    />
                );
            }
        });

        // fjern undefined og punktinfo som ikke har noe gfi info
        andrePlaner = andrePlaner.filter(
            (plan) => plan !== undefined && plan.props.gfi.length
        );
        if (andrePlaner.length) {
            return (
                <>
                    <Box
                        sx={{borderTop: '1px solid lightgray', marginY: '40px'}}
                    ></Box>
                    <Typography
                        sx={(theme) => ({
                            fontSize: 18,
                            fontWeight: 500,
                            marginBottom: '6px',
                        })}
                    >
                        Treff i andre planer:
                    </Typography>
                    {andrePlaner}
                </>
            );
        }
    }

    function readyToRender() {
        if (
            !mapState.gfiState.loading &&
            arealplaner.every((res) => res.isFetched) &&
            !loading
        ) {
            return true;
        } else {
            return false;
        }
    }

    if (!readyToRender()) {
        return <LoadingSpinner />;
    }

    return planIds.length ? (
        <Box>
            {renderInfoOmPunkt()}
            {displaySurroundingPlanWms && renderAndrePlaner()}
        </Box>
    ) : (
        <></>
    );
}
