import {TextFieldProps as MuiTextFieldProps} from '@mui/material';
import {forwardRef} from 'react';
import {StyledTextField} from './toiTextFieldStyle';

export type LabelVariant = 'default' | 'light' | 'semibold';
export type ToiTextFieldVariant = 'map' | 'outlined';

// mui does not allow us to override the variant (like we do with Badge),
// so we send 'variantStyle' instead of 'variant' to the styled component
// issue: https://github.com/mui/material-ui/issues/37846

type OwnProps = {
  labelVariant?: LabelVariant;
  variant?: ToiTextFieldVariant;
};

export type ToiTextFieldProps = Omit<MuiTextFieldProps, keyof OwnProps | 'component'> & OwnProps;

export const ToiTextField = forwardRef<HTMLDivElement, ToiTextFieldProps>((props, ref) => {
  const {size = 'small', variant = 'outlined', ...restProps} = props;
  return (
    <StyledTextField
      ref={ref}
      size={size}
      variantStyle={variant}
      InputLabelProps={{
        shrink: true,
      }}
      {...restProps}
    />
  );
});

export default ToiTextField;
