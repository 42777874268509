import * as React from 'react';
import {CircularProgress, Grid} from '@mui/material';
import {Waypoint} from 'react-waypoint';
import {format} from 'date-fns';
import {setTitle} from '../../../../utils/setTitle';
import ReactTable from '../../../../components/ReactTable';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import {exportToCsv} from '../ReportTools/ClientsideCsv';
import useDispensasjonerGraphQL from '../../../../hooks/dispensasjoner/useDispensasjonerGraphQL';
import {useDispensasjonerQLCsv} from '../../../../hooks/dispensasjoner/useDispensasjonerQLCsv';
import {dispensasjonerReportQuery} from '../../../../hooks/dispensasjoner/dispensasjonerQueries';
import {ReportSortState} from '../ReportTools/CommonTypes';
import DispensasjonerColumns from './DispensasjonerColumns';
import {DispensasjonerQueryVariables} from './Dispensasjoner';

type DispensasjonerReportProps = {
    variables: DispensasjonerQueryVariables;
    kundeId: string;
    kommunenummer: string;
    setVars: React.Dispatch<React.SetStateAction<DispensasjonerQueryVariables>>;
};

export default function DispensasjonerReport({
    variables,
    kundeId,
    kommunenummer,
    setVars,
}: DispensasjonerReportProps) {
    setTitle('Rapporter > Dispensasjoner');
    const csv = useDispensasjonerQLCsv(variables);
    const columns = DispensasjonerColumns(kundeId, kommunenummer);
    const dispensasjoner = useDispensasjonerGraphQL(
        dispensasjonerReportQuery,
        variables
    );

    const handleSort = ({property, direction}: ReportSortState) => {
        if (!direction && variables.sort) {
            setVars({...variables, sort: undefined});
            return;
        }

        if (!direction && property && !variables.sort) return;
        let sorting: any;
        switch (property) {
            case 'planId':
                sorting = [{arealplan: {planId: direction}}];
                break;
            case 'dispensasjonBeskrivelse':
                sorting = [{beskrivelse: direction}];
                break;
            case 'vedtaksDato':
                sorting = [{vedtaksdato: direction}];
                break;
            case 'DispensasjonType':
                sorting = [{dispensasjonType: direction}];
                break;
            case 'DispFra':
                sorting = [{dispFra: direction}];
                break;
            case 'Vedtak':
                sorting = [{vedtak: direction}];
                break;
            case 'sak':
                sorting = [
                    {sak: {sakAar: direction}},
                    {sak: {sakSeknr: direction}},
                ];
                break;
            case 'vertikalniva':
                sorting = [{vertikalnivaType: direction}];
                break;
            case 'Posisjon':
                sorting = [
                    {posisjon: {x: direction}},
                    {posisjon: {y: direction}},
                ];
                break;
            case 'planNavn':
                sorting = [{arealplan: {planNavn: direction}}];
                break;
            case 'planStatus':
                sorting = [{arealplan: {planStatus: direction}}];
                break;
            case 'planType':
                sorting = [{arealplan: {planType: direction}}];
                break;
            default:
                return undefined;
        }

        setVars({...variables, sort: sorting});
    };

    React.useEffect(() => {
        if (csv.csvData && csv.isSuccess) {
            exportToCsv(
                `dispensasjoner_${format(new Date(), 'ddMMyyyy')}`,
                csv.csvData,
                csv.headers
            );
            csv.remove();
        }
    }, [csv.csvData]);

    const handleDownloadClick = () => {
        csv.refetch();
    };

    if (dispensasjoner.isLoading && !variables.sort) return <LoadingSpinner />;
    return (
        <Grid item={true}>
            <ReactTable
                textSize='small'
                customSort={(property, direction) =>
                    handleSort({property, direction})
                }
                columns={columns}
                data={
                    dispensasjoner.dispensasjoner
                        ? dispensasjoner.dispensasjoner
                        : []
                }
                toolbar={{
                    exportCsv: {
                        handleDownloadClick: () => handleDownloadClick(),
                        loadingRequest: csv.isLoading,
                    },
                    dataInfo: dispensasjoner.isFetching ? (
                        <CircularProgress
                            color='primary'
                            size={20}
                            title='Oppdaterer resultater'
                        />
                    ) : (
                        <p>
                            {`${
                                dispensasjoner.totalCount
                                    ? dispensasjoner.totalCount
                                    : 0
                            } dispensasjoner funnet`}
                        </p>
                    ),
                }}
            />
            {dispensasjoner.isFetchingNextPage && <LoadingSpinner />}
            {dispensasjoner?.hasNextPage && (
                <Waypoint
                    onEnter={() => dispensasjoner.fetchNextPage()}
                    bottomOffset='-400px'
                />
            )}
        </Grid>
    );
}
