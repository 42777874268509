import {useMutation, useQueryClient} from 'react-query';
import {
    arkivKodelisterUpdateTilgangerByType,
    KodelisteTilgangDto,
    KodelisteType,
} from '@norkart/nkapi-arealplaner-api';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useSelectedKundeId from '../kunder/useSelectedKundeId';

export default function useUpdateArkivKodelisteTilganger(
    arkivId: number,
    kodelisteType: KodelisteType
) {
    const queryClient = useQueryClient();
    const {authorizedClient} = useArealplanerApiClientContext();
    const kundeId = useSelectedKundeId();

    return useMutation(
        async (data: KodelisteTilgangDto[]) =>
            await arkivKodelisterUpdateTilgangerByType({
                client: authorizedClient,
                path: {arkivId, kundeId},
                query: kodelisteType ? {kodelisteType} : undefined,
                body: data,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'arkivkodelistetilganger',
                    arkivId,
                    kodelisteType,
                ]);
            },
        }
    );
}
