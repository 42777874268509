import * as React from 'react';
import {connect} from 'react-redux';
import {
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog, showDialog} from '../../../store/dialog/actions';
import DocumentForm from '../DocumentForm/index';
import {DokumentUtvidet} from '../../plan/PlanDocumentList/PlanDocumentListDocuments';
import {Behandling} from '../../../hooks/behandlinger/types';
import useUpdateDokument from '../../../hooks/dokumenter/useUpdateDokument';
import {Dispensasjon} from '../../../hooks/dispensasjoner/types';

export interface DocumentFormDialogProps extends ConnectedReduxProps {
    dokument: DokumentUtvidet;
    plandok?: boolean;
    open: boolean;
    behandling?: Behandling;
    dispensasjon?: Dispensasjon;
}

function DocumentFormDialog({
    dokument,
    plandok,
    open,
    behandling,
    dispensasjon,
    dispatch,
}: DocumentFormDialogProps) {
    const {mutateAsync: updateDokument} = useUpdateDokument();
    const onClose = () => {
        hideDialog(dispatch);
    };
    const onDelete = () =>
        hideDialog(dispatch).then(() =>
            dispatch(
                showDialog({
                    dialogType: 'DELETE_DOKUMENT',
                    dialogProps: {dokument},
                })
            )
        );

    const onSubmit = async (data) => {
        return updateDokument({dokument: data, dokumentId: data.id});
    };

    return (
        <Dialog
            open={open}
            aria-labelledby='form-dialog-title'
            fullWidth={true}
            maxWidth='md'
            PaperProps={{style: {overflowY: 'visible'}}}
            disableScrollLock={true}
        >
            <DialogTitle id='form-dialog-title'>
                <Grid
                    container={true}
                    justifyContent='space-between'
                    spacing={8}
                    alignItems='center'
                >
                    <Grid item={true}>{`Endre ${dokument.dokumentnavn}`}</Grid>
                    <Grid item={true}>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{overflowY: 'visible'}}>
                <DocumentForm
                    dokument={dokument}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    onClose={onClose}
                    plandok={plandok}
                    behandling={behandling}
                    dispensasjon={dispensasjon}
                />
            </DialogContent>
        </Dialog>
    );
}

export default connect()(DocumentFormDialog);
