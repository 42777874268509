import {Box, Chip, Typography} from '@mui/material';
import React, {useState} from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useMeta from '../../../hooks/meta/useMeta';
import {JournalposterSettings} from './JournalposterSettings';

type ArkivSelectorProps = {
    selectedArchive: number;
    onClick: (archiveId: number) => void;
};

function ArkivSelector({selectedArchive, onClick}: ArkivSelectorProps) {
    const meta = useMeta();
    const arkiver = meta.data.arkiver;

    return (
        <Box>
            <Typography variant='caption'>Velg arkiv: </Typography>
            {arkiver.map((arkiv) => {
                return (
                    <Chip
                        key={arkiv.id}
                        label={arkiv.beskrivelse}
                        variant={
                            selectedArchive === arkiv.id ? 'filled' : 'outlined'
                        }
                        color={
                            selectedArchive === arkiv.id
                                ? 'secondary'
                                : 'primary'
                        }
                        sx={{
                            marginRight: '5px',
                        }}
                        clickable={true}
                        onClick={(e) => onClick(arkiv.id)}
                    />
                );
            })}
        </Box>
    );
}

export const ArchiveIntegrationSettings = () => {
    const meta = useMeta();
    const arkiver = meta.data.arkiver;
    const [selectedArchive, setSelectedArchive] = useState(meta.aktivtArkiv);

    if (!meta.isFetched) return <LoadingSpinner />;
    if (!meta.hasArkivIntegrasjon())
        return (
            <Box>
                <Typography>Arkivintegrasjon er ikke aktivert.</Typography>
            </Box>
        );

    return (
        <>
            <Typography variant='h1'>Arkivintegrasjon</Typography>
            <Typography sx={{paddingBottom: '20px'}}>
                Her kan du sette opp hvilke egenskaper som gjelder for
                integrasjon mot arkiv.
            </Typography>
            {arkiver.length && arkiver.length > 1 && (
                <Box paddingBottom={10}>
                    <ArkivSelector
                        selectedArchive={selectedArchive}
                        onClick={setSelectedArchive}
                    />
                </Box>
            )}
            <JournalposterSettings arkivId={selectedArchive} />
        </>
    );
};
