/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {CancelablePromise} from '@norkart/norkartapi3-core';
import {Config, request as __request} from '@norkart/norkartapi3-core';
import type {CurrentAuthorizationSelfLookup} from '../models/CurrentAuthorizationSelfLookup';
import type {CurrentAuthorizationSelfLookupResponse} from '../models/CurrentAuthorizationSelfLookupResponse';
import type {UserToProfileAuthorizationLookup} from '../models/UserToProfileAuthorizationLookup';
import type {UserToProfileAuthorizationLookupResponse} from '../models/UserToProfileAuthorizationLookupResponse';

export class AuthorizationlookupService {
    config: Config;

    constructor(config: Config) {
        this.config = {
            ...config,
            BASE:
                config.BASE ||
                'https://www.webatlas.no/WAAPI-ApplicationBackend',
        };
    }

    /**
     * @returns CurrentAuthorizationSelfLookupResponse Success
     * @throws ApiError
     */
    public currentAuthorizationSelfLookupGet({
        accept,
        xWaapiProfile,
    }: {
        /** Accept Header **/
        accept: 'application/json';
        /** Profile to match lookup **/
        xWaapiProfile: string;
    }): CancelablePromise<CurrentAuthorizationSelfLookupResponse> {
        return __request(this.config, {
            method: 'GET',
            path: `/authorizationlookup`,
            headers: {
                Accept: accept,
                'X-WAAPI-Profile': xWaapiProfile,
            },
        });
    }

    /**
     * @returns CurrentAuthorizationSelfLookupResponse Success
     * @throws ApiError
     */
    public currentAuthorizationSelfLookupPost({
        accept,
        xWaapiProfile,
        body,
    }: {
        /** Accept Header **/
        accept: 'application/json';
        /** Profile to match lookup **/
        xWaapiProfile: string;
        body?: CurrentAuthorizationSelfLookup;
    }): CancelablePromise<CurrentAuthorizationSelfLookupResponse> {
        return __request(this.config, {
            method: 'POST',
            path: `/authorizationlookup`,
            headers: {
                Accept: accept,
                'X-WAAPI-Profile': xWaapiProfile,
            },
            body: body,
        });
    }

    /**
     * @returns UserToProfileAuthorizationLookupResponse Success
     * @throws ApiError
     */
    public userToProfileAuthorizationLookupprofileProfilePost({
        accept,
        profile,
        bearerToken,
        body,
    }: {
        /** Accept Header **/
        accept: 'application/json';
        profile: string;
        bearerToken?: string;
        body?: UserToProfileAuthorizationLookup;
    }): CancelablePromise<UserToProfileAuthorizationLookupResponse> {
        return __request(this.config, {
            method: 'POST',
            path: `/authorizationlookup/profile/${profile}`,
            headers: {
                Accept: accept,
            },
            query: {
                BearerToken: bearerToken,
            },
            body: body,
        });
    }
}
