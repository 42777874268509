import {AppBar as MuiAppBar, AppBarProps as MuiAppBarProps, styled, SxProps, Theme} from '@mui/material';
import {forwardRef} from 'react';
import ToiToolbar from '../ToiToolbar/ToiToolbar';
import {toiAppBarStyle} from './toiAppBarStyle';

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    neutral: true;
  }
}

type OwnProps = {
  toolbarSx?: SxProps<Theme>;
};

type ToiAppBarProps = Omit<MuiAppBarProps, keyof OwnProps | 'component'> & OwnProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledAppBar = styled(MuiAppBar)(({theme}): any => {
  return toiAppBarStyle(theme);
});

export const ToiAppBar = forwardRef<HTMLDivElement, ToiAppBarProps>((props, ref) => {
  const {children, toolbarSx, ...restProps} = props;

  return (
    <StyledAppBar color='neutral' ref={ref} {...restProps}>
      <ToiToolbar sx={toolbarSx}>{children}</ToiToolbar>
    </StyledAppBar>
  );
});

export default ToiAppBar;
