// Every accepted coordinate system input values
import {epsg} from './const';

export const coordinateSystem = {};

coordinateSystem.defaultEpsg = epsg.LAT_LNG;
coordinateSystem.defaultUtm = epsg.UTM33;

coordinateSystem.wgs = {
    WGS84: epsg.LAT_LNG,
    'WGS 84': epsg.LAT_LNG,
    WGS: epsg.LAT_LNG,
};

coordinateSystem.utm = {
    UTM32: epsg.UTM32,
    UTM32N: epsg.UTM32,
    'UTM 32N': epsg.UTM32,
    'UTM 32': epsg.UTM32,

    UTM33: epsg.UTM33,
    UTM33N: epsg.UTM33,
    'UTM 33N': epsg.UTM33,
    'UTM 33': epsg.UTM33,

    UTM35: epsg.UTM35,
    UTM35N: epsg.UTM35,
    'UTM 35N': epsg.UTM35,
    'UTM 35': epsg.UTM35,

    UTM: undefined, // UTM without sone is not longer supported, but it should still be recognized as a coordinate
};
