export const arealplanerReportQuery = `query GetArealplanerPaged($first: Int, $after: String, $filter: ArealplanDtoFilterInput, $sort: [ArealplanDtoSortInput!])  {
    arealplanerPaged (first: $first, after: $after, where: $filter, order: $sort) {
         edges{
          node {
            id
            planId
            planNavn
            lovreferanse
            planStatus
            planType
            iKraft
          },
          cursor
      }
      pageInfo {
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
      },
      totalCount
    }
}
`;

export const arealplanerReportCsvQuery = `query GetArealplaner($filter: ArealplanDtoFilterInput, $sort: [ArealplanDtoSortInput!])  {
    arealplaner (where: $filter, order: $sort) {
        id
        planId
        planNavn
        lovreferanse
        lovreferanseId
        planStatus
        planStatusId
        planType
        planTypeId
        iKraft
    }
}
`;
