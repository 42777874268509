import ReactMarkdown from 'react-markdown';
import {SxProps, Theme} from '@mui/material';
import {ToiLink, ToiTypography} from '@norkart/toi-deprecated-components';

export const MarkdownContent = ({
    markdown,
    sx,
}: {
    markdown: string;
    sx: SxProps<Theme>;
}) => {
    return (
        <ReactMarkdown
            children={markdown}
            components={{
                h1({...props}) {
                    return (
                        <ToiTypography sx={{...sx, mb: 1}} variant='h3'>
                            {props.children}
                        </ToiTypography>
                    );
                },
                p({...props}) {
                    return (
                        <ToiTypography
                            variant='body2'
                            sx={{
                                ...sx,
                                display: 'block',
                                mb: 1,
                            }}
                        >
                            {props.children}
                        </ToiTypography>
                    );
                },
                a: ({href, children}) => {
                    return (
                        <ToiLink
                            sx={{...sx, fontSize: 16}}
                            href={href}
                            target='_blank'
                        >
                            {children}
                        </ToiLink>
                    );
                },
            }}
        />
    );
};
