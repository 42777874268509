import ToiStack from '../ToiStack/ToiStack';
import ToiAvatar from '../ToiAvatar/ToiAvatar';
import ToiTypography from '../ToiTypography/ToiTypography';

export interface ToiUserCardProps {
  name?: string;
  company?: string;
  compact?: boolean;
}

const getInitials = (name: string) => {
  return name.split(' ')[0][0] + name.split(' ')[name.split(' ').length - 1][0];
};

export const ToiUserCard = ({name, company, compact = false}: ToiUserCardProps) => (
  <ToiStack direction={'row'} sx={{gap: '10px'}}>
    <ToiAvatar sx={{alignSelf: 'center'}} size='medium' initials={getInitials(name || '')}></ToiAvatar>
    {!compact ? (
      <ToiStack justifyContent='center' alignItems='flex-start'>
        <ToiTypography variant='body3' bold>
          {name}
        </ToiTypography>
        {company && (
          <ToiTypography variant='body3' light>
            {company}
          </ToiTypography>
        )}
      </ToiStack>
    ) : null}
  </ToiStack>
);
