import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    styled,
} from '@mui/material';
import {FeatureCollection, GeoJsonProperties, Geometry} from 'geojson';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import React from 'react';
import {position} from '@norkart/nkm-mapbox-map';
import pointmarkerBlue from '../../assets/images/MarkerKommunekart-blue.png';
import pointmarkerOrange from '../../assets/images/MarkerKommunekart-orange.png';
import useArealplan from '../../hooks/arealplaner/useArealplan';
import BaseMap from './BaseMap';
import DrawControl from './Components/Drawing/DrawControl';
import DrawTextfield from './Components/Drawing/DrawCustomModes/DrawTextfield';
import drawStyles from './Components/Drawing/drawStyles';

const DialogWrapper = styled(Dialog)({
    '& .MuiDialog-paper': {
        maxWidth: '80vw',
        width: '80vw',
        height: '90vh',
    },
});

interface Props {
    onCloseMap: any;
    featureCollection: FeatureCollection;
}

const PlanFeedbackMap = ({onCloseMap, featureCollection}: Props) => {
    const {data: plan} = useArealplan();
    const draw = React.useMemo(
        () =>
            new MapboxDraw({
                displayControlsDefault: false,
                userProperties: true,
                styles: drawStyles,
                modes: Object.assign(
                    {
                        draw_textfield: DrawTextfield,
                    },
                    MapboxDraw.modes
                ) as any,
            }),
        []
    );

    const [deleteAvailable, setDeleteAvailable] = React.useState(false);

    const restoreFeatures = () => {
        if (featureCollection) {
            draw?.set(featureCollection);
        }
    };

    const onselectionchange = (e) => {
        if (e.features.length === 0) {
            setDeleteAvailable(false);
        } else {
            setDeleteAvailable(true);
        }
    };

    const onMapLoad = () => {
        restoreFeatures();
        const map = (window as any).mmap.current;
        map.loadImage(pointmarkerBlue, (error, image) => {
            if (error) throw error;
            map.addImage('pointmarker-blue', image);
        });
        map.loadImage(pointmarkerOrange, (error, image) => {
            if (error) throw error;
            map.addImage('pointmarker-orange', image);
        });
    };

    const doCloseMap = (
        data: FeatureCollection<Geometry, GeoJsonProperties> | undefined
    ) => {
        draw?.changeMode('simple_select', {featureIds: []});
        onCloseMap(data);
    };

    const controls: {
        control: any;
        option?: position;
    }[] = [{control: draw, option: 'top-left'}];
    const events = [
        {type: 'load', callback: onMapLoad},
        {type: 'draw.selectionchange', callback: onselectionchange},
        {
            type: 'draw.delete',
            callback: () => onselectionchange({features: []}),
        },
    ];
    const mapOptions = {
        preserveDrawingBuffer: true,
        refreshExpiredTiles: false,
    };
    return (
        <DialogWrapper fullWidth={true} open={true}>
            <DialogTitle style={{textAlign: 'center'}}>
                <Typography style={{fontSize: 22}}>Påtegning</Typography>
            </DialogTitle>
            <DialogContent id='modalcontent'>
                <DrawControl
                    mapboxDraw={draw}
                    deleteAvailable={deleteAvailable}
                />
                <Box sx={{position: 'relative', height: '100%'}}>
                    <BaseMap
                        controls={controls}
                        events={events}
                        mapOptions={mapOptions}
                        plan={plan}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 20,
                }}
            >
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => doCloseMap(undefined)}
                >
                    Avbryt
                </Button>
                <Button
                    onClick={() => doCloseMap(draw?.getAll())}
                    variant='contained'
                    color='secondary'
                    style={{marginLeft: 10, marginRight: 10, width: 200}}
                >
                    Lagre utsnitt
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

export default PlanFeedbackMap;
