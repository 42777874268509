import {AxiosError, AxiosHeaders, AxiosResponse} from 'axios';
import {useNkAuth} from '@norkart/nk-auth';
import arealplanerConfig from '../config';
import {errorCodes} from '../config/const';
import {setGeneralError} from '../store/shared/actions';
import {store} from '../main';
import {api, apiRestricted} from '../services/api';
import {planpratAxiosInstance} from '../services/apiPlanprat';

const TOKEN = arealplanerConfig.planregister.token;
const BASE_URL = arealplanerConfig.planregister.url + '/api';
const GRAPHQL_URL = arealplanerConfig.planregister.url + `/graphql`;
const PROFILE = arealplanerConfig.planregister.profile;

export const useNkAuthInterceptors = () => {
    const nkAuth = useNkAuth();

    async function checkAuth() {
        const accounts = nkAuth.authData.msalApp.getAllAccounts();
        // Check if logged in before getting authorization
        if (accounts.length > 0) {
            return nkAuth.isAuthorized;
        }
        return false;
    }

    function responseInterceptor(response: AxiosResponse) {
        return response;
    }

    function responsErrorInterceptor(error: AxiosError) {
        if (error.message && error.message === errorCodes.serverError) {
            if (error.message === 'Unauthorized') {
                store.dispatch(
                    setGeneralError('Ikke tilgang. Prøv å logge inn på nytt.')
                );
            } else {
                store.dispatch(setGeneralError(error.message));
            }
        }
        return Promise.reject(error);
    }

    const setupInterceptors = () => {
        // Use token if logged in
        api.interceptors.request.use((config) => {
            return checkAuth().then((isAuthorized) => {
                if (isAuthorized) {
                    return nkAuth.getToken().then((token) => {
                        config.headers = AxiosHeaders.from({
                            ...config.headers,
                            Authorization: `Bearer ${token}`,
                            'X-WAAPI-PROFILE': PROFILE,
                        });
                        config.params = {...config.params};
                        return Promise.resolve(config);
                    });
                } else {
                    config.params = {
                        ...config.params,
                        apitoken: TOKEN,
                    };
                    return Promise.resolve(config);
                }
            });
        });

        planpratAxiosInstance.interceptors.request.use((config) => {
            return nkAuth.getToken().then((token) => {
                config.headers.Authorization = `Bearer ${token}`;
                config.params = {...config.params};
                return Promise.resolve(config);
            });
        });

        apiRestricted.interceptors.request.use((config) => {
            return nkAuth.getToken().then((token) => {
                config.headers.Authorization = `Bearer ${token}`;
                config.params = {...config.params};
                return Promise.resolve(config);
            });
        });

        apiRestricted.interceptors.response.use(
            responseInterceptor,
            responsErrorInterceptor
        );

        api.interceptors.response.use(
            responseInterceptor,
            responsErrorInterceptor
        );
    };

    return setupInterceptors;
};
