//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const StatusVisibleSightEyeIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M12 4C7.16 4 2.9 7.87 1 12C2.9 16.13 7.16 20 12 20C16.84 20 21.1 16.13 23 12C21.1 7.87 16.84 4 12 4ZM12 6C15.8 6 19.07 8.97 20.76 12C19.07 15.03 15.8 18 12 18C8.2 18 4.92999 15.03 3.23999 12C4.92999 8.97 8.2 6 12 6Z' style={props.secondaryStyle} />
            <path d='M12 14C10.9 14 10 13.1 10 12C10 10.9 10.9 10 12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14ZM12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7Z' style={props.primaryStyle} />
        </g>
    ),
    'StatusVisibleSightEyeIcon' // aria-label
);
