import {AxiosResponse} from 'axios';

export default function getFilenameFromResponse(
    response: AxiosResponse<any>
): string {
    const headerLine = response.headers['content-disposition'];
    const startFileNameIndex = headerLine.indexOf('"') + 1;
    const endFileNameIndex = headerLine.lastIndexOf('"');
    let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

    // Support different filename in content disposition
    if (filename === '') {
        const startFileNameIndex = headerLine.indexOf('filename=') + 9;
        const endFileNameIndex = headerLine.lastIndexOf('; filename*=');
        filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
    }

    return filename;
}
