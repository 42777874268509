import {useContext} from 'react';
import {ArealplanerApiClientContext} from './ArealplanerApiClientProvider';

export function useArealplanerApiClientContext() {
    const context = useContext(ArealplanerApiClientContext);
    if (context == null) {
        throw new Error(
            'useArealplanerApiClientContext can only be used under a ArealplanerApiClientProvider'
        );
    }
    return context;
}
