import {Checkbox, MenuItem, Typography} from '@mui/material';
import React from 'react';
import {
    StatusVisibleSightEyeIcon,
    StatusInvisibleIcon,
} from '@norkart/toi-deprecated-icons';

type Props = {
    visible: boolean;
    text: string;
    handleToggle: () => void;
};

export default function KartlagMenuItem({visible, text, handleToggle}: Props) {
    return (
        <MenuItem
            sx={{
                padding: '6px 0 6px 8px',
                '&:hover': {
                    backgroundColor: 'white',
                },
                height: '44px',
                justifyContent: 'space-between',
            }}
            disableGutters={true}
            onClick={handleToggle}
        >
            <Typography
                sx={{
                    fontWeight: visible ? 600 : undefined,
                }}
            >
                {text}
            </Typography>
            <Checkbox
                checked={visible}
                icon={<StatusInvisibleIcon size={0.88} />}
                checkedIcon={<StatusVisibleSightEyeIcon size={0.88} />}
            />
        </MenuItem>
    );
}
