import React, {Component} from 'react';
import {ArrowDownIcon, AppSearchIcon} from '@norkart/toi-deprecated-icons';
import CloseBtn from './CloseBtn';
import HitList from './HitList';

class SearchBox extends Component<any, any> {
    private inputRef = React.createRef<any>();

    state = {
        offsetTop: null,
    };

    componentDidUpdate(prevProps) {
        const hitlistDom = document.getElementById('HitList');
        const nkmSearchDom = this.props.id
            ? document.getElementById(this.props.id)
            : document.getElementById('nkm-search');

        if (hitlistDom) {
            hitlistDom.addEventListener(
                'touchstart',
                () => {
                    if (this.props.mobileFullScreen) {
                        if (nkmSearchDom) {
                            nkmSearchDom.blur();
                        }
                    }
                },
                true
            );
        }
        if (prevProps.mobileFullScreen !== this.props.mobileFullScreen) {
            if (!this.props.mobileFullScreen) {
                if (nkmSearchDom) {
                    nkmSearchDom.blur();
                }
            }
        }
    }

    focus = () => {
        this.inputRef.current.focus();
    };

    render() {
        const className = `nk-search ${this.props.className}`;
        const buttonTheme =
            this.props.theme === 'dark' ? 'nk-white' : 'nk-black';

        const clearResults = (e) => {
            this.props.clearResults(e);
            this.focus();
        };

        const onChange = (e, replacedSearch) => {
            this.props.hitSelected(e, replacedSearch);
            this.focus();
        };

        const statementColor: React.CSSProperties = {
            '--statement-color': this.props.statementColor,
        } as React.CSSProperties;
        return (
            <div
                id={'SearchBox'}
                style={statementColor}
                className={className}
                ref={this.props.setWrapperRef}
                onKeyDown={this.props.onKeyDown}
                onClick={(e) => {
                    this.props.openHits(e);
                }}
            >
                <div
                    className={
                        `form-group has-feedback input-container ${this.props.resultStatus} ` +
                        this.props.theme
                    }
                >
                    <input
                        aria-label={this.props.inputAriaLabel || 'Søkefelt'}
                        onChange={this.props.onChange}
                        type='text'
                        autoFocus={this.props.autofocus}
                        ref={this.inputRef}
                        value={this.props.text || ''}
                        className={`search-input ${this.props.resultStatus}`}
                        autoComplete='off'
                        onFocus={() => {
                            this.props.openHits();
                        }}
                        placeholder={this.props.placeholder}
                        id={this.props.id || 'nkm-search'}
                        onClick={(e) => {
                            this.props.onClick(e);
                        }}
                    />
                    <div className='search-input-icons'>
                        {this.props.text && this.props.text.length > 0 && (
                            <button
                                aria-label='Tøm søkefelt'
                                type='button'
                                id='eraseBtn'
                                onClick={clearResults}
                                tabIndex={0}
                            >
                                {
                                    <CloseBtn
                                        className={
                                            (this.props.text &&
                                                this.props.text.length > 0) ||
                                            this.props.hits.length ||
                                            this.props.isExtraSearchActive
                                                ? 'show'
                                                : 'hide'
                                        }
                                        theme={this.props.theme}
                                    />
                                }
                            </button>
                        )}

                        {!this.props.hideSearchButton && (
                            <>
                                {this.props.mobileFullScreen ? (
                                    <button
                                        type='button'
                                        id='searchIcon'
                                        tabIndex={0}
                                        aria-label='Søk'
                                        onClick={() => {
                                            this.props.onClick('icon');
                                        }}
                                    >
                                        <ArrowDownIcon />
                                    </button>
                                ) : (
                                    <button
                                        type='button'
                                        aria-label='Søk'
                                        id='searchIcon'
                                        tabIndex={0}
                                    >
                                        {this.props.mobileFullscreen ? (
                                            <ArrowDownIcon />
                                        ) : (
                                            <AppSearchIcon />
                                        )}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className={`search-border ${this.props.resultStatus}`} />
                <div>
                    <HitList
                        hitListStyle={this.props.hitListStyle}
                        theme={this.props.theme}
                        noHitsMessage={this.props.noHitsMessage}
                        hoverIndex={this.props.hoverIndex}
                        selectedIndex={this.props.selectedIndex}
                        showNoResults={this.props.showNoResults}
                        displayHits={this.props.displayHits}
                        hits={this.props.hits}
                        goBack={this.props.goBack}
                        returnStreetName={this.props.returnStreetName}
                        isSubSearch={this.props.isSubSearch}
                        setHoverIndex={this.props.setHoverIndex}
                        subSearch={this.props.subSearch}
                        hitSelected={onChange}
                        buttonTheme={buttonTheme}
                        parentTopPos={this.state.offsetTop}
                        mobileFullscreen={this.props.mobileFullScreen}
                        enableStreetButton={this.props.enableStreetButton}
                        disableNewPropertyRedirect={
                            this.props.disableNewPropertyRedirect
                        }
                    />
                </div>
            </div>
        );
    }
}

export default SearchBox;
