import {Theme} from '@mui/material';
import {AvailableWmsLayer} from '../../../types';

export const handleLayerClick = (
    layer: AvailableWmsLayer,
    availableWmsLayers: AvailableWmsLayer[] | undefined,
    addWmsLayer,
    removeWmsLayer
) => {
    const drawnWmsLayers =
        availableWmsLayers && availableWmsLayers.filter((lay) => lay.isVisible);
    const existInList = drawnWmsLayers?.includes(layer);

    if (existInList) {
        removeWmsLayer(layer);
    } else {
        addWmsLayer(layer);
    }
};

export const mapLayerMenuStyle = (theme: Theme) => ({
    marginTop: '1px',
    boxShadow: '0 0 0 2px #00000021',
    backgroundColor: '#eaeaea',
    display: 'flex',
    flexDirection: 'column' as any, //mui ts bug
});
