import {configureStore} from '@reduxjs/toolkit';
import {ApplicationState, createRootReducer} from './store';

export default function getStore(initialState: ApplicationState) {
    const store = configureStore({
        reducer: createRootReducer(),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false, // skrudd av siden vi har Files i state (disse er non-serializable)
                immutableCheck: false, // denne også trigget på Files
            }),
        preloadedState: initialState as any,
    });
    return store;
}
