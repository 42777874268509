import {useMutation, useQueryClient} from 'react-query';
import {
    dokumenterUpdateDokument,
    DokumentForUpdateDto,
} from '@norkart/nkapi-arealplaner-api';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

type Props = {
    dokumentId: number;
    dokument: DokumentForUpdateDto;
};

export default function useUpdateDokument() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const arealplanId = useSelectedArealplanId();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useMutation(
        async (props: Props): Promise<void> => {
            const {data, error} = await dokumenterUpdateDokument({
                client: authorizedClient,
                path: {kundeId, dokumentId: props.dokumentId},
                body: props.dokument,
            });
            if (error) {
                throw new Error('Failed to update document');
            }
            return data;
        },
        {
            onSuccess: () => {
                // Må invalidate fordi rekkefølge blir ikke returnert ved oppdatering av dokument
                queryClient.invalidateQueries([
                    'behandlinger',
                    {kundeId, arealplanId},
                ]);
                queryClient.invalidateQueries([
                    'dispensasjoner',
                    {kundeId, arealplanId},
                ]);
                queryClient.invalidateQueries([
                    'dokumenter',
                    {kundeId, arealplanId},
                ]);
            },
        }
    );
}
