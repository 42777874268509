import * as React from 'react';

export interface WarningProps {
    message;
}

const Warning: React.FC<WarningProps> = ({message}: WarningProps) => {
    return <span style={{color: '#EB9300'}}>{message}</span>;
};

export default Warning;
