import {DokumentUtvidet} from '../features/plan/PlanDocumentList/PlanDocumentListDocuments';
import {Arealplan} from '../hooks/arealplaner/types';

const getDocument = (
    id: number,
    plan: Arealplan,
    allDocuments: DokumentUtvidet[]
) => {
    return allDocuments.find((doc) => doc.id === id);
};

export const documentIsArvet = (
    dokId: number,
    plan: Arealplan,
    allDocuments: DokumentUtvidet[]
): boolean => {
    const doc = getDocument(dokId, plan, allDocuments);
    if (doc) {
        return !!doc.planforhold;
    } else {
        return false;
    }
};

export const documentHasBehandling = (
    dokId: number,
    plan: Arealplan,
    allDocuments: DokumentUtvidet[]
) => {
    const doc = getDocument(dokId, plan, allDocuments);
    if (doc) {
        return !!doc.behandling;
    } else {
        return false;
    }
};
