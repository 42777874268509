import {Theme} from '@mui/material';
import {merge} from 'lodash-es';
import {themeIsLightMode} from '../utils/themeIsLightMode';

export const toiCheckboxStyle = (theme: Theme, error?: boolean) => {
  const isLightMode = themeIsLightMode(theme);

  const focusColor = isLightMode ? theme.palette.common.black : theme.palette.common.white;

  const outlineColor = isLightMode
    ? error
      ? theme.palette.error.main
      : theme.palette.black[70]
    : error
    ? theme.palette.error[70]
    : theme.palette.common.white;
  const outlineColorHover = isLightMode
    ? error
      ? theme.palette.error[120]
      : theme.palette.black[100]
    : error
    ? theme.palette.error.main
    : theme.palette.primary[30];

  const checkedColor = isLightMode ? theme.palette.primary.main : theme.palette.primary[70];
  const checkedHoverColor = isLightMode ? theme.palette.primary[120] : theme.palette.primary[100];

  const focus = {
    outline: `2px dashed ${focusColor}`,
    outlineOffset: -10,
    color: outlineColorHover,
    '&.Mui-checked , &.MuiCheckbox-indeterminate': {
      color: checkedHoverColor,
    },
  };

  const selectedAndSelectedHoverColor = {
    color: checkedColor,
    '&:hover': {
      color: checkedHoverColor,
    },
  };

  const defaultStyle = {
    borderRadius: 0,
    color: outlineColor,

    '& .MuiSvgIcon-root': {
      // makes svg child exactly 22x22 px
      fontSize: 29.34,
    },

    // '& .MuiCheckbox': {

    // }

    '&:hover': {
      color: outlineColorHover,
    },

    '&.Mui-checked:not(.Mui-disabled)': selectedAndSelectedHoverColor,
    '&.MuiCheckbox-indeterminate:not(.Mui-disabled)': selectedAndSelectedHoverColor,
    '&.Mui-disabled': {
      color: isLightMode ? theme.palette.black[30] : theme.palette.black[70],
    },
    '&.Mui-focusVisible': focus,
    '&:focus': focus,
  };

  return merge(defaultStyle);
};
