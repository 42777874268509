import React from 'react';
import {Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import {FileData} from '../../../store/fileImport/types';
import {Behandling} from '../../../hooks/behandlinger/types';
import useDocumentHelpers from '../useDocumentHelpers';
import {Dispensasjon} from '../../../hooks/dispensasjoner/types';
import ImportDocumentListItem from './ImportDocumentListItem';

type Props = {
    documents: FileData[];
    behandling?: Behandling;
    dispensasjon?: Dispensasjon;
    options?: ImportDocumentListOptions;
};

export type ImportDocumentListOptions = {
    editableDate?: boolean;
    allowMultiple?: boolean;
};

function ImportDocumentList({
    documents,
    behandling,
    dispensasjon,
    ...props
}: Props) {
    const helpers = useDocumentHelpers();
    const defaultOptions = {
        editableDate: true,
        allowMultiple: false,
    } as ImportDocumentListOptions;
    const isBehandling = behandling ? true : false;
    const isDispensasjon = dispensasjon ? true : false;

    const optionsFromProps = props.options || {};
    const options = {...defaultOptions, ...optionsFromProps};

    const isDokumentTittlePresent = (): boolean => {
        return !!documents.filter((doc) => doc.dokumentTittel)?.length;
    };
    return (
        <Table>
            <TableHead>
                <TableRow>
                    {options.allowMultiple && <TableCell variant='head' />}
                    <TableCell variant='head'>Dokumenttype</TableCell>
                    <TableCell variant='head'>Dokumentnavn</TableCell>
                    {isDokumentTittlePresent() && (
                        <TableCell variant='head'>Dokumenttittel</TableCell>
                    )}
                    <TableCell variant='head'>Beskrivelse</TableCell>
                    <TableCell variant='head'>Dato</TableCell>
                    {helpers.shouldHoringsdokumentBeActive(behandling) && (
                        <TableCell
                            variant='head'
                            style={{whiteSpace: 'normal'}}
                        >
                            Høringsdokument
                        </TableCell>
                    )}
                    <TableCell
                        variant='head'
                        style={{whiteSpace: 'normal', width: '150px'}}
                    >
                        Vise i gjeldende bestemmelser
                    </TableCell>
                    {(isBehandling || isDispensasjon) && (
                        <TableCell
                            variant='head'
                            style={{whiteSpace: 'normal', width: '80px'}}
                        >
                            Vise i andre dokumenter
                        </TableCell>
                    )}
                    <TableCell variant='head' style={{whiteSpace: 'normal'}}>
                        Dokumenttilgang
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {documents.map((document, i) => (
                    <ImportDocumentListItem
                        key={i}
                        document={document}
                        behandling={behandling}
                        dispensasjon={dispensasjon}
                        options={{
                            showDokumentTittel: isDokumentTittlePresent(),
                            ...options,
                        }}
                    />
                ))}
            </TableBody>
        </Table>
    );
}

export default ImportDocumentList;
