//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const MediaDocumentsIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M20 18H8V2H17.5L20 4.5V18ZM18 0H6V20H22V4L18 0Z' style={props.secondaryStyle} />
            <path d='M5 4V6H4V22H16V21H18V24H2V4H5Z' style={props.primaryStyle} />
        </g>
    ),
    'MediaDocumentsIcon' // aria-label
);
