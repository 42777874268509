import {isValidCoordiate} from '../coordinateHelper';
import {SearchResponse, SearchProperties} from './types';

const output: SearchResponse = {
    Posisjon: {},
    properties: {
        Source: undefined,
        Type: undefined,
        Text: undefined,
        Id: undefined,
    },
};

type searchInputObject = {
    Type;
    PayLoad;
    Text;
    Id;
    Source;
    AdresseId;
    Posisjon;
};

const inputIsString = (input: searchInputObject | string): input is string => {
    return typeof input === 'string';
};

export const formatResult = (
    input: searchInputObject | string
): SearchResponse => {
    if (!inputIsString(input)) {
        if (input.PayLoad) {
            output.properties = setProperties(
                input.PayLoad,
                input.Type,
                input.Text,
                input.Id ? input.Id : null
            );
            output.Posisjon = setGeometry(output.properties.Source);
        } else if (input.Source) {
            output.properties = setProperties(
                input.Source,
                input.Type,
                input.Text,
                input.Id ? input.Id : null
            );
            output.Posisjon = setGeometry(output.properties.Source);
        } else if (input.AdresseId) {
            output.properties = setProperties(
                input,
                'gateadresse',
                input.Text,
                input.AdresseId ? input.AdresseId : null
            );
            output.Posisjon = setGeometry(output.properties.Source);
        } else if (isValidCoordiate(input)) {
            if (input.Posisjon) {
                output.Posisjon = {Point: input.Posisjon, Type: 'Point'};
            }
        }
    } else {
        const coords = input.split(' ');
        output.Posisjon = {
            Point: {
                X: coords[0],
                Y: coords[1],
                SRS: null,
            },
            Type: 'Point',
        };
        output.properties = setProperties(input, 'Point', input, null);
    }

    return output;
};

const setProperties = (source, type, text, id): SearchProperties => {
    return {
        Source: source,
        Type: type,
        Text: text,
        Id: id,
    };
};

const setGeometry = (input): any => {
    if (input.Posisjon) {
        return {Point: input.Posisjon, Type: 'Point'};
    } else if (input.BoundingBox) {
        return {BoundingBox: input.BoundingBox, Type: 'BoundingBox'};
    } else if (input.ArealplanGeometrier) {
        return {
            ArealplanGeometrier: input.ArealplanGeometrier,
            Type: 'ArealplanGeometrier',
        };
    } else if (input.Geometri) {
        return {
            Geometri: input.Geometri,
            Type: 'Geometri',
        };
    }
};
