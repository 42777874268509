import React, {useState} from 'react';
import {Box, Button, Paper, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {setVisibleInMap} from '../../../../../../store/map/actions';
import {
    gfiAttId,
    WmsFeature,
} from '../../../../../../services/apiTjenestekatalogen';
import {Arealplan} from '../../../../../../hooks/arealplaner/types';
import {useNavigateToArealplan} from '../../../../../../hooks/useNavigateToArealplan';
import Attribute from './Attribute';

type Props = {
    plan: Arealplan;
    gfi: WmsFeature[];
    openAttribute;
    setOpenAttribute;
    zoomToDrawnLayer;
    visibleGfiAtt;
};

export default function RelatertPlan({
    plan,
    gfi,
    openAttribute,
    setOpenAttribute,
    zoomToDrawnLayer,
    visibleGfiAtt,
}: Props) {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const navigateArealplan = useNavigateToArealplan();

    return gfi.length ? ( // only render if it has at least one attribute
        <>
            <Paper
                sx={{
                    marginTop: '8px',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                {isOpen ? (
                    <KeyboardArrowDownIcon />
                ) : (
                    <KeyboardArrowRightIcon />
                )}
                <Typography sx={{verticalAlign: 'middle'}}>
                    {plan.planNavn}
                </Typography>
                <Button
                    sx={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                    }}
                    color='secondary'
                    variant='outlined'
                    onClick={() => {
                        navigateArealplan.toArealplanFullscreen(plan.id);
                    }}
                >
                    Vis plan
                </Button>
            </Paper>
            <Box sx={{border: 'solid grey', borderWidth: '0px 1px 1px 1px'}}>
                {isOpen &&
                    gfi?.map((gfiAttribute, index) => (
                        <Attribute
                            key={index}
                            attributeItem={gfiAttribute}
                            setVisibleInMap={(id: gfiAttId, value: boolean) => {
                                dispatch(setVisibleInMap(value, id));
                            }}
                            openAttribute={openAttribute}
                            setOpenAttribute={setOpenAttribute}
                            visibleGfiAtt={visibleGfiAtt}
                            zoomToDrawnLayer={zoomToDrawnLayer}
                        />
                    ))}
            </Box>
        </>
    ) : (
        <></>
    );
}
