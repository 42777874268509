import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import React from 'react';
import GenericTable from '../../../components/GenericTable';
import {
    GTableAction,
    GTableColumn,
} from '../../../components/GenericTable/GenericTable';
import {Token} from '../../../hooks/tokens/types';

type Props = {
    data: Token[];
    handleShare: (token: Token) => void;
    handleEdit: (token: Token) => void;
};

function TokensTable({data, handleShare, handleEdit}: Props) {
    const columns = [
        {title: 'Beskrivelse', field: 'description'},
    ] as GTableColumn[];

    const actions = [
        {
            icon: () => <ShareIcon />,
            tooltip: 'Del',
            iconProps: {
                style: {color: 'rgba(0, 0, 0, 0.54)'},
                fontSize: 'small',
            },
            onClick: (event, row) => {
                handleShare(row);
            },
        },
        {
            icon: EditIcon,
            tooltip: 'Rediger',
            iconProps: {
                style: {color: 'rgba(0, 0, 0, 0.54)'},
                fontSize: 'small',
            },
            onClick: (event, row) => {
                handleEdit(row);
            },
        },
    ] as GTableAction[];
    return (
        <GenericTable
            title='Api-tilganger'
            data={data}
            idColumn='id'
            columns={columns}
            actions={actions}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Ingen tilganger funnet',
                },
            }}
        />
    );
}

export default TokensTable;
