import {Box, BoxProps as MuiBoxProps, PaletteMode, useTheme} from '@mui/material';
import {forwardRef} from 'react';
import {getSvg} from './getSvg';

export type ToiLogoColor = 'flat' | 'bw';
//* Deprecated in v.2, and not in use anywhere ;-) *//
// | 'gradient';
export type ToiLogoVariant = 'full' | 'icon';

type OwnProps = {
  color?: ToiLogoColor;
  variant?: ToiLogoVariant;
  mode?: PaletteMode;
};

type ToiLogoProps = Omit<MuiBoxProps, keyof OwnProps | 'component'> & OwnProps;

export const ToiLogo = forwardRef<HTMLElement, ToiLogoProps>((props, ref) => {
  const {color, variant, mode, ...restProps} = props;
  const theme = useTheme();
  const src = getSvg(color ?? 'flat', variant ?? 'full', mode ?? theme.palette.mode ?? 'light');

  return (
    <Box ref={ref} {...restProps}>
      <img src={src} style={{height: '100%', width: '100%'}} alt='Norkart logo' />
    </Box>
  );
});

export default ToiLogo;
