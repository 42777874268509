//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const MapPolylineIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M20 20H18V18H20V20ZM4 4H6V6H4V4ZM17.41 16L8 6.58997V6H15V4H8V2H2V8H6.59L16 17.41V22H22V16H17.41Z' style={props.secondaryStyle} />
            <path d='M22 2H16V8H22V2ZM20 4V6H18V4H20Z' style={props.primaryStyle} />
        </g>
    ),
    'MapPolylineIcon' // aria-label
);
