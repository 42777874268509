import {Map} from 'mapbox-gl';
import getMapCopyrightText from './getMapCopyrightText';

export default function enableNorkartAttribution(map: Map) {
  let customAttributionSpan: HTMLSpanElement | null = null;
  const spacer = document.createTextNode(' ');

  map.on('data', updateAttribution);
  map.on('load', updateAttribution);
  map.on('moveend', updateAttribution);

  function updateAttribution(event: any) {
    const centerPoint = event.target.getCenter();
    const zoomLevel = event.target.getZoom();
    const container = event.target.getContainer();
    const attributionContainer = container.querySelector('.mapboxgl-ctrl-attrib-inner');
    if (!attributionContainer || attributionContainer.innerHTML === '') {
      return;
    }

    const attributionText = getMapCopyrightText(centerPoint, zoomLevel);
    if (customAttributionSpan !== null) {
      customAttributionSpan.innerHTML = attributionText;
    } else {
      customAttributionSpan = document.createElement('span');
      customAttributionSpan.innerHTML = attributionText;
    }
    attributionContainer.insertBefore(spacer, attributionContainer.firstChild);
    attributionContainer.insertBefore(customAttributionSpan, attributionContainer.firstChild);
  }
}
