export const toiColors = {
  black: {
    10: '#F6F6F6',
    20: '#F0F0F0',
    30: '#DCDCDC',
    40: '#C0C0C0',
    60: '#87888A',
    70: '#68686A',
    80: '#4B4A4C',
    100: '#2E2D30',
    110: '#252426',
    120: '#1C1B1D',
    130: '#111111',
    140: '#000000',
  },
  white: '#ffffff',
  green: {
    10: '#F6FAF6',
    20: '#E8F7E6',
    30: '#DAF2D8',
    40: '#AEE1AC',
    50: '#8FD48F',
    60: '#73C573',
    70: '#56B658',
    80: '#39A547',
    100: '#0A802B',
    110: '#046C25',
    120: '#005600',
    130: '#003F16',
    140: '#00250E',
  },
  red: {
    10: '#FFF0F0',
    20: '#FFD9D9',
    30: '#FFB5B5',
    40: '#FB9191',
    60: '#F36C6C',
    70: '#E64F4A',
    80: '#D63731',
    100: '#C2221C',
    110: '#AE1D12',
    120: '#98190A',
    130: '#801504',
    140: '#661100',
  },
  blue: {
    10: '#EDF2FC',
    20: '#D2DFF7',
    30: '#B5C8EF',
    40: '#9BB1E6',
    60: '#8298DB',
    70: '#6C80CE',
    80: '#5667BF',
    100: '#4350AF',
    110: '#363F9B',
    120: '#2A2F87',
    130: '#202170',
    140: '#171659',
  },
  orange: {
    10: '#FFF6ED',
    20: '#FFE8D1',
    30: '#FFD5AE',
    40: '#FFC18B',
    60: '#FFAB69',
    70: '#FF9546',
    80: '#F57F23',
    100: '#E56800',
    110: '#E65B00',
    120: '#CC4F00',
    130: '#B34300',
    140: '#993800',
  },
  seaGreen: {
    10: '#F5FBF9',
    20: '#E7F4F1',
    30: '#C5E2DE',
    40: '#A4CFCA',
    60: '#85BBB6',
    70: '#66A5A2',
    80: '#4A8E8D',
    100: '#2E7576',
    110: '#236262',
    120: '#194D4E',
    130: '#103738',
    140: '#082021',
  },
  purple: {
    10: '#F7F6FF',
    20: '#EBE8FE',
    30: '#D0C7F9',
    40: '#B8A9F3',
    60: '#A48DEA',
    70: '#9173DF',
    80: '#815BD2',
    100: '#7246C2',
    110: '#5B35A2',
    120: '#452580',
    130: '#30185C',
    140: '#1B0C36',
  },
} as const;

export type ToiColors = typeof toiColors;
