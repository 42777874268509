import axios from 'axios';
import {request, gql} from 'graphql-request';
import arealplanerConfig from '../config';
import {getSelectedCustomer} from '../store';
import {ArealplanId} from '../hooks/arealplaner/types';
import {DispensasjonFormData} from '../hooks/dispensasjoner/types';
import {MovableDocument} from '../features/dokumenter/MoveDocument/MoveDocumentFormItem';
import {FetchJournalDocumentsProps} from '../hooks/journaler/types';
import {Innstilling} from '../hooks/meta/types';
import {GraphQLPagedResponse} from '../graphql/types';
import {store} from '../main';

const API_KEY = arealplanerConfig.planregister.token;
const BASE_URL = arealplanerConfig.planregister.url + '/api';
const GRAPHQL_URL = arealplanerConfig.planregister.url + `/graphql`;
const PROFILE = arealplanerConfig.planregister.profile;

let CancelToken;
let source;

function getCustomer() {
    const state = store.getState();
    return getSelectedCustomer(state);
}

export const api = axios.create({
    headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        'X-WAAPI-PROFILE': PROFILE,
        'X-WAAPI-TOKEN': API_KEY,
    },
});

export const apiRestricted = axios.create({
    headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        'X-WAAPI-PROFILE': PROFILE,
    },
});

// Arealplan
export const searchArealplan = (filters: string) => {
    if (CancelToken) {
        source.cancel('cancel search');
    }
    CancelToken = axios.CancelToken;
    source = CancelToken.source();
    return api.get(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner?${filters}`,
        {
            headers: {accept: 'application/json'},
            cancelToken: source.token,
        }
    );
};
export const searchArealplanMultiple = (filters: string) => {
    //search that wont be canceled. will respond to all requests
    return api.get(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner?${filters}`,
        {
            headers: {accept: 'application/json'},
        }
    );
};
export const fetchArealplan = (payload: {id?: number}) => {
    return api
        .get(
            `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${
                payload.id
            }?includePlanforhold=true`
        )
        .then((res) => res.data);
};
export const fetchArealplanIdByPlanId = (payload: {
    planId: string;
    administrativEnhet: string;
}) => {
    return api
        .get(
            `${BASE_URL}/gi/kunder/${getCustomer()}/arealplaner/id/${
                payload.administrativEnhet
            }/${payload.planId}`
        )
        .then((res) => res.data);
};
export const fetchArealplanEndringer = (id: string) => {
    return api.get(`${BASE_URL}/arealplaner/${id}/endringer`);
};
export const fetchArealplanBehandlinger = (
    id: ArealplanId,
    isAuthorized: boolean
) => {
    const url = `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${id}/behandlinger?includeDokumenter=true`;
    return isAuthorized ? apiRestricted.get(url) : api.get(url);
};
export const fetchArealplanDispensasjoner = (id: ArealplanId) => {
    return api.get(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${id}/dispensasjoner`
    );
};

export const updateDispensasjonDokumentrekkefolge = (id: number, data: any) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/dispensasjoner/${id}/dokumenter/rekkefolge`,
        data
    );
};

export const fetchArealplanDokumenter = (
    id: ArealplanId,
    isAuthorized: boolean
) => {
    const url = `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${id}/dokumenter`;
    return isAuthorized ? apiRestricted.get(url) : api.get(url);
};
export const getNewPlanId = () => {
    return api.get(`${BASE_URL}/kunder/${getCustomer()}/arealplaner/nyplanid`);
};
export const fetchMidlertidigForbudForPlan = (id: number) => {
    return api.get(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${id}/midlertidigforbud`
    );
};

export const fetchDokument = (id: number) => {
    return api.get(`${BASE_URL}/kunder/${getCustomer()}/dokumenter/${id}`);
};

// Behandlinger
export const fetchBehandlingDokumenter = (id: number) => {
    return api.get(
        `${BASE_URL}/kunder/${getCustomer()}/behandlinger/${id}/dokumenter`
    );
};
// Meta
export const fetchMeta = () => {
    return api.get(`${BASE_URL}/kunder/${getCustomer()}/meta`);
};

//Innstillinger
export const fetchInnstillinger = () => {
    return apiRestricted.get(
        `${BASE_URL}/kunder/${getCustomer()}/innstillinger`
    );
};

export const updateInnstillingTyped = (data: Innstilling | undefined) => {
    return apiRestricted.put(
        `${BASE_URL}/kunder/${getCustomer()}/innstillinger/${data?.id}`,
        data
    );
};

export const updateInnstilling = (data) => {
    return apiRestricted.put(
        `${BASE_URL}/kunder/${getCustomer()}/innstillinger/${data.Innstilling}`,
        data,
        {
            validateStatus: (status) => {
                // react-final-form require resolved promise to get submit errors
                return (status >= 200 && status < 300) || status == 400;
            },
        }
    );
};
//REPORTS
//Behandlinger
export const fetchBehandlingerReport = (
    take?: number | null,
    page?: number | null,
    order?: string | null,
    orderBy?: string | null,
    behandlingstypeId?: string | null,
    plantypeId?: string | null,
    planstatusId?: string | null,
    beskrivelse?: string | null,
    fraDatoStart?: string | null,
    fraDatoEnd?: string | null,
    tilDatoStart?: string | null,
    tilDatoEnd?: string | null
) => {
    let url = `${BASE_URL}/kunder/${getCustomer()}/rapporter/behandlinger?`;
    if (page) url += `&page=${page}`;
    if (take) url += `&take=${take}`;
    if (order) url += `&order=${order}`;
    if (orderBy) url += `&orderBy=${orderBy}`;
    if (behandlingstypeId) url += `&behandlingstypeId=${behandlingstypeId}`;
    if (plantypeId) url += `&plantypeId=${plantypeId}`;
    if (planstatusId) url += `&planstatusId=${planstatusId}`;
    if (beskrivelse) url += `&beskrivelse=${beskrivelse}`;
    if (fraDatoStart) url += `&fraDatoStart=${fraDatoStart}`;
    if (fraDatoEnd) url += `&fraDatoEnd=${fraDatoEnd}`;
    if (tilDatoStart) url += `&tilDatoStart=${tilDatoStart}`;
    if (tilDatoEnd) url += `&tilDatoEnd=${tilDatoEnd}`;
    return apiRestricted.get(url);
};
export const fetchBehandlingerReportCsv = (
    order?: string | null,
    orderBy?: string | null,
    behandlingstypeId?: string | null,
    plantypeId?: string | null,
    planstatusId?: string | null,
    beskrivelse?: string | null,
    fraDatoStart?: string | null,
    fraDatoEnd?: string | null,
    tilDatoStart?: string | null,
    tilDatoEnd?: string | null
) => {
    let url = `${BASE_URL}/kunder/${getCustomer()}/rapporter/behandlinger/csv?`;
    if (order) url += `&order=${order}`;
    if (orderBy) url += `&orderBy=${orderBy}`;
    if (behandlingstypeId) url += `&behandlingstypeId=${behandlingstypeId}`;
    if (plantypeId) url += `&plantypeId=${plantypeId}`;
    if (planstatusId) url += `&planstatusId=${planstatusId}`;
    if (beskrivelse) url += `&beskrivelse=${beskrivelse}`;
    if (fraDatoStart) url += `&fraDatoStart=${fraDatoStart}`;
    if (fraDatoEnd) url += `&fraDatoEnd=${fraDatoEnd}`;
    if (tilDatoStart) url += `&tilDatoStart=${tilDatoStart}`;
    if (tilDatoEnd) url += `&tilDatoEnd=${tilDatoEnd}`;
    return apiRestricted.get(url);
};

// Konsistenssjekk
export const fetchKonsistenssjekk = () => {
    return apiRestricted.get(`${BASE_URL}/konsistenssjekk/${getCustomer()}`);
};

export const fetchKonsistenssjekkReportCsv = () => {
    return apiRestricted.get(
        `${BASE_URL}/konsistenssjekk/${getCustomer()}?downloadCsv=true`
    );
};

export const runKonsistenssjekk = () => {
    return apiRestricted.post(`${BASE_URL}/konsistenssjekk/${getCustomer()}`);
};

//Kunder
export const fetchKunder = () => {
    return api.get(`${BASE_URL}/kunder`);
};

export const fetchKunde = (kundeId: string) => {
    return api.get(`${BASE_URL}/kunder/${kundeId}`).then((res) => res.data);
};

// Sak Arkiv
export const fetchFaserForSak = (SakId: number) => {
    return api.get(`${BASE_URL}/kunder/${getCustomer()}/saker/${SakId}/faser`);
};

export const fetchJournaler = (SakId: number, isAuthorized: boolean) => {
    const url = `${BASE_URL}/kunder/${getCustomer()}/saker/${SakId}/journaler`;
    return isAuthorized ? apiRestricted.get(url) : api.get(url);
};

export const fetchJournalDocuments = (
    {
        sakId: SakId,
        journalpostAar: JournalpostAar,
        journalpostSekvensnr: JournalpostSekvensnr,
    }: FetchJournalDocumentsProps,
    isAuthorized: boolean
) => {
    const url = `${BASE_URL}/kunder/${getCustomer()}/saker/${SakId}/journaler/${JournalpostAar}/${JournalpostSekvensnr}/dokumenter`;
    return isAuthorized ? apiRestricted.get(url) : api.get(url);
};

export const fetchJournalDocument = (SakId: number, SystemId: string) => {
    return api.get(
        `${BASE_URL}/kunder/${getCustomer()}/saker/${SakId}/dokumenter/${SystemId}`,
        {
            responseType: 'blob',
        }
    );
};

//Tilbakemeldinger
export const createTilbakemelding = (data: FormData) => {
    const kundeId = getCustomer();
    const arealplanId = data.get('arealplanId');

    return api.post(
        `${BASE_URL}/kunder/${kundeId}/arealplaner/${arealplanId}/tilbakemeldinger`,
        data,
        {
            headers: {'content-type': 'multipart/form-data'},
            validateStatus: (status) => {
                // react-final-form require resolved promise to get submit errors
                return (status >= 200 && status < 300) || status == 400;
            },
        }
    );
};

// ApplikasjonInnstillinger
export const fetchApplikasjonInnstillinger = () => {
    return api.get(`${BASE_URL}/applikasjoninnstillinger`);
};

///// Restricted api endpoints

export const createArealplan = (data) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner`,
        data,
        {
            validateStatus: (status) => {
                // react-final-form require resolved promise to get submit errors
                return (status >= 200 && status < 300) || status == 400;
            },
        }
    );
};
export const updateArealplan = (plan) => {
    return apiRestricted.put(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${plan.id}`,
        plan,
        {
            validateStatus: (status) => {
                // react-final-form require resolved promise to get submit errors
                return (status >= 200 && status < 300) || status == 400;
            },
        }
    );
};
export const updatePartialArealplan = (data: {
    ArealplanId: ArealplanId;
    PlanStatusId: number;
}) => {
    return apiRestricted.patch(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${data.ArealplanId}`,
        data
    );
};
export const deleteArealplan = (id: number) => {
    return apiRestricted.delete(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${id}`
    );
};

export const createBehandling = (data) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${
            data.arealplanId
        }/behandlinger`,
        data,
        {
            validateStatus: (status) => {
                // react-final-form require resolved promise to get submit errors
                return (status >= 200 && status < 300) || status == 400;
            },
        }
    );
};
export const updateBehandling = (data) => {
    return apiRestricted.put(
        `${BASE_URL}/kunder/${getCustomer()}/behandlinger/${data.id}`,
        data,
        {
            validateStatus: (status) => {
                // react-final-form require resolved promise to get submit errors
                return (status >= 200 && status < 300) || status == 400;
            },
        }
    );
};
export const deleteBehandling = (id) => {
    return apiRestricted.delete(
        `${BASE_URL}/kunder/${getCustomer()}/behandlinger/${id}`
    );
};

export const createDispensasjon = (data: DispensasjonFormData) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${
            data.arealplanId
        }/dispensasjoner`,
        data,
        {
            validateStatus: (status) => {
                // react-final-form require resolved promise to get submit errors
                return (status >= 200 && status < 300) || status == 400;
            },
        }
    );
};

export const updateDispensasjon = (id: number, data: DispensasjonFormData) => {
    return apiRestricted.put(
        `${BASE_URL}/kunder/${getCustomer()}/dispensasjoner/${id}`,
        data,
        {
            validateStatus: (status) => {
                // react-final-form require resolved promise to get submit errors
                return (status >= 200 && status < 300) || status == 400;
            },
        }
    );
};

export const createDispensasjonDokument = (data: FormData, id: number) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/dispensasjoner/${id}/dokumenter`,
        data,
        {
            headers: {'content-type': 'multipart/form-data'},
        }
    );
};
export const deleteDispensasjon = (id) => {
    return apiRestricted.delete(
        `${BASE_URL}/kunder/${getCustomer()}/dispensasjoner/${id}`
    );
};

export const createBehandlingDokument = (data: FormData, id: number) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/behandlinger/${id}/dokumenter`,
        data,
        {
            headers: {'content-type': 'multipart/form-data'},
        }
    );
};

export const updateBehandlingDokumentrekkefolge = (id: number, data: any) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/behandlinger/${id}/dokumenter/rekkefolge`,
        data
    );
};

export const moveDocumentFromPlan = (data: MovableDocument, id: number) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/behandlinger/${id}/dokumenter/flyttdokumentfraplan`,
        data
    );
};

export const createArealplanDokument = (data: FormData, id: ArealplanId) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${id}/dokumenter`,
        data,
        {
            headers: {'content-type': 'multipart/form-data'},
        }
    );
};

export const updateArealplanDokumentrekkefolge = (
    id: ArealplanId,
    data: any
) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/arealplaner/${id}/dokumenter/rekkefolge`,
        data
    );
};

export const updateDokument = (dokument: any) => {
    return apiRestricted.put(
        `${BASE_URL}/kunder/${getCustomer()}/dokumenter/${dokument.id}`,
        dokument
    );
};

export const deleteDokument = (id: number) => {
    return apiRestricted.delete(
        `${BASE_URL}/kunder/${getCustomer()}/dokumenter/${id}`
    );
};

//Kodelister

//Kun behandlingstyper er laget i backend
export const fetchKodelisteBehandlingstyper = () => {
    return apiRestricted.get(
        `${BASE_URL}/kunder/${getCustomer()}/kodelister/behandlingstyper`
    );
};

export const fetchArkivKodeliste = (arkivId: number, kodelisteType: string) => {
    return apiRestricted.get(
        `${BASE_URL}/kunder/${getCustomer()}/arkiver/${arkivId}/kodelister?kodelisteType=${kodelisteType}`
    );
};

export const fetchArkivKodelisteTilganger = (
    arkivId: number,
    kodelisteType: string
) => {
    return apiRestricted.get(
        `${BASE_URL}/kunder/${getCustomer()}/arkiver/${arkivId}/kodelister/tilganger?kodelisteType=${kodelisteType}`
    );
};

export const updateArkivKodelisteTilganger = (
    arkivId: number,
    kodelisteType: string,
    data: any
) => {
    return apiRestricted.post(
        `${BASE_URL}/kunder/${getCustomer()}/arkiver/${arkivId}/kodelister/tilganger?kodelisteType=${kodelisteType}`,
        data
    );
};
// Tokens

export const fetchTokens = (tokenType: string) => {
    return apiRestricted.get(
        `${BASE_URL}/kunder/${getCustomer()}/tokens?tokenType=${tokenType}`
    );
};

export const fetchToken = (id: number) => {
    return apiRestricted.get(
        `${BASE_URL}/kunder/${getCustomer()}/tokens/${id}`
    );
};

export const createToken = (description: string, tokenType: string) => {
    return apiRestricted.post(`${BASE_URL}/kunder/${getCustomer()}/tokens`, {
        description,
        tokenType,
    });
};

export const updateToken = (id: number, description: string) => {
    return apiRestricted.put(
        `${BASE_URL}/kunder/${getCustomer()}/tokens/${id}`,
        {
            description,
        }
    );
};

export const deleteToken = (id: number) => {
    return apiRestricted.delete(
        `${BASE_URL}/kunder/${getCustomer()}/tokens/${id}`
    );
};

//GraphQL
export const graphQLRequest = async (
    query: string,
    variables?: Record<string, any> | null,
    headers?: Record<string, string>
) => {
    const graphrequest = await request<GraphQLPagedResponse>(
        `${GRAPHQL_URL}/${getCustomer()}`,
        gql`
            ${query}
        `,
        variables ? variables : undefined,
        headers
            ? headers
            : {
                  'X-WAAPI-Token': API_KEY,
                  'X-WAAPI-PROFILE': PROFILE,
                  'Content-Type': 'application/json',
              }
    );
    return graphrequest;
};
