import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {useEffect} from 'react';
import FormInput from '../../../components/FormInput';
import {Innstilling} from '../../../hooks/meta/types';
import useUpdateFrontendSetting from '../../../hooks/settings/useUpdateFrontendSetting';
import DialogGenericMessage from '../../../components/DialogGenericMessage';

type Props = {
    dialogOpen: boolean;
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    innstilling: Innstilling | undefined;
};

const StyledButton = styled(Button)(({theme}) => ({
    '&:hover': {
        backgroundColor: '#1e7400',
    },
    width: '106px',
    backgroundColor: '#175600',
}));

function FrontendSettingDialog({
    dialogOpen,
    setDialogOpen,
    innstilling,
}: Props) {
    const [setting, setSetting] = React.useState<Innstilling | undefined>();
    const {mutateAsync, isLoading} = useUpdateFrontendSetting();

    useEffect(() => {
        if (innstilling) setSetting(innstilling);
    }, [innstilling]);

    const handleSave = async () => {
        await mutateAsync(setting);
        setDialogOpen(false);
    };

    const handleClose = () => {
        setDialogOpen(false);
        setSetting(innstilling);
    };

    if (setting)
        return (
            <Dialog
                onClose={(event, reason) => {
                    if (
                        reason === 'backdropClick' ||
                        reason === 'escapeKeyDown'
                    )
                        handleClose();
                }}
                open={dialogOpen}
                maxWidth='sm'
                fullWidth={true}
            >
                <DialogTitle>Oppdater innstilling - {setting?.id}</DialogTitle>
                <form>
                    <DialogContent
                        sx={{
                            padding: '10px 20px, 10px, 10px',
                            display: 'flex',
                            alignItems: 'baseline',
                        }}
                    >
                        <FormInput
                            name='verdi'
                            value={setting ? setting.verdi : ''}
                            autoComplete='false'
                            onChange={(e) => {
                                setting &&
                                    setSetting({
                                        ...setting,
                                        verdi: e.target.value,
                                    });
                            }}
                        />
                    </DialogContent>
                    <DialogActions
                        sx={{paddingRight: '24px', paddingBottom: '20px'}}
                    >
                        <Grid container={true} justifyContent='flex-end'>
                            <Grid>
                                <Button
                                    disabled={isLoading}
                                    variant='outlined'
                                    color='primary'
                                    onClick={() => handleClose()}
                                >
                                    Avbryt
                                </Button>
                                <StyledButton
                                    type='submit'
                                    disabled={isLoading}
                                    onClick={() => handleSave()}
                                    variant='contained'
                                >
                                    {isLoading ? (
                                        <CircularProgress
                                            color='inherit'
                                            size={24}
                                        />
                                    ) : (
                                        'Oppdater'
                                    )}
                                </StyledButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        );
    return (
        <DialogGenericMessage
            content='Noe gikk galt, last inn siden på nytt og prøv igjen.'
            title='Feil'
            open={dialogOpen}
            typeOfMessage='warning'
            handleClose={handleClose}
        />
    );
}

export default FrontendSettingDialog;
