import * as React from 'react';
import {useQuery} from 'react-query';
import {format} from 'date-fns';
import {useFetchTokenHeaderWithAuthData} from '@norkart/nk-auth';
import {graphQLRequest} from '../../services/api';
import {BehandlingerQueryVariables} from '../../features/reports/Reports/Behandlinger/Behandlinger';
import {saksbehandlingstidQuery} from './customReportQueries';
import {SaksbehandlingstidReport} from './types';

export function useSaksbehandlingstidQLCsv(
    variables: BehandlingerQueryVariables
) {
    const headings = [
        'PlanId',
        'FraDato',
        'BehandlingstypeFra',
        'BehandlingstypeFraId',
        'TilDato',
        'BehandlingstypeTil',
        'BehandlingstypeTilId',
        'AntallDager',
        'Plantype',
        'PlantypeId',
    ];
    const getHeaders = useFetchTokenHeaderWithAuthData();

    const reactQuery = useQuery<any>(
        ['saksbehandlingstidReportCsv'],
        async () => {
            const headers = await getHeaders();
            return await graphQLRequest(
                saksbehandlingstidQuery,
                variables,
                headers
            );
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
            cacheTime: 0,
            keepPreviousData: false,
        }
    );

    const csvArray = React.useMemo(() => {
        if (reactQuery.data) {
            const saksbehandlingstider: SaksbehandlingstidReport[] =
                reactQuery.data.saksbehandlingstidReport;

            const newLines = saksbehandlingstider.reduce((acc: any, s) => {
                acc.push(
                    [
                        s.planId ? s.planId : '',
                        s.fraDato
                            ? format(new Date(s.fraDato), 'dd.MM.yyyy')
                            : '',
                        s.behandlingFra ? s.behandlingFra.behandlingstype : '',
                        s.behandlingFra
                            ? s.behandlingFra.behandlingstypeId
                            : '',
                        s.tilDato
                            ? format(new Date(s.tilDato), 'dd.MM.yyyy')
                            : '',
                        s.behandlingTil ? s.behandlingTil.behandlingstype : '',
                        s.behandlingTil
                            ? s.behandlingTil.behandlingstypeId
                            : '',
                        s.antallDager ? s.antallDager : '',
                        s.plantype ? s.plantype.beskrivelse : '',
                        s.plantype ? s.plantype.id : '',
                    ].join(';')
                );
                return acc;
            }, []);

            return newLines;
        }
    }, [reactQuery.data]);

    return {...reactQuery, csvData: csvArray, headers: headings};
}
