import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import React from 'react';
import {JournalDocument} from '../../../hooks/journaler/types';
import ImportDocumentFromArchiveSelectFilesListItem from './ImportDocumentFromArchiveSelectFilesListItem';

type Props = {
    documents: JournalDocument[];
};

function ImportDocumentFromArchiveSelectFiles({documents}: Props) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell variant='head' />
                    <TableCell variant='head'>Dokument tittel</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {documents.map((document, i) => (
                    <ImportDocumentFromArchiveSelectFilesListItem
                        key={i}
                        document={document}
                    />
                ))}
            </TableBody>
        </Table>
    );
}

export default ImportDocumentFromArchiveSelectFiles;
