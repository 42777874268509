import axios from 'axios';
import {backend} from '../Consts';
import clientType from '../Types/ClientType';

const clientApi = axios.create();
const url = backend + 'clients';

//TODO Do something about url definition and appId
export async function fetchClients(): Promise<clientType[]> {
  const result = await clientApi.get(url);
  return result.data;
}
