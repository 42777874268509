import * as React from 'react';
import {Grid} from '@mui/material';
import ActionButton, {ActionButtonProps} from './ActionButton';

export interface ActionButtonsProps {
    actionButtons: ActionButtonProps[];
}

function ActionButtons({actionButtons}: ActionButtonsProps) {
    return (
        <Grid container={true}>
            {actionButtons.map((actionButton, index) => (
                <Grid
                    item={true}
                    key={`action-button-${index}`}
                    style={{padding: '10px', paddingLeft: 0, marginBottom: 15}}
                >
                    <ActionButton {...actionButton} />
                </Grid>
            ))}
        </Grid>
    );
}

export default ActionButtons;
