import {styled, Popper as MuiPopper, PopperProps as MuiPopperProps} from '@mui/material';
import {forwardRef} from 'react';
import {toiPopperStyle} from './toiPopperStyle';

export type ToiPopperVariant = 'tooltip' | 'toggletip';
export type ToiPopperPlacement = 'left' | 'bottom left' | 'top left' | 'right' | 'bottom right' | 'top right';

type OwnProps = {
  variant?: ToiPopperVariant;
};

type ToiPopperProps = Omit<MuiPopperProps, keyof OwnProps | 'component'> & OwnProps;

const StyledPopper = styled(MuiPopper)<{
  variant?: string;
}>(({theme, variant, placement}) => {
  return toiPopperStyle(theme, variant, placement);
});

export const ToiPopper = forwardRef<HTMLDivElement, ToiPopperProps>((props, ref) => {
  const {variant, children, ...restProps} = props;

  return (
    <StyledPopper variant={variant} ref={ref} {...restProps}>
      {children}
    </StyledPopper>
  );
});

export default ToiPopper;
