import React, {useEffect, useMemo, useState} from 'react';
import {Box, Button, Checkbox} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import qs from 'qs';
import {AllGeoJSON} from '@turf/helpers';
import {bbox as turfBbox} from '@turf/bbox';
import {FitBounds} from '@norkart/nkm-mapbox-map';
import {
    addFitToBounds,
    resetGFIState,
    resetMapState,
} from '../../store/map/actions';
import useSelectedKundeId from '../../hooks/kunder/useSelectedKundeId';
import useSearch from '../../hooks/search/useSearch';
import {getMapState} from '../../store';
import {getMapboxLayer} from '../map/helpers/getMapboxLayer';
import {gfiAttId, WmsFeature} from '../../services/apiTjenestekatalogen';
import {GFIAttrState} from '../../store/map/types';
import PunktinfoRelatertePlaner from '../map/Components/Menus/PunktInfo/PunktinfoRelatertePlaner';
import useArealplan from '../../hooks/arealplaner/useArealplan';
import {Arealplan} from '../../hooks/arealplaner/types';
import useSelectedArealplanId from '../../hooks/arealplaner/useSelectedArealplanId';
import {useNavigateToArealplan} from '../../hooks/useNavigateToArealplan';
import PlanSummary from './AlternativeHomeRelated/PlanSummary';

type Props = {setDisplaySurroundingPlanWms; displaySurroundingPlanWms};

export default function SidebarPlan({
    setDisplaySurroundingPlanWms,
    displaySurroundingPlanWms,
}: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mapState = useSelector(getMapState);
    const kundeId = useSelectedKundeId();
    const search = useSearch();
    const selectedArealplanId = useSelectedArealplanId();
    const plan = useArealplan(selectedArealplanId);
    const navigateArealplan = useNavigateToArealplan();

    const [visibleGfiFeature, setVisibleGfiFeature] = useState<
        WmsFeature | undefined
    >(getVisibleGfiFeature(mapState.drawnAttInMap, mapState.gfiState));

    useEffect(() => {
        const newVisibleGfiFeature = getVisibleGfiFeature(
            mapState.drawnAttInMap,
            mapState.gfiState
        );
        if (newVisibleGfiFeature !== visibleGfiFeature) {
            setVisibleGfiFeature(newVisibleGfiFeature);
        }
    }, [mapState.gfiState, mapState.drawnAttInMap]);

    const handleSearch = () => {
        dispatch(resetMapState());
        navigate(
            {
                pathname: `/${kundeId}/arealplaner/search`,
                search: qs.stringify({...search.searchState.urlSearchParams}),
            },
            {replace: true}
        );
    };

    function getVisibleGfiFeature(
        drawnAttInMap: gfiAttId | undefined,
        gfiState: GFIAttrState | undefined
    ): WmsFeature | undefined {
        let allAttsLists: WmsFeature[] = [];
        gfiState &&
            gfiState.planAttributes &&
            gfiState.planAttributes.forEach((plan) => {
                plan.wmsLayers.forEach((layer) => {
                    allAttsLists = allAttsLists.concat(layer.attrList);
                });
            });
        const gfiFeature = allAttsLists.filter(
            (att) => att.id === drawnAttInMap
        );
        return gfiFeature.length ? gfiFeature[0] : undefined;
    }

    function zoomToDrawnLayer() {
        if (visibleGfiFeature) {
            const layerToZoomTo: mapboxgl.GeoJSONSourceRaw = getMapboxLayer(
                visibleGfiFeature.Geometry,
                visibleGfiFeature.Interiors,
                visibleGfiFeature.id
            ).source! as mapboxgl.GeoJSONSourceRaw;

            const bbox = turfBbox((layerToZoomTo.data as AllGeoJSON)!) as [
                number,
                number,
                number,
                number
            ];
            const bounds: FitBounds = {
                bounds: bbox,
                options: {padding: 110, linear: true, maxZoom: 20},
            };
            dispatch(addFitToBounds(bounds));
        }
    }

    function getSurroundingFeatures() {
        const surroundingFeatures = mapState.gfiState.planAttributes; //?.filter(
        //  feature => feature.planId !== selectedPlan?.planId
        //)

        if (surroundingFeatures) {
            return surroundingFeatures.map((feature) => feature.planId);
        } else {
            return [];
        }
    }

    const handlePlanClick = (plan: Arealplan) => {
        dispatch(resetGFIState());
        navigateArealplan.toArealplan(plan.id);
    };

    const surroundingPlanFeatures = useMemo(
        () => getSurroundingFeatures(),
        [mapState.gfiState.planAttributes]
    );
    return (
        <Box
            sx={(theme) => ({
                paddingX: '20px',
                paddingBottom: '50px',
                boxSizing: 'border-box',
                borderRight: '1px solid lightgrey',
                overflow: 'auto',
                height: 'calc(100% - 64px)',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('md')]: {
                    width: '30%',
                    minWidth: '400px',
                },
            })}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginY: '20px',
                    flexWrap: 'wrap',
                }}
            >
                <Button onClick={handleSearch}>
                    <ArrowBackIosIcon />
                    Tilbake til søk
                </Button>
                <Button
                    variant='text'
                    onClick={() => {
                        setDisplaySurroundingPlanWms(
                            !displaySurroundingPlanWms
                        );
                    }}
                >
                    <Checkbox
                        color='secondary'
                        checked={displaySurroundingPlanWms}
                    />
                    Vis naboplaner
                </Button>
            </Box>
            <PlanSummary plan={plan.data} />
            <Button
                sx={{marginTop: '20px', width: '190px'}}
                variant='contained'
                color='secondary'
                onClick={() => handlePlanClick(plan.data)}
            >
                Gå til plandetaljer
            </Button>
            <Box sx={{borderTop: '1px solid lightgray', marginY: '40px'}}></Box>
            <PunktinfoRelatertePlaner
                planIds={surroundingPlanFeatures}
                displaySurroundingPlanWms={displaySurroundingPlanWms}
                zoomToDrawnLayer={zoomToDrawnLayer}
            />
        </Box>
    );
}
