import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useEffect, useState} from 'react';
import SpinnerButton from '../../../components/SpinnerButton';
import {Token, TokenType} from '../../../hooks/tokens/types';
import useCreateToken from '../../../hooks/tokens/useCreateToken';
import useDeleteToken from '../../../hooks/tokens/useDeleteToken';
import useUpdateToken from '../../../hooks/tokens/useUpdateToken';
import {useNotification} from '../../../hooks/useNotification';

type FormData = {
    description: string;
};
type Props = {
    isOpen: boolean;
    handleClose: () => void;
    token?: Token;
    tokenType: TokenType;
};

function TokenDialog({isOpen, handleClose, token, tokenType}: Props) {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const createToken = useCreateToken(tokenType);
    const updateToken = useUpdateToken(tokenType);
    const deleteToken = useDeleteToken(tokenType);
    const [formData, setFormData] = useState<FormData>(
        token || {description: ''}
    );
    const {notifySuccess} = useNotification();

    useEffect(() => {
        if (token) setFormData(token);
        else setFormData({description: ''});
    }, [token]);

    const handleSave = async () => {
        const res = token
            ? await updateToken.mutateAsync(
                  {
                      id: token.id,
                      description: formData.description,
                  },
                  {onSuccess: notifySuccess('Tilgang oppdatert')}
              )
            : await createToken.mutateAsync(
                  {description: formData.description},
                  {onSuccess: notifySuccess('Tilgang opprettet')}
              );

        handleClose();
    };

    const handleConfirmDeleteOpen = () => {
        setConfirmDeleteOpen(true);
    };
    const handleConfirmDeleteClose = () => {
        setConfirmDeleteOpen(false);
    };
    const handleDelete = async () => {
        setConfirmDeleteOpen(false);
        if (token) {
            await deleteToken.mutateAsync(token.id, {
                onSuccess: notifySuccess('Tilgang slettet'),
            });
        }
        handleClose();
    };

    const isLoading =
        updateToken.isLoading || createToken.isLoading || deleteToken.isLoading;

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                maxWidth='sm'
                fullWidth={true}
            >
                <DialogTitle>
                    {token ? 'Rediger tilgang' : 'Opprett ny tilgang'}
                </DialogTitle>
                <DialogContent>
                    <form
                        style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            padding: '10px 20px',
                        }}
                    >
                        <label
                            htmlFor='description'
                            style={{marginRight: '5px'}}
                        >
                            Beskrivelse
                        </label>
                        <input
                            name='description'
                            type='text'
                            value={formData.description}
                            autoComplete='false'
                            className='formInput'
                            style={{padding: '5px', flexGrow: 1}}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    description: e.target.value,
                                })
                            }
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Grid container={true} justifyContent='space-between'>
                        <Grid>
                            {token && (
                                <Button onClick={handleConfirmDeleteOpen}>
                                    <DeleteIcon />
                                    Slett tilgang
                                </Button>
                            )}
                        </Grid>
                        <Grid>
                            <Button
                                onClick={handleClose}
                                variant='outlined'
                                color='primary'
                            >
                                Avbryt
                            </Button>
                            <SpinnerButton
                                onClick={handleSave}
                                variant='contained'
                                color='secondary'
                                style={{marginLeft: '10px'}}
                                label={token ? 'Lagre' : 'Opprett'}
                                disabled={isLoading}
                                loading={isLoading}
                            />
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            {token && (
                <Dialog
                    open={confirmDeleteOpen}
                    onClose={handleConfirmDeleteClose}
                >
                    <DialogTitle>Vil du virkelig slette tilgangen?</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleConfirmDeleteClose}>Nei</Button>
                        <Button onClick={handleDelete}>Ja</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default TokenDialog;
