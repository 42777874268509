import {CSSObject, Theme} from '@mui/material';
import {focusStyle} from '../ToiButton/toiButtonStyle';
import {themeIsLightMode} from '../utils/themeIsLightMode';

export function toiIconButtonStyle({theme}: {theme: Theme}): CSSObject {
  const isLightMode = themeIsLightMode(theme);

  return {
    borderRadius: '2px',
    align: 'center',
    color: isLightMode ? theme.palette.black[100] : theme.palette.common.white,

    '&.Mui-focusVisible::before': focusStyle(theme.palette, isLightMode),
    '&:hover': {
      backgroundColor: isLightMode ? theme.palette.black[20] : theme.palette.black[110],
    },
    '&:active, &.active': {
      color: isLightMode ? theme.palette.primary.main : theme.palette.common.white,
      backgroundColor: isLightMode ? theme.palette.primary[30] : 'inherit',
    },
    '&.Mui-focusVisible': {
      backgroundColor: isLightMode ? theme.palette.black[10] : 'inherit',
    },
  };
}
