import axios from 'axios';
import config from '../config';
import {getSetting} from '../config/config';

const PROFILE = config.planregister.profile;

export const planpratBaseURL = getSetting('NX_PLANPRAT_API');

export const planpratAxiosInstance = axios.create({
    baseURL: planpratBaseURL,
    headers: {
        'Content-Type': 'application/json',
        'X-WAAPI-PROFILE': PROFILE,
    },
});

export const postChat = async (data: any, id: string) => {
    const requestData = {
        message: data.message,
        chat_history: data.chatHistoryFormatted,
        plans: data.plansFormatted,
        include_references: data.includeReferences,
    };

    return await planpratAxiosInstance.post(
        planpratBaseURL + '/chat',
        requestData,
        {
            headers: {
                'X-WAAPI-CONTEXT': id,
            },
        }
    );
};
