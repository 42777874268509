import {appInsightsInstance, getCookieConsent} from '../../utils/appInsights';

export default function useTrackArealplanerEvent(
    component: string,
    eventType: 'button' | 'event'
) {
    const userConsent = getCookieConsent();
    return (evData: string) => {
        if (evData !== '' && appInsightsInstance && userConsent.statistic) {
            appInsightsInstance.trackEvent(
                {name: component},
                {[eventType]: evData}
            );
        }
    };
}
