import {Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, styled} from '@mui/material';
import {forwardRef} from 'react';
import {toiCheckboxStyle} from './toiCheckboxStyle';

type OwnProps = {
  //Add your own custom props here.
  error?: boolean;
};

export type ToiCheckboxProps = Omit<MuiCheckboxProps, keyof OwnProps | 'component'> & OwnProps;

const StyledCheckbox = styled(MuiCheckbox, {
  shouldForwardProp: (prop) => prop !== 'error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
})<OwnProps>(({theme, error}): any => {
  return toiCheckboxStyle(theme, error);
});

export const ToiCheckbox = forwardRef<HTMLButtonElement, ToiCheckboxProps>((props, ref) => {
  const {...restProps} = props;

  return <StyledCheckbox disableRipple ref={ref} {...restProps} />;
});

export default ToiCheckbox;
