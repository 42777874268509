//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppSendIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M0 1L4 11L17 12L3.99997 13L0 23L24 12L0 1Z' style={props.secondaryStyle} />
        </g>
    ),
    'AppSendIcon' // aria-label
);
