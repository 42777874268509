import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import * as React from 'react';
import useMeta from '../../../../hooks/meta/useMeta';
import {setTitle} from '../../../../utils/setTitle';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import BehandlingerReportV2 from '../BehandlingerV2/BehandlingerReport';
import BehandlingerFilterV2 from '../BehandlingerV2/BehandlingerFilter';
import {BehandlingReportFilters} from '../../../../hooks/behandlinger/useBehandlingerReport';

export type BehandlingerQueryVariables = {
    first?: number;
    after?: string;
    filter?: {and: any[]} | undefined;
    sort?: any[] | undefined;
};

export default function Behandlinger() {
    setTitle('Rapporter > Behandlinger');
    const initialVariables: BehandlingerQueryVariables = {first: 60};
    const meta = useMeta();
    const [filterValues, setFilterValues] = React.useState<any | undefined>(
        undefined
    );
    const [vars, setVars] =
        React.useState<BehandlingerQueryVariables>(initialVariables);

    const [filterValuesV2, setFilterValuesV2] = React.useState<
        BehandlingReportFilters | undefined
    >(undefined);
    //Oppdaterer variabler til graphql request når "filterValues" oppdateres i "FilterBehandlinger" komponent
    React.useMemo(() => {
        const newVars: BehandlingerQueryVariables = {
            ...vars,
            filter: filterValues,
        };
        setVars((prevValue) => {
            if (newVars === initialVariables) return prevValue;
            else if (!newVars.filter && !newVars.sort) {
                return initialVariables;
            } else {
                return newVars;
            }
        });
    }, [filterValues]);

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <Grid direction='column' container={true}>
            <Typography
                variant='h2'
                fontSize={48}
                sx={{marginTop: 20, marginBottom: 16}}
            >
                Behandlinger
            </Typography>
            <Accordion
                disableGutters={true}
                defaultExpanded={true}
                variant='outlined'
                sx={{
                    boxShadow: 0,
                    width: 'fit-content',
                    '&.MuiAccordion-root': {
                        borderTopLeftRadius: '0%',
                        borderTopRightRadius: '0%',
                    },
                }}
            >
                <AccordionSummary
                    sx={{
                        backgroundColor: 'white',
                        paddingRight: 7,
                        '&.Mui-expanded': {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                        },
                    }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography sx={{paddingLeft: 10}} fontWeight='medium'>
                        Filter
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        paddingBottom: 10,
                        backgroundColor: '#FFFFFF',
                    }}
                >
                    <Grid
                        sx={{
                            width: 'fit-content',
                            padding: '0px 25px 20px 25px',
                        }}
                        direction='column'
                        container={true}
                    >
                        <Grid item={true}>
                            <BehandlingerFilterV2
                                filterValues={filterValuesV2}
                                setFilterValues={setFilterValuesV2}
                                clearFilter={filterValuesV2 ? false : true}
                            />
                        </Grid>
                        <Grid
                            justifyContent={'flex-end'}
                            display={'flex'}
                            xs={12}
                            item={true}
                        >
                            <Button
                                variant='text'
                                color='secondary'
                                onClick={() => setFilterValuesV2(undefined)}
                                sx={{
                                    display: filterValues ? 'flex' : 'none',
                                    height: 40,
                                    marginTop: 10,
                                }}
                            >
                                Nullstill filter
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Grid xs={12} item={true}>
                <BehandlingerReportV2 filterValues={filterValuesV2} />
            </Grid>
        </Grid>
    );
}
