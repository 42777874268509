import {Menu as MuiMenu, MenuProps as MuiMenuProps, styled} from '@mui/material';
import {forwardRef} from 'react';
import {toiMenuStyle} from './toiMenuStyle';

type OwnProps = {
  //Add your own custom props here.
};

type ToiMenuProps = Omit<MuiMenuProps, keyof OwnProps | 'component'> & OwnProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledMenu = styled(MuiMenu)(({theme}): any => {
  return toiMenuStyle(theme);
});

export const ToiMenu = forwardRef<HTMLDivElement, ToiMenuProps>((props, ref) => {
  const {...restProps} = props;

  return <StyledMenu ref={ref} {...restProps} />;
});

export default ToiMenu;
