import {
    Box,
    styled,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';
import {Arealplan} from '../../../hooks/arealplaner/types';
import {formatDate} from '../../../utils/formatDate';
import {formatNasjonalArealplanId} from '../../../utils/formatNasjonalArealplanId';
import PlanStatusChip from '../../plan/PlanStatusChip';

const StyledTableCell = styled(TableCell)({
    border: 0,
});

type Props = {plan: Arealplan};

export default function PlanSummary({plan}: Props) {
    return (
        <Box>
            <Typography
                sx={(theme) => ({
                    fontSize: 18,
                    fontWeight: 500,
                    marginBottom: '6px',
                })}
            >
                {plan.planNavn}
            </Typography>
            <PlanStatusChip
                planStatusId={plan.planStatusId}
                planStatus={plan.planStatus}
            />
            <div style={{height: '6px'}} />
            <Table
                sx={{
                    width: '100%',
                    tableLayout: 'fixed',
                    head: {
                        whiteSpace: 'nowrap',
                    },
                }}
                padding='none'
            >
                <TableBody>
                    <TableRow>
                        <StyledTableCell component='th' scope='row'>
                            Nasjonal arealplanid:
                        </StyledTableCell>
                        <StyledTableCell>
                            {formatNasjonalArealplanId(plan)}
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell component='th' scope='row'>
                            Plantype:
                        </StyledTableCell>
                        <StyledTableCell>{plan.planType}</StyledTableCell>
                    </TableRow>
                    {plan.iKraft && (
                        <TableRow>
                            <StyledTableCell component='th' scope='row'>
                                Ikrafttredelsesdato:
                            </StyledTableCell>
                            <StyledTableCell>
                                {formatDate(plan.iKraft)}
                            </StyledTableCell>
                        </TableRow>
                    )}
                    {plan.sistBehandlet && (
                        <TableRow>
                            <StyledTableCell component='th' scope='row'>
                                Sist behandlet:
                            </StyledTableCell>
                            <StyledTableCell>
                                {formatDate(plan.sistBehandlet)}
                            </StyledTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
}
