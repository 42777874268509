import {MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps, styled} from '@mui/material';
import {forwardRef} from 'react';
import {toiMenuItemStyle} from './toiMenuItemStyle';

type OwnProps = {
  //Add your own custom props here.
};

export type ToiMenuItemProps = Omit<MuiMenuItemProps, keyof OwnProps | 'component'> & OwnProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomToiMenuItem = styled(MuiMenuItem)(({theme}): any => toiMenuItemStyle(theme));

export const ToiMenuItem = forwardRef<HTMLLIElement, ToiMenuItemProps>((props, ref) => {
  return <CustomToiMenuItem {...props} ref={ref}></CustomToiMenuItem>;
});

export default ToiMenuItem;
