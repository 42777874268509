import {useMutation, useQueryClient} from 'react-query';
import {
    dispensasjonerUpdateDispensasjon,
    DispensasjonForUpdateDto,
} from '@norkart/nkapi-arealplaner-api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';

type Props = {
    dispensasjonId: number;
    dispensasjon: DispensasjonForUpdateDto;
};

export default function useUpdateDispensasjon() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const selectedArealplandId = useSelectedArealplanId();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useMutation(
        async (props: Props): Promise<void> => {
            const {data, error} = await dispensasjonerUpdateDispensasjon({
                client: authorizedClient,
                path: {kundeId, dispensasjonId: props.dispensasjonId},
                body: props.dispensasjon,
            });
            if (error) {
                throw new Error('Failed to update dispensasjon');
            }
            return data;
        },
        {
            onSuccess: (_, props) => {
                queryClient.invalidateQueries([
                    'dispensasjoner',
                    {kundeId, arealplanId: selectedArealplandId},
                ]);
            },
        }
    );
}
