import * as React from 'react';
import {CircularProgress, Grid} from '@mui/material';
import {Waypoint} from 'react-waypoint';
import {format} from 'date-fns';
import ReactTable from '../../../../components/ReactTable';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import {exportToCsv} from '../ReportTools/ClientsideCsv';
import useArealplanGraphQL from '../../../../hooks/arealplaner/useArealplanGraphQL';
import {useArealplanQLCsv} from '../../../../hooks/arealplaner/useArealplanQLCsv';
import {arealplanerReportQuery} from '../../../../hooks/arealplaner/arealplanerQueries';
import {ReportSortState} from '../ReportTools/CommonTypes';
import PlanColumns from './PlanColumns';
import {ArealplanQueryVariables} from './Plan';

type ArealplanReportProps = {
    variables: ArealplanQueryVariables;
    kundeId: string;
    kommunenummer: string;
    setVars: React.Dispatch<React.SetStateAction<ArealplanQueryVariables>>;
};

export default function PlanReport({
    variables,
    kundeId,
    kommunenummer,
    setVars,
}: ArealplanReportProps) {
    const csv = useArealplanQLCsv(variables);
    const columns = PlanColumns(kundeId, kommunenummer);
    const arealplan = useArealplanGraphQL(arealplanerReportQuery, variables);

    const handleSort = ({property, direction}: ReportSortState) => {
        if (!direction && variables.sort) {
            setVars({...variables, sort: undefined});
            return;
        }

        if (!direction && property && !variables.sort) return;
        let sorting: any;
        switch (property) {
            case 'planId':
                sorting = [{planId: direction}];
                break;
            case 'iKraft':
                sorting = [{iKraft: direction}];
                break;
            case 'lovreferanse':
                sorting = [{lovreferanse: direction}];
                break;
            case 'planNavn':
                sorting = [{planNavn: direction}];
                break;
            case 'planStatus':
                sorting = [{planStatus: direction}];
                break;
            case 'planType':
                sorting = [{planType: direction}];
                break;
            default:
                return undefined;
        }

        setVars({...variables, sort: sorting});
    };

    React.useEffect(() => {
        if (csv.csvData && csv.isSuccess) {
            exportToCsv(
                `arealplan_${format(new Date(), 'ddMMyyyy')}`,
                csv.csvData,
                csv.headers
            );
            csv.remove();
        }
    }, [csv.csvData]);

    const handleDownloadClick = () => {
        csv.refetch();
    };

    if (arealplan.isLoading && !variables.sort) return <LoadingSpinner />;
    return (
        <Grid item={true}>
            <ReactTable
                textSize='small'
                customSort={(property, direction) =>
                    handleSort({property, direction})
                }
                columns={columns}
                data={arealplan.arealplan ? arealplan.arealplan : []}
                toolbar={{
                    exportCsv: {
                        handleDownloadClick: () => handleDownloadClick(),
                        loadingRequest: csv.isLoading,
                    },
                    dataInfo: arealplan.isFetching ? (
                        <CircularProgress
                            color='primary'
                            size={20}
                            title='Oppdaterer resultater'
                        />
                    ) : (
                        <p>{`${
                            arealplan.totalCount ? arealplan.totalCount : 0
                        }  planer funnet`}</p>
                    ),
                }}
            />
            {arealplan.isFetchingNextPage && <LoadingSpinner />}
            {arealplan?.hasNextPage && (
                <Waypoint
                    onEnter={() => arealplan.fetchNextPage()}
                    bottomOffset='-400px'
                />
            )}
        </Grid>
    );
}
