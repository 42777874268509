import {useMutation, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {
    ArealplanDto,
    arealplanerCreateArealplan,
    ArealplanForCreationDto,
} from '@norkart/nkapi-arealplaner-api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useCreateArealplan() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    const navigate = useNavigate();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useMutation(
        async (arealplan: ArealplanForCreationDto): Promise<ArealplanDto> => {
            const {data, error} = await arealplanerCreateArealplan({
                client: authorizedClient,
                path: {kundeId},
                body: arealplan,
            });
            if (error) throw new Error('Error creating arealplan');
            return data;
        },
        {
            onError: (error: Error) => {
                notify(error.message);
            },
            onSuccess: (arealplan) => {
                queryClient.invalidateQueries([
                    'arealplan',
                    {kundeId, arealplanId: arealplan.id},
                ]);
                navigate(`/${kundeId}/arealplaner/${arealplan.id}`);
            },
        }
    );
}
