//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppCheckmarkCheckDoneIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path fillRule='evenodd' clipRule='evenodd' d='M10.0384 17.3758L9.33126 16.6727C8.44296 15.7894 7.55457 14.9085 6.6656 14.0271C5.77694 13.146 4.88726 12.2638 3.99792 11.3795L5.40813 9.96129C6.29643 10.8446 7.18481 11.7254 8.07378 12.6069L8.07481 12.6079C8.72766 13.2552 9.38083 13.9028 10.0341 14.5516L18.5797 6.00598L19.9939 7.42019L10.0384 17.3758Z' style={props.secondaryStyle} />
        </g>
    ),
    'AppCheckmarkCheckDoneIcon' // aria-label
);
