// TODO: change name to toEPSG?
export const epsg = {
    LAT_LNG: 'EPSG:4326',
    UTM31: 'EPSG:32631',
    UTM32: 'EPSG:32632',
    UTM33: 'EPSG:32633',
    UTM34: 'EPSG:32634',
    UTM35: 'EPSG:32635',
    UTM36: 'EPSG:32636',
    WORLD_MERCATOR: 'EPSG:3395',
    EPSG_54004: 'EPSG:54004',
    EPSG_3857: 'EPSG:3857',
    EPSG_900913: 'EPSG:900913',
    EPSG_25831: 'EPSG:25831',
    EPSG_25832: 'EPSG:25832',
    EPSG_25833: 'EPSG:25833',
    EPSG_25834: 'EPSG:25834',
    EPSG_25835: 'EPSG:25835',
    EPSG_25836: 'EPSG:25836',
};

export const utm = {
    UTM31: 'UTM31',
    UTM32: 'UTM32',
    UTM33: 'UTM33',
    UTM34: 'UTM34',
    UTM35: 'UTM35',
    UTM36: 'UTM36',
};

export const utmOutput = {
    NTMZONE5: 'NTM sone 5',
    NTMZONE6: 'NTM sone 6',
    NTMZONE7: 'NTM sone 7',
    NTMZONE8: 'NTM sone 8',
    NTMZONE9: 'NTM sone 9',
    NTMZONE10: 'NTM sone 10',
    NTMZONE11: 'NTM sone 11',
    NTMZONE12: 'NTM sone 12',
    NTMZONE13: 'NTM sone 13',
    NTMZONE14: 'NTM sone 14',
    NTMZONE15: 'NTM sone 15',
    NTMZONE16: 'NTM sone 16',
    NTMZONE17: 'NTM sone 17',
    NTMZONE18: 'NTM sone 18',
    NTMZONE19: 'NTM sone 19',
    NTMZONE20: 'NTM sone 20',
    NTMZONE21: 'NTM sone 21',
    NTMZONE22: 'NTM sone 22',
    NTMZONE23: 'NTM sone 23',
    NTMZONE24: 'NTM sone 24',
    NTMZONE25: 'NTM sone 25',
    NTMZONE26: 'NTM sone 26',
    NTMZONE27: 'NTM sone 27',
    NTMZONE28: 'NTM sone 28',
    NTMZONE29: 'NTM sone 29',
    NTMZONE30: 'NTM sone 30',
    UTM31: 'UTM 31N',
    UTM32: 'UTM 32N',
    UTM33: 'UTM 33N',
    UTM34: 'UTM 34N',
    UTM35: 'UTM 35N',
    UTM36: 'UTM 36N',
    LAT_LNG: 'Lat Long',
};

export const epsgToUtm = {
    'EPSG:32631': utm.UTM31,
    'EPSG:32632': utm.UTM32,
    'EPSG:32633': utm.UTM33,
    'EPSG:32634': utm.UTM34,
    'EPSG:32635': utm.UTM35,
    'EPSG:32636': utm.UTM36,
};

export const epsgToText = {
    'EPSG:5105': utmOutput.NTMZONE5,
    'EPSG:5106': utmOutput.NTMZONE6,
    'EPSG:5107': utmOutput.NTMZONE7,
    'EPSG:5108': utmOutput.NTMZONE8,
    'EPSG:5109': utmOutput.NTMZONE9,
    'EPSG:5110': utmOutput.NTMZONE10,
    'EPSG:5111': utmOutput.NTMZONE11,
    'EPSG:5112': utmOutput.NTMZONE12,
    'EPSG:5113': utmOutput.NTMZONE13,
    'EPSG:5114': utmOutput.NTMZONE14,
    'EPSG:5115': utmOutput.NTMZONE15,
    'EPSG:5116': utmOutput.NTMZONE16,
    'EPSG:5117': utmOutput.NTMZONE17,
    'EPSG:5118': utmOutput.NTMZONE18,
    'EPSG:5119': utmOutput.NTMZONE19,
    'EPSG:5120': utmOutput.NTMZONE20,
    'EPSG:5121': utmOutput.NTMZONE21,
    'EPSG:5122': utmOutput.NTMZONE22,
    'EPSG:5123': utmOutput.NTMZONE23,
    'EPSG:5124': utmOutput.NTMZONE24,
    'EPSG:5125': utmOutput.NTMZONE25,
    'EPSG:5126': utmOutput.NTMZONE26,
    'EPSG:5127': utmOutput.NTMZONE27,
    'EPSG:5128': utmOutput.NTMZONE28,
    'EPSG:5129': utmOutput.NTMZONE29,
    'EPSG:5130': utmOutput.NTMZONE30,
    'EPSG:32631': utmOutput.UTM31,
    'EPSG:32632': utmOutput.UTM32,
    'EPSG:32633': utmOutput.UTM33,
    'EPSG:32634': utmOutput.UTM34,
    'EPSG:32635': utmOutput.UTM35,
    'EPSG:32636': utmOutput.UTM36,
    'EPSG:4326': utmOutput.LAT_LNG,
};

export const srs = {
    LAT_LNG: 4326,
    UTM31: 32631,
    UTM32: 32632,
    UTM33: 32633,
    UTM34: 32634,
    UTM35: 32635,
    UTM36: 32636,
};

export const srsToEpsg = {
    4326: epsg.LAT_LNG,
    32631: epsg.UTM31,
    32632: epsg.UTM32,
    32633: epsg.UTM33,
    32634: epsg.UTM34,
    32635: epsg.UTM35,
    32636: epsg.UTM36,
};

export const SosiToUtm = {
    21: epsg.UTM31,
    22: epsg.UTM32,
    23: epsg.UTM33,
    24: epsg.UTM34,
    25: epsg.UTM35,
    26: epsg.UTM36,
};

export const sosiToEpsg = {
    21: 'EPSG:32631',
    22: 'EPSG:32632',
    23: 'EPSG:32633',
    24: 'EPSG:32634',
    25: 'EPSG:32635',
    26: 'EPSG:32636',
    27: 'EPSG:25831',
    28: 'EPSG:25832',
    29: 'EPSG:25833',
    30: 'EPSG:25834',
    31: 'EPSG:25835',
    32: 'EPSG:25836',
    205: 'EPSG:5105',
    206: 'EPSG:5106',
    207: 'EPSG:5107',
    208: 'EPSG:5108',
    209: 'EPSG:5109',
    210: 'EPSG:5110',
    211: 'EPSG:5111',
    212: 'EPSG:5112',
    213: 'EPSG:5113',
    214: 'EPSG:5114',
    215: 'EPSG:5115',
    216: 'EPSG:5116',
    217: 'EPSG:5117',
    218: 'EPSG:5118',
    219: 'EPSG:5119',
    220: 'EPSG:5120',
    221: 'EPSG:5121',
    222: 'EPSG:5122',
    223: 'EPSG:5123',
    224: 'EPSG:5124',
    225: 'EPSG:5125',
    226: 'EPSG:5126',
    227: 'EPSG:5127',
    228: 'EPSG:5128',
    229: 'EPSG:5129',
    230: 'EPSG:5130',
    231: 'EPSG:4326',
    232: 'EPSG:54004',
    233: 'EPSG:3857',
    234: 'EPSG:900913',
    235: 'EPSG:3395',
};

export const countyToUtm = {
    '01': utm.UTM32,
    '02': utm.UTM32,
    '03': utm.UTM32,
    '04': utm.UTM32,
    '05': utm.UTM32,
    '06': utm.UTM32,
    '07': utm.UTM32,
    '08': utm.UTM32,
    '09': utm.UTM32,
    10: utm.UTM32,
    11: utm.UTM32,
    12: utm.UTM32,
    14: utm.UTM32,
    15: utm.UTM32,
    16: utm.UTM32,
    17: utm.UTM32,
    50: utm.UTM32, // Nye Troendelag
    18: utm.UTM33, // Nordland
    19: utm.UTM33, // Troms
    20: utm.UTM35, // Finnmark
};
