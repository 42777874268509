import {action} from 'typesafe-actions';
import {SessionActionTypes} from './types';

export const setActivePlanTab = (activeTab: string | undefined) =>
    action(SessionActionTypes.SET_ACTIVE_PLAN_TAB, activeTab);
export const setActiveSakId = (activeSakId: number | undefined) =>
    action(SessionActionTypes.SET_ACTIVE_SAK_ID, activeSakId);
export const setUseAltFrontpage = (useAltFrontpage: boolean) =>
    action(SessionActionTypes.USE_ALTERNATIVE_FRONTPAGE, useAltFrontpage);

export const setExpandDispensasjonId = (id: string | undefined) =>
    action(SessionActionTypes.SET_EXPAND_DISPENSASJON_ID, id);

export const setExpandBehandlingId = (id: string | undefined) =>
    action(SessionActionTypes.SET_EXPAND_BEHANDLING_ID, id);
