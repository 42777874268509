import {DialogActions as MuiDialogActions, DialogActionsProps as MuiDialogActionsProps, styled} from '@mui/material';
import {forwardRef, PropsWithChildren} from 'react';

type ToiDialogActionsProps = MuiDialogActionsProps;

const StyledDialogActions = styled(MuiDialogActions)(({theme}) => {
  return {
    justifyContent: 'flex-start',
    paddingRight: '32px',
    paddingLeft: '32px',
    paddingTop: '24px',
    paddingBottom: '24px',
  };
});

export const ToiDialogActions = forwardRef<HTMLDivElement, PropsWithChildren<ToiDialogActionsProps>>((props, ref) => {
  return <StyledDialogActions ref={ref} {...props} />;
});
