import * as React from 'react';
import {useQuery} from 'react-query';
import {format} from 'date-fns';
import {useFetchTokenHeaderWithAuthData} from '@norkart/nk-auth';
import {graphQLRequest} from '../../services/api';
import {DispensasjonerQueryVariables} from '../../features/reports/Reports/Dispensasjoner/Dispensasjoner';
import {ReplaceLinebreaks} from '../../features/reports/Reports/ReportTools/ReplaceLinebreaks';
import {DispensasjonerCsv, DispensasjonerReport} from './types';
import {dispensasjonerReportCsvQuery} from './dispensasjonerQueries';

export function useDispensasjonerQLCsv(
    variables: DispensasjonerQueryVariables
) {
    const headings = [
        'Beskrivelse',
        'DispensasjonId',
        'DispensasjonstypeId',
        'DispensasjonType',
        'DispFra',
        'Vedtaksdato',
        'Vedtak',
        'VedtakId',
        'PlanId',
        'PlanNavn',
        'Plantype',
        'PlantypeId',
        'Planstatus',
        'PlanstatusId',
        'Vertikalniva',
        'VertikalnivaId',
        'XKoord',
        'YKoord',
        'Sak',
    ];
    const getHeaders = useFetchTokenHeaderWithAuthData();

    const reactQuery = useQuery<any>(
        ['dispensasjonerReportCsv'],
        async () => {
            const headers = getHeaders();
            return await graphQLRequest(
                dispensasjonerReportCsvQuery,
                variables
            );
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
            cacheTime: 0,
            keepPreviousData: false,
        }
    );

    const csvArray = React.useMemo(() => {
        if (reactQuery.data) {
            const dispensasjoner: DispensasjonerReport[] =
                reactQuery.data.dispensasjoner;
            const dispensasjonerCsv: DispensasjonerCsv[] = dispensasjoner.map(
                (d) => {
                    const value: DispensasjonerCsv = {
                        Beskrivelse: d.beskrivelse
                            ? ReplaceLinebreaks(d.beskrivelse)
                            : '',
                        DispensasjonId: d.id,
                        DispensasjonstypeId: d.dispensasjonTypeId
                            ? d.dispensasjonTypeId
                            : undefined,
                        DispensasjonType: d.dispensasjonType
                            ? d.dispensasjonType
                            : '',
                        DispFra: d.dispFra,
                        Vedtaksdato: d.vedtaksdato
                            ? format(new Date(d.vedtaksdato), 'dd.MM.yyyy')
                            : '',
                        Vedtak: d.vedtak ? d.vedtak : '',
                        VedtakId: d.vedtakId ? d.vedtakId : undefined,
                        PlanId: d.arealplan ? d.arealplan?.planId : '',
                        PlanNavn: d.arealplan ? d.arealplan?.planNavn : '',
                        Plantype: d.arealplan ? d.arealplan?.planType : '',
                        PlantypeId: d.arealplan
                            ? d.arealplan?.planTypeId
                            : undefined,
                        Planstatus: d.arealplan ? d.arealplan?.planStatus : '',
                        PlanstatusId: d.arealplan
                            ? d.arealplan?.planStatusId
                            : undefined,
                        Vertikalniva: d.vertikalnivaType
                            ? d.vertikalnivaType
                            : '',
                        VertikalnivaId: d.vertikalnivaId
                            ? d.vertikalnivaId
                            : undefined,
                        XKoord: d.posisjon?.x,
                        YKoord: d.posisjon?.y,
                        Sak: d.sak ? `${d.sak?.sakAar}/${d.sak?.sakSeknr}` : '',
                    };
                    return value;
                }
            );
            const newLines = dispensasjonerCsv.reduce((acc: any, d) => {
                acc.push(
                    [
                        d.Beskrivelse,
                        d.DispensasjonId,
                        d.DispensasjonstypeId,
                        d.DispensasjonType,
                        d.DispFra,
                        d.Vedtaksdato,
                        d.Vedtak,
                        d.VedtakId,
                        d.PlanId,
                        d.PlanNavn,
                        d.Plantype,
                        d.PlantypeId,
                        d.Planstatus,
                        d.PlanstatusId,
                        d.Vertikalniva,
                        d.VertikalnivaId,
                        d.XKoord,
                        d.YKoord,
                        d.Sak,
                    ].join(';')
                );
                return acc;
            }, []);

            return newLines;
        }
    }, [reactQuery.data]);

    return {...reactQuery, csvData: csvArray, headers: headings};
}
