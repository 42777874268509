import {ConversationType} from '@norkart/nora-components';

export type PlanpratEgenskap = {
    navn?: string;
    verdi?: string;
};

export type PlanpratEgenskaper = {
    selected: boolean;
    egenskaper: PlanpratEgenskap[];
};

export type PlanpratMetadata = {
    planId: string;
    planNavn: string;
    planType: string;
    selected: boolean;
    egenskaperFraDelareal: PlanpratEgenskaper[];
};

export type PlanpratPlan = {
    plan_id: string;
    metadata: string;
    knr: string;
    plantype: string;
};

type Chunk = {
    id: string;
    content: string;
    is_used: boolean;
};

type ContentFrom = {
    plan_id: string;
    chunks: Chunk[];
};

export type PlanpratResponseObject = {
    answer: string;
    content_from?: ContentFrom;
};

export const planprat: ConversationType = {
    id: 'planprat',
    searchIndices: [],
    name: 'Planprat',
    description: 'Planprat - Søker i plandokumenter',
    sampleQuestions: [
        'Hva er oppgitt utnyttelsesgrad?',
        'Er det krav om utomhusplan?',
        'Hvor høyt kan jeg bygge på tomten min?',
        'Hva er gjeldende parkeringskrav?',
    ],
    loadingText: 'Planroboten søker i plandokumentene, vent litt...',
    placeholder: 'Spør planroboten!',
    logo: '',
};
