import {orderBy} from 'lodash-es';
import {useQuery} from 'react-query';
import {useNkAuth} from '@norkart/nk-auth';
import {fetchArealplanDokumenter} from '../../services/api';
import {ArealplanId} from '../arealplaner/types';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Dokument} from './types';

const getDokumenter = async (
    arealplanId: ArealplanId,
    isAuthorized: boolean
) => {
    const {data} = await fetchArealplanDokumenter(arealplanId, isAuthorized);
    return data;
};
export default function useArealplanDokumenter() {
    const kundeId = useSelectedKundeId();
    const arealplanId = useSelectedArealplanId();
    const {isAuthorized} = useNkAuth();

    const queryKey = [
        'dokumenter',
        {kundeId, arealplanId, isAuthorized},
    ] as any;
    const query = useQuery<Dokument[]>(
        queryKey,
        () => getDokumenter(arealplanId!, isAuthorized),
        {
            enabled: !!arealplanId,
        }
    );

    const data =
        orderBy(query?.data, ['rekkefolge', 'dokumentType'], ['asc', 'asc']) ||
        [];

    const count = data.length;

    const gjeldendeBestemmelser = data.filter(
        (d) => d.visIGjeldendeBestemmelser
    );
    const plandokumenter = data.filter(
        (d) => !(d.behandling || d.dispensasjon || d.planforhold)
    );
    const andreDokumenter = data.filter((d) => !d.visIGjeldendeBestemmelser);

    return {
        ...query,
        data,
        count,
        gjeldendeBestemmelser,
        plandokumenter,
        andreDokumenter,
    };
}
