import arealplanerConfig from '../config';
import {getApiKeyHeader} from './norkartapicore';
import {PlanerService} from './plananalyse';

const token = arealplanerConfig.planregister.token;

const getConfig = () => {
    return {
        HEADERS: getApiKeyHeader(token),
    };
};

export const getPlanerService = () => new PlanerService(getConfig());
