import {Box, IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import React, {useState} from 'react';
import MenuIcon from '@mui/icons-material/MoreHoriz';
import {Behandling} from '../../../hooks/behandlinger/types';
import useMeta from '../../../hooks/meta/useMeta';
import {useBehandlingActions} from './useBehandlingActions';

type Props = {
    behandling: Behandling;
    onOpen: (behandling: Behandling) => void;
};

export const BehandlingTableRowMenuButton = ({behandling, onOpen}: Props) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const actions = useBehandlingActions();
    const meta = useMeta();

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
        onOpen(behandling);
        e.stopPropagation();
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleEdit = (e) => {
        handleClose(e);
        actions.edit(behandling);
    };

    const handleDocumentFromCatalog = (e) => {
        handleClose(e);
        document.getElementById(`file-upload`)!.click();
    };

    const handleDocumentFromArchive = (e) => {
        handleClose(e);
        actions.importFilesFromArchive(behandling);
    };

    const handleRearrangeDocuments = (e) => {
        handleClose(e);
        actions.rearrangeDocuments(behandling);
    };

    const shouldDisplayDocumentFromArchive =
        Boolean(behandling.sak) && meta.hasArkivIntegrasjon();

    const shouldDisplayRearrangeDocuments = behandling.dokumenter?.length > 1;

    return (
        <Box display='flex' justifyContent='flex-end'>
            <Tooltip title={'Handlinger'}>
                <IconButton
                    onClick={(e) => {
                        handleMenu(e);
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Tooltip>

            <Menu
                id={`${behandling.id}-behandling-menu`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock={true}
            >
                <MenuItem onClick={handleEdit} aria-label='Endre behandling'>
                    Endre behandling
                </MenuItem>
                <MenuItem
                    onClick={handleDocumentFromCatalog}
                    aria-label='Last opp dokument'
                >
                    Last opp dokument
                </MenuItem>
                {shouldDisplayDocumentFromArchive && (
                    <MenuItem
                        onClick={handleDocumentFromArchive}
                        aria-label='Last opp dokument fra arkiv'
                    >
                        Last opp dokument fra arkiv
                    </MenuItem>
                )}
                {shouldDisplayRearrangeDocuments && (
                    <MenuItem
                        onClick={handleRearrangeDocuments}
                        aria-label='Endre dokumentrekkefølge'
                    >
                        Endre dokumentrekkefølge
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};
