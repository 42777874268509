import {Theme, PopperPlacementType} from '@mui/material';
import {merge} from 'lodash-es';

enum TRANSFORM {
  RIGHT = 'rotate(225deg) scale(1.5, 1.5) !important',
  LEFT = 'rotate(135deg) scale(1.5, 1.5) !important',
}
const TOOLTIP_OFFSETT = '20px !important';

export const toiPopperStyle = (theme: Theme, variant?: string, placement?: PopperPlacementType) => {
  const palette = theme.palette;
  const shadow = '#262E2D30';

  const defaultStyle = {};

  const toolTipStyle = (variant?: string) => {
    if (variant === 'toggletip') {
      return {
        '& .MuiTooltip-tooltip': {
          backgroundColor: palette.common.white,
          color: palette.common.black,
          padding: '16px',
          boxShadow: '0px 1px 6px' + shadow + ', 0px 1px 2px ' + shadow,
        },
        '& .MuiTooltip-arrow': {
          '&::before': {
            backgroundColor: palette.common.white,
            boxShadow: '0px 1px 2px' + shadow + ', 0px 1px 2px ' + shadow,
          },
        },
      };
    }
    return {
      '& .MuiTooltip-tooltip': {
        backgroundColor: palette.black[70],
        color: palette.common.white,
        boxShadow: '0px 1px 6px' + shadow + ', 0px 1px 2px ' + shadow,
      },
      '& .MuiTooltip-arrow': {
        '&::before': {
          position: 'absolute !important',
          backgroundColor: palette.black[70],
          boxShadow: '0px 1px 6px' + shadow + ', 0px 1px 2px ' + shadow,
        },
      },
    };
  };

  const positionStyle = (variant?: string, placement?: PopperPlacementType) => {
    if (variant !== 'toggletip') {
      if (placement === 'left') {
        return leftToolTip;
      } else if (placement === 'right') {
        return rightToolTip;
      } else if (placement === 'top-start') {
        return topStartToolTip;
      } else if (placement === 'top') {
        return topToolTip;
      } else if (placement === 'bottom-start') {
        return bottomStartToolTip;
      } else if (placement === 'bottom') {
        return bottomToolTip;
      }
    }

    return {};
  };

  return merge({}, defaultStyle, toolTipStyle(variant), positionStyle(variant, placement));
};

const leftToolTip = {
  '& .MuiTooltip-arrow': {
    right: '4px !important',
    top: '-3.515px !important',
    transform: TRANSFORM.RIGHT,
  },
};

const rightToolTip = {
  '& .MuiTooltip-arrow': {
    left: '4px !important',
    top: '-4px !important',
    transform: TRANSFORM.LEFT,
  },
};

const topStartToolTip = {
  '& .MuiTooltip-arrow': {
    left: '35.415px !important',
    top: '20px !important',
    transform: TRANSFORM.RIGHT,
  },
  '& .MuiTooltip-tooltip': {
    right: TOOLTIP_OFFSETT,
  },
};

const topToolTip = {
  '& .MuiTooltip-arrow': {
    left: '-4px !important',
    top: '20px !important',
    transform: TRANSFORM.LEFT,
  },
  '& .MuiTooltip-tooltip': {
    left: TOOLTIP_OFFSETT,
  },
};

const bottomStartToolTip = {
  '& .MuiTooltip-arrow': {
    left: '36px !important',
    top: '8px !important',
    transform: TRANSFORM.LEFT,
  },
  '& .MuiTooltip-tooltip': {
    right: TOOLTIP_OFFSETT,
  },
};

const bottomToolTip = {
  '& .MuiTooltip-arrow': {
    position: 'absolute !important',
    left: '-4px !important',
    top: '8px !important',
    transform: TRANSFORM.RIGHT,
  },
  '& .MuiTooltip-tooltip': {
    left: TOOLTIP_OFFSETT,
  },
};
