import union from '@turf/union';
import {Feature, MultiPolygon, Polygon} from 'geojson';
import {featureCollection} from '@turf/helpers';
import {formatToMapboxLayer} from './getMapboxLayer';
import {getPolygonsFromLayer} from './layerHelpers';

export function getBorderLayers(vertnivLayers: {
    combined: Feature<MultiPolygon | Polygon>;
    vertniv1: Feature<MultiPolygon | Polygon> | undefined;
    vertniv2: Feature<MultiPolygon | Polygon> | undefined;
    vertniv3: Feature<MultiPolygon | Polygon> | undefined;
}) {
    const {vertniv1, vertniv2, vertniv3} = vertnivLayers;

    return {
        combined: createBorderLayer(
            [vertnivLayers.combined],
            'borderCombined',
            true
        ),
        vertniv1: vertniv1 && createBorderLayer([vertniv1], 'borderVertniv1'),
        vertniv2: vertniv2 && createBorderLayer([vertniv2], 'borderVertniv2'),
        vertniv3: vertniv3 && createBorderLayer([vertniv3], 'borderVertniv3'),
        vertniv1and2:
            vertniv1 &&
            vertniv2 &&
            createBorderLayer([vertniv1, vertniv2], 'borderVertniv12'),
        vertniv1and3:
            vertniv1 &&
            vertniv3 &&
            createBorderLayer([vertniv1, vertniv3], 'borderVertniv13'),
        vertniv2and3:
            vertniv2 &&
            vertniv3 &&
            createBorderLayer([vertniv2, vertniv3], 'borderVertniv23'),
        vertniv1and2and3:
            vertniv1 &&
            vertniv2 &&
            vertniv3 &&
            createBorderLayer(
                [vertniv1, vertniv2, vertniv3],
                'borderVertniv123'
            ),
    };
}

export const createBorderLayer = (
    planLayers: Feature<MultiPolygon | Polygon>[],
    layerId?: string,
    dashed = false
) => {
    const unioned = getUnionLayer(planLayers);
    if (unioned) {
        return formatToMapboxLayer(
            unioned.geometry,
            layerId ? layerId : 'planBorder',
            dashed
                ? {
                      'line-color': '#e81313',
                      'line-width': 4,
                      'line-dasharray': [4, 1],
                  }
                : {
                      'line-color': '#e81313',
                      'line-width': 4,
                  },
            undefined,
            'line'
        );
    } else {
        throw new Error('creation of diffLayer failed');
    }
};

export const getUnionLayer = (layers: Feature<Polygon | MultiPolygon>[]) => {
    let polygonLayers: Feature<Polygon>[] = [];

    layers.forEach((layer) => {
        const features = getPolygonsFromLayer(layer);
        polygonLayers = polygonLayers.concat(features);
    });

    if (polygonLayers.length == 1) return polygonLayers[0];
    else return union(featureCollection(polygonLayers));
};
