import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getResult} from '../../../../../nkmNorkartSearch/utils/resultFunctions';
import {propertyHasGeometry} from '../../../../../services/matrikkelkart';
import {getSearchState} from '../../../../../store';
import {
    setPropertyIsMissing,
    setPropertySearchIsAdressSearch,
    setPropertySearchText,
} from '../../../../../store/search/actions';
import {
    addressSource,
    matrikkelSource,
    NkmSearchProps,
    NkmSearchResponse,
} from '../../SearchResponse';
import {PropertySearchInput} from './PropertySearchInput';
import {FormattedMnr, usePropertySearch} from './usePropertySearch';

export const PropertySearchFilter = () => {
    const propertySearch = usePropertySearch();
    const dispatch = useDispatch();
    const searchState = useSelector(getSearchState);

    const onPropertySearch = (event) => {
        const res: NkmSearchResponse = getResult(event);
        let searchedMnr: FormattedMnr | undefined = undefined;
        if (isMatrikkelsearch(res.properties.Source, res.properties)) {
            searchedMnr = {
                knr: JSON.stringify(res.properties.Source.KommuneNummer),
                bnr: res.properties.Source.BruksNummer,
                gnr: res.properties.Source.GardsNummer,
                fnr: res.properties.Source.FesteNummer,
                snr: res.properties.Source.SeksjonsNummer,
            };
        } else {
            searchedMnr = propertySearch.toFormatedMnr(
                res.properties.Source.AdresseMatrikkelenhetId
            );
        }
        if (!searchedMnr) return;
        dispatch(setPropertySearchText(res.properties.Text));
        dispatch(
            setPropertySearchIsAdressSearch(
                res.properties.Type === 'gateadresse'
            )
        );

        propertySearch.set(searchedMnr);

        checkIfGeometryIsMissing(
            isMatrikkelsearch(res.properties.Source, res.properties)
                ? res.properties.Id
                : res.properties.Source.AdresseMatrikkelenhetId
        );
    };

    const onClearPropertyResult = () => {
        propertySearch.clear();
    };

    const setPropertyGeometryIsMissing = (isMissing?: boolean) => {
        dispatch(setPropertyIsMissing(isMissing));
    };

    const checkIfGeometryIsMissing = (propertyId: string) => {
        setPropertyGeometryIsMissing(undefined);

        propertyHasGeometry(propertyId).then((hasGeometry) =>
            setPropertyGeometryIsMissing(!hasGeometry)
        );
    };

    //Type guard
    const isMatrikkelsearch = (
        src: matrikkelSource | addressSource,
        properties: NkmSearchProps
    ): src is matrikkelSource => properties.Type === 'matrikkelenhet';

    return (
        <PropertySearchInput
            initialValue={searchState.propertySearchText}
            onPropertySearch={onPropertySearch}
            onClearPropertyResult={onClearPropertyResult}
        />
    );
};
