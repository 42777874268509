export const formatDate = (date?: Date) => {
    try {
        return date ? formatDateToJSdate(date) : '';
    } catch (e) {
        return date ? date.toISOString() : '';
    }
};
const formatDateToJSdate = (date: Date) => {
    const d = new Date(date);
    const day = ('0' + d.getDate()).slice(-2);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();

    return `${day}.${month}.${year}`;
};
