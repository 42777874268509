import {useQuery} from 'react-query';
import {fetchClients} from '../Requests/ClientRequests';
import clientType from '../Types/ClientType';

export function useClients() {
  const clients = useQuery<clientType[], Error>('clients', fetchClients);

  // Ensure no undefined data
  return {clients};
}
