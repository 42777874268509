//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const MapRoadHorizontalIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path fillRule='evenodd' clipRule='evenodd' d='M0 4V6H24V4H0ZM0 18V20H24V18H0Z' style={props.secondaryStyle} />
            <path fillRule='evenodd' clipRule='evenodd' d='M2 11V13H6V11H2ZM8 13V11H12V13H8ZM14 11V13H18V11H14ZM20 11V13H24V11H20Z' style={props.primaryStyle} />
        </g>
    ),
    'MapRoadHorizontalIcon' // aria-label
);
