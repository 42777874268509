import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {Grid, SnackbarContent} from '@mui/material';
import {amber, green} from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import {useEffect, useState} from 'react';

export interface OpenSnackbarMessageProps {
    message: string | React.ReactElement;
    variant: 'error' | 'warning' | 'info' | 'success';
    verticalAnchor?: 'top' | 'bottom';
    autoHideDuration?: number;
}

export interface SnackbarMessageProps {}
let openSnackbarFn;

const typeIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const MySnackbarContent = React.forwardRef(function MySnackbarContent(
    props: any,
    ref
) {
    const {className, message, onClose, variant, ...other} = props;
    const Icon = typeIcon[variant];
    return (
        <SnackbarContent
            ref={ref}
            sx={(theme) => ({
                ...(variant === 'success' && {backgroundColor: green[600]}),
                ...(variant === 'error' && {
                    backgroundColor: theme.palette.error.dark,
                }),
                ...(variant === 'info' && {backgroundColor: '#1976D2'}),
                ...(variant === 'warning' && {backgroundColor: amber[700]}),
                flexWrap: 'nowrap',
                display: 'flex',
                '&.MuiSnackbarContent-message': {
                    display: 'flex',
                    alignItems: 'stretch',
                },
                '&.MuiSnackbarContent-action': {
                    display: 'flex',
                    alignSelf: 'flex-start',
                },
            })}
            className={className}
            aria-describedby='client-snackbar'
            message={
                <Grid container={true} justifyContent='space-between'>
                    <Grid item={true}>
                        <span id='client-snackbar'>
                            <Icon
                                sx={{
                                    opacity: 0.9,
                                    marginRight: '10px',
                                }}
                            />
                        </span>
                    </Grid>
                    <Grid item={true}>
                        <span>{message}</span>
                    </Grid>
                </Grid>
            }
            action={[
                <IconButton
                    key='close'
                    aria-label='Close'
                    color='inherit'
                    onClick={onClose}
                >
                    <CloseIcon fontSize='small' />
                </IconButton>,
            ]}
            {...other}
        />
    );
});

function SnackbarMessage(props: SnackbarMessageProps) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('error');
    const [verticalAnchor, setVerticalAnchor] = useState<'top' | 'bottom'>(
        'top'
    );
    const [autoHideDuration, setAutoHideDuration] = useState<number | null>(
        null
    );

    useEffect(() => {
        openSnackbarFn = handleOpen;
    }, []);

    const handleOpen = ({
        message,
        variant,
        verticalAnchor,
        autoHideDuration,
    }) => {
        setOpen(true);
        setMessage(message);
        setVariant(variant);
        setVerticalAnchor(verticalAnchor);
        setAutoHideDuration(autoHideDuration);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: verticalAnchor,
                    horizontal: 'center',
                }}
                style={{marginTop: '64px'}}
                open={open}
                onClose={handleClose}
                autoHideDuration={autoHideDuration}
                ClickAwayListenerProps={{onClickAway: () => {}}}
            >
                <MySnackbarContent
                    onClose={handleClose}
                    variant={variant}
                    message={message}
                />
            </Snackbar>
        </div>
    );
}

export function openSnackbarMessage({
    message,
    variant,
    verticalAnchor,
    autoHideDuration,
}: OpenSnackbarMessageProps) {
    if (!openSnackbarFn) {
        return null;
    }
    if (verticalAnchor) {
        openSnackbarFn({message, variant, verticalAnchor, autoHideDuration});
    } else {
        openSnackbarFn({
            message,
            variant,
            verticalAnchor: 'top',
            autoHideDuration,
        });
    }
}
export default SnackbarMessage;
