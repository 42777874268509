import {styled} from '@mui/material';
import {forwardRef} from 'react';
import React from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {ChevronDownIcon} from '@norkart/toi-deprecated-icons';
import {ToiMenu} from '../ToiMenu/ToiMenu';
import {ToiMenuItem} from '../ToiMenuItem/ToiMenuItem';
import {ToiButton} from '../ToiButton/ToiButton';
import ToiTypography from '../ToiTypography/ToiTypography';
import ToiBox from '../ToiBox/ToiBox';
import {toiAppBarTextButtonStyle} from './toiAppBarTextButtonStyle';

export type ToiAppBarTextButtonProps = {
  title: string;
  altTitle?: string;
  type?: 'dropdown' | 'breadcrumb' | 'municipality' | 'municipalityDropdown' | 'icon';
  action?: (...args: any[]) => void;
  icon?: any; //Type på denne?
  crest?: string;
  crestAlt?: string;
  dropdownItems?: {text: string; crest?: string; altText?: string; action: (...args: any[]) => void}[];
};

const StyledComponent = styled('div')(toiAppBarTextButtonStyle, {
  display: 'flex',
  flexDirection: 'row',
  ':hover': {cursor: 'pointer'},
  alignItems: 'center',
  padding: '7px',
});

export const ToiAppBarTextButton = forwardRef<HTMLDivElement, ToiAppBarTextButtonProps>((props, forwardedRef) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (props.type === 'dropdown') {
    return (
      <StyledComponent ref={forwardedRef}>
        <ToiButton
          variant='text'
          color='secondary'
          onClick={handleClick}
          sx={{fontWeight: 'light', fontSize: '16px', gap: '10px'}}
        >
          {props.title}
          <ChevronDownIcon />
        </ToiButton>
        <ToiMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
          {props.dropdownItems?.map((el, idx) => {
            return (
              <ToiMenuItem onClick={el.action} key={idx}>
                {el.text}
              </ToiMenuItem>
            );
          })}
        </ToiMenu>
      </StyledComponent>
    );
  }
  if (props.type === 'breadcrumb') {
    //TODO: Implement this
    return (
      <StyledComponent ref={forwardedRef}>
        <ToiButton variant='text' color='secondary' onClick={props.action} sx={{fontWeight: 'light', fontSize: '16px'}}>
          {props.title}
        </ToiButton>
      </StyledComponent>
    );
  }
  if (props.type === 'municipality') {
    return (
      <StyledComponent ref={forwardedRef}>
        <ToiButton
          variant='text'
          color='secondary'
          sx={{
            fontWeight: 'light',
            fontSize: '16px',
            gap: '10px',
            ':hover': {backgroundColor: 'white', cursor: 'default'},
          }}
        >
          <img src={props.crest} alt={props.crestAlt} style={{height: '28px'}} />
          {props.altTitle ? (
            <ToiBox sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
              <ToiTypography variant='body2' light sx={{lineHeight: 1, alignSelf: 'flex-start'}}>
                {props.title}
              </ToiTypography>
              <ToiTypography variant='body2' light sx={{lineHeight: 1}}>
                {props.altTitle}
              </ToiTypography>
            </ToiBox>
          ) : (
            props.title
          )}
        </ToiButton>
      </StyledComponent>
    );
  }
  if (props.type === 'municipalityDropdown') {
    return (
      <StyledComponent ref={forwardedRef}>
        <ToiButton
          variant='text'
          color='secondary'
          onClick={handleClick}
          sx={{
            fontWeight: 'light',
            fontSize: '16px',
            gap: '10px',
          }}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <img src={props.crest} alt={props.crestAlt} style={{height: '28px'}} />
          {props.title}
          <ChevronDownIcon />
        </ToiButton>
        <ToiMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
          {props.dropdownItems?.map((el, idx) => {
            return (
              <ToiMenuItem onClick={(el.action, handleClose)} key={idx}>
                <img src={el.crest} alt={el.altText} style={{height: '28px', paddingRight: '10px'}} />
                {el.text}
              </ToiMenuItem>
            );
          })}
        </ToiMenu>
      </StyledComponent>
    );
  }
  if (props.type === 'icon') {
    //TODO: Implement this
    return (
      <StyledComponent ref={forwardedRef}>
        <ToiButton
          variant='text'
          color='secondary'
          onClick={props.action}
          sx={{fontWeight: 'light', fontSize: '16px', gap: '5px'}}
        >
          {props.icon}
          {props.title}
        </ToiButton>
      </StyledComponent>
    );
  }
  return (
    <StyledComponent ref={forwardedRef}>
      <ToiButton variant='text' color='secondary' onClick={props.action} sx={{fontWeight: 'light', fontSize: '16px'}}>
        {props.title}
      </ToiButton>
    </StyledComponent>
  );
});

export default ToiAppBarTextButton;
