import qs from 'query-string';
import {useParams} from 'react-router-dom';
import {openSnackbarMessage} from '../components/SnackbarMessage';
import VisPlan from './VisPlan';

// (fra dok: http://www.system.no/side.html?funksjon=VisPlan&kommunenummer=0123&planidentifikasjon=R0118AP )

export function GI() {
    const {
        funksjon,
        kommunenummer,
        planidentifikasjon,
        saksaar,
        sakssekvensnummer,
    } = qs.parse(window.location.search);
    const {customerId} = useParams();

    if (!funksjon)
        openSnackbarMessage({
            message: 'Ingen funksjon angitt',
            variant: 'error',
        });

    switch (funksjon) {
        case 'VisPlan':
            if (!planidentifikasjon) {
                openSnackbarMessage({
                    message: 'Ingen planidentifikasjon angitt',
                    variant: 'error',
                });
                break;
            }

            if (!kommunenummer) {
                openSnackbarMessage({
                    message: 'Ingen kommunenummer angitt',
                    variant: 'error',
                });
                break;
            }
            return (
                <VisPlan
                    planId={planidentifikasjon.toString()}
                    kommunenummer={kommunenummer.toString()}
                    customerId={customerId}
                />
            );
        default:
            return null;
    }
    return <>hei</>;
}

export default GI;
