export const planstatusLovreferanseQuery = `query GetPlanstatusLovreferanse  {
    planstatusLovreferanseReport {
        gjeldende 
        underArbeid 
        ubestemt 
        utgatt 
        lovreferanse {
            id
            beskrivelse
        }

    }
}
`;

export const planstatusPlantypeQuery = `query GetPlanstatusPlantype {
    planstatusPlantypeReport {
        gjeldende 
        underArbeid 
        ubestemt 
        utgatt 
        plantype {
            id
            beskrivelse
        }
    }
}
`;

export const arealplanerReportCsvQuery = `query GetArealplaner($filter: ArealplanDtoFilterInput, $sort: [ArealplanDtoSortInput!])  {
    arealplaner (where: $filter, order: $sort) {
        id
        planId
        planNavn
        lovreferanse
        lovreferanseId
        planStatus
        planStatusId
        planTypeId
        iKraft 
    }
}
`;

export const saksbehandlingstidQuery = `
query GetSaksbehandlingstid ($inputParams: SaksbehandlingstidQueryDtoInput, $filter: SaksbehandlingstidQueryResultDtoFilterInput) {
  saksbehandlingstidReport(saksbehandlingstid: $inputParams, where:$filter) {
      planId
      behandlingFra {
          behandlingstype 
          behandlingstypeId
      }
      behandlingTil {
          behandlingstype
          behandlingstypeId
      }
      fraDato
      tilDato
      antallDager
      plantype {
        id, 
        beskrivelse
    }
  }
}
`;
