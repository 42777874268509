import {useQuery} from 'react-query';
import {useFetchTokenHeaderWithAuthData} from '@norkart/nk-auth';
import {GraphQLUnpagedResponse} from '../../graphql/types';
import {graphQLRequest} from '../../services/api';

export default function usePlanstatusPlantypeGraphQL(graphqlQuery: string) {
    const getHeaders = useFetchTokenHeaderWithAuthData();
    const query = useQuery<GraphQLUnpagedResponse>(
        ['planstatusPlantypeReportGraphQL'],
        async () => {
            const headers = await getHeaders();
            return await graphQLRequest(graphqlQuery, null, headers);
        },
        {
            cacheTime: 1000 * 60 * 5,
            refetchOnWindowFocus: false,
        }
    );

    return {
        ...query,
        tilstandPlantype: query.data?.planstatusPlantypeReport || [],
    };
}
