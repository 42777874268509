export const combineWithOr = function (regexes) {
    return new RegExp(
        '(' +
            regexes
                .map(function (regex) {
                    if (regex instanceof RegExp) {
                        regex = regex.toString();
                        regex = regex.substring(1, regex.length - 1);
                    }
                    return '(' + regex + ')';
                })
                .join('|') +
            ')'
    );
};

export const combine = function (regexList, strict) {
    regexList = regexList.map(function (regex) {
        const regexString = regex.toString();
        return regexString.substring(1, regexString.length - 1); // remove slash in front and back
    });

    let regexString = regexList.join('');
    if (strict) {
        regexString = '^' + regexString + '$';
    }

    return new RegExp(regexString);
};
