import {PopupRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';

const loginRequestDefault: PopupRequest = {
  scopes: [],
};

const loginRequestDefaultB2c: PopupRequest = {
  scopes: [],
};

const useLoginPopupWithLoginRequest = (loginRequest: PopupRequest) => {
  const msal = useMsal();

  return () => msal.instance.loginPopup(loginRequest);
};

export const useLoginPopupAzureAD = (loginRequest?: PopupRequest) =>
  useLoginPopupWithLoginRequest(loginRequest ?? loginRequestDefault);

export const useLoginPopupNorkartId = (loginRequest?: PopupRequest) =>
  useLoginPopupWithLoginRequest(loginRequest ?? loginRequestDefaultB2c);
