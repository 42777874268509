import {PaletteMode} from '@mui/material';
// LightMode
import LightModeSvg from '../../assets/logo2/norkart_logo.svg';
import LightModeBWSvg from '../../assets/logo2/norkart_logo_bw.svg';
// DarkMode
import DarkModeSvg from '../../assets/logo2/norkart_logo_darkmode.svg';
import DarkModeBWSvg from '../../assets/logo2/norkart_logo_darkmode_bw.svg';
// DarkMode - Small (icon)
import DarkModeSmSvg from '../../assets/logo2/norkart_logo_darkmode_sm.svg';
import DarkModeSmBWSvg from '../../assets/logo2/norkart_logo_darkmode_sm_bw.svg';
// LightMode - Small (icon)
import LightModeSmSvg from '../../assets/logo2/norkart_logo_sm.svg';
import LightModeSmBWSvg from '../../assets/logo2/norkart_logo_sm_bw.svg';

import {ToiLogoColor, ToiLogoVariant} from './ToiLogo';

const SVG = {
  light: {
    full: {
      flat: LightModeSvg,
      bw: LightModeBWSvg,
    },
    icon: {
      flat: LightModeSmSvg,
      bw: LightModeSmBWSvg,
    },
  },
  dark: {
    full: {
      flat: DarkModeSvg,
      bw: DarkModeBWSvg,
    },
    icon: {
      flat: DarkModeSmSvg,
      bw: DarkModeSmBWSvg,
    },
  },
};

export const getSvg = (color: ToiLogoColor, variant: ToiLogoVariant, mode: PaletteMode): string =>
  SVG[mode][variant][color];
