import React from 'react';
import {Grid} from '@mui/material';
import DesktopContainer from '../../../containers/DesktopContainer';
import MobileContainer from '../../../containers/MobileContainer';
import ExpansionPanel from '../../../components/ExpansionPanel';
import HelpText from '../../../components/HelpText/HelpText';
import {helpObject} from '../../../help/helpObject';
import HelpButton from '../../../components/HelpButton/HelpButton';
import PlanTimeline from './components/PlanTimeline';

function PlanProcess() {
    return (
        <>
            <DesktopContainer>
                <Grid
                    container={true}
                    direction={'column'}
                    style={{display: 'flex', marginBottom: 20}}
                >
                    <HelpText
                        style={{marginRight: 50}}
                        helpText={helpObject.Faner.Tidsakse.hovedtekst}
                    />
                    <HelpButton
                        helpText={helpObject.Faner.Tidsakse.MeromTidsakse}
                        type='MeromPlandokumenter'
                        buttonVariant='tekst'
                    />
                </Grid>
                <PlanTimeline />
            </DesktopContainer>
            <MobileContainer>
                <ExpansionPanel
                    title='Tidsakse'
                    helpButtonText={helpObject.Faner.Tidsakse.MeromTidsakse}
                >
                    <PlanTimeline />
                </ExpansionPanel>
            </MobileContainer>
        </>
    );
}

export default PlanProcess;
