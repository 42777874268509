import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import {ToiLikeStyledTextField} from '../../../../components/TextField/ToiLikeStyledTextField';
import {fetchKonsistenssjekkReportCsv} from '../../../../services/api';

export default function Toolbar(props) {
    const [csv, setCsv] = React.useState<any | undefined>();
    const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (csv) {
            const blob = new Blob(['\ufeff', csv], {
                type: 'data:text/csv; charset=utf-8',
            });

            const a = document.createElement('a');
            a.download = 'konsistenssjekk_report.csv';
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();

            setCsv(undefined);
            setIsDownloading(false);
        }
    }, [csv]);

    const handleDownloadClick = (e) => {
        setIsDownloading(true);
        fetchKonsistenssjekkReportCsv()
            .then((res) => res.data)
            .then((r) => setCsv(r))
            .catch((e) => {
                if (e.response.status === 404) return;
                else return e;
            });
    };

    return (
        <Grid
            sx={{alignItems: 'center', height: '50px', padding: '16px'}}
            container={true}
        >
            <Grid xs={6} sx={{}} item={true}>
                {props.dataInfo}
            </Grid>
            <Grid xs={6} sx={{alignItems: 'center'}} item={true}>
                <Grid
                    container={true}
                    style={{alignItems: 'center'}}
                    gap={5}
                    direction='row-reverse'
                >
                    <Tooltip
                        color='primary'
                        title={
                            props.showRunningReport
                                ? 'Kan kun eksportere fullført rapport'
                                : ''
                        }
                    >
                        <span>
                            <Button
                                onClick={(e) => handleDownloadClick(e)}
                                color='secondary'
                                variant='contained'
                                style={{
                                    flexDirection: 'row-reverse',
                                }}
                            >
                                Eksporter
                                {isDownloading ? (
                                    <CircularProgress
                                        sx={{mr: 2, color: 'white'}}
                                        size={20}
                                    />
                                ) : (
                                    <DownloadIcon
                                        sx={{mr: 2}}
                                        fontSize='small'
                                    />
                                )}
                            </Button>
                        </span>
                    </Tooltip>
                    <ToiLikeStyledTextField
                        variant='outlined'
                        onChange={props.onChange}
                        onKeyUp={props.onKeyUp}
                        placeholder='Søk i tabell'
                        size='small'
                        value={props.value}
                        InputProps={{
                            endAdornment: (
                                <div
                                    style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <IconButton
                                        title='Clear'
                                        aria-label='Clear'
                                        size='small'
                                        style={{
                                            visibility:
                                                props.value !== ''
                                                    ? 'visible'
                                                    : 'hidden',
                                        }}
                                        onClick={props.clearSearch}
                                    >
                                        <ClearIcon
                                            color='primary'
                                            fontSize='small'
                                        />
                                    </IconButton>
                                    <SearchIcon fontSize='small' />
                                </div>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

Toolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onKeyUp: PropTypes.func,
    showRunningReport: PropTypes.bool,
    dataInfo: PropTypes.element,
};
