import {
    getMunicipalityWmsLayers,
    WmsLayerGroup,
    LayerType,
} from '../../../services/apiTjenestekatalogen';
import {WmsLayer, MapboxFormattedWmsUrl, AvailableWmsLayer} from '../types';
import {WmsLayersRequestState} from '../../../store/map/types';
import {Arealplan} from '../../../hooks/arealplaner/types';
import {KartlagKonfig, KartlagRegel} from '../../../hooks/meta/types';
import {mapConstants, regelsettConst} from './constants';
import {filterAvailableLayersForPlan} from './layerMapping';

export const fetchWmsLayers = async (
    oldWmsState: WmsLayersRequestState,
    fetchWmsSuccess: (state: WmsLayersRequestState) => void,
    fetchWmsError: () => void,
    knr: string,
    kartlagInnstillinger: KartlagKonfig
) => {
    const wmsLayerGroups: WmsLayerGroup[] | false =
        await getMunicipalityWmsLayers(knr);

    if (wmsLayerGroups) {
        let availableLayers: AvailableWmsLayer[];

        if (wmsLayerGroups.length != 0) {
            availableLayers = mapAvailableLayers(
                wmsLayerGroups[0].Layers,
                kartlagInnstillinger
            );
        } else {
            availableLayers = [];
        }

        fetchWmsSuccess({
            ...oldWmsState,
            loading: false,
            planId: undefined,
            wmsLayers: [],
            availableWmsLayers: availableLayers.sort(
                (a, b) => a.displayOrder - b.displayOrder
            ),
            wmsServerUrl: wmsLayerGroups[0].URL,
            wmsServiceName: wmsLayerGroups[0].Name,
        });
    } else {
        fetchWmsError();
    }
};

export const fetchPlanWMSLayer = async (
    oldWmsState: WmsLayersRequestState,
    plan: Arealplan,
    vertikalnivaa: number[],
    fetchWmsSuccess: (state: WmsLayersRequestState) => void,
    fetchWmsError: () => void,
    planHasMidlertidigForbud: boolean,
    knr: string,
    kartlagInnstillinger: KartlagKonfig
) => {
    const wmsLayerGroups: WmsLayerGroup[] | false =
        await getMunicipalityWmsLayers(knr);

    if (wmsLayerGroups) {
        let availableLayers: AvailableWmsLayer[];

        if (wmsLayerGroups.length != 0) {
            availableLayers = mapAvailableLayers(
                wmsLayerGroups[0].Layers,
                kartlagInnstillinger
            );
        } else {
            availableLayers = [];
        }

        let availableLayersForPlan = filterAvailableLayersForPlan(
            availableLayers,
            plan.planStatusId,
            plan.planTypeId,
            vertikalnivaa,
            planHasMidlertidigForbud
        );

        availableLayersForPlan = availableLayersForPlan.map((lay, index) => {
            return {
                ...lay,
                isVisible: shouldLayerBeDefaultVisible(
                    lay,
                    index,
                    availableLayersForPlan
                ),
            };
        });

        fetchWmsSuccess({
            ...oldWmsState,
            loading: false,
            planId: plan.planId,
            wmsLayers: getInitWmsLayers(
                wmsLayerGroups[0].URL,
                availableLayersForPlan
            ),
            availableWmsLayers: availableLayersForPlan.sort(
                (a, b) => a.displayOrder - b.displayOrder
            ),
            otherWmsLayers: availableLayers.filter(
                (layer) =>
                    !availableLayersForPlan.some(
                        (planLayer) => layer.Name === planLayer.Name
                    )
            ),
            wmsServerUrl: wmsLayerGroups[0].URL,
            wmsServiceName: wmsLayerGroups[0].Name,
        });
    } else {
        fetchWmsError();
    }
};

/** Koble kartlagsinnstillinger for arealplaner til kartlag fra wms */
function mapAvailableLayers(
    layersFromWms: LayerType[],
    layersFromSettings: KartlagRegel[]
) {
    const result: AvailableWmsLayer[] = [];

    // Opprett et lag per regel i arealplaner admin og koble det til det relevante wms laget
    layersFromSettings.forEach((layer) => {
        const wmsLayer = layersFromWms.find(
            (wmsLayer) => wmsLayer.Description === layer.kartlag
        );
        if (wmsLayer) {
            result.push({
                ...wmsLayer,
                ruleset: layer.kartlagRegelsett,
                displayName: layer.beskrivelse,
                drawOrder: layersFromWms.indexOf(wmsLayer),
                isVisible: false, // Settes senere basert på regelsettet
                displayOrder: layer.rekkefolge,
            } as AvailableWmsLayer);
        }
    });

    return result;
}

const shouldLayerBeDefaultVisible = (
    layer: AvailableWmsLayer,
    index: number,
    allLayers: AvailableWmsLayer[]
) => {
    const wantedDefaultLayerDesc = [
        regelsettConst.RegPaaGrunnen,
        regelsettConst.Planforslag,
        regelsettConst.MidlertidigForbudMotTiltak,
    ];
    if (
        wantedDefaultLayerDesc.filter((defaultLayer) =>
            layer.ruleset.includes(defaultLayer)
        ).length > 0
    ) {
        // if any ruleset matches one of wantedDefaultLayerDesc
        return true;
    } else {
        const wantedDefaultLayerExist =
            allLayers.filter((lay) =>
                lay.ruleset.includes(wantedDefaultLayerDesc[0])
            ).length > 0; //Only regulering på grunnen makes us not want to active other layers by default
        if (!wantedDefaultLayerExist) {
            if (
                allLayers[0].ruleset.includes(
                    regelsettConst.MidlertidigForbudMotTiltak
                ) &&
                index === 1
            ) {
                return true;
            } else if (
                !allLayers[0].ruleset.includes(
                    regelsettConst.MidlertidigForbudMotTiltak
                ) &&
                index === 0
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
};

export const getInitWmsLayers = (
    serverUrl: string,
    availWmsLayers: AvailableWmsLayer[]
): WmsLayer[] => {
    const wmsLayers: WmsLayer[] = [];

    const layersToDraw = availWmsLayers
        .filter((lay, index) =>
            shouldLayerBeDefaultVisible(lay, index, availWmsLayers)
        )
        .sort((a, b) => a.drawOrder - b.drawOrder);

    layersToDraw.forEach((newLayer) => {
        const wmsLayer = createMapboxWmsLayer(
            serverUrl,
            availWmsLayers,
            newLayer
        );
        wmsLayers.push(wmsLayer);
    });
    return wmsLayers;
};

export const getWmsLayer = (
    newLayer: AvailableWmsLayer,
    serverUrl: string,
    availWmsLayers: AvailableWmsLayer[]
): WmsLayer => {
    return createMapboxWmsLayer(serverUrl, availWmsLayers, newLayer);
};

const createMapboxWmsLayer = (
    serverUrl: string,
    availWmsLayers: AvailableWmsLayer[],
    newLayer: AvailableWmsLayer
): WmsLayer => {
    let beforeLayerId: string | undefined = undefined;

    availWmsLayers.forEach((layer) => {
        //Draw order: lower number => higher on map
        if (layer.drawOrder < newLayer.drawOrder && layer.isVisible) {
            beforeLayerId = mapConstants.layerIdPrefix + layer.Name;
        }
    });
    if (!beforeLayerId) {
        beforeLayerId = mapConstants.defaultBeforeLayerId;
    }

    let name: string = newLayer.Name;

    if (newLayer.CombinedLayers && newLayer.CombinedLayers.length > 1) {
        name = newLayer.CombinedLayers.slice()
            .reverse()
            .map((c) => c.Name)
            .join(',');
    }

    return {
        minZoom: 0,
        maxZoom: 23,
        id: mapConstants.layerIdPrefix + newLayer.Name,
        beforeLayerId: beforeLayerId,
        url: createWmsTilesUrl(serverUrl, name),
    };
};

const createWmsTilesUrl = (
    serverUrl: string,
    activeLayer: string
): MapboxFormattedWmsUrl => {
    let tilesUrl = getTilesUrl(serverUrl);
    tilesUrl += activeLayer;
    return tilesUrl as MapboxFormattedWmsUrl;
};

const getTilesUrl = (serverUrl: string): string => {
    let tilesUrl = serverUrl;
    tilesUrl += serverUrl.indexOf('?') === -1 ? '?' : '&';
    tilesUrl +=
        'bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&' +
        'request=GetMap&srs=EPSG:3857&width=512&height=512&TRANSPARENT=true&' +
        'useLayerPriority=true&layers=';
    return tilesUrl;
};
