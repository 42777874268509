import axios from 'axios';
import arealplanerConfig from '../config';

const matrikkelKart = axios.create({
    baseURL: arealplanerConfig.matrikkelkart.url,
    headers: {'X-WAAPI-TOKEN': arealplanerConfig.matrikkelkart.token},
});

interface MatrikkelkartResponse {
    GeometryFormat: string;
    SRS: number;
    Teiger: {
        Kommunenummer: number;
        Gaardsnummer: number;
        Bruksnummer: number;
        Festenummer: number;
        Seksjonsnummer: number;
        SeksjonsnummerTeig: number;
        BeregnetAreal: number;
        ArealMerknader: string;
        HovedTeig: boolean;
        Tvist: boolean;
        TeigMedFlereMatrikkelEnheter: boolean;
        Matrikkelenheter: {
            Matrikkelnummer: number;
            Kommunenummer: number;
            Gaardsnummer: number;
            Bruksnummer: number;
            Festenummer: number;
            Seksjonsnummer: number;
        }[];
        GIDtekst: string;
        WellKnownTextGeometri: string;
        Geometri: string; //polygon,
        SRS: number;
        GeometryTextFormat: string;
        Matrikkelnummer: string;
        Id: number;
        Anleggsprojeksjonsflate: boolean;
    }[];
}

const _propertyHasGeometry = async (propertyId: string): Promise<boolean> => {
    const data = await fetchGeometry(propertyId);
    if (data === 'error') {
        return false;
    } else {
        return data.Teiger.length > 0;
    }
};

const fetchGeometry = (
    propertyId: string
): Promise<MatrikkelkartResponse | 'error'> => {
    const path = `/teig/matrikkelnummer/?matrikkelnummer=${propertyId}&GeometryTextFormat=GeoJson`;
    return matrikkelKart.get<MatrikkelkartResponse>(path).then(
        (res) => res.data,
        (err) => 'error'
    );
};

export const getPropertyGeometry = async (
    propertyId: string
): Promise<MatrikkelkartResponse | 'error'> => {
    const data = await fetchGeometry(propertyId);
    if (data === 'error') {
        const removedSeksjonsnummerMnr: string =
            propertyId.substring(0, 17) + '000';
        const data = await fetchGeometry(removedSeksjonsnummerMnr);
        return data;
    } else {
        return data;
    }
};

export const getAddress = (position: {lat: number; lng: number}) => {
    const path = `/teig/punkt?y=${position.lat}&x=${position.lng}&GeometryTextFormat=GeoJson`;
    return matrikkelKart.get<MatrikkelkartResponse>(path);
};

export const propertyHasGeometry = async (
    propertyId: string
): Promise<boolean> => {
    const hasGeometry = await _propertyHasGeometry(propertyId);
    if (!hasGeometry && propertyId.substring(17, 20) !== '000') {
        return _propertyHasGeometry(propertyId.substring(0, 17) + '000');
    }

    return hasGeometry;
};
