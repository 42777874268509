import {useMutation, useQueryClient} from 'react-query';
import {runKonsistenssjekk} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';

const postRunKonsistenssjekk = async () => {
    const {data} = await runKonsistenssjekk();
    return data;
};

export default function useRunKonsistenssjekk() {
    const {notify} = useErrorHandler();
    const kundeId = useSelectedKundeId();
    const queryClient = useQueryClient();

    return useMutation(
        ['run_konsistenssjekk', {kundeId}],
        () => postRunKonsistenssjekk(),
        {
            onError: notify,
        }
    );
}
