import {CircularProgress, Grid} from '@mui/material';
import {format} from 'date-fns';
import * as React from 'react';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import ReactTable from '../../../../components/ReactTable';
import {planstatusPlantypeQuery} from '../../../../hooks/customReports/customReportQueries';
import usePlanstatusPlantypeGraphQL from '../../../../hooks/customReports/usePlanstatusPlantypeGraphQL';
import {usePlanstatusPlantypeGraphQLCsv} from '../../../../hooks/customReports/usePlanstatusPlantypeGraphQLCsv';
import {exportToCsv} from '../ReportTools/ClientsideCsv';
import TilstandPlantypeColumns from './TilstandPlantypeColumns';

export default function TilstandPlantypeReport() {
    const csv = usePlanstatusPlantypeGraphQLCsv();
    const columnsPlantype = TilstandPlantypeColumns();
    const tilstandPlantype = usePlanstatusPlantypeGraphQL(
        planstatusPlantypeQuery
    );

    React.useEffect(() => {
        if (csv.csvData && csv.isSuccess) {
            exportToCsv(
                `planstatus_plantype_report_${format(new Date(), 'ddMMyyyy')}`,
                csv.csvData,
                csv.headers
            );
            csv.remove();
        }
    }, [csv.csvData]);

    const handleDownloadClick = () => {
        csv.refetch();
    };

    if (tilstandPlantype.isLoading) return <LoadingSpinner />;
    return (
        <Grid item={true}>
            <ReactTable
                tableContainerProps={{
                    sx: {
                        overflow: 'visible',
                    },
                }}
                separateFirstColumn={true}
                columns={columnsPlantype}
                data={
                    tilstandPlantype.tilstandPlantype
                        ? tilstandPlantype.tilstandPlantype
                        : []
                }
                toolbar={{
                    exportCsv: {
                        handleDownloadClick: () => handleDownloadClick(),
                        loadingRequest: undefined, //csv.isLoading
                    },
                    dataInfo: tilstandPlantype.isFetching ? (
                        <CircularProgress
                            color='primary'
                            size={20}
                            title='Oppdaterer resultater'
                        />
                    ) : (
                        <></>
                    ),
                }}
            />
        </Grid>
    );
}
