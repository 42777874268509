import * as React from 'react';
import Icon from '@mdi/react';
import {
    mdiFilePdfBox,
    mdiFileWordOutline,
    mdiFileImageOutline,
    mdiFileOutline,
} from '@mdi/js';

type Props = {
    extension?: string;
    style?: React.CSSProperties;
};

function FileIcon({extension, ...props}: Props) {
    let path = mdiFileOutline;
    if (extension) {
        const ext = extension.toLowerCase();
        switch (ext) {
            case 'pdf':
                path = mdiFilePdfBox;
                break;

            case 'doc':
            case 'docx':
                path = mdiFileWordOutline;
                break;

            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'jfif':
            case 'tiff':
            case 'bmp':
            case 'gif':
            case 'svg':
                path = mdiFileImageOutline;
                break;

            default:
                break;
        }
    }
    return <Icon path={path} size={1} {...props} />;
}

export default FileIcon;
