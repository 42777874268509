import {useMutation, useQueryClient} from 'react-query';
import {dokumenterDeleteDokument} from '@norkart/nkapi-arealplaner-api';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useDeleteDokument() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const arealplanId = useSelectedArealplanId();
    const {notify} = useErrorHandler();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useMutation(
        (dokumentId: number) =>
            dokumenterDeleteDokument({
                client: authorizedClient,
                path: {dokumentId: dokumentId, kundeId},
            }),
        {
            onError: notify,
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'dokumenter',
                    {kundeId, arealplanId},
                ]);
                queryClient.invalidateQueries([
                    'behandlinger',
                    {kundeId, arealplanId},
                ]);
                queryClient.invalidateQueries([
                    'dispensasjoner',
                    {kundeId, arealplanId},
                ]);
            },
        }
    );
}
