import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Paper, {PaperProps} from '@mui/material/Paper';
import React from 'react';
import Draggable from 'react-draggable';
import MarkDown from 'react-markdown';
import InfoIcon from '@mui/icons-material/Info';
import useTrackArealplanerEvent from '../../hooks/appinsights/useTrackArealplanerEvent';

type Props = {
    helpText: string;
    type?: string;
    label?: string;
    size?: number;
    buttonVariant?: 'ikon' | 'tekst';
};

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle='#draggable-dialog-title'
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const HelpButton = ({helpText, label, size, type, buttonVariant}: Props) => {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const trackReadMore = useTrackArealplanerEvent('helpButton', 'button');

    const handleClose = (e) => {
        e.stopPropagation();
        setOpen(false);
    };

    const handleOnClick = (e) => {
        trackReadMore(`hjelpknapp_${type}`);
        setOpen(true);
        e.stopPropagation();
    };

    return (
        <>
            <Tooltip
                followCursor={true}
                placement='bottom-end'
                title='Trykk for å lese mer'
            >
                {!buttonVariant || buttonVariant === 'ikon' ? (
                    <div>
                        <IconButton onClick={handleOnClick} size='small'>
                            <InfoIcon fontSize='inherit' color='primary' />
                        </IconButton>
                        {label && (
                            <p style={{margin: 0, marginLeft: '10px'}}>
                                {label}
                            </p>
                        )}
                    </div>
                ) : (
                    <Link
                        sx={{alignSelf: 'flex-start'}}
                        component='button'
                        variant='body1'
                        onClick={(e) => handleOnClick(e)}
                        color={'secondary'}
                    >
                        Les mer
                    </Link>
                )}
            </Tooltip>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                aria-labelledby='draggable-dialog-title'
                PaperComponent={PaperComponent}
                onClose={handleClose}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                maxWidth='md'
                disableScrollLock={true}
            >
                <DialogTitle
                    id='draggable-dialog-title'
                    sx={{
                        cursor: 'move',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingBottom: 0,
                    }}
                >
                    <IconButton
                        onTouchStart={handleClose}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={(theme) => ({
                        padding: '55px',
                        paddingTop: 0,
                        ' & h1, h2, h3': {
                            marginTop: 0,
                            fontWeight: 400,
                        },
                        [theme.breakpoints.down('md')]: {
                            padding: '24px',
                            paddingTop: 0,
                            marginTop: '-20px',
                        },
                    })}
                >
                    <MarkDown>{helpText}</MarkDown>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default HelpButton;
