import {Box, styled} from '@mui/material';
import React from 'react';
import {
    FeatureAttribute,
    gfiAttId,
} from '../../../../../../services/apiTjenestekatalogen';
import DesktopContainer from '../../../../../../containers/DesktopContainer';
import MobileContainer from '../../../../../../containers/MobileContainer';

const LinkToMap = styled('button')(({theme}) => ({
    padding: '5px',
    marginTop: '10px',
    border: 'none',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    fontSize: 17,
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
    '&:disabled': {
        color: 'grey',
        '&:hover': {color: 'grey'},
    },
    '&:focus': {
        fontWeight: 500,
        outline: 'none',
    },
    '&:hover': {
        color: '#093809',
    },
}));
const AttributeRow = styled('p')({
    marginBottom: '3px',
    marginTop: '3px',
});

type Props = {
    attributes: FeatureAttribute[];
    gfiAttId: gfiAttId;
    visibleGfiAtt?: gfiAttId;
    zoomToDrawnLayer;
    setVisibleInMap: (id: gfiAttId, value: boolean) => void;
};

const AttributeDetails = ({
    attributes,
    gfiAttId,
    visibleGfiAtt,
    setVisibleInMap,
    zoomToDrawnLayer,
}: Props) => {
    const gfiAttIdIsVisibleInMap = visibleGfiAtt && visibleGfiAtt === gfiAttId;
    const filteredAttributes = attributes.filter(
        (att) => att.Name !== 'Planidentifikasjon'
    );

    const getAttValue = (att) => {
        if (att.Value.split('(utgått)').length === 2) {
            return att.Value.split('(utgått)')[0];
        } else {
            return att.Value;
        }
    };

    return (
        <Box
            sx={{
                padding: '10px',
                marginBottom: '10px',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                border: '1px solid grey',
            }}
        >
            {filteredAttributes.map((att, index) => {
                return (
                    <div
                        key={index + 'attDetail'}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <AttributeRow
                            style={{
                                width: '40%',
                                fontWeight: 500,
                            }}
                        >
                            {att.Name}:
                        </AttributeRow>
                        <AttributeRow style={{width: '55%'}}>
                            {getAttValue(att)}
                        </AttributeRow>
                    </div>
                );
            })}
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                }}
            >
                <MobileContainer>
                    <LinkToMap
                        tabIndex={0}
                        onClick={() =>
                            setVisibleInMap(gfiAttId, !gfiAttIdIsVisibleInMap)
                        }
                    >
                        {gfiAttIdIsVisibleInMap
                            ? 'Fjern markering'
                            : 'Se markering'}
                    </LinkToMap>
                </MobileContainer>
                <LinkToMap
                    disabled={!gfiAttIdIsVisibleInMap}
                    tabIndex={0}
                    onClick={() => {
                        gfiAttIdIsVisibleInMap && zoomToDrawnLayer();
                    }}
                >
                    Zoom til markering
                </LinkToMap>
                <DesktopContainer>
                    <LinkToMap
                        style={{marginLeft: 20}}
                        tabIndex={0}
                        onClick={() =>
                            setVisibleInMap(gfiAttId, !gfiAttIdIsVisibleInMap)
                        }
                    >
                        {gfiAttIdIsVisibleInMap
                            ? 'Fjern markering i kart'
                            : 'Se markering i kartet'}
                    </LinkToMap>
                </DesktopContainer>
            </div>
        </Box>
    );
};
export default AttributeDetails;
