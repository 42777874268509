import {useQuery} from 'react-query';
import {
    ApplikasjonInnstillingerDto,
    applikasjonInnstillingerGetApplikasjonInnstillinger,
} from '@norkart/nkapi-arealplaner-api';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useApplikasjonInnstillinger() {
    const {authorizedClient} = useArealplanerApiClientContext();
    const query = useQuery<ApplikasjonInnstillingerDto[], any>(
        ['applikasjonInnstillinger'],
        () =>
            applikasjonInnstillingerGetApplikasjonInnstillinger({
                client: authorizedClient,
            }).then((res) => res.data as ApplikasjonInnstillingerDto[])
    );

    return {
        ...query,
        data: query?.data || [],
    };
}
