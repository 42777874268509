import {Box, Typography, styled} from '@mui/material';
import * as React from 'react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import WarningIcon from '@mui/icons-material/Warning';
import useSelectedKundeId from '../../hooks/kunder/useSelectedKundeId';
import useKunde from '../../hooks/kunder/useKunde';
import useCan from '../../utils/auth/useCan';

const StyledKundeTest = styled(Box)({
    position: 'fixed',
    width: '100%',
    height: 50,
    color: '#FFE8D1',
    zIndex: 1000,
    backgroundColor: '#FFE8D1',
    borderBottom: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
});

const StyledKundeDeactivated = styled(Box)({
    position: 'fixed',
    width: '100%',
    height: 50,
    zIndex: 1000,
    backgroundColor: '#FFD9D9',
    borderBottom: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
});

export default function KundestatusTopbar() {
    const kundeId = useSelectedKundeId();
    const {kunde} = useKunde();
    const can = useCan();

    const renderSelectedTopBar = () => {
        if (kunde?.status === 1) {
            return (
                <>
                    <StyledKundeDeactivated>
                        <NotInterestedIcon
                            color='error'
                            fontSize='large'
                            sx={{mr: 10}}
                        />
                        <Typography>
                            {can.edit
                                ? 'Inaktiv kommune. Denne kommunen er for øyeblikket deaktivert og kan ikke redigeres.'
                                : 'Data for denne kommunen i arealplaner.no blir ikke oppdatert før etter kommunereformen er gjennomført.'}
                        </Typography>
                    </StyledKundeDeactivated>
                    <div style={{marginBottom: 50}}></div>
                </>
            );
        } else if (kunde?.status === 2) {
            return (
                <>
                    <StyledKundeTest>
                        <WarningIcon
                            color='warning'
                            fontSize='large'
                            sx={{mr: 10}}
                        />
                        <Typography>
                            Testkommune. Dette er en testkommune og bør kun
                            brukes til testformål.
                        </Typography>
                    </StyledKundeTest>
                    <div style={{marginBottom: 50}}></div>
                </>
            );
        } else return null;
    };
    return renderSelectedTopBar();
}
