import {TypeStreet, TypeStreetAddress} from './const';

export const subSearch = {
    useSubSearch: (hit) => hit.Type === TypeStreet,
    enableBackButton: (hit) => true,
    search: (text, props, searchFunction, id) => {
        return searchFunction(
            text,
            props,
            [TypeStreetAddress],
            'data/matrikkel/adresse/gate/',
            null,
            id
        );
    },
};
