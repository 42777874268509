import {
  AuthorizationlookupService,
  CustomerContext as BackendCustomerContext,
  CurrentAuthorizationSelfLookupResponse,
  DynamicCustomerContextEnabledAppAuthorizationSelfLookupResponse,
  DynamiccustomercontextenabledService,
  Setting,
} from '@norkart/norkartapi3-applicationbackend';
import {getHeaderGenerator} from './getHeaderGenerator';
import {AuthData, CustomerContext} from './types';

interface AuthorizationBase {
  isAuthorized: boolean;
  userId?: string;
  tenantId?: string;
  tenantName?: string;
  profileName?: string;
}

export interface Settings {
  getStringSetting: (name: string) => string | undefined;
  getBooleanSetting: (name: string) => boolean | undefined;
  getNumberSetting: (name: string) => number | undefined;
  getSettings: () => Array<Setting> | undefined;
}

export interface AuthorizationMultiCustomer extends AuthorizationBase {
  customerContexts?: Array<CustomerContext>;
}

export interface Authorization extends AuthorizationBase, Settings {
  configurationName?: string;
  customerName?: string;
  customerId?: string;
}

const getStringSetting = (settings: Array<Setting> | undefined, name: string) =>
  settings?.find((s) => s.Name === name)?.StringValue;
const getBooleanSetting = (settings: Array<Setting> | undefined, name: string) =>
  settings?.find((s) => s.Name === name)?.BooleanValue;
const getNumberSetting = (settings: Array<Setting> | undefined, name: string) =>
  parseFloat(settings?.find((s) => s.Name === name)?.StringValue || '');
const getSettings = (settings: Array<Setting> | undefined) => settings;

const toAuthorizationMultiCustomer = (
  response: DynamicCustomerContextEnabledAppAuthorizationSelfLookupResponse
): AuthorizationMultiCustomer => ({
  isAuthorized: !!response.IsAuthorized,
  profileName: response.ProfileName,
  userId: response.UserId,
  tenantId: response.TenantId,
  tenantName: response.TenantName,
  customerContexts: response.UserCustomerContexts?.map((context) => toCustomerContext(context)),
});

const toCustomerContext = (context: BackendCustomerContext): CustomerContext => ({
  CustomerId: context.CustomerId,
  CustomerName: context.CustomerName,
  getStringSetting: (name: string) => getStringSetting(context.Settings, name),
  getBooleanSetting: (name: string) => getBooleanSetting(context.Settings, name),
  getNumberSetting: (name: string) => getNumberSetting(context.Settings, name),
  getSettings: () => getSettings(context.Settings),
});

export const getAuthorizationMultiCustomer = async (
  authData: AuthData,
  applicationBackendUrl?: string
): Promise<AuthorizationMultiCustomer> => {
  const tokenHeaderGenerator = getHeaderGenerator(authData.msalApp, authData);
  const authorizationlookupService = new DynamiccustomercontextenabledService({
    HEADERS: tokenHeaderGenerator,
    BASE: applicationBackendUrl,
  });

  const response =
    await authorizationlookupService.dynamicCustomerContextEnabledAppAuthorizationSelfLookupauthorizationlookupGet({
      accept: 'application/json',
    });

  return toAuthorizationMultiCustomer(response);
};

const toAuthorization = (response: CurrentAuthorizationSelfLookupResponse): Authorization => ({
  isAuthorized: !!response.IsAuthorized,
  configurationName: response.ConfigurationName,
  customerName: response.CustomerName,
  customerId: response.CustomerId,
  profileName: response.ProfileName,
  userId: response.UserId,
  tenantId: response.TenantId,
  tenantName: response.TenantName,
  getStringSetting: (name: string) => getStringSetting(response.Settings, name),
  getBooleanSetting: (name: string) => getBooleanSetting(response.Settings, name),
  getNumberSetting: (name: string) => getNumberSetting(response.Settings, name),
  getSettings: () => getSettings(response.Settings),
});

export const getAuthorization = async (authData: AuthData, applicationBackendUrl?: string): Promise<Authorization> => {
  const tokenHeaderGenerator = getHeaderGenerator(authData.msalApp, authData);
  const authorizationlookupService = new AuthorizationlookupService({
    HEADERS: tokenHeaderGenerator,
    BASE: applicationBackendUrl,
  });
  const response = await authorizationlookupService.currentAuthorizationSelfLookupGet({
    accept: 'application/json',
    xWaapiProfile: authData.setupNorkartProfile,
  });

  return toAuthorization(response);
};
