import {styled} from '@mui/material';
import classNames from 'classnames';
import React, {InputHTMLAttributes} from 'react';

type Props = InputHTMLAttributes<HTMLInputElement> & {
    error?: boolean;
    warning?: boolean;
};

const FormInputWrapper = styled('div')(({theme}) => ({
    width: '100%',
    '& .formInput': {
        width: '100%',
        padding: '5px',
        fontFamily: theme.typography.fontFamily,
    },
    '& .formInput:disabled': {backgroundColor: '#f0f0f0'},
    '& .formInputError': {border: '1px solid red'},
}));

function FormInput({error, warning, ...props}: Props) {
    return (
        <FormInputWrapper>
            <input
                type='text'
                {...props}
                className={classNames('formInput', {
                    formInputError: false,
                })}
            />
        </FormInputWrapper>
    );
}

export default FormInput;
