import {Card as MuiCard, CardProps as MuiCardProps, CardTypeMap as MuiCardTypeMap, styled} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {forwardRef} from 'react';
import {toiCardStyle} from './toiCardStyle';

type OwnProps = {
  //Add your own custom props here.
  grey?: boolean;
};

type ToiCardProps = Omit<MuiCardProps, keyof OwnProps | 'component'> & OwnProps;

type ToiCardType = OverridableComponent<{
  props: Omit<MuiCardTypeMap<OwnProps>['props'], keyof OwnProps> & OwnProps;
  defaultComponent: MuiCardTypeMap<OwnProps>['defaultComponent'];
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledCard = styled(MuiCard)(({theme}): any => {
  return toiCardStyle(theme);
});

export const ToiCard = forwardRef<HTMLDivElement, ToiCardProps>((props, ref) => {
  const {sx, grey, ...restProps} = props;

  return (
    <StyledCard
      sx={grey ? {...sx, backgroundColor: (theme) => theme.palette.black[10]} : sx}
      ref={ref}
      {...restProps}
    />
  );
}) as ToiCardType;

export default ToiCard;
