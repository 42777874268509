import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
  styled,
} from '@mui/material';
import {forwardRef} from 'react';
import {toiSpinnerStyle} from './toiSpinnerStyle';

type OwnProps = {
  //Add your own custom props here.
};

type ToiSpinnerProps = Omit<MuiCircularProgressProps, keyof OwnProps | 'component'> & OwnProps;

const StyledSpinner = styled(MuiCircularProgress)(({theme}) => {
  return toiSpinnerStyle(theme);
});

export const ToiSpinner = forwardRef<HTMLElement, ToiSpinnerProps>((props, ref) => {
  const {size, ...restProps} = props;

  return <StyledSpinner ref={ref} size={size ?? 24} {...restProps} />;
});

export default ToiSpinner;
