import * as React from 'react';
import {Button, ButtonGroup, Grid, Typography} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {MapboxLayer} from '@norkart/nkm-mapbox-map';
import {MapRoutePins1Icon} from '@norkart/toi-deprecated-icons';
import Error from '../FormError/Error';
import FormInputField from '../FormInputField';
import {Coords} from '../../features/map/types';
import {
    getProj4Def,
    toLatLng,
} from '../../features/behandling/BehandlingFormDialog/helpers';
import {isWithinLayer} from '../../features/map/helpers/layerHelpers';
import useMeta from '../../hooks/meta/useMeta';
import {validateUtmCoordsFormat} from '../../features/helpers/validateUtmCoordsFormat';

type ManualPositionProps = {
    confirmManualCoords: (
        coordsSelected: {x: number; y: number} | undefined
    ) => void;
    coordErrors: {coordError: string} | undefined;
    cancelManualCoords: () => void;
    formValues: {xkoord: number; ykoord: number} | undefined;
};

type DefaultPositionViewProps = {
    coords: {x: number; y: number} | undefined;
    setDisplaySelectMapPosition: (value: boolean) => void;
    onDeleteManualCoords: () => void;
    setEnableManualCoords: (value: React.SetStateAction<boolean>) => void;
};

type Props = {
    coords: {x: number; y: number} | undefined;
    setCoords: React.Dispatch<
        React.SetStateAction<
            | {
                  x: number;
                  y: number;
              }
            | undefined
        >
    >;
    setDisplaySelectMapPosition: (value: boolean) => void;
    planAreaLayer: MapboxLayer | undefined;
    allFormData: React.MutableRefObject<any>;
};

const ManualPositionInput = ({...props}: ManualPositionProps) => {
    const meta = useMeta();

    return (
        <div>
            <Grid
                container={true}
                style={{
                    marginTop: 16,
                    display: 'flex',
                }}
            >
                <Grid
                    item={true}
                    xs={4}
                    style={{
                        position: 'relative',
                        display: 'inline-block',
                        marginRight: 5,
                    }}
                >
                    <Typography
                        style={{
                            position: 'absolute',
                            right: 0,
                            padding: 5,
                            width: 20,
                            height: 20,
                        }}
                    >
                        N
                    </Typography>
                    <FormInputField
                        name='xkoord'
                        maxLength={9}
                        minLength={6}
                        optional={true}
                        fieldProps={{
                            parse: (value) => {
                                if (value == '') return value;
                                return value;
                            },
                        }}
                    />
                </Grid>
                <Grid
                    item={true}
                    xs={4}
                    style={{
                        position: 'relative',
                        justifyContent: 'center',
                        display: 'inline-flex',
                    }}
                >
                    <Typography
                        style={{
                            position: 'absolute',
                            right: 0,
                            padding: 5,
                            width: 20,
                            height: 20,
                        }}
                    >
                        Ø
                    </Typography>
                    <FormInputField
                        name='ykoord'
                        maxLength={9}
                        minLength={6}
                        placeholder=''
                        optional={true}
                        fieldProps={{
                            parse: (value) => {
                                if (value == '') return value;
                                return value;
                            },
                        }}
                    />
                </Grid>
                <Grid item={true} xs={2}>
                    <ButtonGroup variant='text' fullWidth={false}>
                        <Button
                            title={'Velg oppgitt posisjon'}
                            onClick={() =>
                                props.confirmManualCoords({
                                    x: props.formValues?.xkoord as number,
                                    y: props.formValues?.ykoord as number,
                                })
                            }
                        >
                            <CheckIcon fontSize='small' />
                        </Button>
                        <Button
                            title={'Avbryt'}
                            onClick={() => props.cancelManualCoords()}
                        >
                            <CloseIcon fontSize='small' />
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Typography>
                {`(${getProj4Def(
                    meta.referansesystemKode ? meta.referansesystemKode : ''
                )})`}
            </Typography>
            {props.coordErrors && props.coordErrors?.coordError && (
                <Error error={props.coordErrors?.coordError} />
            )}
        </div>
    );
};

const DefaultPositionView = ({...props}: DefaultPositionViewProps) => {
    return (
        <div>
            <ButtonGroup variant='text' fullWidth={false}>
                <Button
                    title={'Endre valgt posisjon'}
                    onClick={() => props.setDisplaySelectMapPosition(true)}
                >
                    <MapRoutePins1Icon size={0.8} />
                    <span style={{paddingLeft: 8}}>Velg i kart</span>
                </Button>
                <Button
                    title={'Fyll inn posisjon manuelt'}
                    onClick={() => props.setEnableManualCoords(true)}
                >
                    <EditIcon fontSize='small' />
                    <span style={{paddingLeft: 8}}>
                        {props.coords ? 'Endre' : 'Oppgi manuelt'}
                    </span>
                </Button>
                {props.coords && (
                    <Button
                        title={'Slett valgt posisjon'}
                        onClick={() => props.onDeleteManualCoords()}
                    >
                        <DeleteIcon fontSize='small' />
                        <span style={{paddingLeft: 8}}>Slett</span>
                    </Button>
                )}
            </ButtonGroup>
        </div>
    );
};

export const PositionOptions = ({
    coords,
    setCoords,
    setDisplaySelectMapPosition,
    planAreaLayer,
    allFormData,
}: Props) => {
    const meta = useMeta();
    const [coordErrors, setCoordErrors] = React.useState<
        {coordError: string} | undefined
    >();
    const [enableManualCoords, setEnableManualCoords] = React.useState(false);

    const manualPositionValid = (latLangCoordsSelected: Coords | undefined) => {
        if (latLangCoordsSelected) {
            if (isWithinLayer(latLangCoordsSelected, planAreaLayer))
                return true;
        }
        return false;
    };

    const onConfirmManualCoords = (
        coordsSelected: {x: number; y: number} | undefined
    ) => {
        const coordsFormatValidationResult =
            validateUtmCoordsFormat(coordsSelected);

        if (coordsFormatValidationResult.x && coordsFormatValidationResult.y) {
            const numberCoords = {
                x: Number(coordsSelected?.x),
                y: Number(coordsSelected?.y),
            };
            const latLangCoords = toLatLng(
                numberCoords,
                meta.referansesystemKode
            );
            if (manualPositionValid(latLangCoords)) {
                setCoords(numberCoords);
                setEnableManualCoords(false);
                setCoordErrors(undefined);
            } else {
                setCoordErrors({coordError: 'Punktet er ikke innenfor planen'});
            }
        } else {
            const fieldErrorMessage =
                !coordsFormatValidationResult.x &&
                coordsFormatValidationResult.y
                    ? 'Nord'
                    : coordsFormatValidationResult.x &&
                      !coordsFormatValidationResult.y
                    ? 'Øst'
                    : 'Nord og Øst';

            setCoordErrors({
                coordError: `Venligst fyll inn en gyldig verdi i felt: ${fieldErrorMessage}`,
            });
        }
    };

    const onCancelManualCoords = () => {
        setCoordErrors(undefined);
        setEnableManualCoords(false);

        if (allFormData.current && coords) {
            allFormData.current.xkoord = coords.x;
            allFormData.current.ykoord = coords.y;
        }

        if (!coords) {
            allFormData.current.xkoord = '';
            allFormData.current.ykoord = '';
        }
    };

    const onDeleteManualCoords = () => {
        setCoords(undefined);
    };

    return (
        <Grid>
            {coords && !enableManualCoords && (
                <p>{` ${coords.x}N ${coords.y}Ø (${getProj4Def(
                    meta.referansesystemKode ? meta.referansesystemKode : ''
                )})`}</p>
            )}
            {!enableManualCoords && (
                <DefaultPositionView
                    coords={coords}
                    onDeleteManualCoords={onDeleteManualCoords}
                    setEnableManualCoords={setEnableManualCoords}
                    setDisplaySelectMapPosition={setDisplaySelectMapPosition}
                />
            )}
            {enableManualCoords && (
                <ManualPositionInput
                    confirmManualCoords={onConfirmManualCoords}
                    cancelManualCoords={onCancelManualCoords}
                    coordErrors={coordErrors}
                    formValues={{
                        xkoord: allFormData.current.xkoord,
                        ykoord: allFormData.current.ykoord,
                    }}
                />
            )}
        </Grid>
    );
};
