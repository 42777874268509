import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import orderBy from 'lodash-es/orderBy';
import DocumentTable from '../../../components/DocumentTable';
import {Behandling} from '../../../hooks/behandlinger/types';
import JournalpostItem from '../../sak/Journalpost/JournalpostItem';

interface BehandlingDetailPanelProps {
    behandling: Behandling;
}

export const behandlingHasDetailsField = (behandling: Behandling) => {
    return (
        behandling.posisjon ||
        behandling.endringstype ||
        (behandling.dokumenter && behandling.dokumenter.length) ||
        behandling.myndighet ||
        behandling.journalpostnummer
    );
};

const BehandlingDetailPanel = ({behandling}: BehandlingDetailPanelProps) => {
    const {myndighet, endringstype, posisjon, journalpostnummer} = behandling;
    const header = {fontWeight: 500};

    return (
        <div
            style={{
                paddingLeft: '80px',
                paddingRight: '20px',
                marginTop: '20px',
                marginBottom: '20px',
            }}
        >
            {behandlingHasDetailsField(behandling) ? (
                <Grid
                    container={true}
                    justifyContent='flex-start'
                    spacing={10}
                    sx={{marginBottom: '20px'}}
                >
                    {myndighet && (
                        <Grid item={true}>
                            <Typography style={header}>Myndighet</Typography>
                            <Typography>{myndighet}</Typography>
                        </Grid>
                    )}
                    {endringstype && (
                        <Grid item={true}>
                            <Typography style={header}>Endringstype</Typography>
                            <Typography>{endringstype}</Typography>
                        </Grid>
                    )}
                    {journalpostnummer && (
                        <Grid item={true}>
                            <Typography style={header}>
                                Journalpostnummer
                            </Typography>
                            <Typography>
                                <JournalpostItem
                                    journalpostData={
                                        {
                                            journalpostnummer:
                                                behandling.journalpostnummer,
                                            journalpostUrl:
                                                behandling.journalpostUrl,
                                            journalpostUrlIntern:
                                                behandling.journalpostUrlIntern,
                                        } as Partial<Behandling>
                                    }
                                />
                            </Typography>
                        </Grid>
                    )}
                    {posisjon && (
                        <Grid item={true}>
                            <Typography style={header}>Posisjon</Typography>
                            <Typography>{`${posisjon.x}N ${posisjon.y}Ø ${posisjon.koordsys}`}</Typography>
                        </Grid>
                    )}
                    {behandling.dokumenter &&
                        behandling.dokumenter.length != 0 && (
                            <Grid item={true} xs={12}>
                                <Typography variant='h3'>Dokumenter</Typography>
                                <DocumentTable
                                    dokumenter={orderBy(
                                        behandling.dokumenter,
                                        [
                                            'rekkefolgeBehandling',
                                            'dokumenttype',
                                        ],
                                        ['asc', 'asc']
                                    )}
                                    behandling={behandling}
                                />
                            </Grid>
                        )}
                </Grid>
            ) : (
                <p>Ingen detaljer</p>
            )}
        </div>
    );
};

export default BehandlingDetailPanel;
