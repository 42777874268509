import mapboxgl, {FitBoundsOptions, FlyToOptions, Map, MapboxOptions} from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, {useEffect, useRef, useState} from 'react';
import './style.scss';
import {addPopup, drawLayers, drawMarker, drawWmsLayers} from './utils/drawLayers';
import enableNorkartAttribution from './utils/enableNorkartAttribution';
import {addFullscreenButton} from './utils/fullscreen';
import {UsePrevious} from './utils/hooks';
import {
  Coords,
  FitBounds,
  FullscreenButton,
  MapboxLayer,
  mapClickEvent,
  MapEvent,
  marker,
  Popup,
  position,
  WmsLayer,
} from './utils/types';

type EventList = {
  click: MapEvent[];
  moveend: MapEvent[];
  load: MapEvent[];
  mousemove: MapEvent[];
};
const eventList: EventList = {click: [], moveend: [], load: [], mousemove: []};

type Control = {control: any; option?: position};

type Props = {
  accessToken: string;
  styleUrl: string;
  zoomLevel?: number;
  onMapLoaded?: () => void;
  centerCoords?: Coords;
  fitBounds?: FitBounds | null;
  pitch?: number;
  bearing?: number;
  useNorkartAttribution?: boolean;
  interactive?: boolean;
  onClick?: (event: mapClickEvent) => void;
  onLayerClick?: (event: mapClickEvent, layerName: string) => void;
  marker?: Coords | marker;
  wmsLayers?: WmsLayer[];
  layers?: MapboxLayer[];
  onMoveend?: (event: any) => void;
  navigationControls?: {show: boolean; options: position};
  controls?: Control[];
  events?: MapEvent[];
  recenterMap?: boolean;
  fireMapResize?: boolean;
  shouldFly?: boolean;
  fullscreenBtn?: FullscreenButton;
  mapContainerId?: string;
  setLayerVisibility?: {layerid: string; show: boolean}[];
  onMapResized?: () => void;
  initFullscreen?: boolean;
  popup?: Popup;
  mapOptions?: Omit<MapboxOptions, 'container'>;
  customIcons?: {url: string; name: string}[];
  clickableLayers?: {name: string}[];
  onMouseMove?: (e: any) => void;
  style?: React.CSSProperties;
};

export const NkmMapboxMap = ({
  accessToken,
  zoomLevel,
  popup,
  fitBounds,
  styleUrl,
  pitch,
  bearing,
  useNorkartAttribution = true,
  interactive = true,
  onClick,
  onLayerClick,
  wmsLayers,
  layers,
  onMapLoaded,
  onMoveend,
  navigationControls,
  controls,
  events,
  recenterMap,
  fireMapResize,
  centerCoords,
  marker,
  fullscreenBtn,
  shouldFly,
  setLayerVisibility,
  onMapResized,
  initFullscreen,
  mapOptions,
  customIcons,
  clickableLayers,
  onMouseMove,
  style,
}: Props) => {
  mapboxgl.accessToken = accessToken;
  const [mapIsLoaded, setMapIsLoaded] = useState<boolean>(false);
  const [activeMarker, setActiveMarker] = useState<mapboxgl.Marker | undefined>(undefined);
  const [activeLayers, setActiveLayers] = useState<MapboxLayer[]>([]);
  const [activeWmsLayers, setActiveWmsLayers] = useState<WmsLayer[]>([]);
  const [fullscreenIsActive, setFullscreenIsActive] = useState<boolean>(initFullscreen ? true : false);
  const fullscreenIsActiveRef = useRef(fullscreenIsActive);
  fullscreenIsActiveRef.current = fullscreenIsActive;
  const eventsRef = React.useRef(events);
  eventsRef.current = events;

  const mapContainerRef = useRef(null);
  const map = useRef<Map>({} as Map);

  const wmsLayersRef = useRef(wmsLayers);
  wmsLayersRef.current = wmsLayers;
  const layerRef = useRef(layers);
  layerRef.current = layers;
  const markerRef = useRef(marker);
  markerRef.current = marker;
  const prevEvents = UsePrevious(events);

  useEffect(() => {
    const initZoomLevel = zoomLevel;
    const initCenterCoords: Coords | [number, number] | undefined = centerCoords;

    const initMapOptions = mapOptions || {};

    const options: MapboxOptions = {
      ...initMapOptions,
      fitBoundsOptions: fitBounds || (null as any), //Undefined crashes map, bug from mapbox
      container: mapContainerRef.current as any,
      style: styleUrl,
      center: initCenterCoords,
      zoom: initZoomLevel || (null as any), //Undefined crashes map, bug from mapbox
      pitch: pitch || (null as any), // pitch in degrees
      bearing: bearing || (null as any), //degrees}
    };
    map.current = new mapboxgl.Map(options);

    if (useNorkartAttribution) {
      enableNorkartAttribution(map.current);
    }

    setInteractiveMode();

    map.current.on('load', () => {
      //have to wait for style to finish loading before geojson can be added
      setMapIsLoaded(true);
      fitToBounds();

      const waiting = () => {
        if (!map.current.isStyleLoaded()) {
          setTimeout(waiting, 200);
        } else {
          initMapLayers();
          initCustomIcons();
          initClickableLayers();
        }
      };
      waiting();
    });

    if (navigationControls && navigationControls.show) {
      map.current.addControl(new mapboxgl.NavigationControl(), navigationControls.options);
    }
    if (fullscreenBtn && fullscreenBtn.showBtn)
      addFullscreenButton(fullscreenBtn, fullscreenIsActiveRef, setFullscreenIsActive, map.current);
    if (setLayerVisibility && setLayerVisibility.length) {
      setLayerVisibility.forEach((lay) => {
        map.current.setLayoutProperty(lay.layerid, 'visibility', lay.show ? 'visible' : 'none');
      });
    }
    controls && addControls(controls);
    addEvents(eventsRef.current);
  }, []);

  useEffect(() => {
    if (mapIsLoaded) {
      fitToBounds();
    }
  });
  useEffect(() => {
    if (mapIsLoaded) {
      drawLayers(layers, activeLayers, setActiveLayers, map.current);
    }
  }, [layers]);

  useEffect(() => {
    if (mapIsLoaded) {
      if (marker) {
        drawMarker(marker, activeMarker, setActiveMarker, map.current);
      } else {
        if (activeMarker) {
          activeMarker.remove();
          setActiveMarker(undefined);
        }
      }
    }
  }, [marker]);

  useEffect(() => {
    if (mapIsLoaded && popup) {
      addPopup(popup, map.current);
    }
  }, [popup]);

  useEffect(() => {
    if (recenterMap) {
      changeMapView();
    }
  }, [recenterMap, zoomLevel, pitch, bearing, centerCoords]);

  useEffect(() => {
    if (mapIsLoaded) {
      fitToBounds();
    }
  }, [fitBounds]);

  useEffect(() => {
    if (mapIsLoaded) {
      drawWmsLayers(wmsLayers, activeWmsLayers, setActiveWmsLayers, map.current, layers);
    }
  }, [wmsLayers]);

  useEffect(() => {
    setInteractiveMode();
  }, [interactive]);

  useEffect(() => {
    map.current.resize();
    fitToBounds();
    onMapResized && onMapResized();
  }, [fireMapResize]);

  useEffect(() => {
    if (setLayerVisibility && setLayerVisibility.length) {
      setLayerVisibility.forEach((lay) => {
        map.current.setLayoutProperty(lay.layerid, 'visibility', lay.show ? 'visible' : 'none');
      });
    }
  }, [setLayerVisibility]);

  useEffect(() => {
    if (onClick) {
      updateEvent(getEventObj('click', onClick));
    }
    if (onMoveend) {
      updateEvent(getEventObj('moveend', onMoveend));
    }
    if (onMapLoaded) {
      updateEvent(getEventObj('load', onMapLoaded));
    }
    if (onMouseMove) {
      updateEvent(getEventObj('mousemove', onMouseMove));
    }
  }, [onClick, onMoveend, onMapLoaded, onMouseMove]);

  useEffect(() => {
    updateEvents(events, prevEvents);
  }, [events]);

  const updateEvents = (events?: MapEvent[], prevEvents?: MapEvent[]) => {
    if (prevEvents) {
      prevEvents.forEach((event) => map.current.off(event.type, event.callback));
    }
    if (events) {
      events.forEach((event) => {
        map.current.on(event.type, event.callback);
      });
    }
  };

  const initClickableLayers = () => {
    if (clickableLayers) {
      clickableLayers.forEach((layer) => {
        // Change the cursor to a pointer when the mouse is over the places layer.
        map.current.on('mouseenter', layer.name, function () {
          map.current.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.current.on('mouseleave', layer.name, function () {
          map.current.getCanvas().style.cursor = '';
        });
        map.current.on('click', layer.name, function (e: any) {
          if (onLayerClick) {
            onLayerClick(e, layer.name);
          }
        });
      });
    }
  };

  const initCustomIcons = () => {
    if (customIcons) {
      customIcons.forEach((icon) => {
        if (!map.current.hasImage(icon.name)) {
          map.current.loadImage(icon.url, function (error, image) {
            if (error) throw error;
            image && map.current.addImage(icon.name, image);
          });
        }
      });
    }
  };

  const initMapLayers = () => {
    const marker = markerRef.current;
    const layers = layerRef.current;
    const wmsLayers = wmsLayersRef.current;

    if (marker) drawMarker(marker, activeMarker, setActiveMarker, map.current);
    if (wmsLayers) drawWmsLayers(wmsLayers, activeWmsLayers, setActiveWmsLayers, map.current, layers);
    if (layers) drawLayers(layers, activeLayers, setActiveLayers, map.current);
    if (popup) addPopup(popup, map.current);
  };

  const getMapboxBounds = (coordinates: any): {_ne: Coords; _sw: Coords} =>
    coordinates.reduce(
      (bounds: any, coord: any) => bounds.extend(coord),
      new mapboxgl.LngLatBounds(coordinates[0], coordinates[1])
    );

  const setInteractiveMode = () => {
    interactive ? enableInteractivity() : disableInteractivity();
  };

  const changeMapView = () => {
    if (fitBounds || !centerCoords) {
      return;
    }
    const options: FlyToOptions = {
      center: [centerCoords.lng, centerCoords.lat],
      zoom: zoomLevel,
      pitch: pitch,
      bearing: bearing,
    };

    if (!shouldFly) {
      map.current.jumpTo(options);
    } else {
      map.current.flyTo(options);
    }
  };

  const addControls = (controls: Control[]) => {
    if (controls) {
      controls.forEach((control) => {
        map.current.addControl(control.control, control.option);
      });
    }
  };
  const enableInteractivity = () => {
    map.current.scrollZoom.enable();
    map.current.dragPan.enable();
    map.current.dragRotate.enable();
    map.current.boxZoom.enable();
    map.current.keyboard.enable();
    map.current.doubleClickZoom.enable();
    map.current.touchZoomRotate.enable();
  };
  const disableInteractivity = () => {
    map.current.scrollZoom.disable();
    map.current.dragPan.disable();
    map.current.boxZoom.disable();
    map.current.dragRotate.disable();
    map.current.keyboard.disable();
    map.current.doubleClickZoom.disable();
    map.current.touchZoomRotate.disable();
  };
  const addEvents = (events?: MapEvent[]) => {
    if (events) {
      events.forEach((event) => map.current.on(event.type, event.callback));
    }
  };

  const updateEvent = (event: MapEvent) => {
    const old = eventList[event.type as keyof EventList].pop();

    if (old) map.current.off(old.type, old.callback);

    eventList[event.type as keyof EventList].push(event);
    map.current.on(event.type, event.callback);
  };

  const getEventObj = (type: string, callback: (event: mapClickEvent) => void): MapEvent => {
    return {type, callback};
  };
  const fitToBounds = () => {
    if (fitBounds) {
      const options: FitBoundsOptions = fitBounds.options ? fitBounds.options : {linear: false, padding: undefined};
      const coordinates = fitBounds.bounds;

      if (isArrayOfArray(coordinates)) {
        const bounds: any = getMapboxBounds(coordinates);
        map.current.fitBounds(bounds, options);
      } else {
        map.current.fitBounds(coordinates, options);
      }
    }
  };

  const isArrayOfArray = (array: any) => {
    if (Array.isArray(array) && Array.isArray(array[0])) {
      return true;
    }

    return false;
  };

  (window as any).mmap = map;
  return (
    <div className='nkm-mapbox-map' style={style}>
      <div id='map' ref={mapContainerRef} />
    </div>
  );
};
