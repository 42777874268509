import * as React from 'react';
import {MenuItem, styled} from '@mui/material';
import {ChevronRight} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {ToiBox, ToiLogo} from '@norkart/toi-deprecated-components';
import {Kunde} from '../../../hooks/kunder/types';

interface CustomerSelectorItemProps {
    customer: Kunde;
}

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    fontWeight: 500,
    height: '58px',
    marginBottom: '10px',
    padding: '5px 15px',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    '&:hover': {
        borderColor: theme.palette.secondary.main,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:focus-visible': {
        borderColor: theme.palette.secondary.main,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
}));

const StartIcon = styled('img')({
    width: '26,66px',
    height: '32px',
    alignItems: 'flex-start',
    marginLeft: -4,
    marginRight: 8,
});

const EndIcon = styled(ChevronRight)({marginLeft: 'auto'});

function CustomerSelectorItem({customer}: CustomerSelectorItemProps) {
    const navigate = useNavigate();
    function handleClick() {
        navigate(`/${customer.id}`);
    }
    return (
        <StyledMenuItem tabIndex={0} onClick={handleClick}>
            <ToiBox
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {customer.crest != null ? (
                    <StartIcon src={customer.crest} alt='kommunevåpen' />
                ) : (
                    <ToiLogo
                        variant='icon'
                        width={23}
                        sx={{ml: -1, mr: 4, pt: 2}}
                    />
                )}

                {customer.navn}
            </ToiBox>
            <EndIcon />
        </StyledMenuItem>
    );
}

export default CustomerSelectorItem;
