import {useQuery} from 'react-query';
import {fetchMidlertidigForbudForPlan} from '../../services/api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {MidlertidigForbud} from './types';

export default function useMidlertidigForbud(selectedPlan?: number) {
    const kundeId = useSelectedKundeId();
    const query = useQuery<MidlertidigForbud[]>(
        ['midlertidigforbud', {kundeId, selectedPlan}],
        () =>
            fetchMidlertidigForbudForPlan(selectedPlan!).then(
                (res) => res.data
            ),
        {
            enabled: !!selectedPlan,
        }
    );

    return {
        ...query,
        data: query?.data || [],
    };
}
