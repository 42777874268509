import * as React from 'react';
import {connect} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog} from '../../../store/dialog/actions';

export interface RemoveDocumentDialogProps extends ConnectedReduxProps {
    open: boolean;
    onOk: () => void;
}

function RemoveDocumentDialog({
    open,
    onOk,
    dispatch,
}: RemoveDocumentDialogProps) {
    function handleClose() {
        hideDialog(dispatch);
    }

    function handleOk() {
        hideDialog(dispatch).then(() => onOk());
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='confirm-remove-doc-dialog-title'
        >
            <DialogTitle id='confirm-remove-doc-dialog-title'>
                Vil du virkelig fjerne dokumentet fra listen?
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>Nei</Button>
                <Button onClick={handleOk}>Ja</Button>
            </DialogActions>
        </Dialog>
    );
}

export default connect()(RemoveDocumentDialog);
