import {Typography} from '@mui/material';
import * as React from 'react';
import {orderBy} from 'lodash-es';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useFrontendSettings from '../../../hooks/settings/useFrontendSettings';
import useCan from '../../../utils/auth/useCan';
import {FrontendSettingsTable} from './FrontendSettingsTable';

export default function FrontendSettings() {
    const can = useCan();
    const innstillinger = useFrontendSettings();
    const isLoading = innstillinger.isLoading;
    if (isLoading)
        return <LoadingSpinner circularProgressProps={{color: 'secondary'}} />;
    if (can.edit)
        return (
            <div>
                <Typography
                    variant='h4'
                    sx={{paddingBottom: 2, paddingLeft: '15px'}}
                >
                    Innstillinger
                </Typography>
                <Typography sx={{paddingBottom: 5, paddingLeft: '15px'}}>
                    Her kan du endre innstillinger som påvirker din kommunes
                    side hos arealplaner.no.
                </Typography>
                <FrontendSettingsTable
                    innstillinger={orderBy(
                        innstillinger.kundeInstillinger,
                        'beskrivelse'
                    )}
                />
            </div>
        );
    return (
        <Typography>Du har ikke rettigheter til å se denne siden.</Typography>
    );
}
