import {merge} from 'lodash-es';
import {Theme, ThemeProvider, PaletteOptions} from '@mui/material';
import {createContext, useContext} from 'react';
import {DEFAULT_TYPOGRAPHY_VARIANT, DefaultTypographyVariantOption, ToiThemeOptions, createToiTheme} from './toiTheme';
import {toiPalette} from './toiPalette';
import {useToiSpacing} from './useToiSpacing';

type Props = {
  children: React.ReactNode;
  themeOverrides?: ToiThemeOptions;
  theme?: Partial<Theme> | ((outerTheme: Theme) => Theme);
};

export const ToiThemeContext = createContext({} as ToiThemeOptions);

/**
 * Sets the theme for the Toi components.
 * Takes in a ToiThemeOptions object to override the default theme.
 * Merges the default theme with the themeOverrides.
 * @param themeOverrides - The theme overrides to be applied to the theme
 */
export const ToiThemeProviderAdvanced = ({children, themeOverrides}: Props) => {
  const currentOverrides = useContext(ToiThemeContext);
  const currentTheme = createToiTheme(merge(currentOverrides, themeOverrides));

  return (
    <ToiThemeContext.Provider value={themeOverrides ?? {}}>
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </ToiThemeContext.Provider>
  );
};

type ToiThemeProviderProps = {
  children: React.ReactNode;
  mode?: 'dark' | 'light';
  primaryColor?: PaletteOptions['primary'];
  defaultTypographyVariant?: DefaultTypographyVariantOption;
  theme?: Partial<Theme> | ((outerTheme: Theme) => Theme);
};

/**
 * Sets the theme for the Toi components, with the option to override certain theme options.
 * Wrapper for ToiThemeProviderAdvanced.
 * @param mode - 'dark' | 'light'
 * @param primaryColor - The primary color to be used in the theme
 * @param defaultTypographyVariant - 'body1' | 'body2' | 'body3'
 */
export const ToiThemeProvider = ({children, mode, primaryColor, defaultTypographyVariant}: ToiThemeProviderProps) => {
  const spacing = useToiSpacing();

  return (
    <ToiThemeProviderAdvanced
      themeOverrides={{
        // We need to spread primary color to avoid mutating it. Otherwise we get buggy behavior like inheriting from siblings.
        // eslint-disable-next-line no-constant-binary-expression
        palette: {mode: mode, primary: {...primaryColor} || toiPalette.primary},
        components: {
          ToiTypography: {
            defaultProps: {variant: defaultTypographyVariant || DEFAULT_TYPOGRAPHY_VARIANT},
          },
        },
        spacing,
      }}
    >
      {children}
    </ToiThemeProviderAdvanced>
  );
};

export default ToiThemeProvider;
