export type Token = {
    id: number;
    tokenString: string;
    description: string;
};

export enum TokenType {
    WMS = 'wms',
    API = 'api',
}
