import {JournalDocument} from '../../hooks/journaler/types';

export interface FileImportState {
    files: FileData[];
    journalDocumentSelection: JournalDocument[];
    errors: FileImportErrors | null;
}

export type FileData = {
    id: string;
    dokumentTypeId: number;
    dokumentNavn: string;
    dokumentTittel?: string;
    beskrivelse?: string;
    dokumentDato?: Date | null;
    horingsdokument: boolean;
    bestemmelser: boolean;
    plandokument: boolean;
    url?: string;
    filetype?: string;
    selected: boolean;
    overwrite: boolean;
    tilgangId: number;
    exists?: boolean;
};

export type FileImportErrors = Record<string, FileImportRowErrors>;

export type FileImportRowErrors = Record<string, string>;

export enum FileImportActionTypes {
    RESET_FILE_IMPORT = '@@planregister/fileimport/RESET',
    ADD_FILE = '@@planregister/fileimport/ADD_FILE',
    REMOVE_FILE = '@@planregister/fileimport/REMOVE_FILE',
    UPDATE_FILE = '@@planregister/fileimport/UPDATE_FILE',
    UPDATE_ERRORS = '@@planregister/fileimport/UPDATE_ERRORS',
    RESET_ERROR_FOR_FIELD = '@@planregister/fileimport/RESET_ERROR_FOR_FIELD',
    RESET_ERROR_FOR_FILE = '@@planregister/fileimport/RESET_ERROR_FOR_FILE',

    ADD_JOURNAL_DOCUMENT = '@@planregister/fileimport/ADD_JOURNAL_DOCUMENT',
    REMOVE_JOURNAL_DOCUMENT = '@@planregister/fileimport/REMOVE_JOURNAL_DOCUMENT',
}
