import {epsg} from './const';
import {combine} from './regex';
import regexItemTypes from './regex-item-types';
import {coordinateSystem} from './coordinate-systems';
import {transform} from './transformation-sync';

const errorMissingCoordinateSystem =
    'Mangler koordinatsystem, for eksempel UTM32';

export class CoordinateParser {
    regexPartList;
    coordSystem;
    coordParts;
    coordLetter;
    coordSystemInfoHasBeenGiven;

    constructor(regexPartList) {
        this.reset();
        this.regexPartList = regexPartList;
    }
    reset() {
        this.coordSystem = undefined;
        this.coordParts = [];
        this.coordLetter = [];
    }

    validate(input) {
        const completeRegex = combine(
            this.regexPartList.map((regexPart) => {
                return regexPart.regex;
            }),
            true
        );
        return completeRegex.test(input);
    }
    //: {north, east , warning?}
    parseAndTransformToWgs(input) {
        this.reset();
        // PARSE
        const regexPartList = this.regexPartList;
        for (let i = 0; i < regexPartList.length; i++) {
            const currentRegex = regexPartList[i].regex;
            const currentRegexType = regexPartList[i].type;

            const prepare = regexPartList[i].prepare;

            let value = input.match(currentRegex)[0];
            input = input.replace(value, '');

            if (value) {
                value = prepare(value);
            } else {
                continue; // handle optional parts
            }

            const types = regexItemTypes;
            switch (currentRegexType) {
                case types.COORDINATE_SYSTEM:
                    this.coordSystem = value;
                    break;
                case types.NO_FUNCTION:
                    // do nothing
                    break;
                case types.NUMBER:
                    this.coordParts.push(parseFloat(value));
                    break;
                case types.NUMBER_COMPOSITE:
                    this.coordParts.push(value);
                    this.coordSystem =
                        this.coordSystem || coordinateSystem.defaultEpsg;
                    break;
                case types.LETTER:
                    this.coordLetter.push(value);
                    break;
            }
        }

        // TRANSFORM
        if (this.coordLetter.length > 0) {
            let mustSwapNumbers = false;

            if (this.coordLetter[0] === 'E' || this.coordLetter[0] === 'W') {
                mustSwapNumbers = true; // must swap numbers since the first was east
            }

            // swap numbers and letters, since the first letter must be north
            if (mustSwapNumbers) {
                const temp = this.coordParts[1];
                this.coordParts[1] = this.coordParts[0];
                this.coordParts[0] = temp;

                const temp1 = this.coordLetter[1];
                this.coordLetter[1] = this.coordLetter[0];
                this.coordLetter[0] = temp1;
            }

            if (this.coordLetter[0] === 'S') {
                this.coordParts[0] = this.coordParts[0] * -1;
            }
            if (this.coordLetter[1] === 'W') {
                this.coordParts[1] = this.coordParts[1] * -1;
            }
        }

        const returnObject = {};

        // Use UTM33 as default if the coordinate is not suitable for wgs84 (lat > -+90)
        if (this.coordSystem === undefined) {
            if (Math.abs(this.coordParts[0]) > 90) {
                this.coordSystem = coordinateSystem.defaultUtm;
                returnObject.warning = errorMissingCoordinateSystem; // search does not show coordinates when warning
            } else {
                this.coordSystem = coordinateSystem.defaultEpsg;
            }
        }

        const transformedCoord = transform(
            this.coordParts[0],
            this.coordParts[1],
            this.coordSystem,
            epsg.LAT_LNG
        );
        returnObject.north = parseFloat(transformedCoord.north);
        returnObject.east = parseFloat(transformedCoord.east);

        return returnObject;
    }
    parseAndTransformToUTM(input) {
        this.reset();
        // PARSE
        const regexPartList = this.regexPartList;
        for (let i = 0; i < regexPartList.length; i++) {
            const currentRegex = regexPartList[i].regex;
            const currentRegexType = regexPartList[i].type;

            const prepare = regexPartList[i].prepare;

            let value = input.match(currentRegex)[0];
            input = input.replace(value, '');

            if (value) {
                value = prepare(value);
            } else {
                continue; // handle optional parts
            }

            const types = regexItemTypes;
            switch (currentRegexType) {
                case types.COORDINATE_SYSTEM:
                    this.coordSystem = value;
                    break;
                case types.NO_FUNCTION:
                    // do nothing
                    break;
                case types.NUMBER:
                    this.coordParts.push(parseFloat(value));
                    break;
                case types.NUMBER_COMPOSITE:
                    this.coordParts.push(value);
                    this.coordSystem =
                        this.coordSystem || coordinateSystem.defaultEpsg;
                    break;
                case types.LETTER:
                    this.coordLetter.push(value);
                    break;
            }
        }

        // TRANSFORM
        if (this.coordLetter.length > 0) {
            let mustSwapNumbers = false;

            if (this.coordLetter[0] === 'E' || this.coordLetter[0] === 'W') {
                mustSwapNumbers = true; // must swap numbers since the first was east
            }

            // swap numbers and letters, since the first letter must be north
            if (mustSwapNumbers) {
                const temp = this.coordParts[1];
                this.coordParts[1] = this.coordParts[0];
                this.coordParts[0] = temp;

                const temp1 = this.coordLetter[1];
                this.coordLetter[1] = this.coordLetter[0];
                this.coordLetter[0] = temp1;
            }

            if (this.coordLetter[0] === 'S') {
                this.coordParts[0] = this.coordParts[0] * -1;
            }
            if (this.coordLetter[1] === 'W') {
                this.coordParts[1] = this.coordParts[1] * -1;
            }
        }

        const returnObject = {};

        // Use UTM33 as default if the coordinate is not suitable for wgs84 (lat > -+90)
        if (this.coordSystem === undefined) {
            if (Math.abs(this.coordParts[0]) > 90) {
                this.coordSystem = coordinateSystem.defaultUtm;
                returnObject.warning = errorMissingCoordinateSystem; // search does not show coordinates when warning
            } else {
                this.coordSystem = coordinateSystem.defaultEpsg;
            }
        }

        const transformedCoord = transform(
            this.coordParts[0],
            this.coordParts[1],
            this.coordSystem,
            epsg.UTM33
        );
        returnObject.north = parseFloat(transformedCoord.north);
        returnObject.east = parseFloat(transformedCoord.east);

        return returnObject;
    }
}
