import {MsalProvider} from '@azure/msal-react';
import {ReactNode, useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {AuthData} from '../../types';
import {ClientSideNavigation} from '../Navigation/ClientSideNavigation';
import {NkAuthContextProvider} from './NkAuthContextProvider';

interface NkAuthProviderProps<TConfig> extends NkSpecificAuthProviderProps<TConfig> {
  getAuthData: (configuration: TConfig) => Promise<AuthData>;
}

export interface NkSpecificAuthProviderProps<TConfig> extends AuthProviderBaseProps {
  configuration: TConfig;
}

export interface AuthProviderBaseProps {
  fallbackComponent?: ReactNode;
  children?: ReactNode;
  withBrowserRouter?: boolean;
}

interface NkAuthProviderBaseProps extends AuthProviderBaseProps {
  authData: AuthData;
}

export const NkAuthProvider = <TConfig,>({
  configuration,
  fallbackComponent,
  getAuthData,
  ...props
}: NkAuthProviderProps<TConfig>) => {
  const [authData, setAuthData] = useState<AuthData | undefined>(undefined);

  useEffect(() => {
    getAuthData(configuration)
      .then(setAuthData)
      .catch((e) => {
        throw new Error('Something went wrong while fetching authData');
      });
  }, [configuration, getAuthData]);

  if (!authData) {
    return fallbackComponent ?? 'Loading...';
  }

  return <NkAuthProviderBase authData={authData} {...props} />;
};

const NkAuthProviderBase = (props: NkAuthProviderBaseProps) =>
  props.withBrowserRouter ? <NkAuthProviderWithBrowserRouter {...props} /> : <NkAuthProviderInner {...props} />;

const NkAuthProviderInner = ({authData, children}: NkAuthProviderBaseProps) => (
  <MsalProvider instance={authData.msalApp}>
    <NkAuthContextProvider authData={authData}>{children}</NkAuthContextProvider>
  </MsalProvider>
);

//Uses React router (client side routing)
const NkAuthProviderWithBrowserRouter = (props: NkAuthProviderBaseProps) => (
  <BrowserRouter>
    <ClientSideNavigation msalApp={props.authData.msalApp}>
      <NkAuthProviderInner {...props} />
    </ClientSideNavigation>
  </BrowserRouter>
);
