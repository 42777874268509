import {CSSObject, Theme} from '@mui/material';
import {ToiAppBarLoginProps} from './ToiAppBarLogin';

const defaultStyle = {};

export function toiAppBarLoginStyle({
  theme,
}: // ownProp1,
// ownPropWithDefault = 'default'
ToiAppBarLoginProps & {theme: Theme}): CSSObject {
  return {};
  // return merge(defaultStyle, someInferredStyle);    <--- Use this to merge style objects
}
