import * as React from 'react';
import {Select, MenuItem, InputBase, SelectProps, styled} from '@mui/material';
import {ChevronDownIcon} from '@norkart/toi-deprecated-icons';

const StyledSelectWithStatus = styled(Select)(({theme, disabled, error}) => ({
    '.MuiSelect-icon': {top: '5px', bottom: '5px'},
    ...(error && {outline: `1px solid ${theme.palette.error.main}`}),
    ...(disabled && {
        outline: '1px solid lightgrey',
        '& .MuiSelect-icon': {
            opacity: '50%',
        },
    }),
}));

const StyledInput = styled(InputBase)(({theme}) => ({
    '& .MuiInputBase-input': {
        minWidth: '80px',
        outline: '1px solid grey',
        backgroundColor: theme.palette.background.default,
        fontSize: 16,
        paddingLeft: '10px',
        borderRadius: 0,
        '&:focus': {
            outline: 'black dashed 1px',
            borderRadius: 0,
            color: theme.palette.secondary.dark,
        },
        '&:selected': {
            color: theme.palette.secondary.dark,
        },
        '&:hover': {
            color: theme.palette.secondary.dark,
        },
    },
}));

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    minHeight: '36px!important',
    width: '99%',
    margin: 'auto',
    '&.MuiListItem-root.Mui-selected': {
        backgroundColor: 'transparent',
        fontWeight: '500',
    },
    '&:hover': {
        color: theme.palette.secondary.dark,
        backgroundColor: 'white',
    },
    '&:selected': {
        backgroundColor: 'transparent',
    },
    '&:focus': {
        outline: 'black dashed 1px',
        backgroundColor: 'white',
        color: theme.palette.secondary.dark,
        '& .MuiListItemIcon-root, & .MuiListItemText-selected': {
            color: theme.palette.common.white,
        },
    },
}));

type Props = {
    nullOption?: boolean;
    nullOptionDisplayText?: string;
    displayId?: boolean;
    error?: boolean;
    options;
    keyCol;
    valueCol;
} & SelectProps;

export function StyledSelect({
    keyCol,
    valueCol,
    options,
    displayId,
    nullOption,
    nullOptionDisplayText,
    error,
    disabled,
    ...selectProps
}: Props) {
    return (
        <StyledSelectWithStatus
            input={<StyledInput />}
            MenuProps={{
                style: {maxHeight: 400, marginTop: 5},
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                disableScrollLock: true,
            }}
            style={{padding: 0}}
            IconComponent={(props) => (
                <div {...props}>
                    <ChevronDownIcon size={1} />
                </div>
            )}
            disabled={disabled}
            error={error}
            displayEmpty={nullOption}
            {...selectProps}
        >
            {nullOption && nullOptionDisplayText ? (
                <StyledMenuItem value=''>
                    {nullOptionDisplayText}
                </StyledMenuItem>
            ) : (
                <StyledMenuItem value='' />
            )}
            {options.map((option) => {
                const key = option[keyCol];
                const value = option[valueCol];
                return (
                    <StyledMenuItem key={key} value={key}>
                        {displayId ? `${key} - ${value}` : value}
                    </StyledMenuItem>
                );
            })}
        </StyledSelectWithStatus>
    );
}
export default StyledSelect;
