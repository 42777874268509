//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppDownloadIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M22 20H2V22H22V20Z' style={props.secondaryStyle} />
            <path d='M10.9965 12.6681L8.19791 9.8695L6.78369 11.2837L12 16.5L17.2162 11.2837L15.802 9.8695L12.9965 12.675L12.9965 2.5H10.9965L10.9965 12.6681Z' style={props.primaryStyle} />
        </g>
    ),
    'AppDownloadIcon' // aria-label
);
