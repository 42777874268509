import _ from 'lodash-es';
import {useQuery} from 'react-query';
import {fetchKunder} from '../../services/api';
import {getMunicipalities} from '../../services/apiKommuneinfo';
import {Kunde} from './types';

const getKunder = async () => {
    const res = await fetchKunder();

    // Add crest to Kunde
    const kommuner = await getMunicipalities();
    const muniById = _.keyBy(kommuner.data.KommuneList, 'KommuneNumber');
    const customers = res.data.map((customer: any) => {
        const komnr = customer && customer.id.slice(-4);
        let crest: string | null = null;
        if (!isNaN(Number(komnr)))
            crest = muniById[komnr] ? muniById[komnr].KommuneCrest : null;
        return {...customer, crest};
    });
    return customers;
};

type KundeFilter = {
    searchTerm?: string;
    showHidden?: boolean;
};

// Kommuner som er en del av reform som skal vises selv om de er inaktive...
const kommunereformHack = [
    '3024',
    '3047',
    '3816',
    '3817',
    '3821',
    '3822',
    '3824',
    '3825',
    '5402',
    '5421',
    '3025',
    '9040',
    '3815',
    '5414',
    '5415',
    '5417',
    '5422',
    '5424',
    '5426',
    '5427',
    '5428',
    '5429',
    '5403',
    '5406',
    '3001',
    '3002',
    '3003',
    '3017',
    '3018',
    '3020',
    '3023',
    '3022',
    '3019',
    '3021',
    '3028',
    '3029',
    '3805',
    '3814',
    '5437',
    '5430',
    '5436',
    '3054',
];
const year = new Date().getFullYear();

export default function useKunder(searchOptions?: KundeFilter) {
    const query = useQuery<Kunde[]>(['kunder'], getKunder, {
        staleTime: 1000 * 60 * 60 * 1, // 1h
        cacheTime: 1000 * 60 * 60 * 2, //  2h
    });

    let filteredData = query?.data;

    if (searchOptions?.searchTerm) {
        filteredData = filteredData?.filter((kunde) =>
            searchOptions?.searchTerm
                ? kunde.navn
                      .toLowerCase()
                      .includes(searchOptions.searchTerm.toLowerCase())
                : true
        );
    }

    if (!searchOptions?.showHidden) {
        filteredData = filteredData?.filter(
            (kunde) =>
                kunde.status == 0 ||
                (kommunereformHack.includes(kunde.kommunenummer) &&
                    year == 2023)
        );
    }

    return {
        ...query,
        data: filteredData || [],
    };
}
