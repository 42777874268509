import * as React from 'react';
import {setTitle} from '../../../../utils/setTitle';

export default function Kostra() {
    setTitle('Rapportert > Kostra');
    return (
        <div>
            <p>Comming soon............</p>
        </div>
    );
}
