import {PaletteMode, useTheme} from '@mui/material';
import {forwardRef, ReactNode} from 'react';
import {getIconStyles} from './getIconStyles';

type iconSize = 'small' | 'default' | 'large' | 'xl' | `${number}${'px' | 'em' | 'rem'}` | number;

export interface IconProps {
  size?: iconSize;
  mode?: PaletteMode;
  blackWhite?: boolean;
  singleColor?: string;
  primaryStyle?: React.CSSProperties;
  secondaryStyle?: React.CSSProperties;
  tertiaryStyle?: React.CSSProperties;
  className?: string;
  'aria-label'?: string;
  title?: string;
  desc?: string;
}

export interface MetaProps {
  pathFunc: (props: PathProps) => ReactNode;
}

export interface PathProps {
  primaryStyle: React.CSSProperties;
  secondaryStyle: React.CSSProperties;
  tertiaryStyle: React.CSSProperties;
  title?: string;
  desc?: string;
}

export interface StaticProps {
  'aria-label': string;
}

const getSizeForSVG = (size: iconSize): string | number => {
  if (typeof size === 'number') {
    return size > 0 ? size * 24 : 24; // Prevents negative icon size
  }

  switch (size) {
    case 'small':
      return '16px';
    case 'default':
      return '24px';
    case 'large':
      return '32px';
    case 'xl':
      return '48px';
    default:
      return size;
  }
};

const SvgIcon = forwardRef((props: IconProps & MetaProps & StaticProps, ref: any) => {
  const palette = useTheme().palette;
  const {size = 'default'} = props;
  const {primaryStyle, secondaryStyle, tertiaryStyle} = getIconStyles(palette, props);
  const sizeForSVG = getSizeForSVG(size);

  return (
    <svg
      className={props.className}
      ref={ref}
      width={sizeForSVG}
      height={sizeForSVG}
      viewBox='0 0 24 24'
      preserveAspectRatio='xMidYMid meet'
      aria-label={props['aria-label']}
    >
      {props.pathFunc({
        primaryStyle,
        secondaryStyle,
        tertiaryStyle,
      })}
    </svg>
  );
});

export default SvgIcon;
