import {
    Accordion,
    AccordionDetails,
    Box,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import * as React from 'react';
import {useKodelisterBehandlingstyper} from '../../../../hooks/kodelister/useKodelisterBehandlingstyper';
import useMeta from '../../../../hooks/meta/useMeta';
import {setTitle} from '../../../../utils/setTitle';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import SaksbehandlingstidReport from './SaksbehandlingstidReport';
import {SaksbehandlingstidFilter} from './SaksbehandlingstidFilter';

export type SaksbehandlingstidQueryVariables = {
    filter?: {and: any[]} | undefined;
    inputParams?: any;
};

const calculateYearMonthDayAverage = (days: number) => {
    const totalYears = days / 365;
    const remainingDays = days % 365;
    const totalMonths = remainingDays / 30;
    const totalDays = remainingDays % 30;

    return {
        years: Math.floor(totalYears),
        months: Math.floor(totalMonths),
        days: Math.round(totalDays),
    };
};

export default function Saksbehandlingstid() {
    setTitle('Rapporter > Saksbehandlingstid');
    const initialVariables: SaksbehandlingstidQueryVariables = {};
    const meta = useMeta();
    const behandlingstyper = useKodelisterBehandlingstyper();
    const [filterValues, setFilterValues] = React.useState<any | undefined>(
        undefined
    );
    const [inputParamsValues, setInputParamsValues] = React.useState<
        any | undefined
    >(undefined);
    const [vars, setVars] =
        React.useState<SaksbehandlingstidQueryVariables>(initialVariables);
    const [averageDays, setAverageDays] = React.useState<number | undefined>(
        undefined
    );
    const yearMonthDayAverage = averageDays
        ? calculateYearMonthDayAverage(averageDays)
        : undefined;

    //Oppdaterer variabler til graphql request når "filterValues" oppdateres i "FilterBehandlinger" komponent
    React.useMemo(() => {
        const newVars: SaksbehandlingstidQueryVariables = {
            ...vars,
            filter: filterValues,
            inputParams: inputParamsValues,
        };
        setVars((prevValue) => {
            if (newVars === initialVariables) return prevValue;
            else if (!newVars.filter && !newVars.inputParams) {
                return initialVariables;
            } else {
                return newVars;
            }
        });
    }, [filterValues, inputParamsValues]);

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <Grid direction='column' container={true}>
            <Typography
                variant='h2'
                fontSize={48}
                sx={{marginTop: 20, marginBottom: 16}}
            >
                Saksbehandlingstid
            </Typography>
            <Grid direction={'row'} container={true}>
                <Grid item={true} xs={8}>
                    <Accordion
                        disableGutters={true}
                        defaultExpanded={true}
                        variant='outlined'
                        sx={{
                            boxShadow: 0,
                            width: 'fit-content',
                            '&.MuiAccordion-root': {
                                borderTopLeftRadius: '0%',
                                borderTopRightRadius: '0%',
                            },
                        }}
                    >
                        <AccordionDetails
                            sx={{
                                paddingBottom: 10,
                                backgroundColor: '#FFFFFF',
                            }}
                        >
                            <Grid
                                sx={{
                                    width: 'fit-content',
                                    padding: '0px 25px 20px 25px',
                                }}
                                direction='column'
                                container={true}
                            >
                                <Grid item={true}>
                                    <SaksbehandlingstidFilter
                                        setInputParamsValues={
                                            setInputParamsValues
                                        }
                                        setFilterValues={setFilterValues}
                                        clearFilter={
                                            filterValues ? false : true
                                        }
                                        behandlingstyperFra={
                                            behandlingstyper.data
                                                ? behandlingstyper.data
                                                : []
                                        }
                                        behandlingstyperTil={
                                            behandlingstyper.data
                                                ? behandlingstyper.data
                                                : []
                                        }
                                        plantyper={meta.data.plantyper}
                                    />
                                </Grid>
                                <Grid
                                    justifyContent={'flex-end'}
                                    display={'flex'}
                                    xs={12}
                                    item={true}
                                >
                                    <Button
                                        variant='text'
                                        color='secondary'
                                        onClick={() =>
                                            setFilterValues(undefined)
                                        }
                                        sx={{
                                            display: filterValues
                                                ? 'flex'
                                                : 'none',
                                            height: 40,
                                            marginTop: 10,
                                        }}
                                    >
                                        Nullstill filter
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid
                    item={true}
                    display='flex'
                    justifyContent='flex-end'
                    pr='10px'
                    pl='5px'
                    xs={4}
                >
                    <Box
                        visibility={averageDays ? 'visible' : 'hidden'}
                        boxSizing={'border-box'}
                        width={'fit-content'}
                        p={'26px'}
                        display={'flex'}
                        flexDirection='column'
                        sx={{
                            border: '1px solid #DCDCDC',
                            height: 'fit-content',
                            backgroundColor: '#F3F5F0',
                        }}
                    >
                        <Typography
                            fontWeight={600}
                            color='#005600'
                            lineHeight='120%'
                            fontSize={'18px'}
                            textTransform='uppercase'
                        >
                            Gjennomsnitt antall dager
                        </Typography>
                        <Typography
                            fontWeight={400}
                            color='#005600'
                            lineHeight='140%'
                            fontSize={'16px'}
                            textTransform='lowercase'
                        >
                            Gjennomsnitt av antall dager mellom behandlingene
                        </Typography>
                        <Typography
                            pt='16px'
                            fontWeight={500}
                            color='#005600'
                            lineHeight='38px'
                            fontSize={'32px'}
                            textTransform='lowercase'
                        >
                            {averageDays && Math.round(averageDays)} dager
                        </Typography>
                        {yearMonthDayAverage && (
                            <Typography
                                fontWeight={400}
                                color='#005600'
                                lineHeight='140%'
                                fontSize={'16px'}
                                textTransform='lowercase'
                            >
                                {yearMonthDayAverage.years} år,{' '}
                                {yearMonthDayAverage.months} måneder,{' '}
                                {yearMonthDayAverage.days} dager
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Grid xs={12} item={true}>
                <SaksbehandlingstidReport
                    setAverageDays={setAverageDays}
                    kundeId={meta.kundeId}
                    kommunenummer={meta.komnr}
                    variables={vars}
                />
            </Grid>
        </Grid>
    );
}
