//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppFullscreenIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path fillRule='evenodd' clipRule='evenodd' d='M4 4H10V6H6V10H4V4ZM4 20V14H6V18H10V20H4ZM14 18V20H20V14H18V18H14Z' style={props.secondaryStyle} />
            <path d='M20 4H14V6H18V10H20V4Z' style={props.primaryStyle} />
        </g>
    ),
    'AppFullscreenIcon' // aria-label
);
