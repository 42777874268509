import * as React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';

export interface LoadingSpinnerProps {
    text?: string;
    circularProgressProps?: CircularProgressProps;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
    text,
    circularProgressProps,
}: LoadingSpinnerProps) => {
    return (
        <div style={{margin: '0 auto', marginTop: 50, textAlign: 'center'}}>
            <CircularProgress {...circularProgressProps} />
            {text ? <p>{text}</p> : <p>Henter informasjon...</p>}
        </div>
    );
};

export default LoadingSpinner;
