import * as React from 'react';
import {createRoot} from 'react-dom/client';
import Main from './main';
import * as serviceWorker from './registerServiceWorker';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Main />);

serviceWorker.unregister();
