import * as React from 'react';
import {Dialog, DialogTitle, DialogActions, Button} from '@mui/material';
import {hideDialog, showDialog} from '../../../store/dialog/actions';
import {ConnectedReduxProps} from '../../../store';
import {Dokument} from '../../../hooks/dokumenter/types';
import useDeleteDokument from '../../../hooks/dokumenter/useDeleteDokument';

export interface DeleteDocumentDialogProps extends ConnectedReduxProps {
    dokument: Dokument;
    open: boolean;
}

const DeleteDocumentDialog = ({
    dokument,
    dispatch,
    open,
}: DeleteDocumentDialogProps) => {
    const {mutate: deleteDokument} = useDeleteDokument();
    const handleClose = () => {
        hideDialog(dispatch).then(() =>
            dispatch(
                showDialog({
                    dialogType: 'EDIT_DOKUMENT',
                    dialogProps: {dokument},
                })
            )
        );
    };

    const handleOk = () => {
        deleteDokument(dokument.id);
        hideDialog(dispatch);
    };
    return (
        <Dialog open={open} aria-labelledby='confirm-dialog-title'>
            <DialogTitle id='confirm-dialog-title'>
                Dokumentet vil slettes helt fra planregisteret, og du kan ikke
                angre. Vil du virkelig slette dokumentet?
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>Nei</Button>
                <Button onClick={handleOk}>Ja</Button>
            </DialogActions>
        </Dialog>
    );
};
export default DeleteDocumentDialog;
