import * as React from 'react';
import MarkDown from 'react-markdown';

type Props = {
    helpText: string;
    style?: React.CSSProperties;
};

const HelpText = ({helpText, style}: Props) => {
    return (
        <div style={style}>
            <div style={{maxWidth: '45vw'}}>
                <MarkDown>{helpText}</MarkDown>
            </div>
        </div>
    );
};
export default HelpText;
