import {Theme} from '@mui/material';
import {merge} from 'lodash-es';

export const toiMenuItemStyle = (theme: Theme) => {
  const defaultStyle = {
    display: 'flex',
    flexDirection: 'row',
    height: '40px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.black[10],
    },
    '&:active': {
      backgroundColor: theme.palette.primary[100],
      color: theme.palette.secondary.main,
    },
  };

  return merge({}, defaultStyle);
};
