import {Avatar, AvatarTypeMap, AvatarProps as MuiAvatarProps, styled} from '@mui/material';
import React, {forwardRef} from 'react';
import {ToiAvatarColors, toiAvatarStyle} from './toiAvatarStyle';

export type AvatarSize = 'small' | 'medium' | 'large' | 'xl' | 'xxl';

type OwnProps = {
  size?: AvatarSize;
  initials?: string;
  fullname?: string;
  colorOverride?: ToiAvatarColors; // Overrides colors set from initials
};

type DefaultComponent = AvatarTypeMap['defaultComponent'];
type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiAvatarProps = MuiAvatarProps<DefaultComponent, OwnProps>;

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => !['colorOverride'].includes(prop.toString()),
})(toiAvatarStyle);

const initialsFromName = (fullName: string) => {
  const names = fullName.trim().split(' ');
  let initials = names[0][0];
  if (names.length > 1) initials += names[names.length - 1][0];
  return initials;
};

export const ToiAvatar = forwardRef<RefType, ToiAvatarProps>((props, ref) => {
  const {children, ...restProps} = props;

  const initials = props.fullname ? initialsFromName(props.fullname) : props.initials;

  return (
    <StyledAvatar initials={initials} ref={ref} {...restProps}>
      {initials || children}
    </StyledAvatar>
  );
});

export default ToiAvatar;
