import React from 'react';
import {
    ToiBox,
    ToiLink,
    ToiTypography,
} from '@norkart/toi-deprecated-components';
import {AppExternalIcon} from '@norkart/toi-deprecated-icons';
import {ToiThemeProvider} from '@norkart/toi-deprecated-theme';
import {useMittInnspillProjects} from '../../../hooks/mittinnspill/useMittInnspillProjects';
import arealplanerConfig from '../../../config';

const BASE_URL = arealplanerConfig.mittinnspill.url;

function MittInnspillLink() {
    const mittInnspillProjects = useMittInnspillProjects();
    if (mittInnspillProjects.isLoading) return null;
    if (mittInnspillProjects?.data.totalCount === 0) return null;

    if (!mittInnspillProjects.data.elements.length) return null;
    const project = mittInnspillProjects?.data?.elements[0];

    return (
        <ToiThemeProvider>
            <ToiBox>
                <ToiTypography mb={2}>
                    Kommunen har lagt ut planen på MittInnspill.
                    <br /> Gå inn på MittInnspill for å gi innspill og se planen
                    i en mer visuell form.
                </ToiTypography>
                <ToiLink
                    href={`${BASE_URL}/prosjekter/${project.id}`}
                    target='_blank'
                    mb={2}
                    EndIcon={AppExternalIcon}
                >
                    Gå til MittInnspill
                </ToiLink>
            </ToiBox>
        </ToiThemeProvider>
    );
}

export default MittInnspillLink;
