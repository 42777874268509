import React, {Component} from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import {Divider} from '@mui/material';
import Header from '../../../features/home/header';

class IEError extends Component<any, any> {
    render() {
        return (
            <>
                <Header />
                <Divider />
                <div
                    className='ieError'
                    style={{
                        width: '80vw',
                        maxWidth: '800px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <h1>Vennligst benytt en annen nettleser</h1>
                    <ErrorIcon color='error' />
                    <p style={{maxWidth: 800}}>
                        arealplaner.no er ikke tilgjengelig i Internet Explorer.
                        Microsoft fraråder bruk av nettleseren ettersom den er
                        utdatert og har problemer rundt sikkerhet. Se artikkel
                        for mer informasjon{' '}
                        <a
                            rel='noreferrer'
                            target='_blank'
                            href='https://www.telegraph.co.uk/technology/2019/02/08/stop-using-internet-explorer-warns-microsofts-security-chief/'
                        >
                            her.
                        </a>
                    </p>
                </div>
            </>
        );
    }
}

export default IEError;
