import DeleteSweep from '@mui/icons-material/DeleteSweep';
import {Accordion, AccordionDetails, Box, Button} from '@mui/material';
import React, {useState} from 'react';
import {ChevronDownIcon} from '@norkart/toi-deprecated-icons';
import {ChevronUpIcon} from '@norkart/toi-deprecated-icons';
import HelpButton from '../../../components/HelpButton/HelpButton';
import {helpObject} from '../../../help/helpObject';
import useMeta from '../../../hooks/meta/useMeta';
import {
    BehandlingstypeFilter,
    OpprinneligAdministrativEnhetFilter,
    PlanStatusFilter,
    PlantypeFilter,
    SaksnummerFilter,
} from './filters';
import FilterLabel from './filters/FilterLabel';
import {useFilters} from './filters/useFilters';
import {DateFilter} from './filters/DateFilter';

export type Option = {
    label: string;
    value: string;
};

const AdvancedFilters = () => {
    const meta = useMeta();
    const filters = useFilters();

    const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

    const disableBehandlingTypeFilter =
        filters.get('fromDate') || filters.get('toDate') ? false : true;

    return (
        <Accordion
            sx={{
                marginBottom: 5,
                boxShadow: 'none',
                backgroundColor: 'inherit',
            }}
            expanded={filtersOpen}
            square={true}
            disableGutters={true}
        >
            <Box
                sx={{display: 'flex', justifyContent: 'space-between'}}
                id='advancedsearch'
            >
                <Button
                    onClick={() => {
                        setFiltersOpen(!filtersOpen);
                    }}
                >
                    {filtersOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    Filter
                </Button>
                {!!filters.activeFiltersFromUrl.length && (
                    <Button
                        onClick={() => filters.clearAll()}
                        startIcon={<DeleteSweep />}
                    >
                        Fjern filtre
                    </Button>
                )}
            </Box>
            <AccordionDetails>
                <PlantypeFilter />
                <PlanStatusFilter />
                <SaksnummerFilter />

                <FilterLabel>
                    Velg dato/periode
                    <HelpButton
                        helpText={helpObject.AvansertFilter.Dato}
                        type='Dato/PeriodeFilter'
                    />
                </FilterLabel>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 5,
                    }}
                >
                    <DateFilter />
                </Box>

                {!disableBehandlingTypeFilter && <BehandlingstypeFilter />}

                {meta.data.kommuner && <OpprinneligAdministrativEnhetFilter />}
            </AccordionDetails>
        </Accordion>
    );
};

export default AdvancedFilters;
