import * as React from 'react';
import axios, {AxiosError} from 'axios';
import {useNavigate} from 'react-router-dom';
import {fetchArealplanId} from '../services/gi';
import {getErrorMessage} from '../utils/getErrorMessage';
import {openSnackbarMessage} from '../components/SnackbarMessage';

export interface Props {
    planId: string;
    kommunenummer: string;
    customerId;
}

function VisPlan(props: Props) {
    const navigate = useNavigate();
    const [errors, setErrors] = React.useState<AxiosError | undefined>();
    const [isLoading, setIsLoading] = React.useState(false);
    const signal = axios.CancelToken.source();

    React.useEffect(() => {
        onGetId();
    }, []);

    const onGetId = async () => {
        const {planId, kommunenummer, customerId} = props;
        try {
            setIsLoading(true);
            const res = await fetchArealplanId(
                planId,
                kommunenummer,
                customerId,
                signal.token
            );
            setIsLoading(false);
            navigate(`/${customerId}/arealplaner/${res.data}`, {replace: true});
        } catch (err) {
            if (!axios.isCancel(err)) {
                if (axios.isAxiosError(err)) {
                    const errors = err;
                    setIsLoading(false);
                    setErrors(errors);
                    openSnackbarMessage({
                        variant: 'error',
                        message: getErrorMessage(errors),
                    });
                }
            }
        }
    };

    return null;
}

export default VisPlan;
