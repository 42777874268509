import {Theme} from '@mui/material';
import {merge} from 'lodash-es';

export const toiAppBarStyle = (theme: Theme) => {
  const defaultStyle = {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  };

  return merge({}, defaultStyle);
};
