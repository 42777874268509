import * as React from 'react';
import {
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    DialogContent,
    DialogActions,
    Button,
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {Form, Field} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {Sak} from '../../../hooks/arealplaner/types';
import {Arkiv} from '../../../hooks/meta/types';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';

export interface BehandlingFormDialogProps {
    saker: Partial<Sak>[];
    arkiver: Arkiv[];
    open: boolean;
    defaultValue: number;
    onClose: () => void;
    onOk: (values) => void;
}

function EditSakDialog({
    open,
    saker,
    arkiver,
    onClose,
    onOk,
    defaultValue,
}: BehandlingFormDialogProps) {
    function handleOk() {
        document
            .getElementById('sak-form')!
            .dispatchEvent(
                new Event('submit', {cancelable: true, bubbles: true})
            );
    }

    function handleSubmit(values) {
        onOk(values);
    }

    function sakForm() {
        return (
            <Form
                initialValues={{saker: saker}}
                onSubmit={handleSubmit}
                mutators={{...arrayMutators}}
                render={({
                    handleSubmit,
                    pristine,
                    invalid,
                    values,
                    form: {
                        mutators: {push},
                    },
                }) => (
                    <form onSubmit={handleSubmit} id='sak-form'>
                        <Button
                            variant='outlined'
                            onClick={() =>
                                push('saker', {arkivId: defaultValue})
                            }
                        >
                            <AddIcon fontSize='small' /> Nytt saksnummer
                        </Button>
                        <FieldArray name='saker'>
                            {({fields}) => (
                                <Table padding='checkbox'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Saksår</TableCell>
                                            <TableCell>Sekvensnummer</TableCell>
                                            <TableCell>Arkiv</TableCell>
                                            <TableCell>{}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fields.map((name, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Field
                                                        name={`${name}.sakAar`}
                                                        component='input'
                                                        maxLength={4}
                                                        style={{padding: '5px'}}
                                                        parse={(
                                                            value,
                                                            name
                                                        ) => {
                                                            if (value == '')
                                                                return value;
                                                            return Number(
                                                                value.replace(
                                                                    /[^\d]/g,
                                                                    ''
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Field
                                                        name={`${name}.sakSeknr`}
                                                        component='input'
                                                        style={{padding: '5px'}}
                                                        parse={(
                                                            value,
                                                            name
                                                        ) => {
                                                            if (value == '')
                                                                return value;
                                                            return Number(
                                                                value.replace(
                                                                    /[^\d]/g,
                                                                    ''
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Field
                                                        name={`${name}.arkivId`}
                                                        parse={(value) =>
                                                            Number(value)
                                                        }
                                                        render={({input}) => (
                                                            <StyledSelect
                                                                {...input}
                                                                options={
                                                                    arkiver
                                                                }
                                                                valueCol='beskrivelse'
                                                                keyCol='id'
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            fields.remove(index)
                                                        }
                                                        size='small'
                                                    >
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </FieldArray>
                        {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre> */}
                    </form>
                )}
            />
        );
    }

    return (
        <Dialog
            open={open}
            aria-labelledby='sak-form-dialog-title'
            maxWidth='lg'
        >
            <DialogTitle id='sak-form-dialog-title'>
                <Grid
                    container={true}
                    justifyContent='space-between'
                    spacing={8}
                    alignItems='center'
                >
                    <Grid item={true}>Redigere saksnummer</Grid>
                    <Grid item={true}>
                        <IconButton onClick={onClose}>
                            <CloseIcon fontSize='small' />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>{sakForm()}</DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='outlined' color='primary'>
                    Avbryt
                </Button>
                <Button
                    onClick={handleOk}
                    variant='contained'
                    color='secondary'
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditSakDialog;
