import {TableCell, TableRow} from '@mui/material';
import * as React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Dispatch} from 'redux';
import StyledCheckbox from '../../../components/Checkbox/StyledCheckbox';
import {JournalDocument} from '../../../hooks/journaler/types';
import {ApplicationState} from '../../../store';
import {
    addJournalDocument,
    removeJournalDocument,
} from '../../../store/fileImport/actions';

type Props = ConnectedProps<typeof connector> & {
    document: JournalDocument;
};

export const ImportDocumentFromArchiveSelectFilesListItem = ({
    document,
    ...props
}: Props) => {
    const isSelected = (document: JournalDocument): boolean => {
        return props.journalDocumentSelection.includes(document);
    };

    const handleChange = (document: JournalDocument) => {
        if (isSelected(document)) {
            props.removeFile(document);
        } else {
            props.addFile(document);
        }
    };

    const handleRemove = (data: JournalDocument) => {
        props.removeFile(data);
    };

    const renderRow = () => (
        <TableRow>
            <TableCell
                style={{textAlign: 'center'}}
                onClick={() => handleChange(document)}
                sx={{cursor: 'pointer'}}
            >
                <StyledCheckbox
                    name='selected'
                    checked={isSelected(document)}
                />
            </TableCell>
            <TableCell
                onClick={() => handleChange(document)}
                sx={{cursor: 'pointer'}}
            >
                {document.dokumentTittel}
            </TableCell>
        </TableRow>
    );
    return <>{renderRow()}</>;
};

const mapStateToProps = (state: ApplicationState) => ({
    journalDocumentSelection: state.fileImport.journalDocumentSelection,
    errors: state.fileImport.errors,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    addFile: (data: JournalDocument) => dispatch(addJournalDocument(data)),
    removeFile: (data: JournalDocument) =>
        dispatch(removeJournalDocument(data)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ImportDocumentFromArchiveSelectFilesListItem);
