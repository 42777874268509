import {useQuery} from 'react-query';
import {useMemo} from 'react';
import {useNkAuth} from '@norkart/nk-auth';
import {fetchJournalDocuments} from '../../services/api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {FetchJournalDocumentsProps, Journal, JournalDocument} from './types';

const getJournalDokumenter = async (
    props: FetchJournalDocumentsProps,
    isAuthorized: boolean
) => {
    const {data} = await fetchJournalDocuments(props, isAuthorized);
    return data;
};

export default function useJournalerDokumenter(journal: Journal) {
    const kundeId = useSelectedKundeId();
    const {isAuthorized} = useNkAuth();
    const props = {
        sakId: journal.sakId,
        journalpostAar: journal.journalpostAar,
        journalpostSekvensnr: journal.journalpostSekvensnr,
    } as FetchJournalDocumentsProps;

    const query = useQuery<JournalDocument[]>(
        ['journaldokumenter', {kundeId, ...props, isAuthorized}],
        () => getJournalDokumenter(props, isAuthorized),
        {
            enabled: !!journal.sakId,
            retry: 0,
            staleTime: 1000 * 60 * 60, // 1h
            refetchOnMount: false,
        }
    );

    const mappedData = useMemo(
        () =>
            query.data &&
            query.data.map((document) => {
                return {
                    ...document,
                    journal,
                };
            }),
        [query.data, journal.id]
    );

    return {...query, data: mappedData || []};
}
