import {useQuery} from 'react-query';
import {
    arkivKodelisterGetByType,
    KodelisteType,
    KodeStringIdDto,
} from '@norkart/nkapi-arealplaner-api';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useSelectedKundeId from '../kunder/useSelectedKundeId';

export function useArkivKodeliste(
    arkivId: number,
    kodelisteType: KodelisteType
) {
    const {authorizedClient} = useArealplanerApiClientContext();
    const kundeId = useSelectedKundeId();

    const getKodeliste = async () => {
        const {data} = await arkivKodelisterGetByType({
            client: authorizedClient,
            path: {arkivId, kundeId},
            query: kodelisteType ? {kodelisteType} : undefined,
        });
        return data as KodeStringIdDto[];
    };

    const query = useQuery<KodeStringIdDto[]>(
        ['arkivkodeliste', arkivId, kodelisteType],
        getKodeliste,
        {refetchOnWindowFocus: false}
    );

    return {...query, data: query?.data || ([] as KodeStringIdDto[])};
}
