import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Tooltip,
    Fade,
    IconButton,
    DialogActions,
    Button,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {Token, TokenType} from '../../../hooks/tokens/types';
import useMeta from '../../../hooks/meta/useMeta';
import useToken from '../../../hooks/tokens/useToken';
import LoadingSpinner from '../../../components/LoadingSpinner';

type Props = {
    tokenType: TokenType;
    token: Token;
    isOpen: boolean;
    handleClose: () => void;
};

function TokenShareDialog({tokenType, isOpen, ...props}: Props) {
    const {komnr} = useMeta();
    const token = useToken(props.token.id);

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };
    const getShareString = (): string => {
        return tokenType == TokenType.API
            ? token.data.tokenString
            : `https://waapi.webatlas.no/wms-dispensasjoner/?api_key=${token.data.tokenString}&CQL_FILTER=KommuneNummer=${komnr};KommuneNummer=${komnr}`;
    };
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (tooltipOpen) {
            timer = setTimeout(handleTooltipClose, 1500);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [tooltipOpen]);

    const copyToClipboard = () => {
        handleTooltipOpen();
        navigator.clipboard.writeText(getShareString());
    };

    const handleClose = () => {
        handleTooltipClose();
        props.handleClose();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth={true}
        >
            <DialogTitle>
                {tokenType == TokenType.WMS ? 'Del WMS URL' : 'Del API nøkkel'}
            </DialogTitle>
            <DialogContent>
                {token.isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Grid container={true} wrap='nowrap' alignItems='baseline'>
                        <Grid item={true} style={{width: '100%'}}>
                            <input
                                type='text'
                                readOnly={true}
                                value={getShareString()}
                                style={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item={true}>
                            <Tooltip
                                open={tooltipOpen}
                                onClose={handleTooltipClose}
                                title='Kopiert'
                                placement='top'
                                TransitionComponent={Fade}
                                disableFocusListener={true}
                                disableHoverListener={true}
                                disableTouchListener={true}
                            >
                                <IconButton onClick={copyToClipboard}>
                                    <FileCopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Lukk</Button>
            </DialogActions>
        </Dialog>
    );
}

export default TokenShareDialog;
