import {useQuery} from 'react-query';
import {useNkAuth} from '@norkart/nk-auth';
import {fetchJournaler} from '../../services/api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Journal} from './types';

const getJournaler = async (id: number, isAuthorized: boolean) => {
    const {data} = await fetchJournaler(id, isAuthorized);
    return data;
};

export default function useJournaler(sakId?: number) {
    const kundeId = useSelectedKundeId();
    const {isAuthorized} = useNkAuth();
    const query = useQuery<Journal[]>(
        ['journaler', {kundeId, sakId, isAuthorized}],
        () => getJournaler(sakId!, isAuthorized),
        {enabled: !!sakId, retry: 0}
    );

    return {...query, data: query.data || []};
}
