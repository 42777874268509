//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const MapPolygonIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M7 2H2V17L7 22H22V11L17 6H11L7 2ZM6 4L10 8H16.17L20 11.83V20H7.82996L4 16.17V4H6Z' style={props.secondaryStyle} />
            <path fillRule='evenodd' clipRule='evenodd' d='M5 9.02002L7.39001 6.67004L6.67004 5.96997L5 7.62V9.02002ZM9.54004 8.78003L5 13.37V11.95L8.81995 8.07996L9.54004 8.78003ZM16.0699 9.31006L16.77 10.02L7.95996 18.72L7.26001 18.02L16.0699 9.31006ZM13.54 9H12.12L5.15002 15.91L5.85999 16.62L13.54 9ZM18.12 11.37L18.83 12.0699L12.05 19H10.64L18.12 11.37ZM19 14.84V16.26L16.28 19H14.86L19 14.84Z' style={props.primaryStyle} />
        </g>
    ),
    'MapPolygonIcon' // aria-label
);
