import {Theme} from '@mui/material';
import {DatePickerProps as MuiDatePickerProps, PickerValidDate} from '@mui/x-date-pickers';
import React, {ForwardedRef} from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {AppCalendarIcon} from '@norkart/toi-deprecated-icons';
import ToiTextField from '../ToiTextField/ToiTextField';
import {StyledDatePicker, datePickerPopperStyle} from './toiDatePickerStyle';

type OwnProps = {
  fullWidth?: boolean;
};

export type ToiDatePickerProps<
  TDate extends PickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
> = Omit<MuiDatePickerProps<TDate, TEnableAccessibleFieldDOMStructure>, keyof OwnProps> & OwnProps;

function ToiDatePickerInner<TDate extends PickerValidDate>(
  props: ToiDatePickerProps<TDate>,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <StyledDatePicker
      {...props}
      ref={ref}
      slots={{textField: ToiTextField, openPickerIcon: AppCalendarIcon}}
      format={props.format ?? 'dd.MM.yyyy'}
      slotProps={{
        ...props.slotProps,
        textField: {
          sx: {width: props.fullWidth ? '100%' : '320px'},
          ...props.slotProps?.textField,
        },
        mobilePaper: {
          sx: {boxShadow: (theme: Theme) => theme.shadows[2]},
          ...props.slotProps?.mobilePaper,
        },
        desktopPaper: {
          sx: {boxShadow: (theme: Theme) => theme.shadows[2]},
          ...props.slotProps?.desktopPaper,
        },
        popper: {
          placement: 'bottom-start',
          sx: (theme: Theme) => datePickerPopperStyle(theme),
          ...props.slotProps?.popper,
        },
      }}
    />
  );
}

export type ToiDatePickerComponent = <
  TDate extends PickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
>(
  props: ToiDatePickerProps<TDate, TEnableAccessibleFieldDOMStructure> & React.RefAttributes<HTMLDivElement>
) => React.JSX.Element;

export const ToiDatePicker = React.forwardRef(ToiDatePickerInner) as ToiDatePickerComponent;

export default ToiDatePicker;
