//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const ChevronRightIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M9.41 19L16.38 12.03L16.35 12L16.38 11.97L9.41 5L8 6.41003L13.58 12L8 17.59L9.41 19Z' style={props.secondaryStyle} />
        </g>
    ),
    'ChevronRightIcon' // aria-label
);
