import tagType from './TagType';

export function isArrayofStrings(arg: unknown): arg is string[] {
  if (arg instanceof Array) {
    if (arg.every((item) => typeof item === 'string')) {
      return true;
    }
  }
  return false;
}

export function istagType(arg: unknown): arg is tagType {
  return typeof (arg as tagType).id === 'number' && typeof (arg as tagType).name === 'string';
}
export function isArrayOfTagType(arg: unknown): arg is tagType[] {
  return arg instanceof Array && !arg.every(istagType);
}
