import {forwardRef, memo, ReactNode} from 'react';
import SvgIcon, {IconProps, PathProps} from './SvgIcon';

const createIcon = (pathFunc: (props: PathProps) => ReactNode, ariaLabel: string) => {
  const Component: React.FC<IconProps> = memo(
    forwardRef((props, ref) => <SvgIcon ref={ref} aria-label={ariaLabel} {...props} pathFunc={pathFunc} />)
  );
  return Component;
};

export default createIcon;
