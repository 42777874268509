import {LoggerOptions} from '@azure/msal-common';
import {AuthConfigurationBase} from '../../msalApp/msalApp';
import {getAzureADAuthData} from '../../msalApp/msalAppAzureAD';
import {NkAuthProvider, NkSpecificAuthProviderProps} from './NkAuthProvider';

export interface AzureADAuthConfiguration extends AuthConfigurationBase {
  authority: string;
  redirectUri?: string;
  loggerOptions?: LoggerOptions;
}

export const NkAzureADAuthProvider = (props: NkSpecificAuthProviderProps<AzureADAuthConfiguration>) => (
  <NkAuthProvider {...props} getAuthData={getAzureADAuthData} />
);
