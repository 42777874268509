import {Link} from '@mui/material';
import {ColumnDef} from '@tanstack/react-table';
import * as React from 'react';
import {format} from 'date-fns';
import {DispensasjonerReport} from '../../../../hooks/dispensasjoner/types';

const baseUrl = window.location.origin;

export default function DispensasjonerColumns(
    kundeId: string,
    kommunenummer: string
) {
    return React.useMemo<ColumnDef<DispensasjonerReport>[]>(
        () => [
            {
                id: 'planId',
                header: () => 'PlanId',
                cell: (r) => (
                    <Link
                        key={r.row.id + 'link'}
                        href={`${baseUrl}/${kundeId}/gi?funksjon=VisPlan&planidentifikasjon=${r.row.original?.arealplan?.planId}&kommunenummer=${kommunenummer}`}
                        target='_blank'
                        variant='body1'
                        underline='none'
                        color='secondary'
                        fontSize='0.85rem'
                    >
                        {r.row.original?.arealplan?.planId
                            ? r.row.original?.arealplan?.planId
                            : null}
                    </Link>
                ),
                accessorFn: (r, i) => r.arealplan?.planId,
                enableSorting: true,
                size: 70,
            },
            {
                id: 'dispensasjonBeskrivelse',
                accessorKey: 'beskrivelse',
                header: () => 'Beskrivelse',
                enableSorting: true,
                size: 100,
            },
            {
                id: 'vedtaksDato',
                cell: (r) => (
                    <>
                        {r.row.original?.vedtaksdato
                            ? format(
                                  new Date(r.row.original.vedtaksdato),
                                  'dd.MM.yyyy'
                              )
                            : ''}
                    </>
                ),
                accessorFn: (r, i) => r.vedtaksdato && new Date(r.vedtaksdato),
                header: () => 'Vedtaksdato',
                enableSorting: true,
                sortingFn: 'datetime',
                size: 50,
            },
            {
                id: 'DispensasjonType',
                accessorFn: (r, i) => r.dispensasjonType,
                header: () => 'Dispensasjontype',
                enableSorting: true,
                size: 70,
            },
            {
                id: 'DispFra',
                accessorFn: (r, i) => r.dispFra,
                header: () => 'Dispensasjon fra',
                enableSorting: true,
                size: 70,
            },
            {
                id: 'Vedtak',
                accessorFn: (r, i) => r.vedtak,
                header: () => 'Vedtak',
                enableSorting: true,
                size: 70,
            },
            {
                id: 'planNavn',
                accessorFn: (r, i) => r.arealplan?.planNavn,
                header: () => 'Plannavn',
                size: 200,
            },
            {
                id: 'planStatus',
                accessorFn: (r, i) => r.arealplan?.planStatus,
                header: () => 'Planstatus',
                enableSorting: true,
                size: 70,
            },
            {
                id: 'planType',
                accessorFn: (r, i) => r.arealplan?.planType,
                header: () => 'Plantype',
                enableSorting: true,
                size: 70,
            },
            {
                id: 'sak',
                accessorFn: (r, i) => {
                    return r.sak ? `${r.sak?.sakAar}/${r.sak?.sakSeknr}` : null;
                },
                header: () => 'Saksnummer',
                enableResizing: true,
                enableSorting: true,
                size: 70,
            },
            {
                id: 'vertikalniva',
                accessorFn: (r, i) => r.vertikalnivaType,
                header: () => 'Vertikalnivå',
                enableSorting: true,
                size: 70,
            },
            {
                id: 'Posisjon',
                accessorFn: (r) => r.posisjon?.x && r.posisjon?.y,
                cell: (r) =>
                    r.row.original?.posisjon?.x ? (
                        <>{`${r.row.original?.posisjon.x}, ${r.row.original?.posisjon?.y}`}</>
                    ) : (
                        ''
                    ),
                header: () => 'Posisjon',
                size: 70,
                enableSorting: true,
            },
        ],
        []
    );
}
