import * as React from 'react';
import {Field, FieldProps} from 'react-final-form';
import {Typography} from '@mui/material';
import classNames from 'classnames';
import FormError from '../FormError';

export interface FormTextareaFieldProps
    extends React.AllHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    optional?: boolean;
    disabled?: boolean;
    fieldProps?: Partial<FieldProps2>;
}
type FieldProps2 = FieldProps<any, any, HTMLElement>;

export const FormTextareaField: React.FC<FormTextareaFieldProps> = ({
    name,
    label: title,
    type = 'text',
    optional = false,
    disabled = false,
    fieldProps = {},
    ...rest
}: FormTextareaFieldProps) => (
    <Field
        name={name}
        subscription={{value: true, touched: true, error: true, initial: true}}
        {...fieldProps}
        render={({input, meta}) => (
            <label>
                {title && (
                    <Typography
                        className={classNames({
                            importantFormLabel: !(optional || disabled),
                        })}
                    >
                        {title}
                    </Typography>
                )}
                <div>
                    <textarea
                        {...input}
                        {...rest}
                        disabled={disabled}
                        style={{fontSize: 'inherit', borderWidth: 2}}
                        className={classNames({
                            formInput: true,
                            formInputError: meta.error && meta.touched,
                        })}
                    />
                    <FormError name={name} />
                </div>
            </label>
        )}
    />
);
export default FormTextareaField;
