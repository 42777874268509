const regexItemTypes = {
    NUMBER: 'NUMBER',
    NUMBER_SPECIAL: 'NUMBER_SPECIAL',
    NUMBER_COMPOSITE: 'NUMBER_COMPOSITE',
    LETTER: 'LETTER',
    COORDINATE_SYSTEM: 'COORDINATE_SYSTEM',
    NO_FUNCTION: 'NO_FUNCTION',
};

export default regexItemTypes;
