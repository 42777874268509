import {
    Card,
    CardActionArea,
    CardContent,
    Grow,
    styled,
    TableCell,
} from '@mui/material';
import React, {useState} from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Arealplan} from '../../../hooks/arealplaner/types';
import PlanSummary from './PlanSummary';

const StyledTableCell = styled(TableCell)({
    border: 0,
});

type Props = {
    plan: Arealplan;
    customerId: string;
    handlePlanClick: (plan: Arealplan) => void;
    highlight?: boolean;
};

export default function PlanCard({
    plan,
    customerId,
    handlePlanClick,
    highlight,
}: Props) {
    const [checked, setChecked] = useState(false);
    return (
        <Card
            onClick={() => handlePlanClick(plan)}
            key={plan.id}
            sx={(theme) => ({
                marginBottom: '1px',
                backgroundColor: highlight
                    ? '#f0f0f0'
                    : theme.palette.background.paper,
            })}
            onMouseEnter={() => setChecked(true)}
            onMouseLeave={() => setChecked(false)}
        >
            <CardActionArea>
                <CardContent
                    sx={(theme) => ({
                        maxWidth: '700px',
                        display: 'flex',
                        alignItems: 'center',
                    })}
                >
                    <PlanSummary plan={plan} />
                    <Grow in={checked}>
                        <ArrowForwardIosIcon />
                    </Grow>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
