import * as React from 'react';
import {ToiDatePicker} from '@norkart/toi-deprecated-components';
import {ToiThemeProvider} from '@norkart/toi-deprecated-theme';

type Props = {
    input: {
        name: string;
        value: string | null;
        onChange: (date: Date | null) => void;
    };
    label?: string;
    onClear?: () => void;
    minDate?: Date;
    maxDate?: Date;
    disabled?: boolean;
    placeholder?: string;
};
const DatePickerComp = ({
    input: {name, value, onChange},
    label,
    onClear,
    minDate,
    maxDate,
    disabled,
    placeholder,
}: Props) => {
    const _handleSetDate = (date: Date | null) => {
        onChange(date);
    };

    return (
        <ToiThemeProvider>
            <ToiDatePicker
                sx={{width: '100%'}}
                name={name}
                label={label}
                value={value ? new Date(value) : null}
                onChange={_handleSetDate}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                slotProps={{
                    textField: {placeholder: placeholder || 'Velg dato'},
                    field: {clearable: !!onClear, onClear: onClear},
                }}
            />
        </ToiThemeProvider>
    );
};

export default DatePickerComp;
