import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import arealplanerConfig from '../config';

export type ConsentProps = {
    statistic: boolean;
    marketing: boolean;
    functional: boolean;
    unclassified: boolean;
};

// Defining the Application Insights instance used for logging
export let appInsightsInstance: ApplicationInsights | undefined = undefined;

const getApplicationInsights = (consent: ConsentProps) =>
    new ApplicationInsights({
        config: {
            connectionString:
                arealplanerConfig.applicationInsightsConnectionString,
            enableRequestHeaderTracking: consent.statistic,
            enableResponseHeaderTracking: consent.statistic,
            correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
            enableAjaxErrorStatusText: consent.statistic,
            enableAjaxPerfTracking: consent.statistic,
            disableCookiesUsage: !(consent.statistic || consent.functional),
            enableAutoRouteTracking: consent.statistic,
        },
    });

// Remove Application Insights cookies
function removeAppInsightsCookies() {
    document.cookie =
        'ai_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie =
        'ai_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

// Loading and unloading Application Insights based on cookie consent
const handleAnalyticsTrackingByConsent = (consent: ConsentProps) => {
    if (consent.statistic || consent.functional) {
        const appInsights = getApplicationInsights(consent);
        appInsights.loadAppInsights();
        appInsightsInstance = appInsights;
    } else {
        appInsightsInstance?.unload();
        removeAppInsightsCookies();
        appInsightsInstance = undefined;
    }
};

// Get cookie consent from all categories
export const getCookieConsent = () => {
    const consentForStatistic = (
        window as any
    )?.CookieInformation?.getConsentGivenFor('cookie_cat_statistic');
    const consentForMarketing = (
        window as any
    )?.CookieInformation?.getConsentGivenFor('cookie_cat_marketing');
    const consentForFunctional = (
        window as any
    )?.CookieInformation?.getConsentGivenFor('cookie_cat_functional');
    const consentForUnclassified = (
        window as any
    )?.CookieInformation?.getConsentGivenFor('cookie_cat_unclassified');
    return {
        statistic: consentForStatistic,
        marketing: consentForMarketing,
        functional: consentForFunctional,
        unclassified: consentForUnclassified,
    };
};

// Listen to changed consent-events to trigger loading and unloading of Application Insights
window.addEventListener('CookieInformationConsentGiven', function () {
    const cookieConsent = getCookieConsent();
    handleAnalyticsTrackingByConsent(cookieConsent);
});
