import {useQuery} from 'react-query';
import {useNkAuth} from '@norkart/nk-auth';
import {fetchArealplanBehandlinger} from '../../services/api';
import {ArealplanId} from '../arealplaner/types';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Behandling} from './types';

const getBehandlinger = async (
    arealplanId: ArealplanId,
    isAuthorized: boolean
) => {
    const {data} = await fetchArealplanBehandlinger(arealplanId, isAuthorized);
    return data;
};

export default function useBehandlinger() {
    const kundeId = useSelectedKundeId();
    const arealplanId = useSelectedArealplanId();
    const {isAuthorized} = useNkAuth();

    const query = useQuery<Behandling[]>(
        ['behandlinger', {kundeId, arealplanId, isAuthorized}],
        () => getBehandlinger(arealplanId!, isAuthorized),
        {enabled: !!arealplanId}
    );

    return {...query, data: query?.data || []};
}
