import * as React from 'react';
import ExpansionPanel from '../../../components/ExpansionPanel';
import DesktopContainer from '../../../containers/DesktopContainer';
import MobileContainer from '../../../containers/MobileContainer';
import PlanFeedbackContent from './PlanFeedbackContent';

const PlanFeedback = () => {
    return (
        <>
            <MobileContainer>
                <ExpansionPanel title={`Si din mening`}>
                    <PlanFeedbackContent />
                </ExpansionPanel>
            </MobileContainer>
            <DesktopContainer>
                <PlanFeedbackContent />
            </DesktopContainer>
        </>
    );
};

export default PlanFeedback;
