const drawStyles = [
    {
        id: 'gl-draw-polygon-fill-inactive',
        type: 'fill',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'fill-color': '#ff007f',
            'fill-outline-color': '#ff007f',
            'fill-opacity': 0.1,
        },
    },
    {
        id: 'gl-draw-polygon-fill-active',
        type: 'fill',
        filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        paint: {
            'fill-color': '#fbb03b',
            'fill-outline-color': '#fbb03b',
            'fill-opacity': 0.1,
        },
    },
    {
        id: 'gl-draw-polygon-midpoint',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
        paint: {
            'circle-radius': 3,
            'circle-color': '#fbb03b',
        },
    },
    {
        id: 'gl-draw-polygon-stroke-inactive',
        type: 'line',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#ff007f',
            'line-width': 4,
        },
    },
    {
        id: 'gl-draw-polygon-stroke-active',
        type: 'line',
        filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 4,
        },
    },
    {
        id: 'gl-draw-line-inactive',
        type: 'line',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'LineString'],
            ['!=', 'mode', 'static'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#ff007f',
            'line-width': 4,
        },
    },
    {
        id: 'gl-draw-line-active',
        type: 'line',
        filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['==', 'active', 'true'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 4,
        },
    },
    {
        id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'circle-radius': 5,
            'circle-color': '#fff',
        },
    },
    {
        id: 'gl-draw-polygon-and-line-vertex-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'circle-radius': 4,
            'circle-color': '#ff007f',
        },
    },
    {
        id: 'gl-draw-textpoint-active',
        type: 'symbol',
        filter: [
            'all',
            ['==', 'user_textpoint', 'true'],
            ['==', 'active', 'true'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'text-halo-width': 2,
            'text-halo-color': '#fbb03b',
        },
        layout: {
            'text-field': ['get', 'user_title'],
            'text-anchor': 'top-left',
            'text-allow-overlap': true,
        },
    },
    {
        id: 'gl-draw-textpoint-inactive',
        type: 'symbol',
        filter: [
            'all',
            ['==', 'user_textpoint', 'true'],
            ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'text-halo-width': 2,
            'text-halo-color': 'rgba(255, 255, 255, 1)',
        },
        layout: {
            'text-field': ['get', 'user_title'],
            'text-anchor': 'top-left',
            'text-allow-overlap': true,
        },
    },
    {
        id: 'gl-draw-point-inactive',
        type: 'symbol',
        filter: [
            'all',
            ['!=', 'user_textpoint', 'true'],
            ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static'],
        ],
        layout: {
            'icon-image': 'pointmarker-blue',
            'icon-anchor': 'bottom',
            'icon-ignore-placement': true,
            'icon-allow-overlap': true,
        },
    },
    {
        id: 'gl-draw-point-active',
        type: 'symbol',
        filter: [
            'all',
            ['!=', 'user_textpoint', 'true'],
            ['==', '$type', 'Point'],
            ['!=', 'meta', 'midpoint'],
            ['==', 'meta', 'feature'],
            ['==', 'active', 'true'],
        ],
        layout: {
            'icon-image': 'pointmarker-orange',
            'icon-anchor': 'bottom',
            'icon-ignore-placement': true,
            'icon-allow-overlap': true,
        },
    },
    {
        id: 'gl-draw-polygon-fill-static',
        type: 'fill',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        paint: {
            'fill-color': '#404040',
            'fill-outline-color': '#404040',
            'fill-opacity': 0.1,
        },
    },
    {
        id: 'gl-draw-polygon-stroke-static',
        type: 'line',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#404040',
            'line-width': 2,
        },
    },
    {
        id: 'gl-draw-line-static',
        type: 'line',
        filter: [
            'all',
            ['==', 'mode', 'static'],
            ['==', '$type', 'LineString'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#404040',
            'line-width': 2,
        },
    },
    {
        id: 'gl-draw-point-static',
        type: 'circle',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
        paint: {
            'circle-radius': 20,
            'circle-color': '#404040',
        },
    },
];

export default drawStyles;
