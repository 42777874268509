import {CircularProgress, Grid} from '@mui/material';
import {format} from 'date-fns';
import * as React from 'react';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import ReactTable from '../../../../components/ReactTable';
import {saksbehandlingstidQuery} from '../../../../hooks/customReports/customReportQueries';
import useSaksbehandlingstidGraphQL from '../../../../hooks/customReports/useSaksbehandlingstidGraphQL';
import {useSaksbehandlingstidQLCsv} from '../../../../hooks/customReports/useSaksbehandlingstidQLCsv';
import {setTitle} from '../../../../utils/setTitle';
import {exportToCsv} from '../ReportTools/ClientsideCsv';
import {SaksbehandlingstidQueryVariables} from './Saksbehandlingstid';
import SaksbehandlingstidColumns from './SaksbehandlingstidColumns';

const average = (array: number[]) => {
    return array.reduce((a, b) => a + b) / array.length;
};

type SaksbehandlingstidReportProps = {
    variables: SaksbehandlingstidQueryVariables;
    kundeId: string;
    kommunenummer: string;
    setAverageDays: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export default function SaksbehandlingstidReport({
    variables,
    kundeId,
    kommunenummer,
    setAverageDays,
}: SaksbehandlingstidReportProps) {
    setTitle('Rapporter > Saksbehandlingstid');
    const csv = useSaksbehandlingstidQLCsv(variables);
    const columns = SaksbehandlingstidColumns(kundeId, kommunenummer);
    const saksbehandlingstid = useSaksbehandlingstidGraphQL(
        saksbehandlingstidQuery,
        variables
    );

    React.useEffect(() => {
        if (csv.csvData && csv.isSuccess) {
            exportToCsv(
                `saksbehandlingstid_${format(new Date(), 'ddMMyyyy')}`,
                csv.csvData,
                csv.headers
            );
            csv.remove();
        }
    }, [csv.csvData]);

    const handleDownloadClick = () => {
        csv.refetch();
    };

    React.useEffect(() => {
        if (saksbehandlingstid.saksbehandlingstid.length !== 0) {
            const saksbehandlingDays =
                saksbehandlingstid.saksbehandlingstid.map((a) => a.antallDager);
            const averageDays = average(saksbehandlingDays);
            setAverageDays(averageDays);
        } else setAverageDays(undefined);
    }, [saksbehandlingstid]);

    if (saksbehandlingstid.isLoading) return <LoadingSpinner />;
    return (
        <Grid item={true}>
            <ReactTable
                columns={columns}
                data={
                    saksbehandlingstid.saksbehandlingstid
                        ? saksbehandlingstid.saksbehandlingstid
                        : []
                }
                toolbar={{
                    exportCsv: {
                        handleDownloadClick: () => handleDownloadClick(),
                        loadingRequest: csv.isLoading,
                    },
                    dataInfo: saksbehandlingstid.isFetching ? (
                        <CircularProgress
                            color='primary'
                            size={20}
                            title='Oppdaterer resultater'
                        />
                    ) : (
                        <p>
                            {`${
                                saksbehandlingstid.saksbehandlingstid.length
                                    ? saksbehandlingstid.saksbehandlingstid
                                          .length
                                    : 0
                            } fullførte planbehandlinger funnet`}
                        </p>
                    ),
                }}
            />
        </Grid>
    );
}
