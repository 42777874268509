import {Sak} from '../../../hooks/arealplaner/types';
import useBehandlinger from '../../../hooks/behandlinger/useBehandlinger';
import useMeta from '../../../hooks/meta/useMeta';
import {useHasMittInnspillProject} from '../../../hooks/mittinnspill/useHasMittInnspillProject';
import usePlanNotificationHelpers from '../PlanNotifications/usePlanNotificationHelpers';

export default function usePlanViewHelpers() {
    const meta = useMeta();
    const planNotificationHelpers = usePlanNotificationHelpers();
    const {data: behandlinger} = useBehandlinger();
    const hasMittInnspillProject = useHasMittInnspillProject();

    // Viser kun tidsakse og saksdokumenter for planstatus:
    // Planforslag
    // Planlegging igangsatt
    // Kun opprettet sak (foreløpig ikke annonsert)
    // Vedtatt plan med utsatt rettsvirkning
    const shouldShowTidsakse = (PlanStatusId: number, sak?: Sak) => {
        if (!sak) return false;
        return meta.hasPlandialog() && [0, 1, 2, 6].includes(PlanStatusId);
    };

    const shouldShowSaksdokumenter = (PlanStatusId: number, sak?: Sak) => {
        return shouldShowTidsakse(PlanStatusId, sak);
    };

    // Viser kun tilbakemelding for planstatus 2 eller 1 hvis dette er satt
    // Viser kun tilbakemelding i høringsperiode hvis det er angitt
    const shouldShowTilbakemelding = (PlanStatusId: number) => {
        const showBasedOnHoringsperiode =
            meta.hasTilbakemeldingBegrensetHoringsperiode()
                ? !!planNotificationHelpers.getHoringsperioder({behandlinger})
                      ?.length
                : true;

        const rightPlanstatus =
            PlanStatusId === 2 ||
            (meta.hasFeature('tilbakemelding_ved_planstatus_1') &&
                PlanStatusId === 1);
        const prerequisits = showBasedOnHoringsperiode && rightPlanstatus;

        if (!meta.hasTilbakemelding() && meta.hasMittInnspill())
            return prerequisits && hasMittInnspillProject;

        return (
            prerequisits &&
            (meta.hasTilbakemelding() ||
                meta.hasTilbakemeldingEgetSkjema() ||
                meta.hasMittInnspill())
        );
    };

    return {
        shouldShowTidsakse,
        shouldShowSaksdokumenter,
        shouldShowTilbakemelding,
    };
}
