//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppCalendarIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path fillRule='evenodd' clipRule='evenodd' d='M4 6H6V4H11V6H13V4H18V6H20V4H22V24H24V2H20V0H18V2H13V0H11V2H6V0H4V2H0V24H18V22H2V4H4V6Z' style={props.secondaryStyle} />
            <path fillRule='evenodd' clipRule='evenodd' d='M4 13H8V9H4V13ZM10 13H14V9H10V13ZM20 13H16V9H20V13ZM4 19H8V15H4V19ZM14 19H10V15H14V19ZM16 19H20V15H16V19Z' style={props.primaryStyle} />
        </g>
    ),
    'AppCalendarIcon' // aria-label
);
