import {useContext} from 'react';
import {NkAuthContext} from '../context/NkAuthContext';

export const useNkAuth = () => {
  const context = useContext(NkAuthContext);
  if (context == null) {
    throw new Error('useNkAuth can only be used under a  NkMsalProvider');
  }
  return context;
};
