//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const MapLayersIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path fillRule='evenodd' clipRule='evenodd' d='M0 11.47L2.70001 10.02L4.83002 11.01L3 12.01L11.99 16.01L20.99 12H21L19.27 11.01L21.27 10L23.99 11.48V12.47L12 18.01L0.0100098 12.47L0 11.47ZM0 16.4699L2.70001 15.02L4.83002 16.01L3 17.01L11.99 21.01L20.99 17H21L19.27 16.01L21.27 15L23.99 16.4799V17.4699L12 23.01L0.0100098 17.4699L0 16.4699Z' style={props.secondaryStyle} />
            <path d='M20.99 7L12 11L3.01001 7L12.01 3.01001L21.01 7H21H20.99ZM12 1L0 6.44995V7.44995L12 12.99L23.99 7.45996V6.45996L12 1Z' style={props.primaryStyle} />
        </g>
    ),
    'MapLayersIcon' // aria-label
);
