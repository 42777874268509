/* eslint-disable no-process-env */
declare global {
    interface Window {
        env: Record<string, string>;
    }
}

export class config {
    public static get(key: string) {
        if (window.env) {
            return window.env[key] ?? process.env[key];
        }
        return process.env[key];
    }
}
