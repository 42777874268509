import {config} from '@norkart/core-appconfig';

declare global {
    interface Window {
        config: any;
    }
}

window.config = window.config || {};

interface PlanregisterConfig {
    token: string;
    url: string;
    customer: string;
    profile: string;
    client_id: string;
    environment: string;
    authority: string;
    api_scope: string;
}

interface MatrikkelkartConfig {
    token: string;
    url: string;
}

interface Config {
    planregister: PlanregisterConfig;
    matrikkelkart: MatrikkelkartConfig;
    mapbox: {
        accessToken: string;
        styleUrl: string;
    };
    tjenestepanel: {
        appid: string;
    };
    kommuneinfo: {
        url: string;
    };
    applicationInsightsKey: string;
    applicationInsightsConnectionString: string;
    mittinnspill: {
        apiUrl: string;
        url: string;
    };
}

export const getSetting: any = (key: string) => {
    return config.get(key);
};

const arealplanerConfig: Config = {
    planregister: {
        token: getSetting('NX_API_KEY') || '',
        url: getSetting('NX_API_BASE_URL') || '',
        customer: getSetting('NX_API_CUSTOMER') || '',
        profile: getSetting('NX_WAAPI_PROFILE') || '',
        client_id: getSetting('NX_CLIENT_ID') || '',
        environment: getSetting('NX_HOSTING_ENVIRONMENT') || '',
        authority: getSetting('NX_MSAL_AUTHORITY') || '',
        api_scope: getSetting('NX_API_SCOPE') || '',
    },
    matrikkelkart: {
        url: getSetting('NX_MATRIKKELKART_URL') || '',
        token: getSetting('NX_API_KEY') || '',
    },
    mapbox: {
        accessToken: getSetting('NX_MAP_ACCESSTOKEN'),
        styleUrl: getSetting('NX_MAPBOX_STYLE'),
    },
    kommuneinfo: {
        url: getSetting('NX_KOMMUNEINFO'),
    },
    tjenestepanel: {
        appid: getSetting('NX_TJENESTEPANEL_APPID'),
    },
    applicationInsightsKey: getSetting('NX_APPLICATION_INSIGHTS_KEY'),
    applicationInsightsConnectionString: getSetting(
        'NX_APPLICATION_INSIGHTS_CS'
    ),
    mittinnspill: {
        apiUrl: getSetting('NX_MITTINNSPILL_API_BASE_URL'),
        url: getSetting('NX_MITTINNSPILL_BASE_URL'),
    },
};

export default arealplanerConfig;
