import * as React from 'react';
import {CircularProgress, Grid} from '@mui/material';
import {format} from 'date-fns';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import ReactTable from '../../../../components/ReactTable';
import {planstatusLovreferanseQuery} from '../../../../hooks/customReports/customReportQueries';
import usePlanstatusLovreferaseGraphQL from '../../../../hooks/customReports/usePlanstatusLovreferaseGraphQL';
import {usePlanstatusLovreferaseGraphQLCsv} from '../../../../hooks/customReports/usePlanstatusLovreferaseGraphQLCsv';
import {exportToCsv} from '../ReportTools/ClientsideCsv';
import TilstandLovreferanseColumns from './TilstandLovreferanseColumns';

export default function TilstandLovreferanseReport() {
    const csv = usePlanstatusLovreferaseGraphQLCsv();
    const columnsLovref = TilstandLovreferanseColumns();
    const tilstandLovref = usePlanstatusLovreferaseGraphQL(
        planstatusLovreferanseQuery
    );

    React.useEffect(() => {
        if (csv.csvData && csv.isSuccess) {
            exportToCsv(
                `planstatus_lovreferanse_report_${format(
                    new Date(),
                    'ddMMyyyy'
                )}`,
                csv.csvData,
                csv.headers
            );
            csv.remove();
        }
    }, [csv.csvData]);

    const handleDownloadClick = () => {
        csv.refetch();
    };

    if (tilstandLovref.isLoading) return <LoadingSpinner />;
    return (
        <Grid item={true}>
            <ReactTable
                tableContainerProps={{
                    sx: {
                        overflow: 'visible',
                    },
                }}
                separateFirstColumn={true}
                columns={columnsLovref}
                data={
                    tilstandLovref.tilstandLovref
                        ? tilstandLovref.tilstandLovref
                        : []
                }
                toolbar={{
                    exportCsv: {
                        handleDownloadClick: () => handleDownloadClick(),
                        loadingRequest: undefined, //csv.isLoading
                    },
                    dataInfo: tilstandLovref.isFetching ? (
                        <CircularProgress
                            color='primary'
                            size={20}
                            title='Oppdaterer resultater'
                        />
                    ) : (
                        <></>
                    ),
                }}
            />
        </Grid>
    );
}
