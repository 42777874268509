import {useMutation, useQueryClient} from 'react-query';
import {dispensasjonerDeleteDispensasjon} from '@norkart/nkapi-arealplaner-api';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useDeleteDispensasjon() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const arealplanId = useSelectedArealplanId();
    const {notify} = useErrorHandler();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useMutation(
        (dispensasjonId: number) =>
            dispensasjonerDeleteDispensasjon({
                client: authorizedClient,
                path: {kundeId, dispensasjonId: dispensasjonId},
            }),
        {
            onError: notify,
            onSuccess: () =>
                queryClient.invalidateQueries([
                    'dispensasjoner',
                    {kundeId, arealplanId},
                ]),
        }
    );
}
