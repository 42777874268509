//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppExternalIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path fillRule='evenodd' clipRule='evenodd' d='M20 14V20H4V4H9H10V2H9H2V22H22V14H20Z' style={props.secondaryStyle} />
            <path d='M20 5.5L12.5 13L11 11.5L18.5 4H14V2H22V10H20V5.5Z' style={props.primaryStyle} />
        </g>
    ),
    'AppExternalIcon' // aria-label
);
