import {ReactNode, useEffect, useState} from 'react';
import {NkAuthContext} from '../../context/NkAuthContext';
import {getTokenFetchFunction} from '../../getApiTokenFetchFunction';
import {
  Authorization,
  AuthorizationMultiCustomer,
  getAuthorization,
  getAuthorizationMultiCustomer,
} from '../../getAuthorization';
import {useIsLoggedIn} from '../../hooks';
import {changeCustomerContext, getDefaultMultiCustomerContext} from '../../multiCustomerContext';
import {AuthData, CustomerContext, LoadingStatus} from '../../types';

interface NkAuthContextProviderProps {
  authData: AuthData;
  children?: ReactNode;
}

export const NkAuthContextProvider = ({authData, children}: NkAuthContextProviderProps) => {
  const isAuthenticated = useIsLoggedIn();

  const queryParameters = new URLSearchParams(window.location.search);
  const customerContextIdQueryParameter = queryParameters.get('nk-auth-customerContextId') ?? undefined;

  const [customerContext, setCustomerContext] = useState<CustomerContext | undefined>(undefined);

  const [isFetching, setIsFetching] = useState<LoadingStatus>('notStarted');

  const [authorizationMultiCustomer, setAuthorizationMultiCustomer] = useState<AuthorizationMultiCustomer | undefined>(
    undefined
  );

  const [authorizationSingleCustomer, setAuthorizationSingleCustomer] = useState<Authorization | undefined>(undefined);

  useEffect(() => {
    if (isAuthenticated) {
      getAuthorizationBasedOnMultiOrSingleCustomerContext();
    } else {
      setAuthorizationMultiCustomer(undefined);
      setAuthorizationSingleCustomer(undefined);
    }
  }, [isAuthenticated]);

  const getAuthorizationBasedOnMultiOrSingleCustomerContext = () => {
    setIsFetching('loading');
    if (authData.isMultiCustomerContext && !authorizationMultiCustomer) {
      // Get multicustomercontext authorization
      getAuthorizationMultiCustomer(authData, authData.applicationBackendUrl)
        .then((authResult) => {
          setAuthorizationMultiCustomer(authResult);

          const currentCustomerContext: CustomerContext | undefined = getDefaultMultiCustomerContext(
            authResult.customerContexts,
            customerContextIdQueryParameter
          );

          setCustomerContext(currentCustomerContext);
        })
        .finally(() => setIsFetching('completed'));
    } else if (!authData.isMultiCustomerContext && !authorizationSingleCustomer) {
      // Get authorization for single customer context
      getAuthorization(authData, authData.applicationBackendUrl)
        .then((authResult) => {
          setAuthorizationSingleCustomer(authResult);

          const singleCustomerContext: CustomerContext = {
            getBooleanSetting: authResult.getBooleanSetting,
            getNumberSetting: authResult.getNumberSetting,
            getStringSetting: authResult.getStringSetting,
            getSettings: authResult.getSettings,
            CustomerId: authResult.customerId,
            CustomerName: authResult.customerName,
          };

          setCustomerContext(singleCustomerContext);
        })
        .finally(() => setIsFetching('completed'));
    }
  };

  const authorization = authData.isMultiCustomerContext ? authorizationMultiCustomer : authorizationSingleCustomer;

  const availableCustomerContexts = authData.isMultiCustomerContext
    ? authorizationMultiCustomer?.customerContexts ?? []
    : customerContext
    ? [customerContext]
    : [];

  return (
    <NkAuthContext.Provider
      value={{
        isAuthorized: !!authorization?.isAuthorized,
        fetchingAuthorizationResultStatus: isFetching,
        profile: authData.setupNorkartProfile,
        authData: authData,
        getToken: getTokenFetchFunction(authData.msalApp, authData, authData.tokenType ?? 'accessToken'),
        customerContextData: {
          currentCustomerContext: customerContext,
          setCurrentCustomerContext: (customerContext: CustomerContext) =>
            changeCustomerContext(
              customerContext,
              availableCustomerContexts,
              setCustomerContext,
              !!authData.isMultiCustomerContext
            ),
          availableCustomerContexts: availableCustomerContexts,
        },
      }}
    >
      {children}
    </NkAuthContext.Provider>
  );
};
