import React, {useMemo} from 'react';
import NkaDropdown, {SelectedOption} from '../../../../components/Dropdown';
import useMeta from '../../../../hooks/meta/useMeta';
import FilterLabel from './FilterLabel';
import {useFilter} from './useFilter';

export const OpprinneligAdministrativEnhetFilter = () => {
    const meta = useMeta();
    const filter = useFilter('opprinneligAdministrativEnhet');

    const getSelectedValue = useMemo<string>(() => {
        return filter.get() || '';
    }, [filter]);

    const handleChange = (option: SelectedOption) => {
        option ? filter.set(String(option.value)) : filter.clear();
    };

    return (
        <>
            <FilterLabel>Opprinneling administrativ enhet</FilterLabel>
            <NkaDropdown
                size='small'
                MenuProps={{disableScrollLock: true}}
                style={{width: 300}}
                value={getSelectedValue}
                options={meta.data.kommuner.map((k) => {
                    return {
                        value: k.kommuneNummer,
                        label: `${k.kommuneNummer} - ${k.kommuneNavn}`,
                    };
                })}
                handleChange={handleChange}
                nullOption={true}
            />
        </>
    );
};
