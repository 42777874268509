import {formatDate} from '../../../utils/formatDate';
import {formatNasjonalArealplanId} from '../../../utils/formatNasjonalArealplanId';
import {DokumentUtvidet} from './PlanDocumentListDocuments';

export const getDokumentKilde = (dokument: DokumentUtvidet) => {
    return dokument.behandling
        ? `Behandling: ${formatDate(dokument.behandling.fraDato)} ${
              dokument.behandling.behandlingstype
          }`
        : dokument.dispensasjon
        ? `Dispensasjon: ${formatDate(dokument.dispensasjon.vedtaksdato)} ${
              dokument.dispensasjon.dispensasjonType
          }`
        : dokument.planforhold
        ? `Arvet fra plan ${formatNasjonalArealplanId({
              komnr: dokument.planforhold.komnr,
              planId: dokument.planforhold.planId,
          })}`
        : 'Plan';
};
