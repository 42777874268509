import * as React from 'react';
import {useQuery} from 'react-query';
import {useFetchTokenHeaderWithAuthData} from '@norkart/nk-auth';
import {graphQLRequest} from '../../services/api';
import {PlanstatusLovreferanseReport} from './types';
import {planstatusLovreferanseQuery} from './customReportQueries';

export function usePlanstatusLovreferaseGraphQLCsv() {
    const headings = [
        'LovreferanseId',
        'Lovreferanse',
        'Gjeldende',
        'Planer under arbeid',
        'Ubestemt',
        'Utgått',
    ];
    const getHeaders = useFetchTokenHeaderWithAuthData();

    const reactQuery = useQuery<any>(
        ['planstatusLovreferanseReportGraphQLCsv'],
        async () => {
            const headers = await getHeaders();
            return await graphQLRequest(
                planstatusLovreferanseQuery,
                null,
                headers
            );
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
            cacheTime: 0,
            keepPreviousData: false,
        }
    );

    const csvArray = React.useMemo(() => {
        if (reactQuery.data) {
            const planstatusLovreferanseReport: PlanstatusLovreferanseReport[] =
                reactQuery.data.planstatusLovreferanseReport;

            const newLines = planstatusLovreferanseReport.reduce(
                (acc: any, d) => {
                    acc.push(
                        [
                            d.lovreferanse.id,
                            d.lovreferanse.beskrivelse,
                            d.gjeldende,
                            d.underArbeid,
                            d.ubestemt,
                            d.utgatt,
                        ].join(';')
                    );
                    return acc;
                },
                []
            );

            return newLines;
        }
    }, [reactQuery.data]);

    return {...reactQuery, csvData: csvArray, headers: headings};
}
