import * as React from 'react';
import {connect} from 'react-redux';
import {
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {orderBy} from 'lodash-es';
import {hideDialog} from '../../../store/dialog/actions';
import {ConnectedReduxProps} from '../../../store';
import {DokumentUtvidet} from '../../plan/PlanDocumentList/PlanDocumentListDocuments';
import DocumentTable from '../../../components/DocumentTable';
import DesktopContainer from '../../../containers/DesktopContainer';
import MobileContainer from '../../../containers/MobileContainer';
import DocumentList from '../../../components/DocumentList';

export interface Props extends ConnectedReduxProps {
    documents: DokumentUtvidet[];
    open: boolean;
    title?: string;
}

function DocumentListDialog({
    documents,
    open,
    title = 'Dokumenter',
    dispatch,
}: Props) {
    const onClose = () => {
        hideDialog(dispatch);
    };

    return (
        <Dialog
            open={open}
            aria-labelledby='form-dialog-title'
            fullWidth={true}
            maxWidth='md'
            PaperProps={{style: {overflowY: 'visible'}}}
            disableScrollLock={true}
        >
            <DialogTitle id='form-dialog-title'>
                <Grid
                    container={true}
                    justifyContent='space-between'
                    spacing={8}
                    alignItems='center'
                >
                    <Grid item={true}>{title}</Grid>
                    <Grid item={true}>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{overflowY: 'auto'}}>
                <DesktopContainer>
                    <DocumentTable
                        dokumenter={orderBy(
                            documents,
                            ['rekkefolgeBehandling', 'dokumenttype'],
                            ['asc', 'asc']
                        )}
                        options={{actions: false, header: true}}
                    />
                </DesktopContainer>
                <MobileContainer>
                    <DocumentList documents={documents} />
                </MobileContainer>
            </DialogContent>
        </Dialog>
    );
}

export default connect()(DocumentListDialog);
