import difference from '@turf/difference';
import {
    Feature,
    FeatureCollection,
    GeoJsonProperties,
    MultiPolygon,
    Polygon,
} from 'geojson';
import {createMapboxGeojsonLayer} from './layerHelpers';

export function getDiffLayers(
    vertnivLayers: {
        combined: Feature<MultiPolygon | Polygon>;
        vertniv1: Feature<MultiPolygon | Polygon> | undefined;
        vertniv2: Feature<MultiPolygon | Polygon> | undefined;
        vertniv3: Feature<MultiPolygon | Polygon> | undefined;
    },
    municipalityGeom: Feature<MultiPolygon | Polygon>
) {
    const {vertniv1, vertniv2, vertniv3} = vertnivLayers;

    return {
        combined: createDiffLayer([vertnivLayers.combined], municipalityGeom),
        vertniv1:
            vertniv1 &&
            createDiffLayer([vertniv1], municipalityGeom, 'diffVertniv1'),
        vertniv2:
            vertniv2 &&
            createDiffLayer([vertniv2], municipalityGeom, 'diffVertniv2'),
        vertniv3:
            vertniv3 &&
            createDiffLayer([vertniv3], municipalityGeom, 'diffVertniv3'),
        vertniv1and2:
            vertniv1 &&
            vertniv2 &&
            createDiffLayer(
                [vertniv1, vertniv2],
                municipalityGeom,
                'diffVertniv12'
            ),
        vertniv1and3:
            vertniv1 &&
            vertniv3 &&
            createDiffLayer(
                [vertniv1, vertniv3],
                municipalityGeom,
                'diffVertniv13'
            ),
        vertniv2and3:
            vertniv2 &&
            vertniv3 &&
            createDiffLayer(
                [vertniv2, vertniv3],
                municipalityGeom,
                'diffVertniv23'
            ),
        vertniv1and2and3:
            vertniv1 &&
            vertniv2 &&
            vertniv3 &&
            createDiffLayer(
                [vertniv1, vertniv2, vertniv3],
                municipalityGeom,
                'diffVertniv123'
            ),
    };
}

export function createDiffLayer(
    planPolygons: Feature<MultiPolygon | Polygon>[],
    municipalityLayer: Feature<MultiPolygon | Polygon>,
    layerId?: string
) {
    const featureCollection: FeatureCollection<
        Polygon | MultiPolygon,
        GeoJsonProperties
    > = {
        type: 'FeatureCollection',
        features: [municipalityLayer, ...planPolygons],
    };

    const diff = difference(featureCollection);

    if (diff) {
        return createMapboxGeojsonLayer(
            layerId || 'difflayer',
            diff,
            '#fff',
            '#444',
            1
        );
    } else {
        throw new Error('creation of diffLayer failed');
    }
}
