import {styled, Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps} from '@mui/material';
import {isString} from 'lodash-es';
import {forwardRef} from 'react';
import ToiPopper, {ToiPopperVariant} from '../ToiPopper/ToiPopper';
import ToiTypography from '../ToiTypography/ToiTypography';
import {toiTooltipStyle} from './toiTooltipStyle';

export type ToiToolTipVariant = 'tooltip' | 'toggletip';
export type ToiToolTipPlacement = 'left' | 'right' | 'top-start' | 'top' | 'bottom-start' | 'bottom';

type OwnProps = {
  variant?: ToiToolTipVariant;
  placement?: ToiToolTipPlacement;
};

export type ToiTooltipProps = Omit<MuiTooltipProps, keyof OwnProps | 'component'> & OwnProps;

const StyledTooltip = styled(MuiTooltip)(({theme}) => {
  return toiTooltipStyle(theme);
});

export const ToiToolTip = forwardRef<HTMLDivElement, ToiTooltipProps>((props, ref) => {
  const {PopperProps, variant, title, ...restProps} = props;
  const toiPopperVariant: ToiPopperVariant = variant as ToiPopperVariant;
  const titleWithTypography = isString(title) ? <ToiTypography variant='body3'>{title}</ToiTypography> : title;

  return (
    <StyledTooltip
      title={titleWithTypography}
      ref={ref}
      PopperComponent={(PopperProps) => {
        return <ToiPopper variant={toiPopperVariant} {...PopperProps} />;
      }}
      {...restProps}
    />
  );
});

export default ToiToolTip;
