import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ApplicationState} from '../../../store';
import {resetMapState} from '../../../store/map/actions';
import PlanInfo from './PlanInfo';

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetMap: () => dispatch(resetMapState()),
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanInfo);
