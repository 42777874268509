import {Reducer} from '@reduxjs/toolkit';
import {
    DialogState,
    HideDialogAction,
    ShowDialogAction,
    DialogActionTypes as actionTypes,
} from './types';

const initialState: DialogState = {
    dialogType: null,
    dialogProps: {},
    open: false,
};

type KnownActions = ShowDialogAction | HideDialogAction;

const reducer: Reducer<DialogState, KnownActions> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case actionTypes.SHOW_DIALOG:
            return {
                ...state,
                dialogType: action.payload.dialogType,
                dialogProps: action.payload.dialogProps,
                open: true,
            };
        case actionTypes.HIDE_DIALOG:
            return {...state, open: false};
        default:
            return state;
    }
};

export {reducer as dialogReducer};
