import React from 'react';
import {BackButtonText} from './const';
import HitElement from './HitElement';

export default function HitList(props) {
    if (props.showNoResults) {
        return (
            <div
                className={
                    props.mobileFullscreen
                        ? 'list-group result-list-fullscreen'
                        : 'list-group result-list'
                }
            >
                <button
                    type='button'
                    disabled
                    className='list-group-item list-error'
                >
                    {props.noHitsMessage}
                </button>
            </div>
        );
    }
    if (!props.hits.length || !props.displayHits) {
        return null;
    }

    const calculateIndex = (idx) => {
        if (
            (backButtonCheck && !streetButtonCheck) ||
            (!backButtonCheck && streetButtonCheck)
        ) {
            return idx + 1;
        } else if (backButtonCheck && streetButtonCheck) {
            return idx + 2;
        } else {
            return idx;
        }
    };

    const backButtonIndex = 0;
    const streetButtonIndex = 1;

    const streetButtonCheck =
        props.subSearch && props.enableStreetButton && props.isSubSearch;

    const backButtonCheck =
        props.subSearch &&
        props.subSearch.enableBackButton &&
        props.hits.some((x) => props.subSearch.enableBackButton(x)) &&
        props.isSubSearch;
    return (
        <div
            className={
                props.mobileFullscreen
                    ? 'list-group result-list-fullscreen '
                    : 'list-group result-list ' + props.theme
            }
            style={props.hitListStyle}
        >
            {backButtonCheck ? (
                <HitElement
                    onMouseEnter={props.setHoverIndex}
                    text={BackButtonText}
                    index={backButtonIndex}
                    selected={backButtonIndex}
                    hover={backButtonIndex === props.hoverIndex}
                    hitSelected={props.goBack}
                    enableBackButton={props.subSearch.enableBackButton}
                    buttonTheme={props.buttonTheme}
                />
            ) : null}

            {streetButtonCheck && props.hits.length > 0 ? (
                <HitElement
                    onMouseEnter={props.setHoverIndex}
                    text={props.hits[0].Text.match(/[^0-9]+/)}
                    index={streetButtonIndex}
                    selected={streetButtonIndex === props.selectedIndex}
                    hover={streetButtonIndex === props.hoverIndex}
                    hitSelected={props.returnStreetName}
                    buttonTheme={props.buttonTheme}
                    enableStreetButton={streetButtonCheck}
                />
            ) : null}

            {props.hits.map(function (hit, idx) {
                const index = calculateIndex(idx);
                const invalidAddress =
                    hit.PayLoad &&
                    hit.PayLoad.Utgatt &&
                    !props.disableNewPropertyRedirect;
                const showNextMatrikkel =
                    invalidAddress ||
                    (hit.PayLoad &&
                        hit.PayLoad.Utgatt &&
                        props.disableNewPropertyRedirect);

                return (
                    <HitElement
                        key={index}
                        text={
                            showNextMatrikkel
                                ? `${hit.Text} (Utgått${
                                      hit.PayLoad.NyMatrikkelenhet
                                          ? ', nytt mnr: ' +
                                            parseEiendomsident(
                                                hit.PayLoad.NyMatrikkelenhet
                                            ) +
                                            ')'
                                          : ')'
                                  }`
                                : hit.PostalArea
                                ? hit.Text + ` (${hit.PostalArea})`
                                : hit.Text
                        }
                        index={index}
                        disabled={
                            invalidAddress && !hit.PayLoad.NyMatrikkelenhet
                        }
                        hover={
                            invalidAddress && !hit.PayLoad.NyMatrikkelenhet
                                ? false
                                : index === props.hoverIndex
                        }
                        setHoverIndex={props.setHoverIndex}
                        selected={
                            invalidAddress && !hit.PayLoad.NyMatrikkelenhet
                                ? false
                                : index === props.selectedIndex
                        }
                        onMouseEnter={
                            invalidAddress && !hit.PayLoad.NyMatrikkelenhet
                                ? (e) => {}
                                : props.setHoverIndex
                        }
                        hitSelected={
                            invalidAddress && !hit.PayLoad.NyMatrikkelenhet
                                ? (e) => {}
                                : props.hitSelected
                        }
                    />
                );
            })}
        </div>
    );
}

export function parseEiendomsident(ident) {
    if (!ident) {
        return false;
    }
    const fnr = parseInt(ident.substring(13, 17), 10);
    const snr = parseInt(ident.substring(17), 10);
    const obj = {
        knr: parseInt(ident.substring(0, 4), 10),
        gnr: parseInt(ident.substring(4, 9), 10),
        bnr: parseInt(ident.substring(9, 13), 10),
        fnr: fnr !== 0 ? fnr : 0,
        snr: snr !== 0 ? snr : 0,
    };
    return ToTextFormatedMnr(obj);
}

function ToTextFormatedMnr(mnr) {
    const textParts: any = [];
    let text;
    if (mnr.gnr) {
        textParts.push(mnr.gnr);
    }
    if (mnr.bnr) {
        textParts.push(mnr.bnr);
    }
    if (mnr.fnr || mnr.snr) {
        textParts.push(mnr.fnr);
    }
    if (mnr.snr) {
        textParts.push(mnr.snr);
    }
    if (mnr.knr) {
        text = mnr.knr + '- ' + textParts.join('/');
    }
    return text;
}
