import {OwnProps, ToiTypographyVariant, TypographyAttributes} from '../ToiTypography/ToiTypography';

export const validateTypographyVariant = (variant: ToiTypographyVariant) => {
  const unsupportedVariants = ['h5', 'h6', 'string', 'overline'];
  unsupportedVariants.includes(variant) && console.error(`The variant ${variant} is not suppoerted in ToiTypography`);
};

export const validateTypographyAttributes = (props: OwnProps) => {
  const typographyAttributes: {[key in keyof TypographyAttributes]: boolean | undefined} = {
    uppercase: props.uppercase,
    bold: props.bold,
    italic: props.italic,
    light: props.light,
    semibold: props.semibold,
  };
  const selectedAttributes: (keyof TypographyAttributes)[] = (
    Object.keys(typographyAttributes) as (keyof TypographyAttributes)[]
  ).filter((att) => typographyAttributes[att]);

  if (selectedAttributes.filter((att) => att).length > 1)
    console.error(
      'Do not combine several toi typography attributes, i.e. bold and italic. It is not a valid part of the typography set.'
    );

  switch (props.variant) {
    case 'h1':
      verifySupport(['uppercase'], selectedAttributes, 'h1');
      break;
    case 'h2':
      verifySupport([], selectedAttributes, 'h2');
      break;
    case 'h3':
      verifySupport([], selectedAttributes, 'h3');
      break;
    case 'h4':
      verifySupport([], selectedAttributes, 'h4');
      break;
    case 'subtitle1':
      verifySupport([], selectedAttributes, 'subtitle1');
      break;
    case 'subtitle2':
      verifySupport([], selectedAttributes, 'subtitle2');
      break;
    case 'button':
      verifySupport([], selectedAttributes, 'button');
      break;
    case 'inherit':
      verifySupport([], selectedAttributes, 'inherit');
      break;
    case 'body1':
      verifySupport(['light', 'italic'], selectedAttributes, 'body1');
      break;
    case 'body2':
      verifySupport(['light', 'italic', 'bold'], selectedAttributes, 'body2');
      break;
    case 'body3':
      verifySupport(['light', 'italic', 'bold'], selectedAttributes, 'body3');
      break;
    case 'label':
      verifySupport(['light', 'semibold'], selectedAttributes, 'body3');
      break;
  }
};

const verifySupport = (
  supportedAttributes: (keyof TypographyAttributes)[],
  selectedAttributes: (keyof TypographyAttributes)[],
  selectedVariant: ToiTypographyVariant
) => {
  const nonSupportedAttributes = selectedAttributes.filter((att) => !supportedAttributes.includes(att));
  nonSupportedAttributes.length > 0 && printErrorMessage(selectedVariant, nonSupportedAttributes);
};

const printErrorMessage = (
  chosenVariant: ToiTypographyVariant,
  nonSupportedAttributes: (keyof TypographyAttributes)[]
) => {
  const errorMsg = `ToiTypography with variant ${chosenVariant} does not support the passed attribute(s): ${nonSupportedAttributes.join(
    ', '
  )}`;
  console.error(errorMsg);
};
