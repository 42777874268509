import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchPlanWMSLayer,
    fetchWmsLayers,
} from '../../features/map/helpers/wmsHelpers';
import {getMapState} from '../../store';
import {
    fetchWms,
    fetchWmsError,
    fetchWmsSuccess,
} from '../../store/map/actions';
import {Arealplan} from '../arealplaner/types';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useMeta from '../meta/useMeta';
import useMidlertidigForbud from '../midlertidigForbud/useMidlertidigForbud';

export default function useWmsManager() {
    const meta = useMeta();
    const selectedArealplanId = useSelectedArealplanId();
    const midlertidigForbud = useMidlertidigForbud(selectedArealplanId);
    const dispatch = useDispatch();
    const mapState = useSelector(getMapState);

    const [initPlan, setInitPlan] = useState<Arealplan | undefined>();
    const [init, setInit] = useState<boolean>();

    useEffect(() => {
        if (meta.isFetched) {
            if (initPlan && midlertidigForbud.isFetched) {
                initForPlan(initPlan);
                setInitPlan(undefined);
            } else if (init) {
                initForMunicipality();
                setInit(false);
            }
        }
    }, [meta.isFetched, midlertidigForbud.isFetched, init, initPlan]);

    function triggerInitForPlan(plan: Arealplan) {
        setInitPlan(plan);
    }

    function triggerInitForMunicipality() {
        setInit(true);
    }

    /** Set up state to show wms layers for the given plan */
    function initForPlan(plan: Arealplan) {
        //Check if planWmsLayers are already fetched for this planid
        dispatch(fetchWms());
        fetchPlanWMSLayer(
            mapState.wmsLayerState,
            plan,
            plan.vertikalniva.map((vert) => vert.id),
            (data) => dispatch(fetchWmsSuccess(data)),
            () => dispatch(fetchWmsError()),
            midlertidigForbud.data.length > 0,
            meta.komnr,
            meta.data.kartlaginnstillinger
        );
    }

    /** Set up state to show all wms layers for the municipality */
    function initForMunicipality() {
        dispatch(fetchWms());
        fetchWmsLayers(
            mapState.wmsLayerState,
            (data) => dispatch(fetchWmsSuccess(data)),
            () => dispatch(fetchWmsError()),
            meta.komnr,
            meta.data.kartlaginnstillinger
        );
    }

    return {triggerInitForPlan, triggerInitForMunicipality};
}
