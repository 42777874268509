import {IPublicClientApplication} from '@azure/msal-browser';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CustomNavigationClient} from './CustomNavigationClient';

interface Props {
  msalApp: IPublicClientApplication;
  children: React.ReactNode;
}

/**
 *  https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/typescript-sample/src/App.tsx
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
export function ClientSideNavigation({msalApp, children}: Props) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalApp.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
