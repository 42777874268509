import * as React from 'react';
import {Typography, styled} from '@mui/material';
import ExpansionPanel from '../../../components/ExpansionPanel';
import {formatDate} from '../../../utils/formatDate';
import SakItem from '../../sak/SakList/SakItem';
import {Dispensasjon} from '../../../hooks/dispensasjoner/types';
import DocumentList from '../../../components/DocumentList';
import JournalpostItem from '../../sak/Journalpost/JournalpostItem';

type Props = {
    dispensasjon: Dispensasjon;
    expandedOptions?: {
        onChange?: (isExpanded: boolean) => void;
        expandedId?: number;
        allowMultipleExpanded?: boolean;
        rowId?: number;
    };
};

const PropertyWrapper = styled('div')({
    padding: '3px 0',
});
const PropertyHeader = styled(Typography)({fontWeight: 500});

const DispensasjonListItem = ({dispensasjon, expandedOptions}: Props) => {
    const date = formatDate(dispensasjon.vedtaksdato);
    return (
        <ExpansionPanel
            extraInfo={date}
            title={dispensasjon.dispensasjonType}
            showBorderWhenOpen={true}
            expandedOptions={expandedOptions}
            bgColor='white'
        >
            <>
                <div style={{paddingLeft: '15px'}}>
                    {dispensasjon.vedtak && (
                        <PropertyWrapper>
                            <PropertyHeader>Vedtak:</PropertyHeader>
                            <Typography>{dispensasjon.vedtak}</Typography>
                        </PropertyWrapper>
                    )}

                    {dispensasjon.sak && (
                        <PropertyWrapper>
                            <PropertyHeader>Saksnr:</PropertyHeader>
                            <Typography>
                                <SakItem sak={dispensasjon.sak} />
                            </Typography>
                        </PropertyWrapper>
                    )}
                    {dispensasjon.beskrivelse && (
                        <PropertyWrapper>
                            <PropertyHeader>Beskrivelse:</PropertyHeader>
                            <Typography>{dispensasjon.beskrivelse}</Typography>
                        </PropertyWrapper>
                    )}

                    {dispensasjon.dispFra && (
                        <PropertyWrapper>
                            <PropertyHeader>
                                Formål/hensynsone det dispenseres fra:
                            </PropertyHeader>
                            <Typography>{dispensasjon.dispFra}</Typography>
                        </PropertyWrapper>
                    )}

                    {dispensasjon.journalpostnummer && (
                        <PropertyWrapper>
                            <PropertyHeader>Journalpostnummer:</PropertyHeader>
                            <Typography>
                                <JournalpostItem
                                    journalpostData={
                                        {
                                            journalpostnummer:
                                                dispensasjon.journalpostnummer,
                                            journalpostUrl:
                                                dispensasjon.journalpostUrl,
                                            journalpostUrlIntern:
                                                dispensasjon.journalpostUrlIntern,
                                        } as Partial<Dispensasjon>
                                    }
                                />
                            </Typography>
                        </PropertyWrapper>
                    )}
                    {dispensasjon.dokumenter.length > 0 && (
                        <PropertyWrapper>
                            <PropertyHeader style={{borderBottom: '0px'}}>
                                Dokumenter:
                            </PropertyHeader>
                        </PropertyWrapper>
                    )}
                </div>
                <div style={{marginLeft: '20px', marginTop: '-5px'}}>
                    <DocumentList documents={dispensasjon.dokumenter} />
                </div>
            </>
        </ExpansionPanel>
    );
};

export default DispensasjonListItem;
