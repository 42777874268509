import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {orderBy} from 'lodash-es';
import {Dispensasjon} from '../../../hooks/dispensasjoner/types';
import DocumentTable from '../../../components/DocumentTable';
import JournalpostItem from '../../sak/Journalpost/JournalpostItem';
import {Dokument} from '../../../hooks/dokumenter/types';

type Props = {
    dispensasjon: Dispensasjon;
};
const DispensasjonListDetailPanel = ({dispensasjon}: Props) => {
    const {dispFra, journalpostnummer} = dispensasjon;
    const header = {fontWeight: 500};

    const hasDetailFields = () =>
        dispensasjon.dispFra ||
        dispensasjon.dokumenter?.length ||
        dispensasjon.journalpostnummer;

    return (
        <div
            style={{
                paddingLeft: '80px',
                paddingRight: '20px',
                marginTop: '20px',
                marginBottom: '20px',
            }}
        >
            {hasDetailFields() ? (
                <Box>
                    <Grid
                        container={true}
                        justifyContent='flex-start'
                        spacing={10}
                        sx={{marginBottom: '20px'}}
                    >
                        {dispFra && (
                            <Grid item={true}>
                                <Typography style={header}>
                                    Formål/hensynsone det dispenseres fra
                                </Typography>
                                <Typography>{dispFra}</Typography>
                            </Grid>
                        )}
                        {journalpostnummer && (
                            <Grid item={true}>
                                <Typography style={header}>
                                    Journalpostnummer
                                </Typography>
                                <Typography>
                                    <JournalpostItem
                                        journalpostData={
                                            {
                                                journalpostnummer:
                                                    dispensasjon.journalpostnummer,
                                                journalpostUrl:
                                                    dispensasjon.journalpostUrl,
                                                journalpostUrlIntern:
                                                    dispensasjon.journalpostUrlIntern,
                                            } as Partial<Dispensasjon>
                                        }
                                    />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>

                    {dispensasjon.dokumenter &&
                        dispensasjon.dokumenter.length != 0 && (
                            <>
                                <Typography variant='h3'>Dokumenter</Typography>
                                <DocumentTable
                                    dokumenter={
                                        orderBy(
                                            dispensasjon.dokumenter,
                                            [
                                                'rekkefolgeDispensasjon',
                                                'dokumenttype',
                                            ],
                                            ['asc', 'asc']
                                        ) as Dokument[]
                                    }
                                    dispensasjon={dispensasjon}
                                />
                            </>
                        )}
                </Box>
            ) : (
                <p>Ingen detaljer</p>
            )}
        </div>
    );
};
export default DispensasjonListDetailPanel;
