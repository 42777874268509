import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import * as React from 'react';
import {Field, Form} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {sortBy} from 'lodash-es';
import Checkbox from '../../../components/Checkbox';
import DatePicker from '../../../components/DatePicker';
import FormInputField from '../../../components/FormInputField';
import FormSelectInput from '../../../components/FormSelectInput';
import LoadingSpinner from '../../../components/LoadingSpinner';
import SpinnerButton from '../../../components/SpinnerButton';
import {Arealplan} from '../../../hooks/arealplaner/types';
import {Behandling} from '../../../hooks/behandlinger/types';
import {Dispensasjon} from '../../../hooks/dispensasjoner/types';
import {Dokument} from '../../../hooks/dokumenter/types';
import useArealplanDokumenter from '../../../hooks/dokumenter/useArealplanDokumenter';
import useMeta from '../../../hooks/meta/useMeta';
import {getFileExtension} from '../../../utils/getFileExtension';
import {parseDate} from '../../../utils/parseDate';
import {getDokumentKilde} from '../../plan/PlanDocumentList/helpers';
import {DokumentUtvidet} from '../../plan/PlanDocumentList/PlanDocumentListDocuments';
import useDocumentHelpers from '../useDocumentHelpers';

export interface DocumentFormProps {
    dokument: DokumentUtvidet;
    plandok?: boolean;
    onSubmit: (data: Dokument) => void;
    onDelete: (id) => void;
    onClose: () => void;
    plan?: Arealplan;
    behandling?: Behandling;
    dispensasjon?: Dispensasjon;
}

const WhenFieldChanges = ({field, predictor, set, to}: any) => (
    <Field name={set} subscription={{}}>
        {({input: {onChange}}) => (
            <OnChange name={field}>
                {(value) => {
                    if (predictor(value)) {
                        onChange(to);
                    }
                }}
            </OnChange>
        )}
    </Field>
);

function DocumentForm({
    dokument,
    plandok = false,
    onDelete,
    onClose,
    ...props
}: DocumentFormProps) {
    const meta = useMeta();
    const kilde = getDokumentKilde(dokument);
    const helpers = useDocumentHelpers();
    const {plandokumenter} = useArealplanDokumenter();
    const fileExtention = '.' + getFileExtension(dokument.dokumentnavn);

    const isNameUnique = (id: number, dokumentnavn: string): boolean => {
        let filteredDocs = [] as Dokument[];

        if (props.behandling)
            filteredDocs = props.behandling.dokumenter.filter(
                (doc) => doc.id != id
            );

        if (props.dispensasjon)
            filteredDocs = props.dispensasjon.dokumenter.filter(
                (doc) => doc.id != id
            );

        if (plandok && !filteredDocs.length)
            filteredDocs = plandokumenter.filter((doc) => doc.id != id);

        if (
            filteredDocs.map((doc) => doc.dokumentnavn).includes(dokumentnavn)
        ) {
            return false;
        }

        return true;
    };

    async function onSubmit(data: Dokument) {
        if (!isNameUnique(data.id, data.dokumentnavn))
            return Promise.resolve({dokumentnavn: 'Dokumentet finnes fra før'});
        await props.onSubmit(data);
        onClose();
    }

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={dokument}
            validate={(values) => {
                const errors = {} as any;
                if (!values.dokumentnavn)
                    errors.dokumentnavn = 'Dokumentet må ha et navn';
                if (
                    values.tilgangId <
                    helpers.getDefaultTilgangId(props.behandling)
                )
                    errors.tilgangId =
                        'Dokumentet må ha en av tilgangstypene i listen';
                return errors;
            }}
            render={({
                handleSubmit,
                values,
                submitting,
                pristine,
                errors,
                submitErrors,
            }) => (
                <Box
                    sx={(theme) => ({
                        form: {
                            '& .importantFormLabel': {fontWeight: 600},
                            '& .formInput': {
                                width: '100%',
                                padding: '5px',
                                fontFamily: theme.typography.fontFamily,
                            },
                            '& .formInput:disabled': {
                                backgroundColor: '#f0f0f0',
                            },
                            '& .formInputError': {border: '1px solid red'},
                            '& .textarea': {fontSize: 'inherit'},
                        },
                    })}
                >
                    <form onSubmit={handleSubmit}>
                        <WhenFieldChanges
                            field='visIGjeldendeBestemmelser'
                            predictor={(value) => value}
                            set='visIPlandokumenter'
                            to={false}
                        />
                        <WhenFieldChanges
                            field='dokumenttypeId'
                            predictor={(value) => true}
                            set='visIGjeldendeBestemmelser'
                            to={false}
                        />
                        <Grid container={true} spacing={8}>
                            <Grid item={true} xs={6}>
                                <FormSelectInput
                                    name='dokumenttypeId'
                                    title='Dokumenttype'
                                    options={sortBy(
                                        meta.data.dokumenttyper.filter(
                                            (dokumenttype) =>
                                                dokumenttype.id != 22
                                        ),
                                        (dokumenttype) =>
                                            dokumenttype.beskrivelse
                                    )}
                                    keyCol='id'
                                    valueCol='beskrivelse'
                                    displayId={false}
                                    // eslint-disable-next-line
                                    autoFocus={true}
                                    nullOption={false}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Grid
                                    container={true}
                                    justifyContent='flex-start'
                                    spacing={8}
                                >
                                    {dokument.dokumentnummer && (
                                        <Grid item={true}>
                                            <Typography>
                                                Dokumentnummer
                                            </Typography>
                                            <Typography>
                                                {dokument.dokumentnummer}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item={true}>
                                        <Field
                                            name='dokumentdato'
                                            parse={parseDate}
                                            render={({
                                                input: {name, value, onChange},
                                                meta,
                                            }) => (
                                                <div>
                                                    <Typography>
                                                        <label htmlFor={name}>
                                                            Dato
                                                        </label>
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            minWidth: '200px',
                                                        }}
                                                    >
                                                        <DatePicker
                                                            input={{
                                                                name,
                                                                value,
                                                                onChange,
                                                            }}
                                                            onClear={() =>
                                                                onChange(null)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Typography className='importantFormLabel'>
                                    Dokumentnavn
                                </Typography>
                                <Grid
                                    container={true}
                                    justifyContent='flex-start'
                                    alignItems='baseline'
                                    spacing={2}
                                >
                                    <Grid item={true} style={{flexGrow: 2}}>
                                        <FormInputField
                                            name='dokumentnavn'
                                            fieldProps={{
                                                format: (value: string) => {
                                                    const ix =
                                                        value.lastIndexOf(
                                                            fileExtention
                                                        );
                                                    return value
                                                        ? value.substring(0, ix)
                                                        : '';
                                                },
                                                parse: (value) => {
                                                    return (
                                                        value &&
                                                        value + fileExtention
                                                    );
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true}>
                                        <Typography>{fileExtention}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Typography>Beskrivelse</Typography>
                                <Field
                                    name='beskrivelse'
                                    component='textarea'
                                    className='formInput textarea'
                                    rows={3}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <FormSelectInput
                                    name='tilgangId'
                                    title='Tilgang'
                                    options={helpers.getAvailableTilgangstyper(
                                        props.behandling
                                    )}
                                    keyCol='id'
                                    valueCol='navn'
                                    displayId={false}
                                    nullOption={false}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <FormControlLabel
                                    label='Skal vises i Gjeldende bestemmelser'
                                    control={
                                        <Field
                                            name='visIGjeldendeBestemmelser'
                                            component={Checkbox}
                                            type='checkbox'
                                            disabled={
                                                !helpers.shouldBestemmelserBeActive(
                                                    values.dokumenttypeId
                                                )
                                            }
                                        />
                                    }
                                />
                            </Grid>
                            {!plandok && (
                                <Grid item={true} xs={6}>
                                    <FormControlLabel
                                        label='Skal vises i plandokumenter'
                                        control={
                                            <Field
                                                name='visIPlandokumenter'
                                                component={Checkbox}
                                                type='checkbox'
                                                disabled={
                                                    Number(
                                                        values.visIGjeldendeBestemmelser
                                                    ) == 1
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                            )}
                            {helpers.shouldHoringsdokumentBeActive(
                                props.behandling
                            ) && (
                                <Grid item={true} xs={6}>
                                    <FormControlLabel
                                        label='Høringsdokument'
                                        control={
                                            <Field
                                                name='horingsdokument'
                                                component={Checkbox}
                                                type='checkbox'
                                            />
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid
                                item={true}
                                xs={12}
                                style={{marginTop: '20px'}}
                            >
                                <Grid
                                    container={true}
                                    justifyContent='space-between'
                                    alignItems='baseline'
                                >
                                    <Grid item={true}>
                                        {dokument &&
                                            dokument.id &&
                                            ((
                                                <IconButton
                                                    onClick={onDelete}
                                                    disabled={
                                                        kilde === 'Plan'
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    <DeleteIcon />
                                                    <p style={{fontSize: 14}}>
                                                        {kilde === 'Plan'
                                                            ? 'Slett dokument'
                                                            : 'Gå til behandling for å slette'}
                                                    </p>
                                                </IconButton>
                                            ) ||
                                                null)}
                                    </Grid>
                                    <Grid item={true}>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            onClick={onClose}
                                            disabled={submitting}
                                        >
                                            Avbryt
                                        </Button>

                                        <SpinnerButton
                                            type='submit'
                                            disabled={submitting || pristine}
                                            label='Lagre'
                                            loading={submitting}
                                            style={{marginLeft: '20px'}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre>
          <pre>Pristine: {JSON.stringify(pristine, null, 2)}</pre>
          <pre>Submitting: {JSON.stringify(submitting, null, 2)}</pre>
          <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
          <pre>SubmitErrors: {JSON.stringify(submitErrors, null, 2)}</pre> */}
                    </form>
                </Box>
            )}
        />
    );
}

export default DocumentForm;
