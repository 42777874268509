import {PlanId} from '../hooks/arealplaner/types';

export interface formatNasjonalArealplanIdProps {
    komnr: number;
    planId: PlanId;
}

export const formatNasjonalArealplanId = ({
    komnr,
    planId,
}: formatNasjonalArealplanIdProps) => {
    const _Komnr = String(komnr);
    return komnr && planId ? `${_Komnr}_${planId}` : 'NA';
};
