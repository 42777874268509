import {BASE_TITLE} from '../config/const';
import arealplanerConfig from '../config';

export function setTitle(title = '') {
    const {environment} = arealplanerConfig.planregister;
    const envBaseTitle =
        environment.toLowerCase() === 'prod'
            ? BASE_TITLE
            : `${environment.toUpperCase()} | ${BASE_TITLE}`;
    if (title == '') document.title = envBaseTitle;
    else document.title = `${envBaseTitle} | ${title}`;
}
