//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppPlusIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M19 11H13V5H11V11H5V13H11V19H13V13H19V11Z' style={props.secondaryStyle} />
        </g>
    ),
    'AppPlusIcon' // aria-label
);
