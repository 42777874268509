import * as React from 'react';
import {Grid, IconButton, Paper, Typography, Link} from '@mui/material';
import {connect} from 'react-redux';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {Dokument} from '../../hooks/dokumenter/types';
import {ConnectedReduxProps} from '../../store';
import {showDialog} from '../../store/dialog/actions';
import FileIcon from '../FileIcon';
import {getFileExtension} from '../../utils/getFileExtension';
import useDokuments from '../../hooks/dokumenter/useDokuments';
import {getDokumentKilde} from '../../features/plan/PlanDocumentList/helpers';

export interface DocumentListItemProps extends ConnectedReduxProps {
    document: Dokument;
    bgColor: string;
}

function DocumentListItem({
    document,
    bgColor,
    dispatch,
}: DocumentListItemProps) {
    const {openDokument} = useDokuments();
    function handleDownloadClick(e) {
        openDokument(document.id);
    }

    function handleShowDetail() {
        dispatch(
            showDialog({dialogType: 'DOKUMENT_DETAIL', dialogProps: {document}})
        );
    }

    return (
        <Paper
            square={true}
            style={{marginBottom: '10px', background: bgColor}}
        >
            <Grid
                container={true}
                justifyContent='space-between'
                alignItems='stretch'
                wrap='nowrap'
                style={{width: '100%', padding: '8px 12px'}}
            >
                <Grid
                    item={true}
                    style={{flexGrow: 1, cursor: 'pointer'}}
                    onClick={handleDownloadClick}
                >
                    <Typography style={{fontWeight: 'bold'}}>
                        {document.dokumenttype}
                    </Typography>
                    <Grid container={true} wrap='nowrap'>
                        <Grid item={true}>
                            <FileIcon
                                extension={getFileExtension(
                                    document.dokumentnavn
                                )}
                            />
                        </Grid>
                        <Grid item={true}>
                            <Grid
                                container={true}
                                direction='column'
                                wrap='nowrap'
                            >
                                <Typography
                                    style={{
                                        wordBreak: 'break-all',
                                    }}
                                >
                                    <Link underline='always'>
                                        {document.dokumentnavn}
                                    </Link>
                                </Typography>
                                <Typography
                                    style={{
                                        wordBreak: 'break-all',
                                        fontSize: 12,
                                    }}
                                >
                                    {document.beskrivelse}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item={true}>
                    {getDokumentKilde(document) != 'Plan' && (
                        <IconButton onClick={handleShowDetail}>
                            <InfoOutlined />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
}

export default connect()(DocumentListItem);
