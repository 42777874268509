import React from 'react';
import useSearchHelpers from '../useSearchHelpers';
import CheckboxSectionAlt from './CheckboxSection';
import {useFilter} from './useFilter';
import FilterLabel from './FilterLabel';

export const BehandlingstypeFilter = () => {
    const helpers = useSearchHelpers();
    const filter = useFilter('behandlingstype');

    const getSelectedFilters = (): string[] => {
        return filter.get()?.split(',') || [];
    };

    const handleChange = (value: string) => {
        filter.set(value);
    };

    return (
        <>
            <FilterLabel>Velg behandlingstype</FilterLabel>
            <CheckboxSectionAlt
                selectedOptions={getSelectedFilters()}
                options={helpers.behandlingstyperCheckboxList}
                handleChange={handleChange}
            />
        </>
    );
};
