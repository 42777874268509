import {Typography, Box, Button} from '@mui/material';
import React from 'react';

type Props = {
    header: string;
    content: string;
    Icon: JSX.Element;
    onClick: () => void;
};

const InfoButton = ({header, content, Icon, onClick}: Props) => {
    return (
        <Button
            focusRipple={true}
            onClick={onClick}
            sx={(theme) => ({
                minHeight: 200,
                width: 320,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                padding: 10,
            })}
        >
            <Typography
                variant='h3'
                sx={{
                    textAlign: 'center',
                    letterSpacing: '0.06em',
                    fontSize: 18,
                    fontWeight: 300,
                }}
            >
                {header}
            </Typography>
            <Box sx={{margin: '20px auto'}}>{Icon}</Box>
            <Typography sx={{fontSize: 16}}>{content}</Typography>
        </Button>
    );
};
export default InfoButton;
