import axios from 'axios';
import {backend} from '../Consts';
import tagType from '../Types/TagType';

const tagApi = axios.create();
const url = backend + 'tags';

//TODO Do something about url definition and appId
export async function fetchTags(): Promise<tagType[]> {
  const result = await tagApi.get(url);
  return result.data;
}
