import {useQuery} from 'react-query';
import {getPlanerService} from '../../services/apiPlananalyse';
import {MappedPlanAnalyseResponse} from '../../services/plananalyse';

function usePlanerForEiendom({
    knr,
    gnr,
    bnr,
    fnr,
    snr,
}: {
    knr?: string | null | undefined;
    gnr?: number | undefined;
    bnr?: number | undefined;
    fnr?: number | undefined;
    snr?: number | undefined;
}) {
    const planer = getPlanerService();
    const query = useQuery<MappedPlanAnalyseResponse>(
        ['plananalyse', {knr, gnr, bnr, fnr, snr}],
        () =>
            planer.planerGetPlaner({
                knr,
                gnr,
                bnr,
                snr,
                includeGeometries: false,
                includeSeksjoner: true,
            }),
        {
            refetchOnWindowFocus: false,
            retry: false,
            enabled: Boolean(knr) && Boolean(gnr) && Boolean(bnr),
        }
    );

    return query;
}

export default usePlanerForEiendom;
