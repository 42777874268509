import {openSnackbarMessage} from '../components/SnackbarMessage';

export const useNotification = () => {
    const notifySuccess = (message: string) => () => {
        openSnackbarMessage({
            message,
            variant: 'success',
            verticalAnchor: 'bottom',
            autoHideDuration: 2000,
        });
    };

    const notifyError = (message: string) => () => {
        openSnackbarMessage({
            message,
            variant: 'error',
            verticalAnchor: 'bottom',
        });
    };

    return {notifySuccess, notifyError};
};
