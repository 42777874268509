export const planforholdReportQuery = `
query GetPlanforholdPaged ($first: Int, $after:String, $filter: PlanforholdReportDtoFilterInput, $sort: [PlanforholdReportDtoSortInput!]) {
    planforholdPaged (first: $first,after: $after, where: $filter, order: $sort){ 
        edges {
            node {
                  planforholdType {
                      beskrivelseEtterkommer
                      beskrivelseForloper
                      id
                  }
                  planforholdTypeId
                  planIdFra
                  planIdFraNavigation {
                      planStatus
                      planStatusId
                      planNavn
                      planType 
                      planTypeId
                      planId
                      iKraft
                  }
                  planIdTil
                  planIdTilNavigation {
                      planStatus
                      planStatusId
                      planNavn
                      planType 
                      planTypeId
                      planId
                      iKraft
                  }
               },
               cursor 
            }
        pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        },
        totalCount    
      }
        
  }  
`;

export const planforholdCsvQuery = `query GetPlanforhold ($filter: PlanforholdReportDtoFilterInput, $sort: [PlanforholdReportDtoSortInput!]) {
    planforhold (where: $filter, order: $sort) { 
        planforholdType {
            beskrivelseEtterkommer
            beskrivelseForloper
            id
        }
        planforholdTypeId
        planIdFra
        planIdFraNavigation {
            planStatus
            planStatusId
            planNavn
            planType 
            planTypeId
            planId
            iKraft
        }
        planIdTil
        planIdTilNavigation {
            planStatus
            planStatusId
            planNavn
            planType 
            planTypeId
            planId
            iKraft
        }
    }
}`;
