import * as React from 'react';
import {Dokument} from '../../hooks/dokumenter/types';
import DocumentListItem from './DocumentListItem';

export interface DocumentListProps {
    documents: Dokument[];
    bgColor?: string;
}

export function DocumentList({documents, bgColor}: DocumentListProps) {
    return (
        <>
            {documents &&
                documents.map((document) => {
                    return (
                        <DocumentListItem
                            key={document.id}
                            document={document}
                            bgColor={bgColor || 'default'}
                        />
                    );
                })}
        </>
    );
}

export default DocumentList;
