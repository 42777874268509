import {ComponentPropsWithRef, ElementType, forwardRef} from 'react';
import ToiTypography, {ToiTypographyProps} from '../../ToiTypography/ToiTypography';
import ToiBox from '../../ToiBox/ToiBox';

export const ToiDialogTitle = forwardRef(
  <D extends ElementType>(props: ToiTypographyProps<D>, ref: ComponentPropsWithRef<D>['ref']) => {
    const {variant = 'h3', component = 'h1', ...restProps} = props;
    return (
      <ToiBox ref={ref}>
        <ToiTypography variant={variant} component={component} {...restProps}></ToiTypography>
      </ToiBox>
    );
  }
);
