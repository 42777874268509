import {combineReducers, Reducer} from '@reduxjs/toolkit';
import {KunderActionTypes as actionTypes, KunderState} from './types';

const selectedKundeId: Reducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.SELECT_KUNDE: {
            return action.payload;
        }
        case actionTypes.RESET_KUNDE: {
            return null;
        }
        default:
            return state;
    }
};

export const kunderReducer = combineReducers({
    selectedKundeId,
});

export const getSelectedCustomer = (state: KunderState) =>
    state.selectedKundeId;
