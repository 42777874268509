import {createContext, useMemo} from 'react';
import {Client, createClient} from '@hey-api/client-fetch';
import {config} from '@norkart/core-appconfig';
import {useFetchTokenHeaderWithAuthData} from '@norkart/nk-auth';

const apiBase = config.get('NX_API_BASE_URL');

const openHeaders = {
    'X-WAAPI-TOKEN': config.get('NX_API_KEY') || '',
    'X-WAAPI-PROFILE': config.get('NX_WAAPI_PROFILE') || '',
} as Record<string, unknown>;

const client = createClient({
    baseUrl: apiBase,
    headers: openHeaders,
});

const authorizedClient = createClient({
    baseUrl: apiBase,
});

type ArealplanerApiClientContextState = {
    client: Client;
    authorizedClient: Client;
};

export const ArealplanerApiClientContext =
    createContext<ArealplanerApiClientContextState>({
        client,
        authorizedClient,
    });

export function ArealplanerApiClientProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const getHeaders = useFetchTokenHeaderWithAuthData();

    authorizedClient.interceptors.request.use(async (request) => {
        const headers = await getHeaders();
        for (const [key, value] of Object.entries(headers)) {
            request.headers.set(key, value);
        }
        return request;
    });

    const contextValue = useMemo(
        () => ({client, authorizedClient}),
        [client, authorizedClient]
    );

    return (
        <ArealplanerApiClientContext.Provider value={contextValue}>
            {children}
        </ArealplanerApiClientContext.Provider>
    );
}
