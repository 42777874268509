import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    IconButton,
    Grid,
    Table,
    TableBody,
    TableRow,
    TableCell,
    useMediaQuery,
    Theme,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import {formatDate} from '../../utils/formatDate';
import {ConnectedReduxProps} from '../../store';
import {hideDialog} from '../../store/dialog/actions';
import {Dokument} from '../../hooks/dokumenter/types';
import {getDokumentKilde} from '../../features/plan/PlanDocumentList/helpers';

export interface DocumentDetailProps extends ConnectedReduxProps {
    document: Dokument;
    open: boolean;
}
export function DocumentDetail({
    dispatch,
    open,
    document,
}: DocumentDetailProps) {
    const fullScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    function handleClose() {
        hideDialog(dispatch);
    }
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby='dokumentdetaljer'
            disableScrollLock={true}
        >
            <DialogTitle id='dokumentdetaljer'>
                <Grid
                    container={true}
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Grid item={true}>{document.dokumenttype}</Grid>
                    <Grid item={true}>
                        <IconButton onClick={handleClose}>
                            <Close fontSize='small' />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <div style={{wordBreak: 'keep-all'}}>
                    <Table
                        sx={{
                            width: '100%',
                            tableLayout: 'fixed',
                            '& td': {
                                border: 0,
                                wordBreak: 'keep-all',
                                // padding: theme.spacing(4)
                            },
                            '& td:first-of-type': {
                                width: '150px',
                            },
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell variant='head'>
                                    Dokumentnavn:
                                </TableCell>
                                <TableCell>{document.dokumentnavn}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head'>
                                    Dokumentdato:
                                </TableCell>
                                <TableCell>
                                    {formatDate(document.dokumentdato)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head'>
                                    Beskrivelse:
                                </TableCell>
                                <TableCell>{document.beskrivelse}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head'>Kilde:</TableCell>
                                <TableCell>
                                    {getDokumentKilde(document)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </DialogContent>
        </Dialog>
    );
}
export default DocumentDetail;
