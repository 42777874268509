import MapboxDraw from '@mapbox/mapbox-gl-draw';
import React from 'react';
import {
    MapPolygonIcon,
    MapPolylineIcon,
    MapRoutePins1Icon,
    AppDeleteRecycleIcon,
    AppTextIcon,
    StatusInfoIcon,
} from '@norkart/toi-deprecated-icons';
import './Drawcontrol.css';
import {Tooltip} from '@mui/material';

type props = {
    mapboxDraw: MapboxDraw | undefined;
    deleteAvailable: boolean;
};

const DrawControl = ({mapboxDraw, deleteAvailable}: props) => {
    const [activeMode, setActiveMode] = React.useState<string | undefined>('');
    const [activeModeText, setActiveModeText] = React.useState('');

    const getActiveMode = () => {
        try {
            return mapboxDraw?.getMode();
        } catch (e) {
            return '';
        }
    };

    React.useEffect(() => {
        const activeMode = getActiveMode();
        setActiveMode(activeMode);
        setActiveModeText(getActiveModeText(activeMode));
    }, [getActiveMode()]);

    const getActiveModeText = (activeMode) => {
        if (activeMode === 'draw_line_string')
            return 'Klikk i kart for å tegne linje';
        if (activeMode === 'draw_polygon')
            return 'Klikk i kart for å tegne polygon';
        if (activeMode === 'draw_point')
            return 'Klikk i kart for å tegne punkt';
        if (activeMode === 'draw_textfield')
            return 'Klikk i kart for å legge til tekst';
        return '';
    };

    return (
        <div className='drawcontrol-container'>
            <div className='drawcontrol-buttons'>
                <Tooltip title='Trykk for å tegne linje' placement='top'>
                    <button
                        className={
                            activeMode === 'draw_line_string'
                                ? 'drawcontrol-button-active'
                                : 'drawcontrol-button'
                        }
                        onClick={() => {
                            mapboxDraw?.changeMode('draw_line_string');
                            setActiveMode('draw_line_string');
                        }}
                    >
                        <MapPolylineIcon />
                    </button>
                </Tooltip>
                <Tooltip title='Trykk for å tegne polygon' placement='top'>
                    <button
                        className={
                            activeMode === 'draw_polygon'
                                ? 'drawcontrol-button-active'
                                : 'drawcontrol-button'
                        }
                        onClick={() => {
                            mapboxDraw?.changeMode('draw_polygon');
                            setActiveMode('draw_polygon');
                        }}
                    >
                        <MapPolygonIcon />
                    </button>
                </Tooltip>
                <Tooltip title='Trykk for å tegne punkt' placement='top'>
                    <button
                        className={
                            activeMode === 'draw_point'
                                ? 'drawcontrol-button-active'
                                : 'drawcontrol-button'
                        }
                        onClick={() => {
                            mapboxDraw?.changeMode('draw_point');
                            setActiveMode('draw_point');
                        }}
                    >
                        <MapRoutePins1Icon />
                    </button>
                </Tooltip>
                <Tooltip title='Trykk for å legge til tekst' placement='top'>
                    <div className='fix-tooltip-on-disabled'>
                        <button
                            className={
                                activeMode === 'draw_textfield'
                                    ? 'drawcontrol-button-active'
                                    : 'drawcontrol-button'
                            }
                            onClick={() => {
                                mapboxDraw?.changeMode('draw_textfield' as any);
                                setActiveMode('draw_textfield');
                            }}
                        >
                            <AppTextIcon />
                        </button>
                    </div>
                </Tooltip>
                <Tooltip
                    title='Velg objekt i kartet og trykk her for å slette'
                    placement='top'
                >
                    <div className='fix-tooltip-on-disabled'>
                        <button
                            className='drawcontrol-button'
                            onClick={() => mapboxDraw?.trash()}
                            disabled={!deleteAvailable}
                            style={
                                !deleteAvailable ? {pointerEvents: 'none'} : {}
                            }
                        >
                            <AppDeleteRecycleIcon />
                        </button>
                    </div>
                </Tooltip>
            </div>
            {activeModeText ? (
                <div className='drawcontrol-active-mode'>
                    <StatusInfoIcon />
                    <span>{activeModeText}</span>
                </div>
            ) : null}
        </div>
    );
};

export default DrawControl;
