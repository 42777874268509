import {Action, AnyAction, combineReducers, Dispatch} from '@reduxjs/toolkit';
// Search
import {searchReducer} from './search/reducer';
import {SearchState} from './search/types';
//Fullscreen
import {mapReducer} from './map/reducer';
import {MapState} from './map/types';
// Kunder
import {kunderReducer} from './kunder/reducer';
import {KunderState} from './kunder/types';
// Dialog
import {dialogReducer} from './dialog/reducer';
import {DialogState} from './dialog/types';
// Shared
import {sharedReducer} from './shared/reducer';
import {SharedState} from './shared/types';
// User
import {TableSelection} from './selectedDocumentsTable/reducer';
import {tableSelectionReducer} from './selectedDocumentsTable/reducer';
// File Import
import {fileImportReducer} from './fileImport/reducer';
import {FileImportState} from './fileImport/types';
// Session
import {sessionReducer} from './session/reducer';
import {SessionState} from './session/types';

export interface ApplicationState {
    search: SearchState;
    map: MapState;
    kunder: KunderState;
    dialog: DialogState;
    shared: SharedState;
    tableSelection: TableSelection;
    fileImport: FileImportState;
    session: SessionState;
}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>;
}

export const createRootReducer = () =>
    combineReducers({
        search: searchReducer,
        map: mapReducer,
        kunder: kunderReducer,
        dialog: dialogReducer,
        shared: sharedReducer,
        tableSelection: tableSelectionReducer,
        fileImport: fileImportReducer,
        session: sessionReducer,
    });

export * from './selectors';
