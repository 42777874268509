import {useMutation, useQueryClient} from 'react-query';
import {
    ArealplanDto,
    arealplanerUpdateArealplan,
    ArealplanForUpdateDto,
} from '@norkart/nkapi-arealplaner-api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

type Props = {
    arealplanId: number;
    arealplanForUpdate: ArealplanForUpdateDto;
};

export default function useUpdateArealplan() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useMutation(
        async (props: Props): Promise<ArealplanDto> => {
            const {data, error} = await arealplanerUpdateArealplan({
                client: authorizedClient,
                path: {kundeId, arealplanId: props.arealplanId},
                body: props.arealplanForUpdate,
            });
            if (!data || error) {
                throw new Error('Error updating arealplan');
            }
            return data;
        },
        {
            onError: notify,
            onSuccess: (_, props) => {
                queryClient.invalidateQueries([
                    'arealplan',
                    {kundeId, arealplanId: props.arealplanId},
                ]);

                queryClient.invalidateQueries([
                    'dokumenter',
                    {kundeId, arealplanId: props.arealplanId},
                ]);
            },
        }
    );
}
