//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppTextIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M20 4H4V8H6V6H11V18H9V20H15V18H13V6H18V8H20V4Z' style={props.secondaryStyle} />
            <path d='M20 20H17V18H20V20Z' style={props.primaryStyle} />
        </g>
    ),
    'AppTextIcon' // aria-label
);
