import {useQuery} from 'react-query';
import {fetchArealplan} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Arealplan} from './types';

export default function useSelectedArealplan(id?: number) {
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();

    const query = useQuery<Arealplan>(
        ['arealplan', {kundeId, arealplanId: id}],
        () => fetchArealplan({id}),
        {
            enabled: !!kundeId && !!id,
            onError: notify,
            keepPreviousData: !!id, // hvis vi går fra en planid til en annen så behold gammel data til ny data kommer inn
        }
    );
    return {...query, data: query?.data};
}
