import {Link, LinkProps, LinkTypeMap, SvgIconTypeMap, styled} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import React, {ElementType} from 'react';
import {getSecureUrl} from '@norkart/validation-url';
import {
  getDefaultTypographyVariant,
  ITypographyVariantOverrides,
  ToiTypographyVariant,
} from '../ToiTypography/ToiTypography';
import {validateTypographyAttributes, validateTypographyVariant} from '../ToiTypography/TypographyVariantValidator';
import {toiLinkStyle} from './ToiLinkStyle';

export type ToiLinkVariant = ToiTypographyVariant;
export type ToiLinkColor = 'primary' | 'secondary';

declare module '@mui/material/Link' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface LinkPropsVariantOverrides extends ITypographyVariantOverrides {}
}

type OwnProps = {
  children?: React.ReactNode;
  variant?: ToiLinkVariant;
  uppercase?: boolean;
  color?: ToiLinkColor;
  definition?: boolean;
  inline?: boolean;
  StartIcon?: OverridableComponent<SvgIconTypeMap> | React.FC;
  EndIcon?: OverridableComponent<SvgIconTypeMap> | React.FC;
};

type ToiLinkTypeMap = LinkTypeMap<OwnProps>;

export type ToiLinkProps<C extends ElementType = 'a'> = LinkProps<C, OwnProps>;

const StyledLink = styled(Link, {
  //we need these so that  mui will not forward these to the DOM and giv us warnings
  shouldForwardProp: (prop) => !['variant', 'uppercase', 'color', 'definition', 'inline'].includes(prop.toString()),
})<Pick<OwnProps, 'variant' | 'uppercase' | 'color' | 'definition' | 'inline'>>(
  ({theme, variant, uppercase, color, definition, inline}) => {
    const defaultVariant = getDefaultTypographyVariant(theme);

    validateTypographyVariant(variant || defaultVariant);
    validateTypographyAttributes({variant: variant || defaultVariant, uppercase: uppercase});

    return toiLinkStyle(theme, variant || defaultVariant, uppercase, color, definition, inline);
  }
);

export const ToiLink = React.forwardRef<HTMLAnchorElement, ToiLinkProps>((props, ref) => {
  const {StartIcon, EndIcon, ...restProps} = props;

  return (
    <StyledLink
      {...restProps}
      href={'href' in restProps && typeof restProps.href === 'string' ? getSecureUrl(restProps.href) : undefined}
      ref={ref}
    >
      {StartIcon && <StartIcon />}
      {props.children}
      {EndIcon && <EndIcon />}
    </StyledLink>
  );
}) as OverridableComponent<ToiLinkTypeMap>;

export default ToiLink;
