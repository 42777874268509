import {TableHead, styled} from '@mui/material';
import _ from 'lodash-es';
import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import GenericTable from '../../../components/GenericTable';
import {GTableColumn} from '../../../components/GenericTable/GenericTable';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {Journal} from '../../../hooks/journaler/types';
import useJournaler from '../../../hooks/journaler/useJournaler';
import {
    ApplicationState,
    getActiveSakId,
    getSelectedDocumentCountByJournalId,
} from '../../../store';
import {formatDate} from '../../../utils/formatDate';
import ImportDocumentFromArchiveDetailPanel from './ImportDocumentFromArchiveDetailPanel';

type Props = ConnectedProps<typeof connector> & {
    journaler: Journal[];
};

type JournalWithCount = Journal & {
    selectedCount: number;
};

const StyledSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'error' && prop !== 'selected',
})<{error?: boolean; selected: boolean}>(({theme, error, selected}) => ({
    ...(error && {
        color: theme.palette.error.main,
    }),
    ...(selected && {fontWeight: 'bold'}),
}));

function ImportDocumentFromArchive(props: Props) {
    const selectedSakId = useSelector(getActiveSakId);
    const {isLoading, isError} = useJournaler(selectedSakId);
    const [journals, setJournals] = useState<JournalWithCount[]>();

    useEffect(() => {
        if (props.journaler) {
            const journals = props.journaler.map(
                (journal) =>
                    ({
                        ...journal,
                        selectedCount: props.selectedCountById[journal.id],
                    } as JournalWithCount)
            );
            setJournals(journals);
        }
    }, [props.journaler, props.selectedCountById]);

    const columns = [
        {
            field: 'id',
            cellStyle: {whiteSpace: 'nowrap', paddingRight: '10px'},
            render: (journal) => (
                <StyledSpan
                    selected={Boolean(journal.selectedCount)}
                    error={Boolean(journal.errorsCount)}
                >
                    {journal.id}
                </StyledSpan>
            ),
        },
        {
            field: 'journalDato',
            title: 'Journaldato',
            render: (journal) => (
                <StyledSpan
                    selected={Boolean(journal.selectedCount)}
                    error={Boolean(journal.errorsCount)}
                >
                    {formatDate(journal.journalDato)}
                </StyledSpan>
            ),
            cellStyle: {paddingRight: '20px'},
        },
        {
            field: 'title',
            title: 'Journalpost',
            cellStyle: {},
            render: (journal) => {
                return (
                    <StyledSpan selected={Boolean(journal.selectedCount)}>
                        <span>{journal.title}</span>
                        {journal.selectedCount ? (
                            <span style={{paddingLeft: '4px'}}>
                                ({journal.selectedCount})
                            </span>
                        ) : null}
                    </StyledSpan>
                );
            },
        },
    ] as GTableColumn[];

    const renderDetailPanel = (journal) => (
        <ImportDocumentFromArchiveDetailPanel journal={journal} />
    );

    if (isLoading) return <LoadingSpinner />;
    if (isError) return <div>Ingen journalposter for sak</div>;
    return (
        <GenericTable
            columns={columns}
            idColumn='id'
            data={_.orderBy(journals, 'journalpostNummer', 'desc')}
            title='Journaler'
            onRowClick={(e, rowData, toggleExpand) => toggleExpand(rowData)}
            detailPanel={[
                {
                    tooltip: 'Dokumenter',
                    render: renderDetailPanel,
                },
            ]}
            options={{allowMultipleExpanded: true, header: true}}
            components={{
                Header: (props) => (
                    <>
                        <colgroup>
                            <col style={{width: '52px'}} />
                            <col style={{width: '150px'}} />
                            <col style={{width: '150px'}} />
                            <col style={{width: 'auto'}} />
                        </colgroup>
                        <TableHead {...props} />
                    </>
                ),
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Ingen journalposter for sak',
                },
            }}
        />
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    selectedCountById: getSelectedDocumentCountByJournalId(state),
});
const mapDispatchToProps = (dispatch) => ({});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ImportDocumentFromArchive);
