import {InteractionStatus, SsoSilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {useEffect, useState} from 'react';
import {useIsLoggedIn} from './useIsLoggedIn';

const defaultRequest: SsoSilentRequest = {};
const MAX_FAILED_CONSECUTIVE_ATTEMPTS = 3;

export const useSsoSilent = (attemptLogin: boolean, loginRequest?: SsoSilentRequest) => {
  const {inProgress, instance} = useMsal();
  const [isRunning, setIsRunning] = useState(attemptLogin);
  const [failedConsecutiveAttempts, setFailedConsecutiveAttempts] = useState(0);
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    const silentLogin = async () => {
      if (inProgress === InteractionStatus.None && attemptLogin) {
        try {
          const res = await instance.ssoSilent(loginRequest ?? defaultRequest);
          instance.setActiveAccount(res.account);
          setFailedConsecutiveAttempts(0);
        } catch {
          setFailedConsecutiveAttempts((count) => count + 1);
        } finally {
          setIsRunning(false);
        }
      }
    };
    if (!isLoggedIn && failedConsecutiveAttempts < MAX_FAILED_CONSECUTIVE_ATTEMPTS) {
      silentLogin();
    } else {
      setIsRunning(false);
    }
  }, [inProgress, attemptLogin, instance, loginRequest, isLoggedIn, failedConsecutiveAttempts]);

  return isRunning;
};
