import axios from 'axios';
import {openSnackbarMessage} from '../../components/SnackbarMessage/SnackbarMessage';

export default function useErrorHandler() {
    const notify = (err: any) => {
        if (axios.isAxiosError(err)) {
            openSnackbarMessage({
                message: err.response?.data?.message || 'Noe gikk galt',
                variant: 'error',
            });
        }
    };

    return {notify};
}
