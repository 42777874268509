import {useEffect} from 'react';
import {useNkAuthInterceptors} from './hooks/useNkAuthInterceptors';

export function ConfigureAxiosInterceptors({children}) {
    const setupInterceptors = useNkAuthInterceptors();

    useEffect(() => {
        setupInterceptors();
    }, []);

    return children;
}
