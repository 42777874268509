/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {CancelablePromise} from '@norkart/norkartapi3-core';
import {Config, request as __request} from '@norkart/norkartapi3-core';
import type {DynamicCustomerContextEnabledAppAuthorizationSelfLookup} from '../models/DynamicCustomerContextEnabledAppAuthorizationSelfLookup';
import type {DynamicCustomerContextEnabledAppAuthorizationSelfLookupResponse} from '../models/DynamicCustomerContextEnabledAppAuthorizationSelfLookupResponse';

export class DynamiccustomercontextenabledService {
    config: Config;

    constructor(config: Config) {
        this.config = {
            ...config,
            BASE:
                config.BASE ||
                'https://www.webatlas.no/WAAPI-ApplicationBackend',
        };
    }

    /**
     * @returns DynamicCustomerContextEnabledAppAuthorizationSelfLookupResponse Success
     * @throws ApiError
     */
    public dynamicCustomerContextEnabledAppAuthorizationSelfLookupauthorizationlookupGet({
        accept,
    }: {
        /** Accept Header **/
        accept: 'application/json';
    }): CancelablePromise<DynamicCustomerContextEnabledAppAuthorizationSelfLookupResponse> {
        return __request(this.config, {
            method: 'GET',
            path: `/dynamiccustomercontextenabled/authorizationlookup`,
            headers: {
                Accept: accept,
            },
        });
    }

    /**
     * @returns DynamicCustomerContextEnabledAppAuthorizationSelfLookupResponse Success
     * @throws ApiError
     */
    public dynamicCustomerContextEnabledAppAuthorizationSelfLookupauthorizationlookupPost({
        accept,
        body,
    }: {
        /** Accept Header **/
        accept: 'application/json';
        body?: DynamicCustomerContextEnabledAppAuthorizationSelfLookup;
    }): CancelablePromise<DynamicCustomerContextEnabledAppAuthorizationSelfLookupResponse> {
        return __request(this.config, {
            method: 'POST',
            path: `/dynamiccustomercontextenabled/authorizationlookup`,
            headers: {
                Accept: accept,
            },
            body: body,
        });
    }
}
