import {Theme} from '@mui/material';
import {merge} from 'lodash-es';
import {themeIsLightMode} from '../utils/themeIsLightMode';
import {DividerSize} from './ToiDivider';

export const toiDividerStyle = (
  theme: Theme,
  orientation?: 'vertical' | 'horizontal',
  color?: string,
  size?: DividerSize
) => {
  const isLightMode = themeIsLightMode(theme);

  const calculateDividerColor = (color: string | undefined) => {
    switch (color) {
      case 'green':
        return theme.palette.green[70];
      case 'black':
        return isLightMode ? theme.palette.black[100] : theme.palette.black[30];
      case undefined:
        return isLightMode ? theme.palette.black[30] : theme.palette.black[80];
      default:
        return color;
    }
  };

  const dividerColor = calculateDividerColor(color);

  const calculateTextColor = (color: string | undefined) => {
    if (color == 'green' && isLightMode) {
      return theme.palette.green[100];
    } else if (typeof color === 'undefined') {
      return isLightMode ? theme.palette.black[70] : theme.palette.black[40];
    } else {
      return dividerColor;
    }
  };

  const textColor = calculateTextColor(color);

  const defaultStyle = {
    borderColor: dividerColor,
    // Divider with children is rendered as a div and must be handled
    '&.MuiDivider-withChildren::before, &.MuiDivider-withChildren::after': {
      borderColor: dividerColor,
    },
    color: textColor,
  };

  const lineThickness = (orientation?: 'vertical' | 'horizontal', size?: DividerSize) => {
    if (size === 'medium') {
      if (orientation === 'vertical') return {borderRightWidth: '2px'};
      return {borderBottomWidth: '2px'};
    }
    if (size === 'large') {
      if (orientation === 'vertical') return {borderRightWidth: '3px'};
      return {borderBottomWidth: '3px'};
    }
    if (size === 'xl') {
      if (orientation === 'vertical') return {borderRightWidth: '4px'};
      return {borderBottomWidth: '4px'};
    }
    if (size === 'xxl') {
      if (orientation === 'vertical') return {borderRightWidth: '5px'};
      return {borderBottomWidth: '5px'};
    }
    if (size === 'xxxl') {
      if (orientation === 'vertical') return {borderRightWidth: '6px'};
      return {borderBottomWidth: '6px'};
    }

    if (orientation === 'vertical') return {borderRightWidth: '1px'};
    return {borderBottomWidth: '1px'};
  };

  return merge({}, defaultStyle, lineThickness(orientation, size));
};
