import {Box, Button, Link, Typography} from '@mui/material';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import DesktopContainer from '../../containers/DesktopContainer';
import {setUseAltFrontpage} from '../../store/session/actions';
import Footer from '../footer';
import PredefinedFilters from '../NewFrontpage/PredefinedFilters';
import Search from '../search/Search/Search';
import SearchResult from '../search/SearchResult';
import KartBakgrunn from '../../assets/images/kart.png';
import LoadingSpinner from '../../components/LoadingSpinner';
import useMeta from '../../hooks/meta/useMeta';
import {usePropertySearch} from '../search/Search/filters/PropertySearchFilter/usePropertySearch';
import {useFilters} from '../search/Search/filters/useFilters';
import useTrackArealplanerEvent from '../../hooks/appinsights/useTrackArealplanerEvent';
import Header from './header';

function Home() {
    const dispatch = useDispatch();
    const {customerId} = useParams();
    const meta = useMeta();
    const filters = useFilters();
    const propertyFilter = usePropertySearch();
    const trackHome = useTrackArealplanerEvent('forside', 'button');

    const handlechangeToMap = (comp: string) => {
        trackHome(comp);
        dispatch(setUseAltFrontpage(true));
    };

    if (!meta.isFetched) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {filters.activeFiltersFromUrl.length || propertyFilter.get() ? (
                    <DesktopContainer>
                        <Link
                            sx={{
                                position: 'absolute',
                                cursor: 'pointer',
                                width: 200,
                                marginY: 15,
                                marginX: '20px',
                            }}
                            onClick={() =>
                                handlechangeToMap('byttTilKart_fraSøk')
                            }
                        >
                            Bytt til Kart
                        </Link>
                    </DesktopContainer>
                ) : null}
                <Header />
                <Search />
                <Box>
                    {filters.activeFiltersFromUrl.length ||
                    propertyFilter.get() ? (
                        <SearchResult />
                    ) : (
                        <>
                            <PredefinedFilters />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: 20,
                                    maxHeight: 230,
                                }}
                            >
                                <DesktopContainer>
                                    <Box
                                        onClick={() =>
                                            handlechangeToMap(
                                                'byttTilKart_fraSøk'
                                            )
                                        }
                                        sx={{
                                            position: 'relative',
                                            border: '1px solid grey',
                                            overflow: 'hidden',
                                            marginX: '10px',
                                        }}
                                    >
                                        <img alt='kart' src={KartBakgrunn} />
                                        <Button
                                            onClick={() =>
                                                handlechangeToMap(
                                                    'byttTilKart_fraForside'
                                                )
                                            }
                                            variant='contained'
                                            color='secondary'
                                            sx={{
                                                position: 'absolute',
                                                right: 15,
                                                bottom: 15,
                                            }}
                                        >
                                            Gå til kart
                                        </Button>
                                    </Box>
                                </DesktopContainer>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: 10,
                                        backgroundColor: '#F2F5F0',
                                        width: 512,
                                        marginX: '10px',
                                    }}
                                >
                                    <Typography
                                        variant='h3'
                                        sx={{marginBottom: 5}}
                                    >
                                        Andre nyttige sider
                                    </Typography>
                                    <Link
                                        color='secondary'
                                        href={`/${customerId}/faq`}
                                    >
                                        Hjelp
                                    </Link>
                                    <Link
                                        color='secondary'
                                        href={`/${customerId}/relevanteLenker`}
                                    >
                                        Eksterne sider
                                    </Link>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
            <Footer />
        </>
    );
}

export default Home;
