import {useQuery} from 'react-query';
import {useFetchTokenHeaderWithAuthData} from '@norkart/nk-auth';
import {GraphQLUnpagedResponse} from '../../graphql/types';
import {graphQLRequest} from '../../services/api';
import {PlanstatusLovreferanseReport} from './types';

export default function usePlanstatusLovreferaseGraphQL(graphqlQuery: string) {
    const getHeaders = useFetchTokenHeaderWithAuthData();
    const query = useQuery<GraphQLUnpagedResponse>(
        ['planstatusLovreferanseReportGraphQL'],
        async () => {
            const headers = await getHeaders();
            return await graphQLRequest(graphqlQuery, null, headers);
        },
        {
            cacheTime: 1000 * 60 * 5,
            refetchOnWindowFocus: false,
        }
    );

    return {
        ...query,
        tilstandLovref:
            (query.data
                ?.planstatusLovreferanseReport as PlanstatusLovreferanseReport[]) ||
            [],
    };
}
