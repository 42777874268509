import axios, {CancelToken} from 'axios';
import arealplanerConfig from '../config';

const TOKEN = arealplanerConfig.planregister.token;
const TOKENSTRING = `?apitoken=${TOKEN}`;
const BASE_URL = arealplanerConfig.planregister.url + '/api';

export const fetchArealplanId = (
    planId: string,
    kommunenummer: string,
    customerId: string,
    cancelToken: CancelToken
) => {
    return axios.get(
        `${BASE_URL}/gi/kunder/${customerId}/arealplaner/id/${kommunenummer}/${planId}${TOKENSTRING}`,
        {cancelToken}
    );
};
