import {Reducer} from '@reduxjs/toolkit';
import {SearchState, SearchActionTypes} from './types';
import * as actions from './actions';

export const initialState: SearchState = {
    activeSortFilter: null,
    isActive: false,
    activeSearchTab: 0,
    activeSearchFilter: 'property',
    searchedPropertyGeom: undefined,
    urlSearchParams: undefined,
    propertyGeometryIsMissing: undefined,
    propertySearchText: undefined,
    propertySearchIsAdressSearch: undefined,
};

export type SearchAction =
    | ReturnType<typeof actions.searchIsActive>
    | ReturnType<typeof actions.addActiveSearchTab>
    | ReturnType<typeof actions.addSortFilter>
    | ReturnType<typeof actions.setSearchedPropertyGeom>
    | ReturnType<typeof actions.setUrlSearchParams>
    | ReturnType<typeof actions.setPropertyIsMissing>
    | ReturnType<typeof actions.setPropertySearchText>
    | ReturnType<typeof actions.setPropertySearchIsAdressSearch>;

const reducer: Reducer<SearchState, SearchAction> = (
    state = initialState,
    action
): SearchState => {
    switch (action.type) {
        case SearchActionTypes.SEARCH_IS_ACTIVE:
            return {...state, isActive: action.payload};

        case SearchActionTypes.ADD_SORT_FILTER:
            return {
                ...state,
                activeSortFilter: action.payload,
            };
        case SearchActionTypes.ADD_ACTIVE_SEARCH_TAB:
            return {
                ...state,
                activeSearchTab: action.payload.tabId,
                activeSearchFilter: action.payload.activeSearch,
            };
        case SearchActionTypes.SET_SEARCHED_PROPERTY_GEOM: {
            return {
                ...state,
                searchedPropertyGeom: action.payload,
            };
        }
        case SearchActionTypes.SET_URL_SEARCH_PARAMS: {
            return {
                ...state,
                urlSearchParams: action.payload,
            };
        }

        case SearchActionTypes.SET_PROPERTY_SEARCH_TEXT: {
            return {
                ...state,
                propertySearchText: action.payload,
            };
        }
        case SearchActionTypes.SET_PROPERTY_SEARCH_IS_ADRESS_SEARCH: {
            return {
                ...state,
                propertySearchIsAdressSearch: action.payload,
            };
        }
        case SearchActionTypes.SET_PROPERTY_IS_MISSING:
            return {
                ...state,
                propertyGeometryIsMissing: action.payload,
            };
        default:
            return state;
    }
};

export {reducer as searchReducer};

export const searchIsActive = (state: SearchState) => state.isActive;
export const getActiveSortFilter = (state: SearchState) =>
    state.activeSortFilter;
export const getUrlSearchParams = (state: SearchState) => state.urlSearchParams;
