import {Link} from '@mui/material';
import {ColumnDef} from '@tanstack/react-table';
import * as React from 'react';
import {format} from 'date-fns';
import {PlanforholdReport} from '../../../../hooks/planforhold/types';

const baseUrl = window.location.origin;

export default function PlanforholdColumns(
    kundeId: string,
    kommunenummer: string
) {
    return React.useMemo<ColumnDef<PlanforholdReport>[]>(
        () => [
            {
                id: 'planIdforloper',
                header: () => 'PlanId',
                cell: (r) => (
                    <Link
                        key={r.row.id + 'link'}
                        href={`${baseUrl}/${kundeId}/gi?funksjon=VisPlan&planidentifikasjon=${r.row.original?.planIdFraNavigation?.planId}&kommunenummer=${kommunenummer}`}
                        target='_blank'
                        variant='body1'
                        underline='none'
                        color='secondary'
                        fontSize='0.85rem'
                    >
                        {r.row.original?.planIdFraNavigation?.planId
                            ? r.row.original?.planIdFraNavigation?.planId
                            : null}
                    </Link>
                ),
                accessorFn: (r, i) => r.planIdFraNavigation?.planId,
                enableSorting: true,
                size: 50,
            },
            {
                id: 'fraDato',
                cell: (r) => (
                    <>
                        {r.row.original?.planIdFraNavigation.iKraft
                            ? format(
                                  new Date(
                                      r.row.original.planIdFraNavigation.iKraft
                                  ),
                                  'dd.MM.yyyy'
                              )
                            : ''}
                    </>
                ),
                accessorFn: (r, i) =>
                    r.planIdFraNavigation.iKraft &&
                    new Date(r.planIdFraNavigation.iKraft),
                header: () => 'Ikraft forløper ',
                enableSorting: true,
                sortingFn: 'datetime',
                size: 50,
            },

            {
                id: 'planNavn',
                accessorFn: (r) => r.planIdFraNavigation.planNavn,
                header: () => 'Plannavn',
                enableSorting: true,
                size: 100,
            },
            {
                id: 'planStatus',
                accessorFn: (r) => r.planIdFraNavigation?.planStatus,
                header: () => 'Planstatus',
                enableSorting: true,
                size: 50,
            },
            {
                id: 'planType',
                accessorFn: (r) => r.planIdFraNavigation?.planType,
                header: () => 'Plantype',
                enableSorting: true,
                size: 50,
            },
            {
                id: 'Planforhold',
                accessorFn: (r) => r.planforholdType?.beskrivelseEtterkommer,
                cell: (r) => (
                    <>
                        {
                            r.row.original?.planforholdType
                                ?.beskrivelseEtterkommer
                        }
                        :{' '}
                        {
                            <Link
                                key={r.row.id + 'link'}
                                href={`${baseUrl}/${kundeId}/gi?funksjon=VisPlan&planidentifikasjon=${r.row.original?.planIdTilNavigation?.planId}&kommunenummer=${kommunenummer}`}
                                target='_blank'
                                variant='body1'
                                underline='none'
                                color='secondary'
                                fontSize='0.9rem'
                            >
                                {r.row.original?.planIdTilNavigation?.planId
                                    ? r.row.original?.planIdTilNavigation
                                          ?.planId
                                    : null}
                            </Link>
                        }
                    </>
                ),
                header: () => 'Planforhold',
                enableSorting: true,
                size: 50,
            },
            {
                id: 'tilDato',
                cell: (r) => (
                    <>
                        {r.row.original?.planIdTilNavigation.iKraft
                            ? format(
                                  new Date(
                                      r.row.original.planIdTilNavigation.iKraft
                                  ),
                                  'dd.MM.yyyy'
                              )
                            : ''}
                    </>
                ),
                accessorFn: (r, i) =>
                    r.planIdTilNavigation.iKraft &&
                    new Date(r.planIdTilNavigation.iKraft),
                header: () => 'Ikraft etterkommer',
                enableSorting: true,
                sortingFn: 'datetime',
                size: 50,
            },
        ],
        []
    );
}
