import {Feature, MultiPolygon, Polygon} from 'geojson';

export type OrderBy =
    | 'planid'
    | 'planstatus'
    | 'plantype'
    | 'plannavn'
    | 'ikrafttredelsesdato'
    | 'sistbehandlet';
export type SearchTabType = 'name_id' | 'property';
export type Order = 'asc' | 'desc';
export interface SearchState {
    activeSortFilter: SortFilter | null;
    isActive: boolean;
    activeSearchTab: number;
    activeSearchFilter: SearchTabType;
    searchedPropertyGeom: undefined | Feature<MultiPolygon> | Feature<Polygon>;
    urlSearchParams?: UrlSearchParams;
    propertyGeometryIsMissing?: boolean;
    propertySearchText?: string;
    propertySearchIsAdressSearch?: boolean;
}

export type SortFilter = {order: Order; orderBy: OrderBy};

export interface UrlSearchParams {
    term?: string;
    knr?: string;
    gnr?: number;
    bnr?: number;
    fnr?: number;
    snr?: number;
    teigid?: number;
    lnglat?: string;
    planTypeId?: number | string;
    planStatusId?: number | string;
    page?: number;
    take?: number;
    order?: 'asc' | 'desc';
    orderBy?: OrderBy;
    saksnummer?: string;
    fromDate?: Date;
    toDate?: Date;
    behandlingstypeId?: string;
    opprinneligAdministrativEnhet?: string;
}

export enum SearchActionTypes {
    SET_URL_SEARCH_PARAMS = '@@planregister/arealplaner/SET_URL_SEARCH_PARAMS',
    SEARCH_IS_ACTIVE = '@@planregister/arealplaner/SEARCH_IS_ACTIVE',
    REPLACE_SEARCH_FILTERS = '@@planregister/arealplaner/REPLACE_SEARCH_FILTERS',
    ADD_SORT_FILTER = '@@planregister/arealplaner/ADD_SORT_FILTER',
    ADD_ACTIVE_SEARCH_TAB = '@@planregister/arealplaner/ADD_ACTIVE_SEARCH_TAB',
    SET_SEARCHED_PROPERTY_GEOM = '@@planregister/map/SET_SEARCHED_PROPERTY_GEOM',
    SET_PROPERTY_IS_MISSING = '@@planregister/map/SET_PROPSERTY_IS_MISSING',
    SET_PROPERTY_SEARCH_TEXT = '@@planregister/arealplaner/SET_PROPERTY_SEARCH_TEXT',
    SET_PROPERTY_SEARCH_IS_ADRESS_SEARCH = '@@planregister/arealplaner/SET_PROPERTY_SEARCH_IS_ADRESS_SEARCH',
}
