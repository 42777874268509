import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {getSelectedCustomer, ApplicationState} from '../../store';
import {select as selectCustomer} from '../../store/kunder/actions';
import CustomerRoutes from './CustomerRoutes';

const mapStateToProps = (state: ApplicationState) => ({
    selectedCustomer: getSelectedCustomer(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    selectCustomer: (id: string) => dispatch(selectCustomer(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRoutes);
