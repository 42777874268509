import {PaletteOptions} from '@mui/material/styles';
import {ToiColors, toiColors} from '@norkart/toi-deprecated-colors';

type ToiColorScale = keyof ToiColors[keyof ToiColors];

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
  export interface PaletteColor extends Record<ToiColorScale, string> {}

  export interface Palette extends ToiColors {
    error: PaletteColor;
    warning: PaletteColor;
    info: PaletteColor;
    success: PaletteColor;
    neutral: PaletteColor;
  }

  // allow configuration using `createTheme`
  export interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

export const toiPalette: PaletteOptions = {
  primary: {
    main: toiColors.green[100],
    ...toiColors.green,
  },
  secondary: {
    main: toiColors.white,
    contrastText: toiColors.green[100],
    ...toiColors,
  },
  error: {
    main: toiColors.red[100],
    contrastText: toiColors.red[30],
    ...toiColors.red,
  },
  warning: {
    main: toiColors.orange[100],
    contrastText: toiColors.orange[30],
    ...toiColors.orange,
  },
  info: {
    main: toiColors.blue[100],
    contrastText: toiColors.blue[30],
    ...toiColors.blue,
  },
  success: {
    main: toiColors.green[100],
    contrastText: toiColors.green[30],
    ...toiColors.green,
  },
  neutral: {
    main: toiColors.white,
    contrastText: toiColors.black[100],
  },
  common: {
    black: toiColors.black[100],
    white: toiColors.white,
  },
  ...toiColors,
};
